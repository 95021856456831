import { Divider } from "@material-ui/core";
import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

function DstDetailPrint1({ findDst }) {
  console.log("The find dst data for print:::", findDst?.yields);
  let hasCommercial = false;
  let properties = findDst?.property;
  for (let i = 0; i < properties?.length; i++) {
    if (properties[i].propertyTypeofDST === "Commercial") {
      properties[i].state = true;
      hasCommercial = true;
      break; // Assuming you want to stop after finding the first occurrence
    }
  }
  const sum =
    +findDst?.yields?.year1Yield +
    +findDst?.yields?.year2Yield +
    +findDst?.yields?.year3Yield +
    +findDst?.yields?.year4Yield +
    +findDst?.yields?.year5Yield +
    +findDst?.yields?.year6Yield +
    +findDst?.yields?.year7Yield +
    +findDst?.yields?.year8Yield +
    +findDst?.yields?.year9Yield +
    +findDst?.yields?.year10Yield;
  // const averageCount = Object.keys(findDst?.yields).filter((emp) => emp !== "").length
  const average = sum / 10;

  let arr;
  let max;
  let min;
  if (findDst && findDst.yields) {
    arr = Object.values(findDst.yields);
  } else {
    return;
  }

  if (arr && arr.length > 0) {
    max = Math.max(...arr);
    min = Math.min(...arr);
  } else {
    return;
  }
  console.log(max, "avg");

  const DSCR =
    +findDst?.basicInfo?.year1NetOperatingIncome /
    +(
      +findDst?.basicInfo?.offeringDebt * +(+findDst?.basicInfo?.loanRate / 100)
    );

  const totalOfAllExpensesAbovePurchasePrice =
    +findDst?.proceeds?.OOCosts +
    +findDst?.proceeds?.brokerDealerCommissions +
    +findDst?.proceeds?.wholesalingCosts +
    (+findDst?.proceeds?.transactionCommission +
      +findDst?.proceeds?.financingFee +
      +findDst?.proceeds?.otherFees) +
    +findDst?.proceeds?.thirdPartyClosingCosts;

  const newDate = new Date(findDst?.basicInfo?.concordeApprovalDate);
  const formattedDate = newDate.toLocaleDateString("en-US");

  const sortedKeys = Object.keys(findDst?.yields).sort((a, b) => {
    const yearA = parseInt(a.match(/\d+/)[0], 10);
    const yearB = parseInt(b.match(/\d+/)[0], 10);
    return yearA - yearB;
  });
  const sortedYears = sortedKeys.map((key) =>
    key.replace("year", "Y").replace("Yield", "")
  );
  const sortedYields = sortedKeys.map((key) => findDst?.yields[key]);
  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          fontSize: "0.8rem",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h4 style={{ margin: "0px" }}>
            {findDst?.basicInfo?.fullLegalNameOfDst}
          </h4>
          <p style={{ margin: "0px" }}>
            {findDst?.basicInfo?.nameOfTheSponsor || null}
          </p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p style={{ margin: "0px" }}>
            {findDst?.basicInfo?.generalLocationRelativeToMajorMarket || null}
          </p>
          <p style={{ margin: "0px" }}>
            {findDst?.basicInfo?.dstPropertiesStyle || null}
          </p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            gap: "2rem",
          }}
        >
          <p style={{ margin: "0px" }}>
            {findDst?.basicInfo?.dstPropertiesClass || null}
          </p>
          <p style={{ margin: "0px" }}>
            {findDst?.basicInfo?.dstPopertiesSector || null}
          </p>
        </div>
      </div>
      <div
        style={{
          border: "1px solid black",
          borderRadius: "1px",
          fontSize: "0.8rem",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "5px",
          }}
        >
          <Grid
            container
            spacing={0}
            style={{ padding: "0.2rem 0.2rem" }}
            columnGap={6}
          >
            <Grid item xs={4}>
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <h4 style={{ margin: "0px" }}>First-year yield:</h4>
                  <p style={{ margin: "0px" }}>{`${
                    findDst?.yields?.year1Yield || 0
                  }%`}</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <h4 style={{ margin: "0px" }}>Average yield:</h4>
                  <p style={{ margin: "0px" }}>{`${
                    average.toFixed(2) || 0
                  }%`}</p>
                </div>
                <div style={{ marginLeft: "3rem" }}>
                  <ul style={{ padding: "0px" }}>
                    <li>Highest: {`${max || 0}%`}</li>
                    <li>Lowest: {`${min || 0}%`}</li>
                  </ul>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <h4 style={{ margin: "0px" }}>Offering Leverage:</h4>
                  <p style={{ margin: "0px" }}>{`${
                    (
                      (+findDst?.basicInfo?.offeringDebt /
                        (+findDst?.basicInfo?.offeringDebt +
                          +findDst?.basicInfo?.offeringEquity)) *
                      100
                    ).toFixed(2) || 0
                  }%`}</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <h4 style={{ margin: "0px" }}>Purchase price:</h4>
                  <p style={{ margin: "0px" }}>{`$ ${
                    Number(findDst?.basicInfo?.purchasePrice).toLocaleString(
                      "en-US"
                    ) || 0
                  }`}</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <h4 style={{ margin: "0px" }}>Price per unit:</h4>
                  <p style={{ margin: "0px" }}>{`$ ${
                    Number(
                      (
                        findDst?.basicInfo?.purchasePrice /
                        findDst?.basicInfo?.totalUnitsInPortfolio
                      ).toFixed()
                    ).toLocaleString("en-US") || 0
                  }`}</p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <h4 style={{ margin: "0px" }}>Ave. monthly rent:</h4>
                <p style={{ margin: "0px" }}>{`$ ${
                  Number(
                    (
                      findDst?.basicInfo?.year1EffectiveGrossRevenue /
                      findDst?.basicInfo?.totalUnitsInPortfolio /
                      12
                    ).toFixed()
                  ).toLocaleString("en-US") || 0
                }`}</p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <h4 style={{ margin: "0px" }}>Appraised value:</h4>
                <p style={{ margin: "0px" }}>{`$ ${
                  Number(findDst?.basicInfo?.appraisedValue).toLocaleString(
                    "en-US"
                  ) || 0
                }`}</p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <h4 style={{ margin: "0px" }}>Year 1 NOI:</h4>
                <p style={{ margin: "0px" }}>{`$${
                  Number(
                    findDst?.basicInfo?.year1NetOperatingIncome
                  ).toLocaleString("en-US") || 0
                }`}</p>
              </div>
            </Grid>
            <Grid item xs={3}>
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <h4 style={{ margin: "0px" }}>Equity:</h4>
                  <p style={{ margin: "0px" }}>{`$ ${
                    Number(findDst?.basicInfo?.offeringEquity).toLocaleString(
                      "en-US"
                    ) || 0
                  }`}</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <h4 style={{ margin: "0px" }}>Debt:</h4>
                  <p style={{ margin: "0px" }}>{`$ ${
                    Number(findDst?.basicInfo?.offeringDebt).toLocaleString(
                      "en-US"
                    ) || 0
                  }`}</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <h4 style={{ margin: "0px" }}>Total:</h4>
                  <p style={{ margin: "0px" }}>{`$ ${
                    Number(
                      +findDst?.basicInfo?.offeringDebt +
                        +findDst?.basicInfo?.offeringEquity
                    ).toLocaleString("en-US") || 0
                  }`}</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "2.5rem",
                  }}
                >
                  <h4 style={{ margin: "0px" }}>All reserves:</h4>
                  <p style={{ margin: "0px" }}>{`$ ${
                    Number(findDst?.proceeds?.totalDstReserves).toLocaleString(
                      "en-US"
                    ) || 0
                  }`}</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <h4 style={{ margin: "0px" }}>Loan term:</h4>
                  <p style={{ margin: "0px" }}>{`${
                    findDst?.basicInfo?.loanTerm || 0
                  }`}</p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <h4 style={{ margin: "0px" }}>Fixed / Variable:</h4>
                <p style={{ margin: "0px" }}>
                  {findDst?.basicInfo?.fixedVariable || "None"}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <h4 style={{ margin: "0px" }}>Loan rate:</h4>
                <p style={{ margin: "0px" }}>{`${
                  findDst?.basicInfo?.loanRate || 0
                }%`}</p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <h4 style={{ margin: "0px" }}>Lender:</h4>
                <p style={{ margin: "0px" }}>{`${
                  findDst?.basicInfo?.lender || "None"
                }`}</p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <h4 style={{ margin: "0px" }}>DSCR:</h4>
                <p style={{ margin: "0px" }}>{+DSCR.toFixed(2) || 0}</p>
              </div>
            </Grid>
            <Grid item xs={3}>
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <h4 style={{ margin: "0px" }}>Number Of Properties:</h4>
                  <p style={{ margin: "0px" }}>
                    {findDst?.basicInfo?.numberOfProperties || null}
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <h4 style={{ margin: "0px" }}>Total units:</h4>
                  <p style={{ margin: "0px" }}>
                    {Number(
                      findDst?.basicInfo?.totalUnitsInPortfolio
                    ).toLocaleString("en-US") || 0}
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <h4 style={{ margin: "0px" }}>Built:</h4>
                  <p style={{ margin: "0px" }}>
                    {findDst?.basicInfo?.yearsPropertiesWereBuilt || 0}
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <h4 style={{ margin: "0px" }}>States:</h4>
                  <p style={{ margin: "0px" }}>
                    {findDst?.basicInfo?.states
                      ? findDst.basicInfo.states.join(", ")
                      : "None"}
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "2rem",
                  }}
                >
                  <h4 style={{ margin: "0px" }}>Approved:</h4>
                  <p style={{ margin: "0px" }}>{formattedDate || null}</p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <h4 style={{ margin: "0px" }}>I/O Period:</h4>
                <p style={{ margin: "0px" }}>{`${
                  findDst?.basicInfo?.interestOnlyPeriod || 0
                }`}</p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <h4 style={{ margin: "0px" }}>Total occupancy:</h4>
                <p style={{ margin: "0px" }}>{`${
                  findDst?.basicInfo?.currentDstOccupancy || 0
                }%`}</p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <h4 style={{ margin: "0px" }}>Ave. Lease duration:</h4>
                <p style={{ margin: "0px" }}>{`${
                  findDst?.otherInfo?.averageRemainingLeaseDuration || 0
                }`}</p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <h4 style={{ margin: "0px" }}>UPREIT:</h4>
                {findDst?.basicInfo?.upreit || null}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <h4 style={{ margin: "0px" }}>Min. (1031):</h4>
                <p style={{ margin: "0px" }}>
                  {`$ ${Number(
                    findDst?.basicInfo?.minimum1031Investment
                  ).toLocaleString("en-US")}` || 0}
                </p>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <div
        style={{
          border: "1px solid black",
          borderRadius: "1px",
          margin: "0.5rem 0rem",
          fontSize: "0.8rem",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            margin: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <h4 style={{ margin: "0px" }}>PPM Risks:</h4>
            <p style={{ margin: "0px" }}>
              {findDst?.otherInfo?.pppRiskFactorsPage || null}
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <h4 style={{ margin: "0px" }}>PPM Use of Proceeds:</h4>
            <p style={{ margin: "0px" }}>
              {findDst?.otherInfo?.useOfProceedsPage || null}
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <h4 style={{ margin: "0px" }}>PPM Assumptions:</h4>
            <p style={{ margin: "0px" }}>
              {findDst?.otherInfo?.assumptionsPage || null}
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <h4 style={{ margin: "0px" }}>Cash Flow:</h4>
            <p style={{ margin: "0px" }}>
              {findDst?.otherInfo?.cashFlowPage || null}
            </p>
          </div>
        </div>
      </div>
      <div
        style={{
          border: "1px solid black",
          borderRadius: "1px",
          margin: "0.5rem 0rem",
          fontSize: "0.8rem",
        }}
      >
        <Grid container spacing={6} style={{ padding: "0.2rem 0.2rem" }}>
          <Grid item xs={5}>
            <div>
              <h4 style={{ margin: "0px" }}>
                Offering strengths/opportunities:
              </h4>
              <p style={{ margin: "0px" }}>
                {findDst?.otherInfo?.dstOfferingStrengthAndOppertunities}
              </p>
            </div>
          </Grid>
          <Grid item xs={5}>
            <div>
              <h4 style={{ margin: "0px" }}>Offering weaknesses/threats:</h4>
              <p style={{ margin: "0px" }}>
                {findDst?.otherInfo?.dstOfferingWeaknessesAndThreats}
              </p>
            </div>
          </Grid>
        </Grid>
      </div>
      <div
        style={{
          border: "1px solid black",
          borderRadius: "1px",
          marginTop: "0.5rem",
          fontSize: "0.8rem",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            margin: "10px",
            gap: "1rem",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "0.3rem",
            }}
          >
            <h4 style={{ margin: "0px", textAlign: "center" }}>
              Total of all expenses above purchase price
            </h4>
            <p style={{ margin: "0px" }}>
              {`$ ${
                Number(+totalOfAllExpensesAbovePurchasePrice).toLocaleString(
                  "en-US"
                ) || 0
              }`}
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "0.3rem",
            }}
          >
            <h4 style={{ margin: "0px", textAlign: "center" }}>
              Total load % on equity*
            </h4>
            <p style={{ margin: "0px" }}>
              {`${
                (
                  (+totalOfAllExpensesAbovePurchasePrice /
                    +findDst?.basicInfo?.offeringEquity) *
                  100
                ).toFixed(2) || 0
              }%`}
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "0.3rem",
            }}
          >
            <h4 style={{ margin: "0px", textAlign: "center" }}>
              Total load % on purchase*
            </h4>
            <p style={{ margin: "0px" }}>
              {`${(
                (+totalOfAllExpensesAbovePurchasePrice /
                  +findDst?.basicInfo?.purchasePrice) *
                100
              ).toFixed(2)}%` || 0}
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "0.3rem",
            }}
          >
            <h4 style={{ margin: "0px", textAlign: "center" }}>
              5-yr breakeven**
            </h4>
            <p style={{ margin: "0px" }}>
              {`${
                (
                  (((+totalOfAllExpensesAbovePurchasePrice +
                    +findDst?.basicInfo?.purchasePrice) /
                    0.96 -
                    +findDst?.basicInfo?.purchasePrice) /
                    5 /
                    +findDst?.basicInfo?.purchasePrice) *
                  100
                ).toFixed(2) || 0
              }%`}
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "0.3rem",
            }}
          >
            <h4 style={{ margin: "0px", textAlign: "center" }}>
              7-yr breakeven**
            </h4>
            <p style={{ margin: "0px" }}>
              {`${
                (
                  (((+totalOfAllExpensesAbovePurchasePrice +
                    +findDst?.basicInfo?.purchasePrice) /
                    0.96 -
                    +findDst?.basicInfo?.purchasePrice) /
                    7 /
                    +findDst?.basicInfo?.purchasePrice) *
                  100
                ).toFixed(2) || 0
              }%`}
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "0.3rem",
            }}
          >
            <h4 style={{ margin: "0px", textAlign: "center" }}>
              10-yr breakeven**
            </h4>
            <p style={{ margin: "0px" }}>
              {`${
                (
                  (((+totalOfAllExpensesAbovePurchasePrice +
                    +findDst?.basicInfo?.purchasePrice) /
                    0.96 -
                    +findDst?.basicInfo?.purchasePrice) /
                    10 /
                    +findDst?.basicInfo?.purchasePrice) *
                  100
                ).toFixed(2) || 0
              }%`}
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "0.3rem",
            }}
          >
            <h4 style={{ margin: "0px", textAlign: "center" }}>
              Third party closing costs
            </h4>
            <p style={{ margin: "0px" }}>
              {`$ ${
                Number(
                  +findDst?.proceeds?.thirdPartyClosingCosts
                ).toLocaleString("en-US") || 0
              }`}
            </p>
          </div>
        </div>
      </div>
      <div
        style={{
          border: "1px solid black",
          borderRadius: "1px",
          fontSize: "0.8rem",
        }}
      >
        <p style={{ margin: "0px" }}>*does not include reserves</p>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p style={{ margin: "0px" }}>
            **assumes 4% dispo costs and no remaining reserves; excludes
            distributions
          </p>
          <p style={{ margin: "0px" }}>
            <b>Reserves </b>
            <span
              style={{
                margin: "0rem 2rem",
                borderRight: "1px solid black",
                paddingRight: "0.5rem",
              }}
            >
              {`$${
                Number(findDst?.proceeds?.totalDstReserves).toLocaleString(
                  "en-US"
                ) || 0
              }`}
            </span>
            <b>% of Equity </b>
            <span
              style={{
                margin: "0rem 2rem",
                borderRight: "1px solid black",
                paddingRight: "0.5rem",
              }}
            >
              {`${
                (
                  (+findDst?.proceeds?.totalDstReserves /
                    +findDst?.basicInfo?.offeringEquity) *
                  100
                ).toFixed(2) || 0
              }%`}
            </span>
            <b>% of PP </b>
            <span style={{ margin: "0rem 2rem" }}>
              {`${
                (
                  (+findDst?.proceeds?.totalDstReserves /
                    +findDst?.basicInfo?.purchasePrice) *
                  100
                ).toFixed(2) || 0
              }%`}
            </span>
          </p>
        </div>
        <Grid
          container
          spacing={0}
          style={{ margin: "1rem 0", fontSize: "0.8rem" }}
        >
          <Grid item xs={1.3}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "0.3rem",
              }}
            >
              <h4 style={{ margin: "0px", textAlign: "center" }}>
                synd. costs
              </h4>
              <p style={{ margin: "0px" }}>O&O</p>
              <p style={{ margin: "0px" }}>BD Comm / DD</p>
              <p style={{ margin: "0px" }}>MBD</p>
              <div style={{ width: "100%" }}>
                <p
                  style={{
                    margin: "0px",
                    borderTop: "1px solid #cdcdcd",
                    textAlign: "center",
                    paddingTop: "0.5rem",
                  }}
                >
                  Total
                </p>
              </div>
            </div>
          </Grid>
          <Grid item xs={1.25}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "0.3rem",
              }}
            >
              <h4 style={{ margin: "0px", textAlign: "center" }}>
                % on equity
              </h4>
              <p style={{ margin: "0px" }}>
                {`${
                  (
                    +(
                      +findDst?.proceeds?.OOCosts /
                      +findDst?.basicInfo?.offeringEquity
                    ) * 100
                  ).toFixed(2) || 0
                }%`}{" "}
              </p>
              <p style={{ margin: "0px" }}>
                {`${
                  (
                    (+findDst?.proceeds?.brokerDealerCommissions /
                      +findDst?.basicInfo?.offeringEquity) *
                    100
                  ).toFixed(2) || 0
                }%`}
              </p>
              <p style={{ margin: "0px" }}>
                {`${
                  (
                    (+findDst?.proceeds?.wholesalingCosts /
                      +findDst?.basicInfo?.offeringEquity) *
                    100
                  ).toFixed(2) || 0
                }%`}
              </p>
              <div style={{ width: "100%" }}>
                <p
                  style={{
                    margin: "0px",
                    borderTop: "1px solid #cdcdcd",
                    textAlign: "center",
                    paddingTop: "0.5rem",
                  }}
                >
                  {`${
                    (
                      ((+findDst?.proceeds?.OOCosts +
                        +findDst?.proceeds?.brokerDealerCommissions +
                        +findDst?.proceeds?.wholesalingCosts) /
                        +findDst?.basicInfo?.offeringEquity) *
                      100
                    ).toFixed(2) || 0
                  }%`}
                </p>
              </div>
            </div>
          </Grid>
          <Grid item xs={1.25}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "0.3rem",
              }}
            >
              <h4 style={{ margin: "0px", textAlign: "center" }}>% on purch</h4>
              <p style={{ margin: "0px" }}>
                {`${
                  (
                    (+findDst?.proceeds?.OOCosts /
                      +findDst?.basicInfo?.purchasePrice) *
                    100
                  ).toFixed(2) || 0
                }%`}
              </p>
              <p style={{ margin: "0px" }}>
                {`${
                  (
                    (+findDst?.proceeds?.brokerDealerCommissions /
                      +findDst?.basicInfo?.purchasePrice) *
                    100
                  ).toFixed(2) || 0
                }%`}
              </p>
              <p style={{ margin: "0px" }}>
                {`${
                  (
                    (+findDst?.proceeds?.wholesalingCosts /
                      +findDst?.basicInfo?.purchasePrice) *
                    100
                  ).toFixed(2) || 0
                }%`}
              </p>
              <div style={{ width: "100%" }}>
                <p
                  style={{
                    margin: "0px",
                    borderTop: "1px solid #cdcdcd",
                    textAlign: "center",
                    paddingTop: "0.5rem",
                  }}
                >
                  {`${
                    (
                      ((+findDst?.proceeds?.OOCosts +
                        +findDst?.proceeds?.brokerDealerCommissions +
                        +findDst?.proceeds?.wholesalingCosts) /
                        +findDst?.basicInfo?.purchasePrice) *
                      100
                    ).toFixed(2) || 0
                  }%`}
                </p>
              </div>
            </div>
          </Grid>
          <Grid item xs={1.25}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "0.3rem",
              }}
            >
              <h4 style={{ margin: "0px", textAlign: "center" }}>% on total</h4>
              <p style={{ margin: "0px" }}>
                {`${
                  (
                    (+findDst?.proceeds?.OOCosts /
                      (+findDst?.basicInfo?.offeringEquity +
                        +findDst?.basicInfo?.offeringDebt)) *
                    100
                  ).toFixed(2) || 0
                }%`}
              </p>
              <p style={{ margin: "0px" }}>
                {`${
                  (
                    (+findDst?.proceeds?.brokerDealerCommissions /
                      (+findDst?.basicInfo?.offeringEquity +
                        +findDst?.basicInfo?.offeringDebt)) *
                    100
                  ).toFixed(2) || 0
                }%`}
              </p>
              <p style={{ margin: "0px" }}>
                {`${
                  (
                    (+findDst?.proceeds?.wholesalingCosts /
                      (+findDst?.basicInfo?.offeringEquity +
                        +findDst?.basicInfo?.offeringDebt)) *
                    100
                  ).toFixed(2) || 0
                }%`}
              </p>
              <div style={{ width: "100%" }}>
                <p
                  style={{
                    margin: "0px",
                    borderTop: "1px solid #cdcdcd",
                    textAlign: "center",
                    paddingTop: "0.5rem",
                  }}
                >
                  {`${
                    (
                      ((+findDst?.proceeds?.OOCosts +
                        +findDst?.proceeds?.brokerDealerCommissions +
                        +findDst?.proceeds?.wholesalingCosts) /
                        (+findDst?.basicInfo?.offeringEquity +
                          +findDst?.basicInfo?.offeringDebt)) *
                      100
                    ).toFixed(2) || 0
                  }%`}
                </p>
              </div>
            </div>
          </Grid>
          <Grid item xs={1.25}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "0.3rem",
              }}
            >
              <h4 style={{ margin: "0px", textAlign: "center" }}>Amount</h4>
              <p style={{ margin: "0px" }}>
                {`$ ${
                  Number(+findDst?.proceeds?.OOCosts).toLocaleString("en-US") ||
                  0
                }`}
              </p>
              <p style={{ margin: "0px" }}>
                {`$ ${
                  Number(
                    +findDst?.proceeds?.brokerDealerCommissions
                  ).toLocaleString("en-US") || 0
                }`}
              </p>
              <p style={{ margin: "0px" }}>
                {`$ ${
                  Number(+findDst?.proceeds?.wholesalingCosts).toLocaleString(
                    "en-US"
                  ) || 0
                }`}
              </p>
              <div style={{ width: "100%" }}>
                <p
                  style={{
                    margin: "0px",
                    borderTop: "1px solid #cdcdcd",
                    textAlign: "center",
                    paddingTop: "0.5rem",
                  }}
                >
                  {`$ ${Number(
                    +findDst?.proceeds?.OOCosts +
                      +findDst?.proceeds?.brokerDealerCommissions +
                      +findDst?.proceeds?.wholesalingCosts
                  ).toLocaleString("en-US")}`}
                </p>
              </div>
            </div>
          </Grid>
          <Grid item xs={1.3}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "0.3rem",
              }}
            >
              <h4 style={{ margin: "0px", textAlign: "center" }}>
                Sponsor fees
              </h4>
              <p style={{ margin: "0px" }}>Acquisition</p>
              <p style={{ margin: "0px" }}>Closing/Fin.</p>
              <p style={{ margin: "0px" }}>Other</p>
              <div style={{ width: "100%" }}>
                <p
                  style={{
                    margin: "0px",
                    borderTop: "1px solid #cdcdcd",
                    textAlign: "center",
                    paddingTop: "0.5rem",
                  }}
                >
                  Total
                </p>
              </div>
            </div>
          </Grid>
          <Grid item xs={1.1}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "0.3rem",
              }}
            >
              <h4 style={{ margin: "0px", textAlign: "center" }}>
                % on equity
              </h4>
              <p style={{ margin: "0px" }}>{`${
                (
                  (+findDst?.proceeds?.transactionCommission /
                    +findDst?.basicInfo?.offeringEquity) *
                  100
                ).toFixed(2) || 0
              }%`}</p>
              <p style={{ margin: "0px" }}>{`${
                (
                  (+findDst?.proceeds?.financingFee /
                    +findDst?.basicInfo?.offeringEquity) *
                  100
                ).toFixed(2) || 0
              }%`}</p>
              <p style={{ margin: "0px" }}>{`${
                (
                  (+findDst?.proceeds?.otherFees /
                    +findDst?.basicInfo?.offeringEquity) *
                  100
                ).toFixed(2) || 0
              }%`}</p>
              <div style={{ width: "100%" }}>
                <p
                  style={{
                    margin: "0px",
                    borderTop: "1px solid #cdcdcd",
                    textAlign: "center",
                    paddingTop: "0.5rem",
                  }}
                >
                  {`${
                    (
                      ((+findDst?.proceeds?.transactionCommission +
                        +findDst?.proceeds?.financingFee +
                        +findDst?.proceeds?.otherFees) /
                        +findDst?.basicInfo?.offeringEquity) *
                      100
                    ).toFixed(2) || 0
                  }%`}
                </p>
              </div>
            </div>
          </Grid>
          <Grid item xs={1.1}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "0.3rem",
              }}
            >
              <h4 style={{ margin: "0px", textAlign: "center" }}>% on purch</h4>
              <p style={{ margin: "0px" }}>{`${
                (
                  (+findDst?.proceeds?.transactionCommission /
                    +findDst?.basicInfo?.purchasePrice) *
                  100
                ).toFixed(2) || 0
              }%`}</p>
              <p style={{ margin: "0px" }}>{`${
                (
                  (+findDst?.proceeds?.financingFee /
                    +findDst?.basicInfo?.purchasePrice) *
                  100
                ).toFixed(2) || 0
              }%`}</p>
              <p style={{ margin: "0px" }}>{`${
                (
                  (+findDst?.proceeds?.otherFees /
                    +findDst?.basicInfo?.purchasePrice) *
                  100
                ).toFixed(2) || 0
              }%`}</p>
              <div style={{ width: "100%" }}>
                <p
                  style={{
                    margin: "0px",
                    borderTop: "1px solid #cdcdcd",
                    textAlign: "center",
                    paddingTop: "0.5rem",
                  }}
                >
                  {`${
                    (
                      ((+findDst?.proceeds?.transactionCommission +
                        +findDst?.proceeds?.financingFee +
                        +findDst?.proceeds?.otherFees) /
                        +findDst?.basicInfo?.purchasePrice) *
                      100
                    ).toFixed(2) || 0
                  }%`}
                </p>
              </div>
            </div>
          </Grid>
          <Grid item xs={1.1}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "0.3rem",
              }}
            >
              <h4 style={{ margin: "0px", textAlign: "center" }}>% on total</h4>
              <p style={{ margin: "0px" }}>{`${
                (
                  (+findDst?.proceeds?.transactionCommission /
                    (+findDst?.basicInfo?.offeringEquity +
                      +findDst?.basicInfo?.offeringDebt)) *
                  100
                ).toFixed(2) || 0
              }%`}</p>
              <p style={{ margin: "0px" }}>{`${
                (
                  (+findDst?.proceeds?.financingFee /
                    (+findDst?.basicInfo?.offeringEquity +
                      +findDst?.basicInfo?.offeringDebt)) *
                  100
                ).toFixed(2) || 0
              }%`}</p>
              <p style={{ margin: "0px" }}>{`${
                (
                  (+findDst?.proceeds?.otherFees /
                    (+findDst?.basicInfo?.offeringEquity +
                      +findDst?.basicInfo?.offeringDebt)) *
                  100
                ).toFixed(2) || 0
              }%`}</p>
              <div style={{ width: "100%" }}>
                <p
                  style={{
                    margin: "0px",
                    borderTop: "1px solid #cdcdcd",
                    textAlign: "center",
                    paddingTop: "0.5rem",
                  }}
                >
                  {`${
                    (
                      ((+findDst?.proceeds?.transactionCommission +
                        +findDst?.proceeds?.financingFee +
                        +findDst?.proceeds?.otherFees) /
                        (+findDst?.basicInfo?.offeringEquity +
                          +findDst?.basicInfo?.offeringDebt)) *
                      100
                    ).toFixed(2) || 0
                  }%`}
                </p>
              </div>
            </div>
          </Grid>
          <Grid item xs={1.1}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "0.3rem",
              }}
            >
              <h4 style={{ margin: "0px", textAlign: "center" }}>Amount</h4>
              <p style={{ margin: "0px" }}>{`$ ${
                Number(
                  +findDst?.proceeds?.transactionCommission
                ).toLocaleString("en-US") || 0
              }`}</p>
              <p style={{ margin: "0px" }}>{`$ ${
                Number(+findDst?.proceeds?.financingFee).toLocaleString(
                  "en-US"
                ) || 0
              }`}</p>
              <p style={{ margin: "0px" }}>{`$ ${
                Number(+findDst?.proceeds?.otherFees).toLocaleString("en-US") ||
                0
              }`}</p>
              <div style={{ width: "100%" }}>
                <p
                  style={{
                    margin: "0px",
                    borderTop: "1px solid #cdcdcd",
                    textAlign: "center",
                    paddingTop: "0.5rem",
                  }}
                >
                  {`$ ${Number(
                    +findDst?.proceeds?.transactionCommission +
                      +findDst?.proceeds?.financingFee +
                      +findDst?.proceeds?.otherFees
                  ).toLocaleString("en-US")}`}
                </p>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div
        style={{
          border: "1px solid black",
          borderRadius: "1px",
          margin: "0.2rem 0rem",
          fontSize: "0.8rem",
        }}
      >
        <div style={{ padding: "5px" }}>
          <h4 style={{ margin: "0px" }}>Other General Offering Notes:</h4>
          <p>{findDst?.otherInfo?.generalNotes}</p>
        </div>
      </div>
      <div>
        <h5 style={{ margin: "2px" }}>Projected Cash Flow from PPM</h5>
      </div>
      <table
        border="1"
        cellPadding="5"
        style={{
          borderCollapse: "collapse",
          textAlign: "center",
          width: "100%",
          fontSize: "0.8rem",
        }}
      >
        <thead>
          <tr>
            {sortedYears?.map((year, index) => (
              <th key={index} style={{ margin: "0px", fontSize: "0.8rem" }}>
                {year}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            {sortedYields?.map((yieldValue, index) => (
              <td key={index}>{`${yieldValue}` || "0"}%</td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default DstDetailPrint1;
