import React, { useState, useRef } from "react";
import {
  Grid,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import ReactToPrint from "react-to-print";
import { Button } from "@material-ui/core";
import { alpha, makeStyles, styled } from "@material-ui/core/styles";
import {
  LandscapeOrientation,
  PortraitOrientation,
} from "../../utills/landscapeOrientation";
import { pink } from "@mui/material/colors";

const useStyles = makeStyles({
  customTable: {
    "& .MuiTableCell-sizeSmall": {
      padding: "10px 0px 10px 16px",
    },
  },
});

const PinkSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: pink[600],
    "&:hover": {
      backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: pink[600],
  },
}));

const containerStyle = {
  fontFamily: "'Arial', sans-serif",
  fontSize: "0.75rem",
  color: "#000",
  margin: "0rem 1rem",
  marginTop: "3rem",
};

const headerStyle = {
  fontWeight: "bold",
};

const rowStyle = {
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "8px",
};

const noteStyle = {
  fontSize: "10px",
  marginTop: "15px",
};

const EquityCalcultorPrint = () => {
  const classes = useStyles();
  const componentRef = useRef();
  const { state } = useLocation();
  const tableRows = state?.tableRows;
  const otherInfo = state?.otherInfo;
  const [portraitLayout, setPortraitLayout] = useState(false);

  const cleanNumeric = (value) => {
    if (typeof value !== "string") {
      value = String(value);
    }
    return parseFloat(value.replace(/[^0-9.]/g, ""));
  };

  let statesArray = [];
  const rows = tableRows?.map((row) => {
    let stateValue = Array.isArray(row.state)
      ? row.state.join(", ")
      : row.state;
    if (stateValue && stateValue !== "N/A") {
      let splitStates = stateValue.split(",").map((state) => state.trim());
      statesArray.push(...splitStates);
    }

    return {
      name: row.name,
      equity: cleanNumeric(row.equity),
      debt: Math.round(cleanNumeric(row.debt)),
      total: Math.round(cleanNumeric(row.total)),
      improvementRatio: row.improvementRatio,
      yield: cleanNumeric(row.yield).toFixed(2),
      property: row.property,
      annualDepreciation1: cleanNumeric(row.annualDepreciation1),
      annualDepreciation2: cleanNumeric(row.annualDepreciation2),
    };
  });

  const columnTotals = {
    equity: cleanNumeric(
      tableRows?.reduce((total, row) => +total + cleanNumeric(row.equity), 0)
    ),
    debt: cleanNumeric(
      tableRows?.reduce((total, row) => +total + cleanNumeric(row.debt), 0)
    ),
    total: cleanNumeric(
      tableRows?.reduce((total, row) => +total + cleanNumeric(row.total), 0)
    ),
    improvementRatio: tableRows?.reduce(
      (total, row) =>
        total +
        (cleanNumeric(row.improvementRatio) * cleanNumeric(row.equity)) /
        cleanNumeric(otherInfo.equity),
      0
    ),
    yield: parseFloat(
      (
        tableRows?.reduce((total, row) => {
          const yieldValue = cleanNumeric(row.yield);
          const equity = cleanNumeric(row.equity);

          return total + yieldValue * equity;
        }, 0) / cleanNumeric(otherInfo.equity)
      ).toFixed(2)
    ),

    annualDepreciation1: cleanNumeric(
      tableRows?.reduce(
        (total, row) => +total + cleanNumeric(row.annualDepreciation1),
        0
      )
    ),
    annualDepreciation2: parseFloat(
      (
        tableRows?.reduce((total, row) => {
          const dep2Value = cleanNumeric(row.annualDepreciation2);
          const equity = cleanNumeric(row.equity);

          return total + dep2Value * equity;
        }, 0) / cleanNumeric(otherInfo.equity)
      ).toFixed(2)
    ),
  };

  const totalRow = {
    name: "Totals (Weighted):",
    equity: +columnTotals.equity.toFixed(0),
    debt: +columnTotals.debt.toFixed(0),
    total: +columnTotals.total.toFixed(0),
    improvementRatio: +columnTotals.improvementRatio.toFixed(2),
    yield: +columnTotals.yield.toFixed(2),
    annualDepreciation1: +columnTotals.annualDepreciation1.toFixed(0),
    annualDepreciation2: +columnTotals.annualDepreciation2
  };

  rows?.push(totalRow);

  const newDate = new Date(otherInfo?.date);
  const formattedDate = newDate.toLocaleDateString("en-US");

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          columnGap: "1.5rem",
          marginTop: "2rem",
          marginRight: "2rem",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          <Typography
            style={{ color: portraitLayout === false ? "#1565c0" : "#333" }}
          >
            Portrait
          </Typography>
          <PinkSwitch
            checked={!portraitLayout}
            onChange={() => setPortraitLayout(!portraitLayout)}
          />
          <Typography
            style={{
              color: portraitLayout === true ? "#1565c0" : "#333",
            }}
          >
            Landscape
          </Typography>
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {portraitLayout ? <PortraitOrientation /> : <LandscapeOrientation />}
          <ReactToPrint
            trigger={() => (
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#1976d2",
                  color: "#fff",
                }}
              >
                Print
              </Button>
            )}
            content={() => componentRef.current}
          />
        </div>
      </div>
      <div style={{ margin: "0rem 2rem" }} ref={componentRef}>
        <h4>TAX-EQUIVALENT YIELD CALCULATOR</h4>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "2rem",
          }}
        >
          <div>
            <div style={{ display: "flex", gap: "3rem", fontSize: "0.8rem" }}>
              {/* <h5>Client Investment</h5> */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  gap: "0.7rem",
                }}
              >
                <h5 style={{ margin: "0px" }}>Equity</h5>
                <p style={{ margin: "0px", fontSize: "0.7rem" }}>
                  {`$ ${otherInfo?.equity || 0}`}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  gap: "0.7rem",
                }}
              >
                <h5 style={{ margin: "0px" }}>Debt</h5>
                <p style={{ margin: "0px", fontSize: "0.7rem" }}>
                  {`$ ${otherInfo?.debt || 0}`}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  gap: "0.7rem",
                }}
              >
                <h5 style={{ margin: "0px" }}>Total</h5>
                <p style={{ margin: "0px", fontSize: "0.7rem" }}>
                  {`$ ${otherInfo?.total || 0}`}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  gap: "0.7rem",
                }}
              >
                <h5 style={{ margin: "0px" }}>LTV</h5>
                <p style={{ margin: "0px", fontSize: "0.7rem" }}>
                  {`${otherInfo?.ltv || 0}`}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  gap: "0.7rem",
                }}
              >
                <h5 style={{ margin: "0px" }}>Est. Ann Depreciation</h5>
                <p style={{ margin: "0px", fontSize: "0.7rem" }}>
                  {`$ ${otherInfo?.estimatedAnnualDepreciation || 0}`}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  gap: "0.7rem",
                }}
              >
                <h5 style={{ margin: "0px" }}>(%) of new equity investment</h5>
                <p style={{ margin: "0px", fontSize: "0.7rem" }}>
                  {`${otherInfo?.newEquityInvestment || 0}`}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  gap: "0.7rem",
                }}
              >
                <h5 style={{ margin: "0px" }}>Prepared for:</h5>
                <p style={{ margin: "0px", fontSize: "0.7rem" }}>
                  {otherInfo?.client || "-"}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  gap: "0.7rem",
                }}
              >
                <h5 style={{ margin: "0px" }}>Date:</h5>
                <p style={{ margin: "0px", fontSize: "0.7rem" }}>
                  {formattedDate || "-"}
                </p>
              </div>
            </div>
          </div>
          {/* <div> */}
          {/* <div style={{ display: "flex", gap: "3rem", fontSize: "0.9rem" }}> */}
          {/* <h5>Estimated Annual Depreciation</h5> */}
          {/* </div> */}
          {/* </div> */}
          {/* <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "end",
            }}
          >
            
          </div> */}
        </div>
        <div>
          <TableContainer>
            <Table
              sx={{ minWidth: 650 }}
              aria-label="simple table"
              classes={{ root: classes.customTable }}
              size="small"
            >
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontSize: "0.6rem" }}>
                    Proposed Portfolio
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: "0.6rem" }}>
                    Equity
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: "0.6rem" }}>
                    Debt
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: "0.6rem" }}>
                    Total
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: "0.6rem" }}>
                    Est. Improvement Ratio
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: "0.6rem" }}>
                    Yield
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: "0.6rem" }}>
                    Property Type
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: "0.6rem" }}>
                    Annual Depreciation for Non-1031 Investors
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: "0.6rem" }}>
                    Dep. As % of New Investment
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows?.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      style={{
                        fontWeight: index === rows.length - 1 ? "bold" : null,
                        fontSize: "0.6rem",
                        width: "150px",
                      }}
                    >
                      {row.name || "-"}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        color:
                          index === rows.length - 1 ? "#000000de" : "#000000de",
                        fontWeight: index === rows.length - 1 ? "bold" : null,
                        fontSize: "0.6rem",
                        // width:"100px"
                      }}
                    >
                      {`$ ${row.equity.toLocaleString() || 0}`}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        fontWeight: index === rows.length - 1 ? "bold" : null,
                        fontSize: "0.6rem",
                        // width:"100px"
                      }}
                    >
                      {`$ ${Number(row.debt).toLocaleString("en-US") || 0}`}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        fontWeight: index === rows.length - 1 ? "bold" : null,
                        fontSize: "0.6rem",
                        // width:"100px"
                      }}
                    >
                      {`$ ${Number(row.total).toLocaleString("en-US") || 0}`}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        fontWeight: index === rows.length - 1 ? "bold" : null,
                        fontSize: "0.6rem",
                        // width:"150px"
                      }}
                    >
                      {`${row.improvementRatio || 0}%`}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        fontWeight: index === rows.length - 1 ? "bold" : null,
                        fontSize: "0.6rem",
                        // width:"100px"
                      }}
                    >
                      {`${row.yield || 0}%`}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ fontSize: "0.6rem", width: "100px" }}
                    >
                      {row.property}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        fontWeight: index === rows.length - 1 ? "bold" : null,
                        fontSize: "0.6rem",
                      }}
                    >
                      {Number(row.annualDepreciation1).toLocaleString(
                        "en-US"
                      ) || 0}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        fontSize: "0.6rem",
                        fontWeight: index === rows.length - 1 ? "bold" : null,
                      }}
                    >
                      {`${row.annualDepreciation2}%`}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div style={containerStyle}>
          <div style={rowStyle}>
            <span style={headerStyle}>Total Value New Real Estate:</span>
            <span>{`$ ${Number(totalRow?.total)
              .toFixed()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0
              }`}</span>
          </div>
          <div style={rowStyle}>
            <span style={headerStyle}>Total Value Relinquished Property:</span>
            <span>{`$ ${isNaN(Number(otherInfo?.total.replace(/,/g, "")))
              ? 0
              : Number(otherInfo?.total.replace(/,/g, ""))
                .toFixed()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }`}</span>
          </div>
          <div style={rowStyle}>
            <span style={headerStyle}>
              New Basis Acquired in 1031 Exchange:
            </span>
            <span>{`$ ${(cleanNumeric(totalRow?.total) - cleanNumeric(otherInfo?.total))
              .toFixed()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0
              }`}</span>
          </div>
          <div style={rowStyle}>
            <span style={headerStyle}>
              New Basis as % of Total New Investment:
            </span>
            <span>{`${(
              ((cleanNumeric(totalRow?.total) -
                cleanNumeric(otherInfo?.total)) /
                cleanNumeric(totalRow?.total)) *
              100
            ).toFixed(2) || 0
              }%`}</span>
          </div>
          <div style={rowStyle}>
            <span style={headerStyle}>
              New Annual Depreciation (% of equity):
            </span>
            <span>{`${(
              ((cleanNumeric(totalRow?.total) -
                cleanNumeric(otherInfo?.total)) /
                cleanNumeric(totalRow?.total)) *
              cleanNumeric(totalRow?.annualDepreciation2 / 100) *
              100
            ).toFixed(2) || 0
              }%`}</span>
          </div>
          <div style={rowStyle}>
            <span style={headerStyle}>
              Carryover Annual Depreciation (% of equity)³
            </span>
            <span>{`${otherInfo?.newEquityInvestment || 0}`}</span>
          </div>
          <hr style={{ borderColor: "#fff", margin: "0.7rem 0rem" }} />
          <div style={rowStyle}>
            <span style={headerStyle}>
              Total Depreciation Deduction First Year (% of equity):
            </span>
            <span>{`${(
              ((cleanNumeric(totalRow?.total) -
                cleanNumeric(otherInfo?.total)) /
                cleanNumeric(totalRow?.total)) *
              cleanNumeric(totalRow?.annualDepreciation2 / 100) *
              100 +
              cleanNumeric(otherInfo?.newEquityInvestment)
            ).toFixed(2) || 0
              }%`}</span>
          </div>
          <div style={rowStyle}>
            <span style={headerStyle}>Yield First Year:</span>
            <span>{`${(totalRow?.yield).toFixed(2) || 0}%`}</span>
          </div>
          <div style={rowStyle}>
            <span style={headerStyle}>Yield First Year Subject to Tax:</span>
            <span>{`${(
              cleanNumeric(totalRow?.yield) -
              (((cleanNumeric(totalRow?.total) -
                cleanNumeric(otherInfo?.total)) /
                cleanNumeric(totalRow?.total)) *
                cleanNumeric(totalRow?.annualDepreciation2 / 100) *
                100 +
                cleanNumeric(otherInfo?.newEquityInvestment))
            ).toFixed(2) || 0
              }%`}</span>
          </div>
          <div style={rowStyle}>
            <span style={headerStyle}>Amount First Year Subject to Tax:</span>
            <span>{`$ ${!isNaN(
              cleanNumeric(totalRow?.equity) *
              ((cleanNumeric(totalRow?.yield) -
                (((cleanNumeric(totalRow?.total) -
                  cleanNumeric(otherInfo?.total)) /
                  cleanNumeric(totalRow?.total)) *
                  cleanNumeric(totalRow?.annualDepreciation2 / 100) *
                  100 +
                  cleanNumeric(otherInfo?.newEquityInvestment))) /
                100)
            )
              ? (
                cleanNumeric(totalRow?.equity) *
                ((cleanNumeric(totalRow?.yield) -
                  (((cleanNumeric(totalRow?.total) -
                    cleanNumeric(otherInfo?.total)) /
                    cleanNumeric(totalRow?.total)) *
                    cleanNumeric(totalRow?.annualDepreciation2 / 100) *
                    100 +
                    cleanNumeric(otherInfo?.newEquityInvestment))) /
                  100)
              )
                .toFixed(0)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              : 0
              }`}</span>
          </div>
          <div style={rowStyle}>
            <span style={headerStyle}>Net Amount First Year After Taxes:</span>
            <span>{`$ ${!isNaN(
              (cleanNumeric(totalRow?.yield) / 100) *
              cleanNumeric(totalRow?.equity) -
              cleanNumeric(totalRow?.equity) *
              ((cleanNumeric(totalRow?.yield) -
                (((cleanNumeric(totalRow?.total) -
                  cleanNumeric(otherInfo?.total)) /
                  cleanNumeric(totalRow?.total)) *
                  cleanNumeric(totalRow?.annualDepreciation2) / 100 *
                  100 +
                  cleanNumeric(otherInfo?.newEquityInvestment))) /
                100) *
              cleanNumeric(otherInfo?.assumedIncomTax) / 100
            )
              ? (
                (cleanNumeric(totalRow?.yield) / 100) *
                cleanNumeric(totalRow?.equity) -
                cleanNumeric(totalRow?.equity) *
                ((cleanNumeric(totalRow?.yield) -
                  (((cleanNumeric(totalRow?.total) -
                    cleanNumeric(otherInfo?.total)) /
                    cleanNumeric(totalRow?.total)) *
                    cleanNumeric(totalRow?.annualDepreciation2) / 100 *
                    100 +
                    cleanNumeric(otherInfo?.newEquityInvestment))) /
                  100) *
                cleanNumeric(otherInfo?.assumedIncomTax) / 100
              )
                .toFixed(0)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              : 0
              }`}</span>
          </div>
          <div style={rowStyle}>
            <span style={headerStyle}>
              Estimated Tax Equivalent Yield (assuming distribution includes all
              taxable income):
            </span>
            <span style={{ border: "2px solid #000", padding: "0.5rem" }}>{`${(
              (((cleanNumeric(totalRow?.yield) / 100) *
                cleanNumeric(totalRow?.equity) -
                cleanNumeric(totalRow?.equity) *
                ((cleanNumeric(totalRow?.yield) -
                  (((cleanNumeric(totalRow?.total) -
                    cleanNumeric(otherInfo?.total)) /
                    cleanNumeric(totalRow?.total)) *
                    cleanNumeric(totalRow?.annualDepreciation2 / 100) *
                    100 +
                    cleanNumeric(otherInfo?.newEquityInvestment))) /
                  100) *
                cleanNumeric(otherInfo?.assumedIncomTax) / 100) /
                (1 - cleanNumeric(otherInfo?.assumedIncomTax) / 100) /
                cleanNumeric(totalRow?.equity)) *
              100
            ).toFixed(2) || 0
              }%`}</span>
          </div>
          <div style={noteStyle}>
            <p>
              (1) Assumes 27.5 years for Residential, 39 years for Commercial,
              and 35 years for Blended
            </p>
            <p>
              (2) Merely an estimate. Sponsor will provide property-specific
              depreciation data.
            </p>
            <p>
              (3) Assumes continued application of relinquished depreciation
              schedule - your tax professional may apply a different factor.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
export default EquityCalcultorPrint;
