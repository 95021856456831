import React from 'react';
import ManageDST from './manageDST';

const Home = () => {
    return (
        <div>
            <ManageDST />

        </div>
    )
}

export default Home;