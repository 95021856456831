import React, { useState, useContext, useEffect } from "react";
import { Button, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import MyContext from "../context/appContext";
import { toast } from "react-toastify";
import { Storage } from "aws-amplify";
import FileUploadRoundedIcon from "@mui/icons-material/FileUploadRounded";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import { API } from "aws-amplify";
import { useParams } from "react-router-dom";
import CancelIcon from "@material-ui/icons/Cancel";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const ViewDstOffering = ({ selectedDst }) => {
  let { id } = useParams();
  const appContext = useContext(MyContext);
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [pdfHistory, setPdfHistory] = useState([]);
  console.log(selectedFile, "sleeted file");

  useEffect(() => {
    if (id) {
      getDstById();
    }
  }, [id]);

  const getDstById = async () => {
    try {
      appContext.updateState("loading", true);
      const payload = {
        queryStringParameters: {
          tableName: "dsts_table-dev",
          id: id,
        },
      };
      const res = await API.get("dynamoRW", "/dsts", payload);

      console.log("The Selected dst is.......!", res);
      setPdfHistory(res.Item);
      appContext.updateState("loading", false);
    } catch (error) {
      appContext.updateState("loading", false);
      console.log(error.message);
      toast(`No DST Found! ${error.message}`, { type: "error" });
    } finally {
      setLoading(false);
    }
  };
  console.log(pdfHistory, "SELECTED DST");

  const formatFileSize = (sizeInBytes) => {
    const kilobyte = 1024;
    const megabyte = kilobyte * 1024;

    if (sizeInBytes < kilobyte) {
      return sizeInBytes + " B";
    } else if (sizeInBytes < megabyte) {
      return (sizeInBytes / kilobyte).toFixed(2) + " KB";
    } else {
      return (sizeInBytes / megabyte).toFixed(2) + " MB";
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      setSelectedFile(file);
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file && file.type === "application/pdf") {
      setSelectedFile(file);
    } else {
      toast.error("Failed! Please drop a PDF file");
      console.error("Invalid file type. Please drop a PDF file.");
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleRemoveFile = () => {
    setSelectedFile(null);
  };

  const handleAddData = async () => {
    if (selectedFile) {
      appContext.updateState("loading", true);
      try {
        const key = await uploadImage(
          selectedFile,
          selectedDst.fullLegalNameOfDst
        );
        if (key) {
          await uploadRecord();
        } else {
          console.error("Failed to upload file.");
          toast.error("Failed to upload file.");
        }
      } catch (error) {
        console.error("Error uploading file:", error);
        toast.error("Error uploading file.");
      } finally {
        appContext.updateState("loading", false);
        setSelectedFile(null);
      }
    }
  };

  const uploadImage = async (file, dstName) => {
    try {
      appContext.updateState("loading", true);

      Storage.configure({
        AWSS3: {
          bucket: process.env.REACT_APP_S3_BUCKET,
          region: process.env.REACT_APP_LAMBDA_REGION,
        },
      });

      if (file instanceof File) {
        const key = `${dstName}.pdf`;
        const response = await Storage.put(key, file, {
          contentType: "application/pdf",
        });

        appContext.updateState("loading", false);
        return response.key;
      } else {
        console.error("Invalid file type:", typeof file);
      }
      appContext.updateState("loading", false);
      return null;
    } catch (error) {
      appContext.updateState("loading", false);
      console.error("Error uploading image:", error);
      return null;
    }
  };

  const uploadRecord = async () => {
    const sizeInString = selectedFile.size.toString();
    const fileJson = {
      name: selectedFile.name,
      size: sizeInString,
      date: new Date(parseInt(Date.now())).toLocaleDateString(),
    };
    try {
      appContext.updateState("loading", true);
      const params = {
        body: {
          tableName: "dsts_table-dev",
          Key: {
            id: id,
          },
          AttributeUpdates: {
            uploaded_pdf: {
              Value: [{ ...fileJson }],
              Action: "ADD",
            },
          },
        },
      };
      const res = await API.put("dynamoRW", "/dsts", params);
      if (res) {
        await getDstById();
      }
      appContext.updateState("loading", false);
      toast.success("File uploaded successfully!");
    } catch (error) {
      appContext.updateState("loading", false);
      console.log(error.message);
      toast(`Error uploading image! ${error.message}`, { type: "error" });
    } finally {
      setLoading(false);
    }
  };

  const deleteRecord = async (file) => {
    console.log(file, "file");
    if (!Array.isArray(pdfHistory?.uploaded_pdf)) {
      throw new Error("Invalid properties data");
    }
    const updatedPdfHistory = {
      ...pdfHistory,
      uploaded_pdf: pdfHistory.uploaded_pdf.filter((pdf) => pdf.name !== file),
    };
    try {
      appContext.updateState("loading", true);
      const params = {
        body: {
          tableName: "dsts_table-dev",
          Key: {
            id: id,
          },
          AttributeUpdates: {
            uploaded_pdf: {
              Value: updatedPdfHistory.uploaded_pdf,
              Action: "PUT",
            },
          },
        },
      };
      const res = await API.put("dynamoRW", "/dsts", params);
      if (res) {
        await getDstById();
      }
      appContext.updateState("loading", false);
      toast.success("PDF file deleted successfully!");
    } catch (error) {
      appContext.updateState("loading", false);
      console.log(error.message);
      toast(`Error uploading image! ${error.message}`, { type: "error" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {!selectedFile ? (
        <div
          style={{
            border: "2px dashed #dfdfdf",
            padding: "3rem 2rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            margin: "1rem 10rem",
          }}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FileUploadRoundedIcon
                style={{
                  fontSize: "4rem",
                  backgroundColor: "#c7c7c7",
                  borderRadius: "50%",
                  padding: "0.5rem",
                  color: "#fff",
                  marginBottom: "1.5rem",
                }}
              />
              <p
                style={{
                  margin: "0px",
                  fontSize: "1.4rem",
                  color: "#494949",
                }}
              >
                <Button
                  component="label"
                  variant="outlined"
                  style={{
                    border: "none",
                    fontSize: "1.3rem",
                    padding: "0px",
                    textTransform: "inherit",
                  }}
                >
                  Browse
                  <VisuallyHiddenInput
                    type="file"
                    onChange={handleFileChange}
                    accept=".pdf"
                  />
                </Button>{" "}
                or drag files here
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            border: "2px dashed #dfdfdf",
            padding: "3rem 2rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            margin: "1rem 10rem",
          }}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <div
              style={{
                marginTop: "0.5rem",
                backgroundColor: "#fbfbf8",
                padding: "0.5rem 2rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                gap: "0.5rem",
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ fontSize: "1.5rem" }}>{selectedFile?.name}</div>
                <p style={{ color: "#717171", margin: "0px" }}>
                  {formatFileSize(selectedFile?.size)}
                </p>
              </div>
              <CancelIcon
                style={{ cursor: "pointer", fontSize: "1.3rem" }}
                onClick={handleRemoveFile}
              />
            </div>
          </div>
        </div>
      )}
      {selectedFile ? (
        <div
          style={{
            width: "100%",
            marginTop: "0rem",
            textAlign: "right",
            paddingRight: "10rem",
          }}
        >
          <Button onClick={handleAddData} type="submit" variant="contained">
            Upload PDF
          </Button>
        </div>
      ) : null}
      {pdfHistory?.uploaded_pdf?.length > 0 ? (
        <>
          {pdfHistory?.uploaded_pdf?.map((pdf, ind) => (
            <div
              style={{
                padding: "0.5rem 2rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "0.5rem",
                margin: "1rem 10rem",
                border: "1px solid #dfdfdf",
              }}
              key={ind}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                <div>
                  <InsertDriveFileOutlinedIcon
                    style={{
                      fontSize: "3.5rem",
                      backgroundColor: "#eaeaea",
                      borderRadius: "5px",
                      padding: "0.5rem",
                      color: "#b1b1b1",
                      marginTop: "0.5rem",
                    }}
                  />
                </div>
                <div>
                  <p
                    style={{
                      fontSize: "1.2rem",
                      color: "#2b2b2b",
                      margin: "0px",
                    }}
                  >
                    {pdf.name}
                  </p>
                  <p style={{ color: "#717171", margin: "0px" }}>
                    {formatFileSize(pdf.size)}
                  </p>
                  <p style={{ color: "#717171", margin: "0px" }}>{pdf.date}</p>
                </div>
              </div>
              <IconButton>
                <DeleteIcon
                  style={{ cursor: "pointer", fontSize: "1.3rem" }}
                  onClick={() => deleteRecord(pdf.name)}
                />
              </IconButton>
            </div>
          ))}
        </>
      ) : null}
    </>
  );
};

export default ViewDstOffering;
