import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function CustomizedTables({ selectedDst }) {
  const splitCamelCase = (word) => {
    const words = word.split(/(?=[A-Z])/);
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    const result = capitalizedWords.join(" ");
    return result;
  };

  const shouldExcludePair = (key) => {
    return key === "selectDst" || key === "images";
  };

  return (
    <Paper sx={{ margin: "0 auto" }}>
      <TableContainer>
        <Table
          sx={{ minWidth: 700 }}
          stickyHeader
          aria-label="customized table"
        >
          <TableHead>
            <StyledTableRow>
              <StyledTableCell style={{ width: "50%" }}>Name</StyledTableCell>
              <StyledTableCell style={{ width: "50%" }}>
                Details
              </StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {selectedDst?.map((item, index) => (
              <React.Fragment key={index}>
                {index > 0 && (
                  <StyledTableRow>
                    <StyledTableCell
                      colSpan={2}
                      style={{
                        backgroundColor: "#000",
                        color: "#fff",
                        fontWeight: "bold",
                      }}
                    >
                      {`Property # ${index + 1}`}
                    </StyledTableCell>
                  </StyledTableRow>
                )}
                {Object.entries(item).map(([key, value]) =>
                  shouldExcludePair(key) ? null : (
                    <StyledTableRow key={"dstDetails" + key}>
                      <StyledTableCell>
                        <b>{splitCamelCase(key)}</b>
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {value}
                      </StyledTableCell>
                    </StyledTableRow>
                  )
                )}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
