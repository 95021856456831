import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Typography } from "@mui/material";
import StateTax from "../components/TaxDetails/stateTax";
import FederalTax from "../components/TaxDetails/federalTax";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

const TaxDetails = () => {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div style={{ padding: "20px 20px" }}>
      <div>
        <h4>TAX DETAILS</h4>
      </div>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="State Tax" />
            <Tab label="Federal Tax" />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <StateTax />
        </TabPanel>
        <TabPanel value={value} index={1}>
         <FederalTax />
        </TabPanel>
      </Box>
    </div>
  );
};

export default TaxDetails;
