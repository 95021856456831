import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";

const DstDetailPrint = ({ findDst, image }) => {
  const [primaryProperty, setPrimaryProperty] = useState({});
  const [is_commercial, setIsCommercial] = useState(false);

  useEffect(() => {
    const findPrimary = findDst?.property?.find(
      (item) => item.is_primary === true
    );
    if (findPrimary) {
      setPrimaryProperty(findPrimary);
      if (findPrimary?.propertyTypeofDST === "Commercial") {
        setIsCommercial(true);
      }
    }
  }, [findDst]);

  let properties = findDst?.property;

  properties?.sort((a, b) => new Date(b.dateAdded) - new Date(a.dateAdded));
  const rows = findDst?.property?.map((pro) => ({
    msa: pro.msa || "-",
  }));
  const DSCR =
    +findDst?.basicInfo?.year1NetOperatingIncome /
    +(
      +findDst?.basicInfo?.offeringDebt * +(+findDst?.basicInfo?.loanRate / 100)
    );

  const totalOfAllExpensesAbovePurchasePrice =
    +findDst?.proceeds?.OOCosts +
    +findDst?.proceeds?.brokerDealerCommissions +
    +findDst?.proceeds?.wholesalingCosts +
    (+findDst?.proceeds?.transactionCommission +
      +findDst?.proceeds?.financingFee +
      +findDst?.proceeds?.otherFees) +
    +findDst?.proceeds?.thirdPartyClosingCosts;

  const maxFloor = findDst?.property?.reduce((max, item) => {
    const floor = +item.maxFloors;
    return isNaN(floor) || floor < max ? max : floor;
  }, Number.NEGATIVE_INFINITY);

  const newDate = new Date(findDst?.basicInfo?.concordeApprovalDate);
  const formattedDate = newDate.toLocaleDateString("en-US");

  return (
    <div>
      <div
        style={{
          width: "100%",
          fontSize: "0.8rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "8rem",
        }}
      >
        <h2 style={{ textAlign: "center" }}>
          {findDst?.basicInfo?.fullLegalNameOfDst}
        </h2>
        <div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
          <h3>Sponsor:</h3>
          <p>{findDst?.basicInfo?.nameOfTheSponsor || "None"}</p>
        </div>
      </div>
      <Grid
        container
        spacing={0}
        style={{ marginTop: "0rem", fontSize: "0.8rem" }}
      >
        <Grid item xs={0.7}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h4 style={{ margin: "0px" }}>Units</h4>
            <p style={{ textAlign: "center" }}>
              {findDst?.basicInfo?.totalUnitsInPortfolio || "0"}
            </p>
          </div>
        </Grid>
        <Grid item xs={0.8}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h4 style={{ margin: "0px" }}>Class</h4>
            <p style={{ textAlign: "center" }}>
              {findDst?.basicInfo?.dstPropertiesClass || "None"}
            </p>
          </div>
        </Grid>
        <Grid item xs={1.7}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h4 style={{ margin: "0px" }}>Sector(s)</h4>
            <p style={{ textAlign: "center" }}>
              {findDst?.basicInfo?.dstPropertiesSector || "None"}
            </p>
          </div>
        </Grid>
        <Grid item xs={1.5}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h4 style={{ margin: "0px" }}>MSA(s)</h4>
            <p style={{ textAlign: "center" }}>
              {findDst?.basicInfo?.MSA || "None"}
            </p>
          </div>
        </Grid>
        <Grid item xs={2.2}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h4 style={{ margin: "0px" }}>Location(s)</h4>
            <p style={{ textAlign: "center" }}>
              {findDst?.basicInfo?.generalLocationRelativeToMajorMarket ||
                "None"}
            </p>
          </div>
        </Grid>
        <Grid item xs={1.3}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h4 style={{ margin: "0px" }}>Approval Date</h4>
            <p style={{ textAlign: "center" }}>
              {formattedDate == "Invalid Date"
                ? "N/A"
                : formattedDate || "None"}
            </p>
          </div>
        </Grid>
        <Grid item xs={1}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h4 style={{ margin: "0px" }}>UPREIT</h4>
            <p style={{ textAlign: "center" }}>
              {findDst?.basicInfo?.upreit || "None"}
            </p>
          </div>
        </Grid>
        <Grid item xs={0.7}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h4 style={{ margin: "0px" }}>Y1 Yield</h4>
            <p
              style={{ textAlign: "center" }}
            >{`${findDst?.yields?.year1Yield}%`}</p>
          </div>
        </Grid>
        <Grid item xs={0.7}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h4 style={{ margin: "0px" }}>Min.</h4>
            <p style={{ textAlign: "center" }}>
              {`$${Number(
                findDst?.basicInfo?.minimum1031Investment
              ).toLocaleString("en-US")}` || "None"}
            </p>
          </div>
        </Grid>
        <Grid item xs={0.7}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h4 style={{ margin: "0px" }}>Offering</h4>
            <p style={{ textAlign: "center" }}>{findDst?.basicInfo?.BC}</p>
          </div>
        </Grid>
        <Grid item xs={0.7}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h4 style={{ margin: "0px" }}>Load*</h4>
            <p style={{ textAlign: "center" }}>
              {`${(
                (+totalOfAllExpensesAbovePurchasePrice /
                  +findDst?.basicInfo?.purchasePrice) *
                100
              ).toFixed(2)}%` || 0}
            </p>
          </div>
        </Grid>
      </Grid>
      {/* </div> */}
      <div style={{ width: "100%" }}>
        <Grid
          container
          spacing={0}
          style={{ marginTop: "0rem", fontSize: "0.8rem" }}
        >
          <div style={{ width: "50%" }}>
            <h3 style={{ margin: "0px", textAlign: "center" }}>MARKET NOTES</h3>
          </div>
          <div style={{ width: "50%" }}>
            <h3 style={{ margin: "0px", textAlign: "center" }}>
              1st PROPERTY NOTES &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; (TOTAL # of
              PROPERTIES:{findDst?.property?.length} )
            </h3>
          </div>
          <div
            style={{
              width: "100%",
              borderTop: "1px solid #0000001f",
              margin: "0",
            }}
          />
          <Grid
            item
            xs={6}
            style={{
              borderRight: "1px solid #c9c9c9",
            }}
          >
            <div style={{ marginTop: "1rem" }}>
              <h4 style={{ margin: "0px" }}>Sponsor:</h4>
              <ul style={{ margin: "0px" }}>
                <li>{findDst?.basicInfo?.sponsorBullet1}</li>
                <li>{findDst?.basicInfo?.sponsorBullet2}</li>
                <li>{findDst?.basicInfo?.sponsorBullet3}</li>
              </ul>
            </div>
            <div
              style={{
                justifyContent: is_commercial ? "flex-start" : "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "",
                }}
              >
                <h3>Market Highlights</h3>

                {is_commercial ? (
                  <>
                    <h3>MSA(s)</h3>

                    <Grid container spacing={2} ml={5}>
                      {rows?.map((row, index) => (
                        <Grid
                          item
                          xs={4}
                          key={index}
                          style={{
                            margin: "0px",
                            display: "flex",
                            flexDirection: "row",
                            lineHeight: "0.4rem",
                          }}
                        >
                          {row?.msa}
                        </Grid>
                      ))}
                    </Grid>
                  </>
                ) : (
                  <Grid container style={{}}>
                    <Grid item xs={6} style={{}}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <b>MSA(s)</b>
                        <p style={{ margin: "0px", marginTop: "0.1rem" }}>
                          {findDst?.property?.[0]?.msa}
                        </p>
                        <p style={{ margin: "0px" }}>
                          {findDst?.property?.[0]?.msaProjectedPopGrowth}
                        </p>
                      </div>
                    </Grid>
                    <Grid item xs={6} style={{}}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <b>Sub-Market(s)</b>
                        <p style={{ margin: "0px", marginTop: "0.1rem" }}>
                          {findDst?.property?.[0]?.submarket}
                        </p>
                        <p style={{ margin: "0px" }}>
                          {findDst?.property?.[0]?.subMarketProjectedPopGrowth}
                        </p>
                      </div>
                    </Grid>
                  </Grid>
                )}
                <p style={{ marginTop: "0.7rem" }}>
                  <b>Population Forecast:</b>
                </p>
              </div>
            </div>
            <div>
              <ul style={{ margin: "0px" }}>
                <li>{findDst?.highlights?.marketGrowthPoint1}</li>
                <li>{findDst?.highlights?.marketGrowthPoint2}</li>
                <li>{findDst?.highlights?.marketGrowthPoint3}</li>
                <li>{findDst?.highlights?.marketEmploymentPoint}</li>
                <li>{findDst?.highlights?.marketEconomicPoint}</li>
              </ul>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginRight: "1rem",
                alignItems: "center",
                marginTop: "1rem",
              }}
            >
              <Grid container spacing={0}>
                <Grid item xs={4}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "",
                    }}
                  >
                    <h3>Property Highlights</h3>
                  </div>
                </Grid>
                <Grid item xs={2.5}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <b>Strategy</b>
                    <p style={{ margin: "0px" }}>
                      {findDst?.property?.[0]?.investmentStrategy}
                    </p>
                  </div>
                </Grid>
                <Grid item xs={5.5}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <b>Value Add Notes</b>
                    <p style={{ margin: "0px" }}>
                      {findDst?.property?.[0]?.valueAddNotes}
                    </p>
                  </div>
                </Grid>
              </Grid>
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginRight: "0.3rem",
                  marginTop: "0.5rem",
                }}
              >
                <h4 style={{ margin: "0px" }}>
                  Portfolio Occupancy: &nbsp;
                  <span
                    style={{ fontWeight: "normal" }}
                  >{`${findDst?.basicInfo?.currentDstOccupancy}%`}</span>
                </h4>
                <h4 style={{ margin: "0px" }}>
                  Ave. Lease duration: &nbsp;
                  <span style={{ fontWeight: "normal" }}>
                    {findDst?.otherInfo?.averageRemainingLeaseDuration || "-"}
                  </span>
                </h4>
                <h4 style={{ margin: "0px" }}>
                  Total SF: &nbsp;
                  <span style={{ fontWeight: "normal" }}>
                    {Number(
                      findDst?.property?.reduce(
                        (sum, item) => sum + +item.totalSf,
                        0
                      ) || null
                    ).toLocaleString("en-US") || "-"}
                  </span>
                </h4>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "end",
                  gap: "1rem",
                  marginLeft: "1.5rem",
                }}
              >
                <h4 style={{ margin: "0px", marginTop: "0.5rem" }}>
                  Price per door:{" "}
                </h4>
                <p style={{ margin: "0px" }}>
                  {findDst?.basicInfo?.totalUnitsInPortfolio !== "" &&
                  findDst?.basicInfo?.purchasePrice !== ""
                    ? `$ ${Number(
                        (
                          +findDst?.basicInfo?.purchasePrice /
                          +findDst?.basicInfo?.totalUnitsInPortfolio
                        ).toFixed()
                      ).toLocaleString("en-US")}`
                    : "$ 0"}
                </p>
              </div>
              <ul style={{ margin: "0px" }}>
                <li>{findDst?.highlights?.propertyLocationPoint1}</li>
                <li>{findDst?.highlights?.propertyLocationPoint2}</li>
                <li>{findDst?.highlights?.propertyLocationPoint3}</li>
                <li>{findDst?.highlights?.propertyFeaturePoint1}</li>
                <li>{findDst?.highlights?.propertyFeaturePoint2}</li>
              </ul>
            </div>
            {is_commercial ? null : (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "2rem",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "",
                    }}
                  >
                    <h4>Property Manager:</h4>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <p style={{ marginRight: "1rem" }}>
                      {findDst?.property?.[0]?.propertyManager}
                    </p>
                    <p>{findDst?.property?.[0]?.pmDescription}</p>
                  </div>
                </div>
              </>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginRight: "1rem",
              }}
            >
              <Grid
                container
                sx={{ py: 2 }}
                spacing={1}
                style={{ padding: "0px" }}
              >
                <Grid item xs={12}>
                  <div>
                    <h4 style={{ margin: "0px" }}>Special Notes:</h4>
                    <ul style={{ marginTop: "0rem", paddingLeft: "1rem" }}>
                      <li>
                        Disposition Fee: &nbsp; &nbsp; &nbsp;
                        {`${findDst?.otherInfo?.dispositionFee}%`}
                      </li>
                      <li>
                        % Retained by Sponsor: &nbsp; &nbsp; &nbsp;
                        {`${findDst?.basicInfo?.percentRetainedBySponsor}%`}
                      </li>
                    </ul>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={0} style={{ fontSize: "0.8rem" }}>
              <Grid item xs={7} style={{ marginLeft: "0.4rem" }}>
                <div>
                  <Grid container spacing={0} style={{ marginTop: "0.8rem" }}>
                    <Grid item xs={4}>
                      <h4 style={{ marginBottom: "0px", margin: "0px" }}>
                        Property name:
                      </h4>
                    </Grid>
                    <Grid
                      item
                      xs={7}
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <p
                        style={{
                          marginBottom: "0px",
                          margin: "0px",
                        }}
                      >
                        {`  ${primaryProperty?.propertyName || null}`}
                      </p>
                    </Grid>
                  </Grid>
                  <Grid container spacing={0}>
                    <Grid item xs={4}>
                      <h4
                        className="property-web"
                        style={{ marginBottom: "0px", margin: "0px" }}
                      >
                        {is_commercial
                          ? "Tenant Website:"
                          : "Property Website:"}
                      </h4>
                    </Grid>
                    <Grid
                      item
                      xs={7}
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <p
                        style={{
                          marginBottom: "0px",
                          margin: "0px",
                        }}
                      >
                        {primaryProperty?.propertyWebsite || null}
                      </p>
                    </Grid>
                  </Grid>
                  <Grid container spacing={0}>
                    <Grid item xs={4}>
                      <h4 style={{ marginBottom: "0px", margin: "0px" }}>
                        Built:
                      </h4>
                    </Grid>
                    <Grid
                      item
                      xs={7}
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <p
                        style={{
                          marginBottom: "0px",
                          margin: "0px",
                        }}
                      >
                        {primaryProperty?.yearBuilt || null}
                      </p>
                    </Grid>
                  </Grid>
                  <Grid container spacing={0}>
                    <Grid item xs={4}>
                      <h4 style={{ marginBottom: "0px", margin: "0px" }}>
                        Occupancy:
                      </h4>
                    </Grid>
                    <Grid
                      item
                      xs={7}
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <p
                        style={{
                          marginBottom: "0px",
                          margin: "0px",
                        }}
                      >
                        {`${primaryProperty.currentOccup || 0}%`}
                      </p>
                    </Grid>
                  </Grid>
                  <Grid container spacing={0}>
                    <Grid item xs={4}>
                      <h4 style={{ marginBottom: "0px", margin: "0px" }}>
                        # of buildings:
                      </h4>
                    </Grid>
                    <Grid
                      item
                      xs={7}
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <p
                        style={{
                          marginBottom: "0px",
                          margin: "0px",
                        }}
                      >
                        {primaryProperty.NumberOfBuildings || null}
                      </p>
                    </Grid>
                  </Grid>
                  <Grid container spacing={0}>
                    <Grid item xs={4}>
                      <h4 style={{ marginBottom: "0px", margin: "0px" }}>
                        Max # of floors:
                      </h4>
                    </Grid>
                    <Grid
                      item
                      xs={7}
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <p
                        style={{
                          marginBottom: "0px",
                          margin: "0px",
                        }}
                      >
                        {primaryProperty?.maxFloors || null}
                      </p>
                    </Grid>
                  </Grid>
                  <Grid container spacing={0}>
                    <Grid item xs={4}>
                      <h4 style={{ marginBottom: "0px", margin: "0px" }}>
                        Class:
                      </h4>
                    </Grid>
                    <Grid
                      item
                      xs={7}
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <p
                        style={{
                          marginBottom: "0px",
                          margin: "0px",
                        }}
                      >
                        {primaryProperty?.class || null}
                      </p>
                    </Grid>
                  </Grid>
                  <Grid container spacing={0}>
                    <Grid item xs={4}>
                      <h4 style={{ marginBottom: "0px", margin: "0px" }}>
                        Style:
                      </h4>
                    </Grid>
                    <Grid
                      item
                      xs={7}
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <p
                        style={{
                          marginBottom: "0px",
                          margin: "0px",
                        }}
                      >
                        {primaryProperty?.style || null}
                      </p>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              <Grid item xs={4.8}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    height: "100%",
                    marginTop: "0.5rem",
                    marginBottom: "2rem",
                  }}
                >
                  {image && (
                    <img
                      src={image}
                      height={"170px"}
                      width={"170px"}
                      alt="Preview"
                      style={{ marginLeft: "auto" }}
                    />
                  )}
                </div>
              </Grid>
            </Grid>
            <div>
              <div
                style={{
                  display: "flex",
                  marginLeft: "1rem",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  {is_commercial ? null : (
                    <>
                      {" "}
                      <div
                        style={{
                          border: "1px solid black",
                          width: "4rem",
                          height: "3rem",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <h4 style={{ margin: "0px" }}>1 bed</h4>
                        <p style={{ margin: "0px" }}>
                          {Number(primaryProperty?.oneBedrooms).toLocaleString(
                            "en-US"
                          ) || 0}
                        </p>
                      </div>
                      <div
                        style={{
                          border: "1px solid black",
                          width: "4rem",
                          height: "3rem",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <h4 style={{ margin: "0px" }}>2 bed</h4>
                        <p style={{ margin: "0px" }}>
                          {Number(primaryProperty?.twoBedrooms).toLocaleString(
                            "en-US"
                          ) || 0}
                        </p>
                      </div>
                      <div
                        style={{
                          border: "1px solid black",
                          width: "4rem",
                          height: "3rem",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <h4 style={{ margin: "0px" }}>3 bed</h4>
                        <p style={{ margin: "0px" }}>
                          {Number(
                            primaryProperty?.threeBedrooms
                          ).toLocaleString("en-US") || 0}
                        </p>
                      </div>
                      <div
                        style={{
                          border: "1px solid black",
                          width: "4rem",
                          height: "3rem",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <h4 style={{ margin: "0px" }}>Other</h4>
                        <p style={{ margin: "0px" }}>
                          {Number(primaryProperty?.otherUnits).toLocaleString(
                            "en-US"
                          ) || 0}
                        </p>
                      </div>
                      <div
                        style={{
                          border: "1px solid black",
                          width: "4rem",
                          height: "3rem",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <h4 style={{ margin: "0px" }}>Total</h4>
                        <p style={{ margin: "0px" }}>
                          {Number(
                            +primaryProperty?.oneBedrooms +
                              +primaryProperty?.twoBedrooms +
                              +primaryProperty?.threeBedrooms +
                              +primaryProperty?.otherUnits
                          ).toLocaleString("en-US") || 0}
                        </p>
                      </div>
                    </>
                  )}
                </div>
                <div style={{ marginTop: "0rem" }}>
                  {is_commercial ? null : (
                    <>
                      <h4 style={{ margin: "0px" }}>Unit amenities:</h4>
                      <p style={{ margin: "0px" }}>
                        {primaryProperty?.unitAmenities}
                      </p>
                    </>
                  )}
                </div>
                <div
                  style={{
                    marginTop: "0px",
                    borderBottom: "1px solid #0000001f",
                  }}
                >
                  {is_commercial ? null : (
                    <>
                      <h4 style={{ margin: "0px" }}>Common amenities:</h4>
                      <p style={{ margin: "0px" }}>
                        {primaryProperty?.commonAmenities}
                      </p>
                    </>
                  )}
                </div>

                <div>
                  <div style={{ display: "flex" }}>
                    <h4 style={{ margin: "0px" }}>UNIQUE DEAL RISKS</h4>
                    <div
                      style={{
                        display: "flex",
                        gap: "0.4rem",
                        paddingLeft: "5rem",
                      }}
                    >
                      <h4 style={{ margin: "0px" }}>PPM risk disclosures:</h4>
                      <p style={{ margin: "0px" }}>
                        {findDst?.otherInfo?.pppRiskFactorsPage}
                      </p>
                    </div>
                  </div>
                  <ul style={{ paddingLeft: "1rem" }}>
                    <li>{findDst?.basicInfo?.uniqueDealRisk1}</li>
                    <li>{findDst?.basicInfo?.uniqueDealRisk2}</li>
                    <li>{findDst?.basicInfo?.uniqueDealRisk3}</li>
                    <li>{findDst?.basicInfo?.uniqueDealRisk4}</li>
                    <li>{findDst?.basicInfo?.uniqueDealRisk5}</li>
                  </ul>
                </div>
              </div>
              <div>
                <h4 style={{ margin: "0px", marginLeft: "1rem" }}>
                  OTHER NOTES:
                </h4>
                <p style={{ margin: "0px", marginLeft: "1rem" }}>
                  {findDst?.otherInfo?.generalNotes}
                </p>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          fontSize: "0.8rem",
          marginTop: "2.5rem",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h4 style={{ margin: "0px" }}>Equity</h4>
          <p style={{ margin: "0px" }}>{`$${
            Number(findDst?.basicInfo?.offeringEquity).toLocaleString(
              "en-US"
            ) || 0
          }`}</p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h4 style={{ margin: "0px" }}>Debt</h4>
          <p style={{ margin: "0px" }}>{`$${
            Number(findDst?.basicInfo?.offeringDebt).toLocaleString("en-US") ||
            0
          }`}</p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h4 style={{ margin: "0px" }}>LTV</h4>
          <p style={{ margin: "0px" }}>{`${
            (
              (+findDst?.basicInfo?.offeringDebt /
                (+findDst?.basicInfo?.offeringDebt +
                  +findDst?.basicInfo?.offeringEquity)) *
              100
            ).toFixed(2) || 0
          }%`}</p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h4 style={{ margin: "0px" }}>Term</h4>
          <p style={{ margin: "0px" }}>{`${
            findDst?.basicInfo?.loanTerm || 0
          }`}</p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h4 style={{ margin: "0px" }}>Fixed/Variable</h4>
          <p style={{ margin: "0px" }}>
            {findDst?.basicInfo?.fixedVariable || "None"}
          </p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h4 style={{ margin: "0px" }}>I/O Period</h4>
          <p style={{ margin: "0px" }}>{`${
            findDst?.basicInfo?.interestOnlyPeriod === Number
              ? Number(findDst?.basicInfo?.interestOnlyPeriod).toLocaleString(
                  "en-US"
                )
              : findDst?.basicInfo?.interestOnlyPeriod
          }`}</p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h4 style={{ margin: "0px" }}>Rate</h4>
          <p style={{ margin: "0px" }}>{`${
            findDst?.basicInfo?.loanRate || 0
          }%`}</p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h4 style={{ margin: "0px" }}>Lender</h4>
          <p style={{ margin: "0px" }}>{findDst?.basicInfo?.lender}</p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h4 style={{ margin: "0px" }}>DSCR</h4>
          <p style={{ margin: "0px" }}>{DSCR.toFixed(2)}</p>
        </div>
      </div>
    </div>
  );
};

export default DstDetailPrint;
