import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Logout from "@mui/icons-material/Logout";
import Box from "@mui/material/Box";
import "../css/styles.css";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Settings, Notifications } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const drawerWidth = 350;
// let role=localStorage.getItem("role")
const role = localStorage.getItem("role");
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: role?.toLowerCase() == "investor" ? 0 : drawerWidth,
    width:
      role?.toLowerCase() == "investor"
        ? "100%"
        : `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const style = {
  position: "absolute",
  top: "17%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default function AppBarComponent({ open, setOpen }) {
  const [openModal, setOpenModal] = React.useState(false);
  const [role, setRole] = React.useState("");
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const navigate = useNavigate();
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const handleLogout = () => {
    localStorage.clear();
    window.location.reload();
  };

  React.useEffect(() => {
    if (role === "") {
      checkUserRole();
    }
  }, [role]);

  const checkUserRole = () => {
    let role = localStorage.getItem("role");
    if (role) {
      setRole(role);
    }
  };

  return (
    <AppBar
      position="absolute"
      open={open}
      style={{
        background: "black",
        width: role?.toLowerCase() == "investor" ? "100%" : "",
      }}
    >
      <Toolbar
        sx={{
          pr: "24px",
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          position: "relative",
        }}
      >
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer}
          sx={{
            color: "#fff",
            position: "absolute",
            left: 22,
            ...(open && { display: "none" }),
          }}
        >
          <MenuIcon />
        </IconButton>
        {!open ? (
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1, pl: 7, pt: 1 }}
          >
            <img src="/faviconPNG.png" style={{ height: "21px" }} />
          </Typography>
        ) : null}
        {role == "Super Admin" || role == "Advisor" ? (
          <IconButton onClick={() => navigate("/approve-user")}>
            <Notifications sx={{ color: "#fff" }} />
          </IconButton>
        ) : null}
        <IconButton
          color="inherit"
          onClick={() => navigate("/profile")}
          style={{ marginLeft: "0.7rem" }}
        >
          <Settings sx={{ color: "#fff" }} />
        </IconButton>
        <IconButton
          color="inherit"
          onClick={handleOpenModal}
          style={{ marginLeft: "0.7rem" }}
        >
          <Logout sx={{ color: "#fff" }} />
        </IconButton>
        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" component="h2">
              Are you sure you want to logout?
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <Button
                onClick={handleCloseModal}
                variant="contained"
                sx={{ m: 1, textTransform: "inherit" }}
              >
                Cancel
              </Button>
              <Button
                onClick={handleLogout}
                type="submit"
                variant="contained"
                sx={{ mt: 2, mb: 2, textTransform: "inherit" }}
                className="delButton"
              >
                Logout
              </Button>
            </Typography>
          </Box>
        </Modal>
      </Toolbar>
    </AppBar>
  );
}
