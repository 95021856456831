import {
  Box,
  Button,
  Card,
  Grid,
  InputAdornment,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useNavigate } from "react-router-dom";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function NoiCapRate() {
  let navigate = useNavigate();
  const [otherInfo, setOtherInfo] = useState({
    offeringEquity: "",
    offeringDebt: "",
    total: "",
    startingPPM: "",
    capRate: "",
  });

  console.log(otherInfo, "otherInfo");
  useEffect(() => {
    const storedFormState = sessionStorage.getItem("noiSession");
    console.log("The stored form data:", storedFormState);
    if (storedFormState) {
      const { otherInfo } = JSON.parse(storedFormState);
      console.log("The parsed stored form data:", otherInfo);
      setOtherInfo(otherInfo);
    }
  }, []);
  const applyMask = (inputVal) => {
    const cleanInput = inputVal.replace(/,/g, "").replace(/\.(?=.*\.)/g, "");
    const [wholePart, decimalPart] = cleanInput.split(".");
    const formattedWholePart = wholePart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    if (decimalPart !== undefined) {
      return `${formattedWholePart}.${decimalPart}`;
    } else {
      return formattedWholePart;
    }
  };

  const handleTotalChange = (event) => {
    const inputVal = event.target.value;
    const maskedInput = applyMask(inputVal);

    const { name } = event.target;
    const updatedOtherInfo = {
      ...otherInfo,
      [name]: maskedInput, // Assign the masked input here
    };

    const cleanedEquity = updatedOtherInfo.offeringEquity.replace(
      /[^0-9.]/g,
      ""
    );
    const cleanedDebt = updatedOtherInfo.offeringDebt.replace(/[^0-9.]/g, "");

    if (
      updatedOtherInfo.offeringEquity !== "" &&
      updatedOtherInfo.offeringDebt !== ""
    ) {
      updatedOtherInfo.total = (+cleanedEquity + +cleanedDebt).toLocaleString();
    } else {
      updatedOtherInfo.total = "";
    }

    const cleanedPPM = updatedOtherInfo.startingPPM.replace(/[^0-9.]/g, "");
    const cleanedTotal = updatedOtherInfo.total.replace(/[^0-9.]/g, "");

    if (updatedOtherInfo.total !== "" && updatedOtherInfo.startingPPM !== "") {
      updatedOtherInfo.capRate = (+cleanedPPM / +cleanedTotal) * 100;
    } else {
      updatedOtherInfo.capRate = "";
    }

    setOtherInfo(updatedOtherInfo);
      updateNOISessionStorage(
        updatedOtherInfo,
        rows1,
        rows2,
        rows3,
        rows4,
        rows5,
        rows6,
        rows7
      );

  };
  const cleanedEquity = otherInfo.offeringEquity.replace(/[^0-9.]/g, "");
  const cleanedDebt = otherInfo.offeringDebt.replace(/[^0-9.]/g, "");
  const cleanedPPM = otherInfo.startingPPM.replace(/[^0-9.]/g, "");
  const cleanedTotal = otherInfo.total.replace(/[^0-9.]/g, "");

  const futureValue1 = cleanedPPM * (1 + 4.5 / 100) ** 7;
  const futureValue2 = cleanedPPM * (1 + 3 / 100) ** 7;
  const futureValue3 = cleanedPPM * (1 + 1.5 / 100) ** 7;
  const futureValue4 = cleanedPPM * (1 + 0 / 100) ** 7;
  const futureValue5 = cleanedPPM * (1 + -1.5 / 100) ** 7;
  const futureValue6 = cleanedPPM * (1 + -3 / 100) ** 7;
  const futureValue7 = cleanedPPM * (1 + -4.5 / 100) ** 7;
  const minus50 =
    otherInfo?.capRate !== "" ? (otherInfo?.capRate / 100 - 0.005) * 100 : "-";
  const flat = otherInfo.capRate || "-";
  const plus50 =
    otherInfo?.capRate !== "" ? (otherInfo?.capRate / 100 + 0.005) * 100 : "-";
  const plus100 =
    otherInfo?.capRate !== "" ? (otherInfo?.capRate / 100 + 0.01) * 100 : "-";
  const plus150 =
    otherInfo?.capRate !== "" ? (otherInfo?.capRate / 100 + 0.015) * 100 : "-";

  const rows1 = [
    {
      capRate: "Exit Cap Rate",
      minus50:
        otherInfo.startingPPM !== "" &&
        otherInfo.offeringEquity !== "" &&
        otherInfo.offeringDebt !== ""
          ? `${minus50.toFixed(3)}%`
          : "-",
      flat:
        otherInfo.startingPPM !== "" &&
        otherInfo.offeringEquity !== "" &&
        otherInfo.offeringDebt !== ""
          ? `${(+flat).toFixed(3)}%`
          : "-",
      plus50:
        otherInfo.startingPPM !== "" &&
        otherInfo.offeringEquity !== "" &&
        otherInfo.offeringDebt !== ""
          ? `${plus50.toFixed(3)}%`
          : "-",
      plus100:
        otherInfo.startingPPM !== "" &&
        otherInfo.offeringEquity !== "" &&
        otherInfo.offeringDebt !== ""
          ? `${plus100.toFixed(3)}%`
          : "-",
      plus150:
        otherInfo.startingPPM !== "" &&
        otherInfo.offeringEquity !== "" &&
        otherInfo.offeringDebt !== ""
          ? `${plus150.toFixed(3)}%`
          : "-",
    },
    {
      capRate: "Gross Sale",
      minus50:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              (futureValue1 / (minus50 / 100)).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      flat:
        otherInfo.startingPPM !== ""
          ? `$ ${Number((futureValue1 / (flat / 100)).toFixed()).toLocaleString(
              "en-US"
            )}`
          : "-",
      plus50:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              (futureValue1 / (plus50 / 100)).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      plus100:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              (futureValue1 / (plus100 / 100)).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      plus150:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              (futureValue1 / (plus150 / 100)).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
    },
    {
      capRate: "Net Sale (-4%)",
      minus50:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              ((futureValue1 / (minus50 / 100)) * 0.96).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      flat:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              ((futureValue1 / (flat / 100)) * 0.96).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      plus50:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              ((futureValue1 / (plus50 / 100)) * 0.96).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      plus100:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              ((futureValue1 / (plus100 / 100)) * 0.96).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      plus150:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              ((futureValue1 / (plus150 / 100)) * 0.96).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
    },
    {
      capRate: "Net Proceeds",
      minus50:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              ((futureValue1 / (minus50 / 100)) * 0.96 - cleanedDebt).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      flat:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              ((futureValue1 / (flat / 100)) * 0.96 - cleanedDebt).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      plus50:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              ((futureValue1 / (plus50 / 100)) * 0.96 - cleanedDebt).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      plus100:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              ((futureValue1 / (plus100 / 100)) * 0.96 - cleanedDebt).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      plus150:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              ((futureValue1 / (plus150 / 100)) * 0.96 - cleanedDebt).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
    },
    {
      capRate: "Equity Multiplier",
      minus50:
        otherInfo.startingPPM !== ""
          ? `${Number(
              (
                ((futureValue1 / (minus50 / 100)) * 0.96 - cleanedDebt) /
                cleanedEquity
              ).toFixed(2)
            ).toLocaleString("en-US")}`
          : "-",
      flat:
        otherInfo.startingPPM !== ""
          ? `${Number(
              (
                ((futureValue1 / (flat / 100)) * 0.96 - cleanedDebt) /
                cleanedEquity
              ).toFixed(2)
            ).toLocaleString("en-US")}`
          : "-",
      plus50:
        otherInfo.startingPPM !== ""
          ? `${Number(
              (
                ((futureValue1 / (plus50 / 100)) * 0.96 - cleanedDebt) /
                cleanedEquity
              ).toFixed(2)
            ).toLocaleString("en-US")}`
          : "-",
      plus100:
        otherInfo.startingPPM !== ""
          ? `${Number(
              (
                ((futureValue1 / (plus100 / 100)) * 0.96 - cleanedDebt) /
                cleanedEquity
              ).toFixed(2)
            ).toLocaleString("en-US")}`
          : "-",
      plus150:
        otherInfo.startingPPM !== ""
          ? `${Number(
              (
                ((futureValue1 / (plus150 / 100)) * 0.96 - cleanedDebt) /
                cleanedEquity
              ).toFixed(2)
            ).toLocaleString("en-US")}`
          : "-",
    },
  ];

  const rows2 = [
    {
      capRate: "Exit Cap Rate",
      minus50:
        otherInfo.startingPPM !== "" &&
        otherInfo.offeringEquity !== "" &&
        otherInfo.offeringDebt !== ""
          ? `${minus50.toFixed(3)}%`
          : "-",
      flat:
        otherInfo.startingPPM !== "" &&
        otherInfo.offeringEquity !== "" &&
        otherInfo.offeringDebt !== ""
          ? `${(+flat).toFixed(3)}%`
          : "-",
      plus50:
        otherInfo.startingPPM !== "" &&
        otherInfo.offeringEquity !== "" &&
        otherInfo.offeringDebt !== ""
          ? `${plus50.toFixed(3)}%`
          : "-",
      plus100:
        otherInfo.startingPPM !== "" &&
        otherInfo.offeringEquity !== "" &&
        otherInfo.offeringDebt !== ""
          ? `${plus100.toFixed(3)}%`
          : "-",
      plus150:
        otherInfo.startingPPM !== "" &&
        otherInfo.offeringEquity !== "" &&
        otherInfo.offeringDebt !== ""
          ? `${plus150.toFixed(3)}%`
          : "-",
    },
    {
      capRate: "Gross Sale",
      minus50:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              (futureValue2 / (minus50 / 100)).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      flat:
        otherInfo.startingPPM !== ""
          ? `$ ${Number((futureValue2 / (flat / 100)).toFixed()).toLocaleString(
              "en-US"
            )}`
          : "-",
      plus50:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              (futureValue2 / (plus50 / 100)).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      plus100:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              (futureValue2 / (plus100 / 100)).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      plus150:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              (futureValue2 / (plus150 / 100)).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
    },
    {
      capRate: "Net Sale (-4%)",
      minus50:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              ((futureValue2 / (minus50 / 100)) * 0.96).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      flat:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              ((futureValue2 / (flat / 100)) * 0.96).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      plus50:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              ((futureValue2 / (plus50 / 100)) * 0.96).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      plus100:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              ((futureValue2 / (plus100 / 100)) * 0.96).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      plus150:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              ((futureValue2 / (plus150 / 100)) * 0.96).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
    },
    {
      capRate: "Net Proceeds",
      minus50:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              ((futureValue2 / (minus50 / 100)) * 0.96 - cleanedDebt).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      flat:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              ((futureValue2 / (flat / 100)) * 0.96 - cleanedDebt).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      plus50:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              ((futureValue2 / (plus50 / 100)) * 0.96 - cleanedDebt).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      plus100:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              ((futureValue2 / (plus100 / 100)) * 0.96 - cleanedDebt).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      plus150:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              ((futureValue2 / (plus150 / 100)) * 0.96 - cleanedDebt).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
    },
    {
      capRate: "Equity Multiplier",
      minus50:
        otherInfo.startingPPM !== ""
          ? `${Number(
              (
                ((futureValue2 / (minus50 / 100)) * 0.96 - cleanedDebt) /
                cleanedEquity
              ).toFixed(2)
            ).toLocaleString("en-US")}`
          : "-",
      flat:
        otherInfo.startingPPM !== ""
          ? `${Number(
              (
                ((futureValue2 / (flat / 100)) * 0.96 - cleanedDebt) /
                cleanedEquity
              ).toFixed(2)
            ).toLocaleString("en-US")}`
          : "-",
      plus50:
        otherInfo.startingPPM !== ""
          ? `${Number(
              (
                ((futureValue2 / (plus50 / 100)) * 0.96 - cleanedDebt) /
                cleanedEquity
              ).toFixed(2)
            ).toLocaleString("en-US")}`
          : "-",
      plus100:
        otherInfo.startingPPM !== ""
          ? `${Number(
              (
                ((futureValue2 / (plus100 / 100)) * 0.96 - cleanedDebt) /
                cleanedEquity
              ).toFixed(2)
            ).toLocaleString("en-US")}`
          : "-",
      plus150:
        otherInfo.startingPPM !== ""
          ? `${Number(
              (
                ((futureValue2 / (plus150 / 100)) * 0.96 - cleanedDebt) /
                cleanedEquity
              ).toFixed(2)
            ).toLocaleString("en-US")}`
          : "-",
    },
  ];

  const rows3 = [
    {
      capRate: "Exit Cap Rate",
      minus50:
        otherInfo.startingPPM !== "" &&
        otherInfo.offeringEquity !== "" &&
        otherInfo.offeringDebt !== ""
          ? `${minus50.toFixed(3)}%`
          : "-",
      flat:
        otherInfo.startingPPM !== "" &&
        otherInfo.offeringEquity !== "" &&
        otherInfo.offeringDebt !== ""
          ? `${(+flat).toFixed(3)}%`
          : "-",
      plus50:
        otherInfo.startingPPM !== "" &&
        otherInfo.offeringEquity !== "" &&
        otherInfo.offeringDebt !== ""
          ? `${plus50.toFixed(3)}%`
          : "-",
      plus100:
        otherInfo.startingPPM !== "" &&
        otherInfo.offeringEquity !== "" &&
        otherInfo.offeringDebt !== ""
          ? `${plus100.toFixed(3)}%`
          : "-",
      plus150:
        otherInfo.startingPPM !== "" &&
        otherInfo.offeringEquity !== "" &&
        otherInfo.offeringDebt !== ""
          ? `${plus150.toFixed(3)}%`
          : "-",
    },
    {
      capRate: "Gross Sale",
      minus50:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              (futureValue3 / (minus50 / 100)).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      flat:
        otherInfo.startingPPM !== ""
          ? `$ ${Number((futureValue3 / (flat / 100)).toFixed()).toLocaleString(
              "en-US"
            )}`
          : "-",
      plus50:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              (futureValue3 / (plus50 / 100)).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      plus100:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              (futureValue3 / (plus100 / 100)).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      plus150:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              (futureValue3 / (plus150 / 100)).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
    },
    {
      capRate: "Net Sale (-4%)",
      minus50:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              ((futureValue3 / (minus50 / 100)) * 0.96).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      flat:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              ((futureValue3 / (flat / 100)) * 0.96).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      plus50:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              ((futureValue3 / (plus50 / 100)) * 0.96).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      plus100:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              ((futureValue3 / (plus100 / 100)) * 0.96).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      plus150:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              ((futureValue3 / (plus150 / 100)) * 0.96).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
    },
    {
      capRate: "Net Proceeds",
      minus50:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              ((futureValue3 / (minus50 / 100)) * 0.96 - cleanedDebt).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      flat:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              ((futureValue3 / (flat / 100)) * 0.96 - cleanedDebt).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      plus50:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              ((futureValue3 / (plus50 / 100)) * 0.96 - cleanedDebt).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      plus100:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              ((futureValue3 / (plus100 / 100)) * 0.96 - cleanedDebt).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      plus150:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              ((futureValue3 / (plus150 / 100)) * 0.96 - cleanedDebt).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
    },
    {
      capRate: "Equity Multiplier",
      minus50:
        otherInfo.startingPPM !== ""
          ? `${Number(
              (
                ((futureValue3 / (minus50 / 100)) * 0.96 - cleanedDebt) /
                cleanedEquity
              ).toFixed(2)
            ).toLocaleString("en-US")}`
          : "-",
      flat:
        otherInfo.startingPPM !== ""
          ? `${Number(
              (
                ((futureValue3 / (flat / 100)) * 0.96 - cleanedDebt) /
                cleanedEquity
              ).toFixed(2)
            ).toLocaleString("en-US")}`
          : "-",
      plus50:
        otherInfo.startingPPM !== ""
          ? `${Number(
              (
                ((futureValue3 / (plus50 / 100)) * 0.96 - cleanedDebt) /
                cleanedEquity
              ).toFixed(2)
            ).toLocaleString("en-US")}`
          : "-",
      plus100:
        otherInfo.startingPPM !== ""
          ? `${Number(
              (
                ((futureValue3 / (plus100 / 100)) * 0.96 - cleanedDebt) /
                cleanedEquity
              ).toFixed(2)
            ).toLocaleString("en-US")}`
          : "-",
      plus150:
        otherInfo.startingPPM !== ""
          ? `${Number(
              (
                ((futureValue3 / (plus150 / 100)) * 0.96 - cleanedDebt) /
                cleanedEquity
              ).toFixed(2)
            ).toLocaleString("en-US")}`
          : "-",
    },
  ];

  const rows4 = [
    {
      capRate: "Exit Cap Rate",
      minus50:
        otherInfo.startingPPM !== "" &&
        otherInfo.offeringEquity !== "" &&
        otherInfo.offeringDebt !== ""
          ? `${minus50.toFixed(3)}%`
          : "-",
      flat:
        otherInfo.startingPPM !== "" &&
        otherInfo.offeringEquity !== "" &&
        otherInfo.offeringDebt !== ""
          ? `${(+flat).toFixed(3)}%`
          : "-",
      plus50:
        otherInfo.startingPPM !== "" &&
        otherInfo.offeringEquity !== "" &&
        otherInfo.offeringDebt !== ""
          ? `${plus50.toFixed(3)}%`
          : "-",
      plus100:
        otherInfo.startingPPM !== "" &&
        otherInfo.offeringEquity !== "" &&
        otherInfo.offeringDebt !== ""
          ? `${plus100.toFixed(3)}%`
          : "-",
      plus150:
        otherInfo.startingPPM !== "" &&
        otherInfo.offeringEquity !== "" &&
        otherInfo.offeringDebt !== ""
          ? `${plus150.toFixed(3)}%`
          : "-",
    },
    {
      capRate: "Gross Sale",
      minus50:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              (futureValue4 / (minus50 / 100)).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      flat:
        otherInfo.startingPPM !== ""
          ? `$ ${Number((futureValue4 / (flat / 100)).toFixed()).toLocaleString(
              "en-US"
            )}`
          : "-",
      plus50:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              (futureValue4 / (plus50 / 100)).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      plus100:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              (futureValue4 / (plus100 / 100)).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      plus150:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              (futureValue4 / (plus150 / 100)).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
    },
    {
      capRate: "Net Sale (-4%)",
      minus50:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              ((futureValue4 / (minus50 / 100)) * 0.96).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      flat:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              ((futureValue4 / (flat / 100)) * 0.96).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      plus50:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              ((futureValue4 / (plus50 / 100)) * 0.96).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      plus100:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              ((futureValue4 / (plus100 / 100)) * 0.96).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      plus150:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              ((futureValue4 / (plus150 / 100)) * 0.96).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
    },
    {
      capRate: "Net Proceeds",
      minus50:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              ((futureValue4 / (minus50 / 100)) * 0.96 - cleanedDebt).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      flat:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              ((futureValue4 / (flat / 100)) * 0.96 - cleanedDebt).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      plus50:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              ((futureValue4 / (plus50 / 100)) * 0.96 - cleanedDebt).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      plus100:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              ((futureValue4 / (plus100 / 100)) * 0.96 - cleanedDebt).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      plus150:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              ((futureValue4 / (plus150 / 100)) * 0.96 - cleanedDebt).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
    },
    {
      capRate: "Equity Multiplier",
      minus50:
        otherInfo.startingPPM !== ""
          ? `${Number(
              (
                ((futureValue4 / (minus50 / 100)) * 0.96 - cleanedDebt) /
                cleanedEquity
              ).toFixed(2)
            ).toLocaleString("en-US")}`
          : "-",
      flat:
        otherInfo.startingPPM !== ""
          ? `${Number(
              (
                ((futureValue4 / (flat / 100)) * 0.96 - cleanedDebt) /
                cleanedEquity
              ).toFixed(2)
            ).toLocaleString("en-US")}`
          : "-",
      plus50:
        otherInfo.startingPPM !== ""
          ? `${Number(
              (
                ((futureValue4 / (plus50 / 100)) * 0.96 - cleanedDebt) /
                cleanedEquity
              ).toFixed(2)
            ).toLocaleString("en-US")}`
          : "-",
      plus100:
        otherInfo.startingPPM !== ""
          ? `${Number(
              (
                ((futureValue4 / (plus100 / 100)) * 0.96 - cleanedDebt) /
                cleanedEquity
              ).toFixed(2)
            ).toLocaleString("en-US")}`
          : "-",
      plus150:
        otherInfo.startingPPM !== ""
          ? `${Number(
              (
                ((futureValue4 / (plus150 / 100)) * 0.96 - cleanedDebt) /
                cleanedEquity
              ).toFixed(2)
            ).toLocaleString("en-US")}`
          : "-",
    },
  ];

  const rows5 = [
    {
      capRate: "Exit Cap Rate",
      minus50:
        otherInfo.startingPPM !== "" &&
        otherInfo.offeringEquity !== "" &&
        otherInfo.offeringDebt !== ""
          ? `${minus50.toFixed(3)}%`
          : "-",
      flat:
        otherInfo.startingPPM !== "" &&
        otherInfo.offeringEquity !== "" &&
        otherInfo.offeringDebt !== ""
          ? `${(+flat).toFixed(3)}%`
          : "-",
      plus50:
        otherInfo.startingPPM !== "" &&
        otherInfo.offeringEquity !== "" &&
        otherInfo.offeringDebt !== ""
          ? `${plus50.toFixed(3)}%`
          : "-",
      plus100:
        otherInfo.startingPPM !== "" &&
        otherInfo.offeringEquity !== "" &&
        otherInfo.offeringDebt !== ""
          ? `${plus100.toFixed(3)}%`
          : "-",
      plus150:
        otherInfo.startingPPM !== "" &&
        otherInfo.offeringEquity !== "" &&
        otherInfo.offeringDebt !== ""
          ? `${plus150.toFixed(3)}%`
          : "-",
    },
    {
      capRate: "Gross Sale",
      minus50:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              (futureValue5 / (minus50 / 100)).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      flat:
        otherInfo.startingPPM !== ""
          ? `$ ${Number((futureValue5 / (flat / 100)).toFixed()).toLocaleString(
              "en-US"
            )}`
          : "-",
      plus50:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              (futureValue5 / (plus50 / 100)).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      plus100:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              (futureValue5 / (plus100 / 100)).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      plus150:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              (futureValue5 / (plus150 / 100)).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
    },
    {
      capRate: "Net Sale (-4%)",
      minus50:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              ((futureValue5 / (minus50 / 100)) * 0.96).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      flat:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              ((futureValue5 / (flat / 100)) * 0.96).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      plus50:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              ((futureValue5 / (plus50 / 100)) * 0.96).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      plus100:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              ((futureValue5 / (plus100 / 100)) * 0.96).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      plus150:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              ((futureValue5 / (plus150 / 100)) * 0.96).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
    },
    {
      capRate: "Net Proceeds",
      minus50:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              ((futureValue5 / (minus50 / 100)) * 0.96 - cleanedDebt).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      flat:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              ((futureValue5 / (flat / 100)) * 0.96 - cleanedDebt).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      plus50:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              ((futureValue5 / (plus50 / 100)) * 0.96 - cleanedDebt).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      plus100:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              ((futureValue5 / (plus100 / 100)) * 0.96 - cleanedDebt).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      plus150:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              ((futureValue5 / (plus150 / 100)) * 0.96 - cleanedDebt).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
    },
    {
      capRate: "Equity Multiplier",
      minus50:
        otherInfo.startingPPM !== ""
          ? `${Number(
              (
                ((futureValue5 / (minus50 / 100)) * 0.96 - cleanedDebt) /
                cleanedEquity
              ).toFixed(2)
            ).toLocaleString("en-US")}`
          : "-",
      flat:
        otherInfo.startingPPM !== ""
          ? `${Number(
              (
                ((futureValue5 / (flat / 100)) * 0.96 - cleanedDebt) /
                cleanedEquity
              ).toFixed(2)
            ).toLocaleString("en-US")}`
          : "-",
      plus50:
        otherInfo.startingPPM !== ""
          ? `${Number(
              (
                ((futureValue5 / (plus50 / 100)) * 0.96 - cleanedDebt) /
                cleanedEquity
              ).toFixed(2)
            ).toLocaleString("en-US")}`
          : "-",
      plus100:
        otherInfo.startingPPM !== ""
          ? `${Number(
              (
                ((futureValue5 / (plus100 / 100)) * 0.96 - cleanedDebt) /
                cleanedEquity
              ).toFixed(2)
            ).toLocaleString("en-US")}`
          : "-",
      plus150:
        otherInfo.startingPPM !== ""
          ? `${Number(
              (
                ((futureValue5 / (plus150 / 100)) * 0.96 - cleanedDebt) /
                cleanedEquity
              ).toFixed(2)
            ).toLocaleString("en-US")}`
          : "-",
    },
  ];

  const rows6 = [
    {
      capRate: "Exit Cap Rate",
      minus50:
        otherInfo.startingPPM !== "" &&
        otherInfo.offeringEquity !== "" &&
        otherInfo.offeringDebt !== ""
          ? `${minus50.toFixed(3)}%`
          : "-",
      flat:
        otherInfo.startingPPM !== "" &&
        otherInfo.offeringEquity !== "" &&
        otherInfo.offeringDebt !== ""
          ? `${(+flat).toFixed(3)}%`
          : "-",
      plus50:
        otherInfo.startingPPM !== "" &&
        otherInfo.offeringEquity !== "" &&
        otherInfo.offeringDebt !== ""
          ? `${plus50.toFixed(3)}%`
          : "-",
      plus100:
        otherInfo.startingPPM !== "" &&
        otherInfo.offeringEquity !== "" &&
        otherInfo.offeringDebt !== ""
          ? `${plus100.toFixed(3)}%`
          : "-",
      plus150:
        otherInfo.startingPPM !== "" &&
        otherInfo.offeringEquity !== "" &&
        otherInfo.offeringDebt !== ""
          ? `${plus150.toFixed(3)}%`
          : "-",
    },
    {
      capRate: "Gross Sale",
      minus50:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              (futureValue6 / (minus50 / 100)).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      flat:
        otherInfo.startingPPM !== ""
          ? `$ ${Number((futureValue6 / (flat / 100)).toFixed()).toLocaleString(
              "en-US"
            )}`
          : "-",
      plus50:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              (futureValue6 / (plus50 / 100)).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      plus100:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              (futureValue6 / (plus100 / 100)).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      plus150:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              (futureValue6 / (plus150 / 100)).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
    },
    {
      capRate: "Net Sale (-4%)",
      minus50:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              ((futureValue6 / (minus50 / 100)) * 0.96).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      flat:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              ((futureValue6 / (flat / 100)) * 0.96).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      plus50:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              ((futureValue6 / (plus50 / 100)) * 0.96).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      plus100:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              ((futureValue6 / (plus100 / 100)) * 0.96).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      plus150:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              ((futureValue6 / (plus150 / 100)) * 0.96).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
    },
    {
      capRate: "Net Proceeds",
      minus50:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              ((futureValue6 / (minus50 / 100)) * 0.96 - cleanedDebt).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      flat:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              ((futureValue6 / (flat / 100)) * 0.96 - cleanedDebt).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      plus50:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              ((futureValue6 / (plus50 / 100)) * 0.96 - cleanedDebt).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      plus100:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              ((futureValue6 / (plus100 / 100)) * 0.96 - cleanedDebt).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      plus150:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              ((futureValue6 / (plus150 / 100)) * 0.96 - cleanedDebt).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
    },
    {
      capRate: "Equity Multiplier",
      minus50:
        otherInfo.startingPPM !== ""
          ? `${Number(
              (
                ((futureValue6 / (minus50 / 100)) * 0.96 - cleanedDebt) /
                cleanedEquity
              ).toFixed(2)
            ).toLocaleString("en-US")}`
          : "-",
      flat:
        otherInfo.startingPPM !== ""
          ? `${Number(
              (
                ((futureValue6 / (flat / 100)) * 0.96 - cleanedDebt) /
                cleanedEquity
              ).toFixed(2)
            ).toLocaleString("en-US")}`
          : "-",
      plus50:
        otherInfo.startingPPM !== ""
          ? `${Number(
              (
                ((futureValue6 / (plus50 / 100)) * 0.96 - cleanedDebt) /
                cleanedEquity
              ).toFixed(2)
            ).toLocaleString("en-US")}`
          : "-",
      plus100:
        otherInfo.startingPPM !== ""
          ? `${Number(
              (
                ((futureValue6 / (plus100 / 100)) * 0.96 - cleanedDebt) /
                cleanedEquity
              ).toFixed(2)
            ).toLocaleString("en-US")}`
          : "-",
      plus150:
        otherInfo.startingPPM !== ""
          ? `${Number(
              (
                ((futureValue6 / (plus150 / 100)) * 0.96 - cleanedDebt) /
                cleanedEquity
              ).toFixed(2)
            ).toLocaleString("en-US")}`
          : "-",
    },
  ];

  const rows7 = [
    {
      capRate: "Exit Cap Rate",
      minus50:
        otherInfo.startingPPM !== "" &&
        otherInfo.offeringEquity !== "" &&
        otherInfo.offeringDebt !== ""
          ? `${minus50.toFixed(3)}%`
          : "-",
      flat:
        otherInfo.startingPPM !== "" &&
        otherInfo.offeringEquity !== "" &&
        otherInfo.offeringDebt !== ""
          ? `${(+flat).toFixed(3)}%`
          : "-",
      plus50:
        otherInfo.startingPPM !== "" &&
        otherInfo.offeringEquity !== "" &&
        otherInfo.offeringDebt !== ""
          ? `${plus50.toFixed(3)}%`
          : "-",
      plus100:
        otherInfo.startingPPM !== "" &&
        otherInfo.offeringEquity !== "" &&
        otherInfo.offeringDebt !== ""
          ? `${plus100.toFixed(3)}%`
          : "-",
      plus150:
        otherInfo.startingPPM !== "" &&
        otherInfo.offeringEquity !== "" &&
        otherInfo.offeringDebt !== ""
          ? `${plus150.toFixed(3)}%`
          : "-",
    },
    {
      capRate: "Gross Sale",
      minus50:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              (futureValue7 / (minus50 / 100)).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      flat:
        otherInfo.startingPPM !== ""
          ? `$ ${Number((futureValue7 / (flat / 100)).toFixed()).toLocaleString(
              "en-US"
            )}`
          : "-",
      plus50:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              (futureValue7 / (plus50 / 100)).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      plus100:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              (futureValue7 / (plus100 / 100)).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      plus150:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              (futureValue7 / (plus150 / 100)).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
    },
    {
      capRate: "Net Sale (-4%)",
      minus50:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              ((futureValue7 / (minus50 / 100)) * 0.96).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      flat:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              ((futureValue7 / (flat / 100)) * 0.96).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      plus50:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              ((futureValue7 / (plus50 / 100)) * 0.96).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      plus100:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              ((futureValue7 / (plus100 / 100)) * 0.96).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      plus150:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              ((futureValue7 / (plus150 / 100)) * 0.96).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
    },
    {
      capRate: "Net Proceeds",
      minus50:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              ((futureValue7 / (minus50 / 100)) * 0.96 - cleanedDebt).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      flat:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              ((futureValue7 / (flat / 100)) * 0.96 - cleanedDebt).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      plus50:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              ((futureValue7 / (plus50 / 100)) * 0.96 - cleanedDebt).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      plus100:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              ((futureValue7 / (plus100 / 100)) * 0.96 - cleanedDebt).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
      plus150:
        otherInfo.startingPPM !== ""
          ? `$ ${Number(
              ((futureValue7 / (plus150 / 100)) * 0.96 - cleanedDebt).toFixed()
            ).toLocaleString("en-US")}`
          : "-",
    },
    {
      capRate: "Equity Multiplier",
      minus50:
        otherInfo.startingPPM !== ""
          ? `${Number(
              (
                ((futureValue7 / (minus50 / 100)) * 0.96 - cleanedDebt) /
                cleanedEquity
              ).toFixed(2)
            ).toLocaleString("en-US")}`
          : "-",
      flat:
        otherInfo.startingPPM !== ""
          ? `${Number(
              (
                ((futureValue7 / (flat / 100)) * 0.96 - cleanedDebt) /
                cleanedEquity
              ).toFixed(2)
            ).toLocaleString("en-US")}`
          : "-",
      plus50:
        otherInfo.startingPPM !== ""
          ? `${Number(
              (
                ((futureValue7 / (plus50 / 100)) * 0.96 - cleanedDebt) /
                cleanedEquity
              ).toFixed(2)
            ).toLocaleString("en-US")}`
          : "-",
      plus100:
        otherInfo.startingPPM !== ""
          ? `${Number(
              (
                ((futureValue7 / (plus100 / 100)) * 0.96 - cleanedDebt) /
                cleanedEquity
              ).toFixed(2)
            ).toLocaleString("en-US")}`
          : "-",
      plus150:
        otherInfo.startingPPM !== ""
          ? `${Number(
              (
                ((futureValue7 / (plus150 / 100)) * 0.96 - cleanedDebt) /
                cleanedEquity
              ).toFixed(2)
            ).toLocaleString("en-US")}`
          : "-",
    },
  ];

  const handleAddData = () => {
    navigate("/noi-cap-rate-report", {
      state: {
        otherInfo,
        rows1,
        rows2,
        rows3,
        rows4,
        rows5,
        rows6,
        rows7,
      },
    });
    const data = {
      otherInfo: otherInfo,
      rows1: rows1,
      rows2: rows2,
      rows3: rows3,
      rows4: rows4,
      rows5: rows5,
      rows6: rows6,
      rows7: rows7,
    };
    console.log("The data is......", data);
    sessionStorage.setItem("noiSession", JSON.stringify(data));
  };

  const updateNOISessionStorage = (
    otherInfo,
    rows1,
    rows2,
    rows3,
    rows4,
    rows5,
    rows6,
    rows7
  ) => {
    const data = {
      otherInfo,
      rows1,
      rows2,
      rows3,
      rows4,
      rows5,
      rows6,
      rows7,
    };
    sessionStorage.setItem("noiSession", JSON.stringify(data));
  };

  const sessionClear = async () => {
    sessionStorage.clear("noiSession");
    setOtherInfo((prevFormData) => {
      const clearedState = Object.fromEntries(
        Object.keys(prevFormData).map((key) => [key, ""])
      );
      return clearedState;
    });
  };
  return (
    <>
      <div style={{ margin: "2rem" }}>
        <Grid container spacing={2}>
          <Card
            style={{
              width: "100%",
              marginTop: "1rem",
              marginLeft: "1rem",
              padding: "1rem 0rem",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "1.5rem",
              }}
            >
              <h4 style={{ margin: "1rem 2rem" }}>
                NOI/Cap Rate Sensitivity Analysis
              </h4>
              <div style={{ margin: "0rem 2rem" }}>
                <Button
                  onClick={handleAddData}
                  type="submit"
                  variant="contained"
                  style={{
                    marginLeft: "2rem",
                    marginTop: "1rem",
                    marginBottom: "1rem",
                  }}
                >
                  View Report
                </Button>
                <Button
                  onClick={sessionClear}
                  type="submit"
                  variant="contained"
                  style={{
                    marginLeft: "2rem",
                    marginTop: "1rem",
                    marginBottom: "1rem",
                  }}
                >
                  Reset
                </Button>
              </div>
            </div>
            <div style={{ margin: "0rem 2rem" }}>
              <Grid container spacing={2}>
                <Grid item xs={3} className="Grid-Gap">
                  <h5>Client Investment</h5>
                </Grid>
                <Grid item xs={3} className="Grid-Gap">
                  <TextField
                    size="small"
                    margin="normal"
                    fullWidth
                    name="offeringEquity"
                    label="Offering Equity"
                    value={otherInfo.offeringEquity}
                    onChange={handleTotalChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={3} className="Grid-Gap">
                  <TextField
                    size="small"
                    margin="normal"
                    fullWidth
                    name="offeringDebt"
                    label="Offering Debt"
                    value={otherInfo.offeringDebt}
                    onChange={handleTotalChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={3} className="Grid-Gap">
                  <TextField
                    size="small"
                    margin="normal"
                    fullWidth
                    name="total"
                    label="Total Offering Price"
                    value={otherInfo.total}
                    InputProps={{
                      readOnly: true,
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </div>
            <div style={{ margin: "2rem 2rem" }}>
              <Grid container spacing={2}>
                <Grid item xs={3} className="Grid-Gap">
                  {/* <h5>Client Investment</h5> */}
                </Grid>
                <Grid item xs={3} className="Grid-Gap">
                  <TextField
                    size="small"
                    margin="normal"
                    fullWidth
                    name="startingPPM"
                    label="Starting NOI From PPM"
                    value={otherInfo.startingPPM}
                    onChange={handleTotalChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={3} className="Grid-Gap">
                  <TextField
                    size="small"
                    margin="normal"
                    fullWidth
                    name="capRate"
                    label="All in CAP Rate"
                    value={(+otherInfo.capRate).toFixed(2) || null}
                    InputProps={{
                      readOnly: true,
                      startAdornment: (
                        <InputAdornment position="start">%</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </div>
            <div style={{ marginLeft: "2rem", marginTop: "5rem" }}>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={3}
                  className="Grid-Gap"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h5>Annual NOI Change (+4.5%)</h5>
                </Grid>
                <Grid item xs={9} className="Grid-Gap">
                  <TableContainer>
                    <Table
                      style={{ minWidth: 700, margin: "0 auto" }}
                      aria-label="customized table"
                    >
                      <TableHead>
                        <TableRow>
                          <StyledTableCell align="center">
                            Cap Rate
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            -50 bps
                          </StyledTableCell>
                          <StyledTableCell align="center">Flat</StyledTableCell>
                          <StyledTableCell align="center">
                            +50 bps
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            +100 bps
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            +150 bps
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows1.map((row, ind) => (
                          <StyledTableRow key={ind}>
                            <StyledTableCell align="center">
                              {row.capRate}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.minus50 !== "-" ? (
                                <span
                                  style={{
                                    color:
                                      ind === rows1.length - 1
                                        ? row.minus50 >= 1.0
                                          ? "green"
                                          : row.minus50 >= 0.9 &&
                                            row.minus50 < 1.0
                                          ? "#ff9f00"
                                          : row.minus50 < 0.9
                                          ? "red"
                                          : "normal"
                                        : "normal",
                                    fontWeight:
                                      ind === rows1.length - 1
                                        ? "bold"
                                        : "normal",
                                  }}
                                >
                                  {row.minus50}
                                </span>
                              ) : (
                                "-"
                              )}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.flat !== "-" ? (
                                <span
                                  style={{
                                    color:
                                      ind === rows1.length - 1
                                        ? row.flat >= 1.0
                                          ? "green"
                                          : row.flat >= 0.9 && row.flat < 1.0
                                          ? "#ff9f00"
                                          : row.flat < 0.9
                                          ? "red"
                                          : "normal"
                                        : "normal",
                                    fontWeight:
                                      ind === rows1.length - 1
                                        ? "bold"
                                        : "normal",
                                  }}
                                >
                                  {row.flat}
                                </span>
                              ) : (
                                "-"
                              )}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.plus50 !== "-" ? (
                                <span
                                  style={{
                                    color:
                                      ind === rows1.length - 1
                                        ? row.plus50 >= 1.0
                                          ? "green"
                                          : row.plus50 >= 0.9 &&
                                            row.plus50 < 1.0
                                          ? "#ff9f00"
                                          : row.plus50 < 0.9
                                          ? "red"
                                          : "normal"
                                        : "normal",
                                    fontWeight:
                                      ind === rows1.length - 1
                                        ? "bold"
                                        : "normal",
                                  }}
                                >
                                  {row.plus50}
                                </span>
                              ) : (
                                "-"
                              )}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.plus100 !== "-" ? (
                                <span
                                  style={{
                                    color:
                                      ind === rows1.length - 1
                                        ? row.plus100 >= 1.0
                                          ? "green"
                                          : row.plus100 >= 0.9 &&
                                            row.plus100 < 1.0
                                          ? "#ff9f00"
                                          : row.plus100 < 0.9
                                          ? "red"
                                          : "normal"
                                        : "normal",
                                    fontWeight:
                                      ind === rows1.length - 1
                                        ? "bold"
                                        : "normal",
                                  }}
                                >
                                  {row.plus100}
                                </span>
                              ) : (
                                "-"
                              )}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.plus150 !== "-" ? (
                                <span
                                  style={{
                                    color:
                                      ind === rows1.length - 1
                                        ? row.plus150 >= 1.0
                                          ? "green"
                                          : row.plus150 >= 0.9 &&
                                            row.plus150 < 1.0
                                          ? "#ff9f00"
                                          : row.plus150 < 0.9
                                          ? "red"
                                          : "normal"
                                        : "normal",
                                    fontWeight:
                                      ind === rows1.length - 1
                                        ? "bold"
                                        : "normal",
                                  }}
                                >
                                  {row.plus150}
                                </span>
                              ) : (
                                "-"
                              )}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </div>
            <div style={{ marginLeft: "2rem", marginTop: "4rem" }}>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={3}
                  className="Grid-Gap"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h5>Annual NOI Change (+3%)</h5>
                </Grid>
                <Grid item xs={9} className="Grid-Gap">
                  <TableContainer>
                    <Table
                      style={{ minWidth: 700, margin: "0 auto" }}
                      aria-label="customized table"
                    >
                      <TableHead>
                        <TableRow>
                          <StyledTableCell align="center">
                            Cap Rate
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            -50 bps
                          </StyledTableCell>
                          <StyledTableCell align="center">Flat</StyledTableCell>
                          <StyledTableCell align="center">
                            +50 bps
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            +100 bps
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            +150 bps
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows2.map((row, ind) => (
                          <StyledTableRow key={ind}>
                            <StyledTableCell align="center">
                              {row.capRate}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.minus50 !== "-" ? (
                                <span
                                  style={{
                                    color:
                                      ind === rows1.length - 1
                                        ? row.minus50 >= 1.0
                                          ? "green"
                                          : row.minus50 >= 0.9 &&
                                            row.minus50 < 1.0
                                          ? "#ff9f00"
                                          : row.minus50 < 0.9
                                          ? "red"
                                          : "normal"
                                        : "normal",
                                    fontWeight:
                                      ind === rows1.length - 1
                                        ? "bold"
                                        : "normal",
                                  }}
                                >
                                  {row.minus50}
                                </span>
                              ) : (
                                "-"
                              )}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.flat !== "-" ? (
                                <span
                                  style={{
                                    color:
                                      ind === rows1.length - 1
                                        ? row.flat >= 1.0
                                          ? "green"
                                          : row.flat >= 0.9 && row.flat < 1.0
                                          ? "#ff9f00"
                                          : row.flat < 0.9
                                          ? "red"
                                          : "normal"
                                        : "normal",
                                    fontWeight:
                                      ind === rows1.length - 1
                                        ? "bold"
                                        : "normal",
                                  }}
                                >
                                  {row.flat}
                                </span>
                              ) : (
                                "-"
                              )}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.plus50 !== "-" ? (
                                <span
                                  style={{
                                    color:
                                      ind === rows1.length - 1
                                        ? row.plus50 >= 1.0
                                          ? "green"
                                          : row.plus50 >= 0.9 &&
                                            row.plus50 < 1.0
                                          ? "#ff9f00"
                                          : row.plus50 < 0.9
                                          ? "red"
                                          : "normal"
                                        : "normal",
                                    fontWeight:
                                      ind === rows1.length - 1
                                        ? "bold"
                                        : "normal",
                                  }}
                                >
                                  {row.plus50}
                                </span>
                              ) : (
                                "-"
                              )}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.plus100 !== "-" ? (
                                <span
                                  style={{
                                    color:
                                      ind === rows1.length - 1
                                        ? row.plus100 >= 1.0
                                          ? "green"
                                          : row.plus100 >= 0.9 &&
                                            row.plus100 < 1.0
                                          ? "#ff9f00"
                                          : row.plus100 < 0.9
                                          ? "red"
                                          : "normal"
                                        : "normal",
                                    fontWeight:
                                      ind === rows1.length - 1
                                        ? "bold"
                                        : "normal",
                                  }}
                                >
                                  {row.plus100}
                                </span>
                              ) : (
                                "-"
                              )}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.plus150 !== "-" ? (
                                <span
                                  style={{
                                    color:
                                      ind === rows1.length - 1
                                        ? row.plus150 >= 1.0
                                          ? "green"
                                          : row.plus150 >= 0.9 &&
                                            row.plus150 < 1.0
                                          ? "#ff9f00"
                                          : row.plus150 < 0.9
                                          ? "red"
                                          : "normal"
                                        : "normal",
                                    fontWeight:
                                      ind === rows1.length - 1
                                        ? "bold"
                                        : "normal",
                                  }}
                                >
                                  {row.plus150}
                                </span>
                              ) : (
                                "-"
                              )}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </div>
            <div style={{ marginLeft: "2rem", marginTop: "4rem" }}>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={3}
                  className="Grid-Gap"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h5>Annual NOI Change (+1.5%)</h5>
                </Grid>
                <Grid item xs={9} className="Grid-Gap">
                  <TableContainer>
                    <Table
                      style={{ minWidth: 700, margin: "0 auto" }}
                      aria-label="customized table"
                    >
                      <TableHead>
                        <TableRow>
                          <StyledTableCell align="center">
                            Cap Rate
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            -50 bps
                          </StyledTableCell>
                          <StyledTableCell align="center">Flat</StyledTableCell>
                          <StyledTableCell align="center">
                            +50 bps
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            +100 bps
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            +150 bps
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows3.map((row, ind) => (
                          <StyledTableRow key={ind}>
                            <StyledTableCell align="center">
                              {row.capRate}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.minus50 !== "-" ? (
                                <span
                                  style={{
                                    color:
                                      ind === rows1.length - 1
                                        ? row.minus50 >= 1.0
                                          ? "green"
                                          : row.minus50 >= 0.9 &&
                                            row.minus50 < 1.0
                                          ? "#ff9f00"
                                          : row.minus50 < 0.9
                                          ? "red"
                                          : "normal"
                                        : "normal",
                                    fontWeight:
                                      ind === rows1.length - 1
                                        ? "bold"
                                        : "normal",
                                  }}
                                >
                                  {row.minus50}
                                </span>
                              ) : (
                                "-"
                              )}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.flat !== "-" ? (
                                <span
                                  style={{
                                    color:
                                      ind === rows1.length - 1
                                        ? row.flat >= 1.0
                                          ? "green"
                                          : row.flat >= 0.9 && row.flat < 1.0
                                          ? "#ff9f00"
                                          : row.flat < 0.9
                                          ? "red"
                                          : "normal"
                                        : "normal",
                                    fontWeight:
                                      ind === rows1.length - 1
                                        ? "bold"
                                        : "normal",
                                  }}
                                >
                                  {row.flat}
                                </span>
                              ) : (
                                "-"
                              )}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.plus50 !== "-" ? (
                                <span
                                  style={{
                                    color:
                                      ind === rows1.length - 1
                                        ? row.plus50 >= 1.0
                                          ? "green"
                                          : row.plus50 >= 0.9 &&
                                            row.plus50 < 1.0
                                          ? "#ff9f00"
                                          : row.plus50 < 0.9
                                          ? "red"
                                          : "normal"
                                        : "normal",
                                    fontWeight:
                                      ind === rows1.length - 1
                                        ? "bold"
                                        : "normal",
                                  }}
                                >
                                  {row.plus50}
                                </span>
                              ) : (
                                "-"
                              )}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.plus100 !== "-" ? (
                                <span
                                  style={{
                                    color:
                                      ind === rows1.length - 1
                                        ? row.plus100 >= 1.0
                                          ? "green"
                                          : row.plus100 >= 0.9 &&
                                            row.plus100 < 1.0
                                          ? "#ff9f00"
                                          : row.plus100 < 0.9
                                          ? "red"
                                          : "normal"
                                        : "normal",
                                    fontWeight:
                                      ind === rows1.length - 1
                                        ? "bold"
                                        : "normal",
                                  }}
                                >
                                  {row.plus100}
                                </span>
                              ) : (
                                "-"
                              )}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.plus150 !== "-" ? (
                                <span
                                  style={{
                                    color:
                                      ind === rows1.length - 1
                                        ? row.plus150 >= 1.0
                                          ? "green"
                                          : row.plus150 >= 0.9 &&
                                            row.plus150 < 1.0
                                          ? "#ff9f00"
                                          : row.plus150 < 0.9
                                          ? "red"
                                          : "normal"
                                        : "normal",
                                    fontWeight:
                                      ind === rows1.length - 1
                                        ? "bold"
                                        : "normal",
                                  }}
                                >
                                  {row.plus150}
                                </span>
                              ) : (
                                "-"
                              )}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </div>
            <div style={{ marginLeft: "2rem", marginTop: "4rem" }}>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={3}
                  className="Grid-Gap"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h5>Annual NOI Change (Flat)</h5>
                </Grid>
                <Grid item xs={9} className="Grid-Gap">
                  <TableContainer>
                    <Table
                      style={{ minWidth: 700, margin: "0 auto" }}
                      aria-label="customized table"
                    >
                      <TableHead>
                        <TableRow>
                          <StyledTableCell align="center">
                            Cap Rate
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            -50 bps
                          </StyledTableCell>
                          <StyledTableCell align="center">Flat</StyledTableCell>
                          <StyledTableCell align="center">
                            +50 bps
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            +100 bps
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            +150 bps
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows4.map((row, ind) => (
                          <StyledTableRow key={ind}>
                            <StyledTableCell align="center">
                              {row.capRate}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.minus50 !== "-" ? (
                                <span
                                  style={{
                                    color:
                                      ind === rows1.length - 1
                                        ? row.minus50 >= 1.0
                                          ? "green"
                                          : row.minus50 >= 0.9 &&
                                            row.minus50 < 1.0
                                          ? "#ff9f00"
                                          : row.minus50 < 0.9
                                          ? "red"
                                          : "normal"
                                        : "normal",
                                    fontWeight:
                                      ind === rows1.length - 1
                                        ? "bold"
                                        : "normal",
                                  }}
                                >
                                  {row.minus50}
                                </span>
                              ) : (
                                "-"
                              )}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.flat !== "-" ? (
                                <span
                                  style={{
                                    color:
                                      ind === rows1.length - 1
                                        ? row.flat >= 1.0
                                          ? "green"
                                          : row.flat >= 0.9 && row.flat < 1.0
                                          ? "#ff9f00"
                                          : row.flat < 0.9
                                          ? "red"
                                          : "normal"
                                        : "normal",
                                    fontWeight:
                                      ind === rows1.length - 1
                                        ? "bold"
                                        : "normal",
                                  }}
                                >
                                  {row.flat}
                                </span>
                              ) : (
                                "-"
                              )}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.plus50 !== "-" ? (
                                <span
                                  style={{
                                    color:
                                      ind === rows1.length - 1
                                        ? row.plus50 >= 1.0
                                          ? "green"
                                          : row.plus50 >= 0.9 &&
                                            row.plus50 < 1.0
                                          ? "#ff9f00"
                                          : row.plus50 < 0.9
                                          ? "red"
                                          : "normal"
                                        : "normal",
                                    fontWeight:
                                      ind === rows1.length - 1
                                        ? "bold"
                                        : "normal",
                                  }}
                                >
                                  {row.plus50}
                                </span>
                              ) : (
                                "-"
                              )}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.plus100 !== "-" ? (
                                <span
                                  style={{
                                    color:
                                      ind === rows1.length - 1
                                        ? row.plus100 >= 1.0
                                          ? "green"
                                          : row.plus100 >= 0.9 &&
                                            row.plus100 < 1.0
                                          ? "#ff9f00"
                                          : row.plus100 < 0.9
                                          ? "red"
                                          : "normal"
                                        : "normal",
                                    fontWeight:
                                      ind === rows1.length - 1
                                        ? "bold"
                                        : "normal",
                                  }}
                                >
                                  {row.plus100}
                                </span>
                              ) : (
                                "-"
                              )}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.plus150 !== "-" ? (
                                <span
                                  style={{
                                    color:
                                      ind === rows1.length - 1
                                        ? row.plus150 >= 1.0
                                          ? "green"
                                          : row.plus150 >= 0.9 &&
                                            row.plus150 < 1.0
                                          ? "#ff9f00"
                                          : row.plus150 < 0.9
                                          ? "red"
                                          : "normal"
                                        : "normal",
                                    fontWeight:
                                      ind === rows1.length - 1
                                        ? "bold"
                                        : "normal",
                                  }}
                                >
                                  {row.plus150}
                                </span>
                              ) : (
                                "-"
                              )}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </div>
            <div style={{ marginLeft: "2rem", marginTop: "4rem" }}>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={3}
                  className="Grid-Gap"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h5>Annual NOI Change (-1.5%)</h5>
                </Grid>
                <Grid item xs={9} className="Grid-Gap">
                  <TableContainer>
                    <Table
                      style={{ minWidth: 700, margin: "0 auto" }}
                      aria-label="customized table"
                    >
                      <TableHead>
                        <TableRow>
                          <StyledTableCell align="center">
                            Cap Rate
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            -50 bps
                          </StyledTableCell>
                          <StyledTableCell align="center">Flat</StyledTableCell>
                          <StyledTableCell align="center">
                            +50 bps
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            +100 bps
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            +150 bps
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows5.map((row, ind) => (
                          <StyledTableRow key={ind}>
                            <StyledTableCell align="center">
                              {row.capRate}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.minus50 !== "-" ? (
                                <span
                                  style={{
                                    color:
                                      ind === rows1.length - 1
                                        ? row.minus50 >= 1.0
                                          ? "green"
                                          : row.minus50 >= 0.9 &&
                                            row.minus50 < 1.0
                                          ? "#ff9f00"
                                          : row.minus50 < 0.9
                                          ? "red"
                                          : "normal"
                                        : "normal",
                                    fontWeight:
                                      ind === rows1.length - 1
                                        ? "bold"
                                        : "normal",
                                  }}
                                >
                                  {row.minus50}
                                </span>
                              ) : (
                                "-"
                              )}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.flat !== "-" ? (
                                <span
                                  style={{
                                    color:
                                      ind === rows1.length - 1
                                        ? row.flat >= 1.0
                                          ? "green"
                                          : row.flat >= 0.9 && row.flat < 1.0
                                          ? "#ff9f00"
                                          : row.flat < 0.9
                                          ? "red"
                                          : "normal"
                                        : "normal",
                                    fontWeight:
                                      ind === rows1.length - 1
                                        ? "bold"
                                        : "normal",
                                  }}
                                >
                                  {row.flat}
                                </span>
                              ) : (
                                "-"
                              )}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.plus50 !== "-" ? (
                                <span
                                  style={{
                                    color:
                                      ind === rows1.length - 1
                                        ? row.plus50 >= 1.0
                                          ? "green"
                                          : row.plus50 >= 0.9 &&
                                            row.plus50 < 1.0
                                          ? "#ff9f00"
                                          : row.plus50 < 0.9
                                          ? "red"
                                          : "normal"
                                        : "normal",
                                    fontWeight:
                                      ind === rows1.length - 1
                                        ? "bold"
                                        : "normal",
                                  }}
                                >
                                  {row.plus50}
                                </span>
                              ) : (
                                "-"
                              )}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.plus100 !== "-" ? (
                                <span
                                  style={{
                                    color:
                                      ind === rows1.length - 1
                                        ? row.plus100 >= 1.0
                                          ? "green"
                                          : row.plus100 >= 0.9 &&
                                            row.plus100 < 1.0
                                          ? "#ff9f00"
                                          : row.plus100 < 0.9
                                          ? "red"
                                          : "normal"
                                        : "normal",
                                    fontWeight:
                                      ind === rows1.length - 1
                                        ? "bold"
                                        : "normal",
                                  }}
                                >
                                  {row.plus100}
                                </span>
                              ) : (
                                "-"
                              )}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.plus150 !== "-" ? (
                                <span
                                  style={{
                                    color:
                                      ind === rows1.length - 1
                                        ? row.plus150 >= 1.0
                                          ? "green"
                                          : row.plus150 >= 0.9 &&
                                            row.plus150 < 1.0
                                          ? "#ff9f00"
                                          : row.plus150 < 0.9
                                          ? "red"
                                          : "normal"
                                        : "normal",
                                    fontWeight:
                                      ind === rows1.length - 1
                                        ? "bold"
                                        : "normal",
                                  }}
                                >
                                  {row.plus150}
                                </span>
                              ) : (
                                "-"
                              )}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </div>
            <div style={{ marginLeft: "2rem", marginTop: "4rem" }}>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={3}
                  className="Grid-Gap"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h5>Annual NOI Change (-3%)</h5>
                </Grid>
                <Grid item xs={9} className="Grid-Gap">
                  <TableContainer>
                    <Table
                      style={{ minWidth: 700, margin: "0 auto" }}
                      aria-label="customized table"
                    >
                      <TableHead>
                        <TableRow>
                          <StyledTableCell align="center">
                            Cap Rate
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            -50 bps
                          </StyledTableCell>
                          <StyledTableCell align="center">Flat</StyledTableCell>
                          <StyledTableCell align="center">
                            +50 bps
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            +100 bps
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            +150 bps
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows6.map((row, ind) => (
                          <StyledTableRow key={ind}>
                            <StyledTableCell align="center">
                              {row.capRate}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.minus50 !== "-" ? (
                                <span
                                  style={{
                                    color:
                                      ind === rows1.length - 1
                                        ? row.minus50 >= 1.0
                                          ? "green"
                                          : row.minus50 >= 0.9 &&
                                            row.minus50 < 1.0
                                          ? "#ff9f00"
                                          : row.minus50 < 0.9
                                          ? "red"
                                          : "normal"
                                        : "normal",
                                    fontWeight:
                                      ind === rows1.length - 1
                                        ? "bold"
                                        : "normal",
                                  }}
                                >
                                  {row.minus50}
                                </span>
                              ) : (
                                "-"
                              )}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.flat !== "-" ? (
                                <span
                                  style={{
                                    color:
                                      ind === rows1.length - 1
                                        ? row.flat >= 1.0
                                          ? "green"
                                          : row.flat >= 0.9 && row.flat < 1.0
                                          ? "#ff9f00"
                                          : row.flat < 0.9
                                          ? "red"
                                          : "normal"
                                        : "normal",
                                    fontWeight:
                                      ind === rows1.length - 1
                                        ? "bold"
                                        : "normal",
                                  }}
                                >
                                  {row.flat}
                                </span>
                              ) : (
                                "-"
                              )}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.plus50 !== "-" ? (
                                <span
                                  style={{
                                    color:
                                      ind === rows1.length - 1
                                        ? row.plus50 >= 1.0
                                          ? "green"
                                          : row.plus50 >= 0.9 &&
                                            row.plus50 < 1.0
                                          ? "#ff9f00"
                                          : row.plus50 < 0.9
                                          ? "red"
                                          : "normal"
                                        : "normal",
                                    fontWeight:
                                      ind === rows1.length - 1
                                        ? "bold"
                                        : "normal",
                                  }}
                                >
                                  {row.plus50}
                                </span>
                              ) : (
                                "-"
                              )}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.plus100 !== "-" ? (
                                <span
                                  style={{
                                    color:
                                      ind === rows1.length - 1
                                        ? row.plus100 >= 1.0
                                          ? "green"
                                          : row.plus100 >= 0.9 &&
                                            row.plus100 < 1.0
                                          ? "#ff9f00"
                                          : row.plus100 < 0.9
                                          ? "red"
                                          : "normal"
                                        : "normal",
                                    fontWeight:
                                      ind === rows1.length - 1
                                        ? "bold"
                                        : "normal",
                                  }}
                                >
                                  {row.plus100}
                                </span>
                              ) : (
                                "-"
                              )}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.plus150 !== "-" ? (
                                <span
                                  style={{
                                    color:
                                      ind === rows1.length - 1
                                        ? row.plus150 >= 1.0
                                          ? "green"
                                          : row.plus150 >= 0.9 &&
                                            row.plus150 < 1.0
                                          ? "#ff9f00"
                                          : row.plus150 < 0.9
                                          ? "red"
                                          : "normal"
                                        : "normal",
                                    fontWeight:
                                      ind === rows1.length - 1
                                        ? "bold"
                                        : "normal",
                                  }}
                                >
                                  {row.plus150}
                                </span>
                              ) : (
                                "-"
                              )}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </div>
            <div style={{ marginLeft: "2rem", marginTop: "4rem" }}>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={3}
                  className="Grid-Gap"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h5>Annual NOI Change (-4.5%)</h5>
                </Grid>
                <Grid item xs={9} className="Grid-Gap">
                  <TableContainer>
                    <Table
                      style={{ minWidth: 700, margin: "0 auto" }}
                      aria-label="customized table"
                    >
                      <TableHead>
                        <TableRow>
                          <StyledTableCell align="center">
                            Cap Rate
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            -50 bps
                          </StyledTableCell>
                          <StyledTableCell align="center">Flat</StyledTableCell>
                          <StyledTableCell align="center">
                            +50 bps
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            +100 bps
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            +150 bps
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows7.map((row, ind) => (
                          <StyledTableRow key={ind}>
                            <StyledTableCell align="center">
                              {row.capRate}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.minus50 !== "-" ? (
                                <span
                                  style={{
                                    color:
                                      ind === rows1.length - 1
                                        ? row.minus50 >= 1.0
                                          ? "green"
                                          : row.minus50 >= 0.9 &&
                                            row.minus50 < 1.0
                                          ? "#ff9f00"
                                          : row.minus50 < 0.9
                                          ? "red"
                                          : "normal"
                                        : "normal",
                                    fontWeight:
                                      ind === rows1.length - 1
                                        ? "bold"
                                        : "normal",
                                  }}
                                >
                                  {row.minus50}
                                </span>
                              ) : (
                                "-"
                              )}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.flat !== "-" ? (
                                <span
                                  style={{
                                    color:
                                      ind === rows1.length - 1
                                        ? row.flat >= 1.0
                                          ? "green"
                                          : row.flat >= 0.9 && row.flat < 1.0
                                          ? "#ff9f00"
                                          : row.flat < 0.9
                                          ? "red"
                                          : "normal"
                                        : "normal",
                                    fontWeight:
                                      ind === rows1.length - 1
                                        ? "bold"
                                        : "normal",
                                  }}
                                >
                                  {row.flat}
                                </span>
                              ) : (
                                "-"
                              )}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.plus50 !== "-" ? (
                                <span
                                  style={{
                                    color:
                                      ind === rows1.length - 1
                                        ? row.plus50 >= 1.0
                                          ? "green"
                                          : row.plus50 >= 0.9 &&
                                            row.plus50 < 1.0
                                          ? "#ff9f00"
                                          : row.plus50 < 0.9
                                          ? "red"
                                          : "normal"
                                        : "normal",
                                    fontWeight:
                                      ind === rows1.length - 1
                                        ? "bold"
                                        : "normal",
                                  }}
                                >
                                  {row.plus50}
                                </span>
                              ) : (
                                "-"
                              )}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.plus100 !== "-" ? (
                                <span
                                  style={{
                                    color:
                                      ind === rows1.length - 1
                                        ? row.plus100 >= 1.0
                                          ? "green"
                                          : row.plus100 >= 0.9 &&
                                            row.plus100 < 1.0
                                          ? "#ff9f00"
                                          : row.plus100 < 0.9
                                          ? "red"
                                          : "normal"
                                        : "normal",
                                    fontWeight:
                                      ind === rows1.length - 1
                                        ? "bold"
                                        : "normal",
                                  }}
                                >
                                  {row.plus100}
                                </span>
                              ) : (
                                "-"
                              )}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.plus150 !== "-" ? (
                                <span
                                  style={{
                                    color:
                                      ind === rows1.length - 1
                                        ? row.plus150 >= 1.0
                                          ? "green"
                                          : row.plus150 >= 0.9 &&
                                            row.plus150 < 1.0
                                          ? "#ff9f00"
                                          : row.plus150 < 0.9
                                          ? "red"
                                          : "normal"
                                        : "normal",
                                    fontWeight:
                                      ind === rows1.length - 1
                                        ? "bold"
                                        : "normal",
                                  }}
                                >
                                  {row.plus150}
                                </span>
                              ) : (
                                "-"
                              )}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </div>
          </Card>
        </Grid>
      </div>
    </>
  );
}

export default NoiCapRate;
