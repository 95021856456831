import React, { useCallback, useContext, useEffect, useState } from "react";
import { Grid, MenuItem } from "@material-ui/core";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import MyContext from "../context/appContext";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Card from "@mui/material/Card";
import { useLocation, useNavigate } from "react-router-dom";
import { API } from "aws-amplify";
import { addMonths, format } from "date-fns";
import { toast } from "react-toastify";
import { Alert } from "@mui/material";
import { debounce } from "lodash";

const defaultTheme = createTheme();

function InputComponent({ defaultValue, inputRef, ...props }) {
  const [value, setValue] = React.useState(() => props.value || defaultValue);

  const handleChange = (event) => {
    setValue(event.target.value);
    if (props.onChange) {
      props.onChange(event);
    }
  };

  return (
    <div style={{ width: `95%`, position: "relative" }}>
      <span
        style={{
          position: "absolute",
          top: `-10px`,
          left: `10px`,
          backgroundColor: "white",
          zIndex: 1,
          fontSize: `11px`,
          color: `#1976d2`,
        }}
      >
        {props.label}
      </span>
      <input ref={inputRef} {...props} onChange={handleChange} value={value} />
    </div>
  );
}
const CashFlowQuestionnaire = () => {
  const navigate = useNavigate();
  const appContext = useContext(MyContext);
  const [loading, setLoading] = useState(false);
  const [stateList, setStateList] = useState([]);
  const [error, setError] = useState(false);
  const [errMessage, setErrMessage] = useState(false);
  const [err2Message, setErr2Message] = useState(false);
  const { state } = useLocation();
  const [estLoanBlnc, setEstLoanBlnc] = useState("");
  const [mortPayment, setMortPayment] = useState("");
  const [principalPayment, setPrincipalPayment] = useState("");
  const [outputNotify, setOutputNotify] = useState(false);
  const customPropValue = state?.customProp;
  console.log("The custom property value::", customPropValue);
  const [formData, setFormData] = useState({
    clientName: "",
    interviewDate: "",
    reportDate: "",
    streetAddress: "",
    stateZipAddress: "",
    clientStateResidance: "",
    propertyState: "",
    msa: "",
    submarket: "",
    residentialOrCommercial: "",
    propertyType: "",
    descriptiveNotes: "",
    estimatedGrossValue: "",
    expectedSale: "",
    adjustedbasis: "",
    rentableUnits: "",
    rentableSquareFeet: "",
    purchaseDate: "",
    purchaseAmount: "",
    forwardlosses: "",
    amountBorrowed: "",
    refinanceDate: "",
    interestRate: "",
    loanTerms: "",
    loanBalance: "",
    mortgagepayment: "",
    propertyManagement: "",
    principal: "",
    depreciationClaimed: "",
    valueImprovements: "",
    capexSincePurchase: "",
    grossRent: "",
    reimbursements: "",
    water: "",
    trash: "",
    landscape: "",
    facilityMaintenance: "",
    security: "",
    reimbursedUtility: "",
    hoaFee: "",
    associationDues: "",
    majorRepairs: "",
    OtherMisc: "",
    personalhoursSpent: "",
    bookkeeping: "",
    marketing: "",
    issurance: "",
    annualPropertyTax: "",
    legal: "",
    eachUnitVacany: "",
    unitTurnOver: "",
    tenants: "",
    roof: "",
    roofYears: "",
    plumbing: "",
    plumbingYears: "",
    hvac: "",
    hvacYears: "",
    hardscaping: "",
    hardscapingYears: "",
    structural: "",
    structuralYears: "",
    allOther: "",
    otherYears: "",
    fetchedPrincipal: false,
  });

  const [errors, setErrors] = useState({
    clientName: false,
    reportDate: false,
    streetAddress: false,
    clientStateResidance: false,
    propertyState: false,
    residentialOrCommercial: false,
    estimatedGrossValue: false,
    expectedSale: false,
    rentableUnits: false,
    purchaseDate: false,
    purchaseAmount: false,
    forwardlosses: false,
    amountBorrowed: false,
    refinanceDate: false,
    interestRate: false,
    loanTerms: false,
    loanBalance: false,
    mortgagepayment: false,
    principal: false,
    depreciationClaimed: false,
    valueImprovements: false,
    capexSincePurchase: false,
    grossRent: false,
  });
  const domRef = React.useRef();

  useEffect(() => {
    getStates();
  }, []);

  const applyMask = (fieldName, inputVal) => {
    const fieldsToMask = [
      "estimatedGrossValue",
      "adjustedbasis",
      "purchasePrice",
      "purchaseAmount",
      "forwardlosses",
      "amountBorrowed",
      "loanBalance",
      "mortgagepayment",
      "principal",
      "capexSincePurchase",
      "grossRent",
      "reimbursements",
      "propertyManagement",
      "water",
      "trash",
      "landscape",
      "facilityMaintenance",
      "security",
      "reimbursedUtility",
      "hoaFee",
      "associationDues",
      "majorRepairs",
      "OtherMisc",
      "bookkeeping",
      "marketing",
      "issurance",
      "annualPropertyTax",
      "legal",
      "tenants",
      "roof",
      "plumbing",
      "hvac",
      "hardscaping",
      "structural",
      "allOther",
      "rentableUnits",
    ];

    if (fieldsToMask.includes(fieldName)) {
      const cleanInput = inputVal.replace(/,/g, "").replace(/\.(?=.*\.)/g, "");
      const [wholePart, decimalPart] = cleanInput.split(".");
      const formattedWholePart = wholePart.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ","
      );

      if (decimalPart !== undefined) {
        return `${formattedWholePart}.${decimalPart}`;
      } else {
        return formattedWholePart;
      }
    } else {
      return inputVal;
    }
  };

  useEffect(() => {
    const storedFormState = sessionStorage.getItem("formState");

    if (storedFormState) {
      const { cashFlow } = JSON.parse(storedFormState);
      setFormData(cashFlow);
    }
  }, [customPropValue]);

  const handleChange = (e) => {
    setOutputNotify(false);
    setError(false);
    const inputVal = e.target.value;
    const fieldName = e.target.name;
    const maskedInput = applyMask(fieldName, inputVal);

    if (["principal", "mortgagepayment", "loanBalance"].includes(fieldName)) {
      setFormData((prevFormData) => {
        const newFormData = {
          ...prevFormData,
          [fieldName]: maskedInput,
          fetchedPrincipal: false,
        };
        handleSessionState(newFormData);
        return newFormData;
      });
    } else {
      setFormData((prevFormData) => {
        const newFormData = {
          ...prevFormData,
          [fieldName]: maskedInput,
        };
        handleSessionState(newFormData);
        return newFormData;
      });
    }
    if (inputVal.trim() !== "") {
      setErrors({
        ...errors,
        [fieldName]: false,
      });
    }
  };

  const debouncedSaveSession = useCallback(
    debounce((newFormData) => {
      sessionStorage.setItem(
        "formState",
        JSON.stringify({ cashFlow: newFormData })
      );
    }, 300),
    []
  );

  const handleSessionState = (newFormData) => {
    debouncedSaveSession(newFormData);
  };
  // const handleSessionState = async (updateForm) => {
  //   // const formState = {
  //   //   cashFlow: { ...formData },
  //   // };
  //   const formState = {
  //     cashFlow: { ...updateForm },
  //   };
  //   sessionStorage.setItem("formState", JSON.stringify(formState));
  // };
  const onSessionClear = async () => {
    sessionStorage.clear("formState");
    setFormData((prevFormData) => {
      const clearedState = Object.fromEntries(
        Object.keys(prevFormData).map((key) => [key, ""])
      );
      return clearedState;
    });
  };

  const onSubmit = async () => {
    const requiredFields = [
      "clientName",
      "reportDate",
      "streetAddress",
      "propertyState",
    ];
    try {
      appContext.updateState("loading", true);

      const formState = {
        cashFlow: { ...formData },
      };
      sessionStorage.setItem("formState", JSON.stringify(formState));
      sessionStorage.setItem("domRef", domRef.current.innerHTML);
      navigate("/cashflow-report", {
        state: {
          formData,
        },
      });
      appContext.updateState("loading", false);
    } catch (error) {
      appContext.updateState("loading", false);
      toast(`Error posting Cash Flow Data! ${error.message}`, {
        type: "error",
      });
    }
  };

  const onSubmitTax = async () => {
    const requiredFields = [
      "clientName",
      "reportDate",
      "streetAddress",
      "propertyState",
      "residentialOrCommercial",
      "estimatedGrossValue",
      "expectedSale",
      "purchaseDate",
      "purchaseAmount",
      "forwardlosses",
      "loanBalance",
      "mortgagepayment",
      "principal",
      "depreciationClaimed",
      "valueImprovements",
      "capexSincePurchase",
    ];
    try {
      appContext.updateState("loading", true);

      let hasErrors = false;
      const newErrors = {};
      requiredFields.forEach((field) => {
        if (formData[field].trim() === "") {
          newErrors[field] = true;
          hasErrors = true;
        }
      });
      setErrors(newErrors);

      if (hasErrors) {
        appContext.updateState("loading", false);
        return;
      }

      const formState = {
        cashFlow: { ...formData },
      };
      sessionStorage.setItem("formState", JSON.stringify(formState));
      navigate("/tax-report", {
        state: {
          formData,
        },
      });
      appContext.updateState("loading", false);
    } catch (error) {
      appContext.updateState("loading", false);
      toast(`Error posting Tax Analysis! ${error.message}`, {
        type: "error",
      });
    }
  };

  const getStates = async () => {
    try {
      const payload = {
        queryStringParameters: {
          tableName: "state_info-dev",
          id: "all",
          LastEvaluatedKey: "None",
        },
      };
      const res = await API.get("dynamoRW", "/dsts", payload);
      setStateList(res);
    } catch (error) {
      console.log(error.message);
      toast(`No State Found! ${error.message}`, { type: "error" });
    } finally {
      setLoading(false);
    }
  };

  const stateOption = stateList
    ?.map((st) => ({
      state: st.state,
      code: st.code,
    }))
    .sort((a, b) => a.state.localeCompare(b.state));

  // Fetching loan fields calculations //

  const handleFetchLoanData = () => {
    if (
      !formData?.amountBorrowed ||
      !formData?.interestRate ||
      !formData?.loanTerms ||
      (!formData?.refinanceDate && !formData?.purchaseDate)
    ) {
      setOutputNotify(true);
      return;
    }
    const rows = [];
    const startDate = formData?.refinanceDate
      ? formData?.refinanceDate
      : formData?.purchaseDate;
    let currentBalance = +formData?.amountBorrowed
      ?.toString()
      .replace(/[^0-9.]/g, "");
    let cleanedInterestRate = +formData?.interestRate
      ?.toString()
      .replace(/[^0-9.]/g, "");
    let cleanedLoanTerm = +formData?.loanTerms
      ?.toString()
      .replace(/[^0-9.]/g, "");
    const interestRatePercent = cleanedInterestRate / 100;
    const r = interestRatePercent / 12;
    const numOfMonths = cleanedLoanTerm * 12;
    const X = Math.pow(1 + r, numOfMonths) - 1;
    const forY = Math.pow(1 + r, numOfMonths);
    const Y = r * forY;
    const Z = X / Y;
    const monthlyPayment = currentBalance / Z;

    for (let index = 0; index < numOfMonths; index++) {
      const paymentDate = addMonths(startDate, index + 1);
      const beginningBalance = currentBalance;
      const interest =
        (beginningBalance * interestRatePercent * cleanedLoanTerm) /
        numOfMonths;
      const principal = monthlyPayment - interest;
      const endingBalance = beginningBalance - principal;
      const selectedDate = new Date(formData?.reportDate);
      const rowPaymentDate = new Date(paymentDate);

      rows.push({
        serial: `${index + 1}`,
        paymentDate: format(paymentDate, "MM/dd/yyyy"),
        beginningBalance: ` ${
          Number(beginningBalance.toFixed(2)).toLocaleString("en-US") || 0
        }`,
        payment: ` ${
          Number(monthlyPayment.toFixed(2)).toLocaleString("en-US") || 0
        }`,
        principal: ` ${
          Number(principal.toFixed(2)).toLocaleString("en-US") || 0
        }`,
        interest: ` ${
          Number(interest.toFixed(2)).toLocaleString("en-US") || 0
        }`,
        endingBalance: ` ${
          Number(endingBalance.toFixed(2)).toLocaleString("en-US") || 0
        }`,
        paymentMonth:
          selectedDate >= rowPaymentDate &&
          selectedDate < addMonths(rowPaymentDate, 1)
            ? 1
            : 0,
      });

      currentBalance = endingBalance;
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      mortgagepayment: monthlyPayment
        ? monthlyPayment.toFixed(2)
        : monthlyPayment,
      fetchedPrincipal: true,
    }));
    setErrors({
      ...errors,
      mortgagepayment: false,
      loanBalance: false,
      principal: false,
    });
    setMortPayment(monthlyPayment.toFixed(2));

    const x = rows?.find((y) => y.paymentMonth === 1);
    if (x) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        loanBalance: x.beginningBalance,
        principal: x.principal,
        fetchedPrincipal: true,
      }));
      setEstLoanBlnc(x.beginningBalance);
      setPrincipalPayment(x.principal);
    }
  };

  return (
    <div ref={domRef} style={{ padding: "0px 20px" }}>
      <Box sx={{ width: "100%" }}>
        <Container component="main">
          <CssBaseline />
          <Box>
            <h3 className="hide-on-print" style={{ margin: "2rem 0rem" }}>
              Cash Flow Analysis Questionnaire
            </h3>
            <Grid container sx={{ py: 2 }} spacing={2}>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  error={errors.clientName}
                  size="small"
                  margin="normal"
                  fullWidth
                  name="clientName"
                  value={formData.clientName}
                  onChange={handleChange}
                  label="Client Name"
                  InputProps={{
                    style: { backgroundColor: "#e1f2fa" },
                  }}
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  error={errors.reportDate}
                  value={formData.reportDate}
                  size="small"
                  margin="normal"
                  fullWidth
                  name={"reportDate"}
                  onChange={(e) => {
                    setFormData((prevFormData) => {
                      const newFormData = {
                        ...prevFormData,
                        reportDate: e.target.value,
                      };
                      handleSessionState(newFormData);
                      return newFormData;
                    });
                    setErrors({
                      ...errors,
                      reportDate: false,
                    });
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    style: { backgroundColor: "#e1f2fa" },
                  }}
                  type="date"
                  label="Report Date"
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  error={errors.streetAddress}
                  size="small"
                  margin="normal"
                  fullWidth
                  name={"streetAddress"}
                  value={formData.streetAddress}
                  InputProps={{
                    style: { backgroundColor: "#e1f2fa" },
                  }}
                  onChange={handleChange}
                  label={"Rental Property Street Address"}
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  value={formData.propertyState}
                  error={errors.propertyState}
                  select
                  size="small"
                  margin="normal"
                  fullWidth
                  name="propertyState"
                  onChange={(e) => {
                    setFormData((prevFormData) => {
                      const newFormData = {
                        ...prevFormData,
                        propertyState: e.target.value,
                      };
                      handleSessionState(newFormData);
                      return newFormData;
                    });
                    setErrors({
                      ...errors,
                      propertyState: false,
                    });
                  }}
                  label="Select Property State"
                  InputProps={{
                    style: { backgroundColor: "#e1f2fa" },
                  }}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        style: {
                          maxHeight: "300px",
                        },
                      },
                    },
                  }}
                >
                  <MenuItem value="" key="">
                    Clear
                  </MenuItem>
                  {stateOption?.map((option) => (
                    <MenuItem
                      key={option.code}
                      value={`${option.state} - ${option.code}`}
                    >
                      {`${option.state} - ${option.code}`}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  value={formData.clientStateResidance}
                  error={errors.clientStateResidance}
                  select
                  size="small"
                  margin="normal"
                  fullWidth
                  name="clientStateResidance"
                  onChange={(e) => {
                    setFormData((prevFormData) => {
                      const newFormData = {
                        ...prevFormData,
                        clientStateResidance: e.target.value,
                      };
                      handleSessionState(newFormData);
                      return newFormData;
                    });
                    setErrors({
                      ...errors,
                      clientStateResidance: false,
                    });
                  }}
                  label="Select Client State of Residence"
                  InputProps={{
                    style: { backgroundColor: "#e1f2fa" },
                  }}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        style: {
                          maxHeight: "300px",
                        },
                      },
                    },
                  }}
                >
                  <MenuItem value="" key="">
                    Clear
                  </MenuItem>
                  {stateOption?.map((option) => (
                    <MenuItem
                      key={option.code}
                      value={`${option.state} - ${option.code}`}
                    >
                      {`${option.state} - ${option.code}`}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
            <h3>Property Description and History</h3>
            <Grid container sx={{ py: 2 }} spacing={2}>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  error={errors.estimatedGrossValue}
                  size="small"
                  margin="normal"
                  fullWidth
                  value={formData.estimatedGrossValue}
                  InputProps={{
                    style: { backgroundColor: "#e1f2fa" },
                  }}
                  onChange={handleChange}
                  name={"estimatedGrossValue"}
                  label={`Client's estimated gross value of the property`}
                />
              </Grid>

              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  error={errors.expectedSale}
                  size="small"
                  margin="normal"
                  fullWidth
                  name={"expectedSale"}
                  value={formData.expectedSale}
                  InputProps={{
                    style: { backgroundColor: "#e1f2fa" },
                  }}
                  onChange={handleChange}
                  label={`Expected sale costs (%)`}
                />
              </Grid>

              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  error={errors.rentableUnits}
                  size="small"
                  margin="normal"
                  fullWidth
                  name={"rentableUnits"}
                  value={formData.rentableUnits}
                  onChange={handleChange}
                  label={`Number of separate rentable units`}
                />
              </Grid>
            </Grid>

            <h3>Loan</h3>
            <p>
              <b>Does client know:</b>
            </p>
            <Grid container sx={{ py: 2 }} spacing={2}>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  error={errors.loanBalance}
                  size="small"
                  margin="normal"
                  fullWidth
                  name={"loanBalance"}
                  value={formData.loanBalance}
                  InputProps={{
                    style: { backgroundColor: "#e1f2fa" },
                  }}
                  onChange={handleChange}
                  label={`Est. current loan balance`}
                />
              </Grid>

              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  error={errors.mortgagepayment}
                  size="small"
                  margin="normal"
                  fullWidth
                  value={formData.mortgagepayment}
                  onChange={handleChange}
                  name={"mortgagepayment"}
                  label={`Mortgage payment (P+I only)`}
                  InputProps={{
                    style: { backgroundColor: "#e1f2fa" },
                  }}
                />
              </Grid>

              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  error={errors.principal}
                  size="small"
                  margin="normal"
                  fullWidth
                  value={formData.principal}
                  onChange={handleChange}
                  name={"principal"}
                  label={`Last monthly PRINCIPAL payment`}
                  InputProps={{
                    style: { backgroundColor: "#e1f2fa" },
                  }}
                />
              </Grid>

              <Grid item xs={12} className="Grid-Gap">
                <p>
                  If client knows all three numbers above, continue to{" "}
                  <b>Monthly Revenues</b>.
                </p>
              </Grid>
              <Grid item xs={12} className="Grid-Gap">
                <p
                  style={{
                    backgroundColor: "#D9D9D9",
                    marginTop: "0px",
                    padding: "7px",
                  }}
                >
                  <b>If any of the three fields above are blank</b>, complete
                  these remaining loan fields and click “Fetch Amortization
                  Calculation” button below.
                </p>
              </Grid>

              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  error={errors.refinanceDate}
                  value={formData.refinanceDate}
                  size="small"
                  margin="normal"
                  fullWidth
                  name={"refinanceDate"}
                  type="date"
                  onChange={(e) => {
                    setFormData((prevFormData) => {
                      const newFormData = {
                        ...prevFormData,
                        refinanceDate: e.target.value,
                      };
                      handleSessionState(newFormData);
                      return newFormData;
                    });
                    setErrors({
                      ...errors,
                      refinanceDate: false,
                    });
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Loan Date (or refinance date)"
                  InputProps={{
                    style: { backgroundColor: "#e6e6fa" },
                  }}
                />
              </Grid>

              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  error={errors.loanTerms}
                  size="small"
                  margin="normal"
                  fullWidth
                  name={"loanTerms"}
                  value={formData.loanTerms}
                  onChange={handleChange}
                  label={`Loan term (years)`}
                  InputProps={{
                    style: { backgroundColor: "#e6e6fa" },
                  }}
                />
              </Grid>

              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  error={errors.amountBorrowed}
                  size="small"
                  margin="normal"
                  fullWidth
                  name={"amountBorrowed"}
                  value={formData.amountBorrowed}
                  label={`Amount borrowed`}
                  onChange={handleChange}
                  InputProps={{
                    style: { backgroundColor: "#e6e6fa" },
                  }}
                />
              </Grid>

              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  error={errors.interestRate}
                  size="small"
                  margin="normal"
                  fullWidth
                  name={"interestRate"}
                  value={formData.interestRate}
                  onChange={handleChange}
                  label={`Est. interest rate`}
                  InputProps={{
                    style: { backgroundColor: "#e6e6fa" },
                  }}
                />
              </Grid>
              <Grid
                item
                xs={4}
                className="Grid-Gap"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "7px",
                }}
              >
                <Button
                  onClick={handleFetchLoanData}
                  variant="contained"
                  sx={{ textTransform: "inherit" }}
                >
                  Fetch Amortization Calculation
                </Button>
              </Grid>
              <Grid item xs={12} className="Grid-Gap">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    width: "100%",
                    marginTop: "1.5rem",
                  }}
                >
                  {outputNotify ? (
                    <Alert severity="error">
                      Please fill the above required fields for amortization
                      calculations.
                    </Alert>
                  ) : null}
                </div>
              </Grid>
            </Grid>

            <h3>Monthly Revenues</h3>
            <Grid container sx={{ py: 2 }} spacing={2}>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  error={errors.grossRent}
                  size="small"
                  margin="normal"
                  fullWidth
                  name={"grossRent"}
                  value={formData.grossRent}
                  onChange={handleChange}
                  label={`Gross rents per month today`}
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  error={errMessage === true}
                  size="small"
                  margin="normal"
                  fullWidth
                  value={formData.reimbursements}
                  onChange={handleChange}
                  name={"reimbursements"}
                  label={`Other mo.fees/reimbursements`}
                />
              </Grid>
            </Grid>

            <h3>Monthly Expenses</h3>
            <Grid container sx={{ py: 2 }} spacing={2}>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  error={errMessage === true}
                  size="small"
                  margin="normal"
                  fullWidth
                  value={formData.propertyManagement}
                  name={"propertyManagement"}
                  onChange={handleChange}
                  label={`Property Management`}
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  error={errMessage === true}
                  size="small"
                  margin="normal"
                  fullWidth
                  name={"trash"}
                  value={formData.trash}
                  onChange={handleChange}
                  label={`Trash`}
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  error={errMessage === true}
                  size="small"
                  margin="normal"
                  fullWidth
                  value={formData.facilityMaintenance}
                  onChange={handleChange}
                  name={"facilityMaintenance"}
                  label={`Other facility maintenance`}
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  error={errMessage === true}
                  size="small"
                  margin="normal"
                  fullWidth
                  value={formData.landscape}
                  onChange={handleChange}
                  name={"landscape"}
                  label={`Landscape/gardening`}
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  error={errMessage === true}
                  size="small"
                  margin="normal"
                  fullWidth
                  name={"security"}
                  value={formData.security}
                  onChange={handleChange}
                  label={`Security`}
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  error={errMessage === true}
                  size="small"
                  margin="normal"
                  fullWidth
                  name={"associationDues"}
                  value={formData.associationDues}
                  onChange={handleChange}
                  label={`Rental-owner association dues`}
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  error={errMessage === true}
                  size="small"
                  margin="normal"
                  fullWidth
                  onChange={handleChange}
                  value={formData.majorRepairs}
                  name={"majorRepairs"}
                  label={`Major repairs/replacements`}
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  error={errMessage === true}
                  size="small"
                  margin="normal"
                  fullWidth
                  name={"water"}
                  value={formData.water}
                  onChange={handleChange}
                  label={`Water/sewer`}
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  error={errMessage === true}
                  size="small"
                  margin="normal"
                  fullWidth
                  onChange={handleChange}
                  value={formData.OtherMisc}
                  name={"OtherMisc"}
                  label={`Other/misc`}
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  error={errMessage === true}
                  size="small"
                  margin="normal"
                  fullWidth
                  name={"hoaFee"}
                  onChange={handleChange}
                  value={formData.hoaFee}
                  label={`HOA fee`}
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  error={errMessage === true}
                  size="small"
                  margin="normal"
                  fullWidth
                  name={"reimbursedUtility"}
                  value={formData.reimbursedUtility}
                  onChange={handleChange}
                  label={`Other Unreimbursed utility`}
                />
              </Grid>

              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  error={errMessage === true}
                  size="small"
                  margin="normal"
                  fullWidth
                  name={"personalhoursSpent"}
                  value={formData.personalhoursSpent}
                  onChange={handleChange}
                  label={`Personal hours spent per month on property`}
                />
              </Grid>
            </Grid>

            <h3>Annual Expenses</h3>
            <Grid container sx={{ py: 2 }} spacing={2}>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  error={errMessage === true}
                  size="small"
                  margin="normal"
                  fullWidth
                  name={"bookkeeping"}
                  value={formData.bookkeeping}
                  onChange={handleChange}
                  label={`Bookkeeping/tax prep`}
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  error={errMessage === true}
                  size="small"
                  margin="normal"
                  fullWidth
                  onChange={handleChange}
                  name={"marketing"}
                  label={`Marketing`}
                  value={formData.marketing}
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  error={errMessage === true}
                  size="small"
                  margin="normal"
                  fullWidth
                  name={"issurance"}
                  value={formData.issurance}
                  onChange={handleChange}
                  label={`Insurance`}
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  error={errMessage === true}
                  size="small"
                  margin="normal"
                  fullWidth
                  value={formData.annualPropertyTax}
                  onChange={handleChange}
                  name={"annualPropertyTax"}
                  label={`Ann. property tax$`}
                />
                <span style={{ fontSize: 12, color: "#757575" }}>
                  System will estimate if blank
                </span>
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  error={errMessage === true}
                  size="small"
                  margin="normal"
                  fullWidth
                  name={"legal"}
                  value={formData.legal}
                  onChange={handleChange}
                  label={`Legal`}
                />
              </Grid>
            </Grid>
            <h3>Vacancy</h3>
            <Grid container sx={{ py: 2 }} spacing={2}>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  error={errMessage === true}
                  size="small"
                  margin="normal"
                  fullWidth
                  value={formData.unitTurnOver}
                  onChange={handleChange}
                  name={"unitTurnOver"}
                  InputProps={{
                    style: { backgroundColor: "#e1f2fa" },
                  }}
                  label={`Over 10 years, how many times will 1 unit turn?`}
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  error={errMessage === true}
                  size="small"
                  margin="normal"
                  fullWidth
                  value={formData.eachUnitVacany}
                  onChange={handleChange}
                  name={"eachUnitVacany"}
                  label={`On average, # of weeks a turned unit vacant`}
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  error={errMessage === true}
                  size="small"
                  margin="normal"
                  fullWidth
                  name={"tenants"}
                  value={formData.tenants}
                  onChange={handleChange}
                  label={`Average cost to prepare a unit between tenants`}
                />
              </Grid>
            </Grid>
            <h3>Upcoming Capital Expenditures(next 10 Years)</h3>
            <Grid container sx={{ py: 2 }} spacing={2}>
              <Grid item xs={4} className="Grid-Gap">
                <Box
                  display={"flex"}
                  paddingX={1}
                  flexDirection="column"
                  width={`100%`}
                  border={`1px solid lightgray`}
                >
                  <Grid item xs={12} className="Grid-Gap">
                    <TextField
                      error={errMessage === true}
                      size="small"
                      margin="normal"
                      fullWidth
                      onChange={handleChange}
                      value={formData.roof}
                      name={"roof"}
                      label={`Roof`}
                    />
                  </Grid>
                  <Grid item xs={12} className="Grid-Gap">
                    <TextField
                      error={errMessage === true}
                      size="small"
                      margin="normal"
                      fullWidth
                      name={"roofYears"}
                      onChange={handleChange}
                      value={formData.roofYears}
                      label={`Years from now (#0-9)`}
                    />
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <Box
                  display={"flex"}
                  paddingX={1}
                  flexDirection="column"
                  width={`100%`}
                  border={`1px solid lightgray`}
                >
                  <Grid item xs={12} className="Grid-Gap">
                    <TextField
                      error={errMessage === true}
                      size="small"
                      margin="normal"
                      fullWidth
                      name={"plumbing"}
                      value={formData.plumbing}
                      onChange={handleChange}
                      label={`Plumbing`}
                    />
                  </Grid>
                  <Grid item xs={12} className="Grid-Gap">
                    <TextField
                      error={errMessage === true}
                      size="small"
                      margin="normal"
                      fullWidth
                      value={formData.plumbingYears}
                      onChange={handleChange}
                      name={"plumbingYears"}
                      label={`Years from now (#0-9)`}
                    />
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <Box
                  display={"flex"}
                  paddingX={1}
                  marginBottom={1}
                  flexDirection="column"
                  width={`100%`}
                  border={`1px solid lightgray`}
                >
                  <Grid item xs={12} className="Grid-Gap">
                    <TextField
                      error={errMessage === true}
                      size="small"
                      margin="normal"
                      fullWidth
                      name={"hvac"}
                      value={formData.hvac}
                      onChange={handleChange}
                      label={`HVAC`}
                    />
                  </Grid>
                  <Grid item xs={12} className="Grid-Gap">
                    <TextField
                      error={errMessage === true}
                      size="small"
                      margin="normal"
                      fullWidth
                      name={"hvacYears"}
                      value={formData.hvacYears}
                      onChange={handleChange}
                      label={`Years from now (#0-9)`}
                    />
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <Box
                  display={"flex"}
                  paddingX={1}
                  marginBottom={1}
                  flexDirection="column"
                  width={`100%`}
                  border={`1px solid lightgray`}
                >
                  <Grid item xs={12} className="Grid-Gap">
                    <TextField
                      error={errMessage === true}
                      size="small"
                      margin="normal"
                      fullWidth
                      value={formData.hardscaping}
                      name={"hardscaping"}
                      onChange={handleChange}
                      label={`Hardscaping/Asphalt`}
                    />
                  </Grid>
                  <Grid item xs={12} className="Grid-Gap">
                    <TextField
                      error={errMessage === true}
                      size="small"
                      margin="normal"
                      fullWidth
                      name={"hardscapingYears"}
                      value={formData.hardscapingYears}
                      onChange={handleChange}
                      label={`Years from now (#0-9)`}
                    />
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <Box
                  display={"flex"}
                  paddingX={1}
                  marginBottom={1}
                  flexDirection="column"
                  width={`100%`}
                  border={`1px solid lightgray`}
                >
                  <Grid item xs={12} className="Grid-Gap">
                    <TextField
                      error={errMessage === true}
                      size="small"
                      margin="normal"
                      fullWidth
                      name={"structural"}
                      value={formData.structural}
                      onChange={handleChange}
                      label={`Structural`}
                    />
                  </Grid>
                  <Grid item xs={12} className="Grid-Gap">
                    <TextField
                      error={errMessage === true}
                      size="small"
                      margin="normal"
                      fullWidth
                      value={formData.structuralYears}
                      onChange={handleChange}
                      name={"structuralYears"}
                      label={`Years from now (#0-9)`}
                    />
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <Box
                  display={"flex"}
                  paddingX={1}
                  marginBottom={1}
                  flexDirection="column"
                  width={`100%`}
                  border={`1px solid lightgray`}
                >
                  <Grid item xs={12} className="Grid-Gap">
                    <TextField
                      error={errMessage === true}
                      size="small"
                      margin="normal"
                      fullWidth
                      name={"allOther"}
                      value={formData.allOther}
                      onChange={handleChange}
                      label={`All Other`}
                    />
                  </Grid>
                  <Grid item xs={12} className="Grid-Gap">
                    <TextField
                      error={errMessage === true}
                      size="small"
                      margin="normal"
                      fullWidth
                      value={formData.otherYears}
                      onChange={handleChange}
                      name={"otherYears"}
                      label={`Years from now (#0-9)`}
                    />
                  </Grid>
                </Box>
              </Grid>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                  width: "100%",
                  marginBottom: "2rem",
                }}
              >
                <Button
                  onClick={onSessionClear}
                  variant="contained"
                  sx={{ mt: 0, mb: 0, mr: 1, textTransform: "inherit" }}
                >
                  Reset
                </Button>

                <div>
                  <Button
                    onClick={onSubmit}
                    variant="contained"
                    sx={{ mt: 0, mb: 0, textTransform: "inherit" }}
                  >
                    Cash Flow Analysis
                  </Button>
                </div>
              </div>
            </Grid>
          </Box>
        </Container>
      </Box>
    </div>
  );
};

export default CashFlowQuestionnaire;
