import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  IconButton,
  InputAdornment,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Box,
  Alert,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { API } from "aws-amplify";
import { toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from "@mui/icons-material/Clear";
import CircularProgress from "@mui/material/CircularProgress";

function PortfolioIllustration() {
  let navigate = useNavigate();
  const tableRef = useRef();
  const [otherInfo, setOtherInfo] = useState({
    clientEquity: "",
  });

  const [tableRows, setTableRows] = useState(() => {
    const initialRows = [...Array(3)].map(() => ({
      id: "",
      clientDebt: "",
      yieldDollar: "",
      yieldPercent: "",
      ltv: "",
    }));
    return initialRows;
  });
  const [tableRowsSecond, setTableRowsSecond] = useState(() => {
    const initialRows = [...Array(3)].map(() => ({
      id: "",
      investPercent: "",
      clientDebt: "",
      yieldDollar: "",
      yieldPercent: "",
    }));
    return initialRows;
  });
  const [dsts, setDsts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errMessage, setErrMessage] = useState(false);

  useEffect(() => {
    getIdData();
  }, []);

  const getIdData = async () => {
    try {
      setLoading(true);
      const payload = {
        queryStringParameters: {
          tableName: "dsts_table-dev",
          id: "id-name",
        },
      };
      const res = await API.get("dynamoRW", "/dsts", payload);
      setDsts(res);
    } catch (error) {
      console.log(error.message);
      toast(`No Data Found! ${error.message}`, { type: "error" });
    } finally {
      setLoading(false);
    }
  };

  const handleDropdownChange = (event, index) => {
    const selectedId = event.target.value;
    const selectedItem = dsts.find((item) => item.id === selectedId);
    console.log(
      "the ltv is....",
      selectedItem?.basicInfo?.offeringDebt,
      selectedItem?.basicInfo?.offeringEquity
    );
    const offeringDebt = parseFloat(
      selectedItem?.basicInfo?.offeringDebt.replace(/[\$,]/g, "")
    );
    const offeringEquity = parseFloat(
      selectedItem?.basicInfo?.offeringEquity.replace(/[\$,]/g, "")
    );
    setTableRows((prevRows) =>
      prevRows.map((row, i) => {
        if (i === index) {
          return {
            id: selectedId,
            name: selectedItem?.basicInfo?.fullLegalNameOfDst,
            clientDebt: "",
            yieldDollar: "",
            clientEquity: "",
            yieldPercent: selectedItem?.yields?.year1Yield.replace(
              /[^\d.]/g,
              ""
            ),
            equity: selectedItem?.basicInfo?.offeringEquity || 0,
            debt: selectedItem?.basicInfo?.offeringDebt || 0,
            ltv:
              ((offeringDebt / (offeringDebt + offeringEquity)) * 100).toFixed(
                2
              ) || 0,
          };
        } else {
          return row;
        }
      })
    );
    setErrMessage(false);
  };

  const handleDropdownChangeSecond = (event, index) => {
    const selectedId = event.target.value;
    const selectedItem = dsts.find((item) => item.id === selectedId);

    setTableRowsSecond((prevRows) =>
      prevRows.map((row, i) => {
        if (i === index) {
          return {
            id: selectedId,
            name: selectedItem?.basicInfo?.fullLegalNameOfDst,
            investPercent: "",
            clientEquity: "",
            clientDebt: "",
            yieldDollar: "",
            yieldPercent: selectedItem?.yields?.year1Yield.replace(
              /[^\d.]/g,
              ""
            ),
            equity: selectedItem?.basicInfo?.offeringEquity || 0,
            debt: selectedItem?.basicInfo?.offeringDebt || 0,
          };
        } else {
          return row;
        }
      })
    );
    setErrMessage(false);
  };
  const handleEquityChange = (event, index) => {
    const newEquityValue = event.target.value.replace(/,/g, "");
    setTableRows((prevRows) =>
      prevRows.map((row, i) => {
        if (i === index) {
          const clientEquity = parseFloat(newEquityValue).toLocaleString();
          const debtWithoutCommas = row.debt.replace(/[\$,]/g, "");
          const equityWithoutCommas = row.equity.replace(/[\$,]/g, "");
          if (isNaN(newEquityValue) || newEquityValue === "") {
            return {
              ...row,
              clientEquity: "",
              clientDebt: "",
              yieldDollar: "",
            };
          }

          const clientDebt =
            (newEquityValue * debtWithoutCommas) / equityWithoutCommas;

          const formattedClientDebt =
            clientDebt === "" ? "" : parseInt(clientDebt).toLocaleString();
          const sanitizedYieldPercent = row.yieldPercent.replace(
            /[^0-9.]/g,
            ""
          );

          function numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          }

          const yieldDollar = (
            newEquityValue *
            (+sanitizedYieldPercent.replace(/%/g, "") / 100)
          ).toFixed(2);
          const formattedYieldDollar = numberWithCommas(yieldDollar);
          return {
            ...row,
            clientEquity,
            clientDebt: formattedClientDebt,
            yieldDollar: formattedYieldDollar,
          };
        } else {
          return row;
        }
      })
    );
  };

  const handleEquityChangeSecond = (event, index) => {
    const fieldName = event.target.name;
    const newValue = event.target.value.replace(/[\$,]/g, "");

    setTableRowsSecond((prevRows) =>
      prevRows.map((row, i) => {
        if (i === index) {
          const updatedRow = {
            ...row,
            [fieldName]: newValue,
          };

          if (fieldName === "clientEquity") {
            const newEquityValue = parseFloat(newValue);
            const clientEquity = newEquityValue.toLocaleString();
            const debtWithoutCommas = row.debt.replace(/[\$,]/g, "");
            const equityWithoutCommas = row.equity.replace(/[\$,]/g, "");
            if (
              isNaN(newEquityValue) ||
              isNaN(debtWithoutCommas) ||
              isNaN(equityWithoutCommas) ||
              newEquityValue === ""
            ) {
              return {
                ...row,
                clientEquity: "",
                clientDebt: "",
                yieldDollar: "",
              };
            }

            const clientDebt =
              (newEquityValue * parseFloat(debtWithoutCommas)) /
              parseFloat(equityWithoutCommas);
            const formattedClientDebt =
              clientDebt === "" ? "" : parseInt(clientDebt).toLocaleString();
            const sanitizedYieldPercent = row.yieldPercent.replace(
              /[^0-9.]/g,
              ""
            );

            const yieldDollar = parseInt(
              newEquityValue *
                (parseFloat(sanitizedYieldPercent.replace(/%/g, "")) / 100)
            ).toLocaleString();

            return {
              ...row,
              clientEquity,
              clientDebt: formattedClientDebt,
              yieldDollar,
            };
          }

          return updatedRow;
        } else {
          return row;
        }
      })
    );
  };

  const handleAddRow = () => {
    setTableRows((prevRows) => [
      ...prevRows,
      {
        id: "",
        clientDebt: "",
        yieldDollar: "",
        yieldPercent: "",
        ltv: "",
      },
    ]);
  };

  console.log(tableRowsSecond, "tableRowsSecond");
  const handleAddRowSecond = () => {
    setTableRowsSecond((prevRows) => [
      ...prevRows,
      {
        id: "",
        clientDebt: "",
        yieldDollar: "",
        yieldPercent: "",
      },
    ]);
  };

  const handleRemoveRow = (index) => {
    setTableRows((prevRows) => {
      if (prevRows.length > 1) {
        return prevRows.filter((_, i) => i !== index);
      }
      return prevRows;
    });
  };

  const handleRemoveRowSecond = (index) => {
    setTableRowsSecond((prevRows) => {
      if (prevRows.length > 1) {
        return prevRows.filter((_, i) => i !== index);
      }
      return prevRows;
    });
  };

  const handleAddData = () => {
    const rowsWithNonEmptyIds = tableRows.filter((row) => row.id !== "");
    const rowsWithNonEmptyIdsSecond = tableRowsSecond.filter(
      (row) => row.id !== ""
    );
    if (
      rowsWithNonEmptyIds.length > 0 ||
      rowsWithNonEmptyIdsSecond.length > 0
    ) {
      navigate("/quick-calculations-report", {
        state: {
          tableRows: rowsWithNonEmptyIds,
          tableRowsSecond: rowsWithNonEmptyIdsSecond,
          otherInfo,
        },
      });
    } else {
      setErrMessage(true);
    }
  };

  const handleClearField = (index) => {
    const emptyRow = {
      id: "",
      clientEquity: "",
      clientDebt: "",
      yieldDollar: "",
      yieldPercent: "",
      ltv: "",
    };
    const updatedTableRows = tableRows.map((row, i) =>
      i === index ? emptyRow : row
    );
    setTableRows(updatedTableRows);
  };

  const handleClearFieldSecond = (index) => {
    const emptyRow = {
      id: "",
      clientEquity: "",
      investPercent: "",
      clientDebt: "",
      yieldDollar: "",
      yieldPercent: "",
    };
    const updatedTableRows = tableRowsSecond.map((row, i) =>
      i === index ? emptyRow : row
    );
    setTableRowsSecond(updatedTableRows);
  };

  console.log(tableRows, "Table Rows");

  return (
    <>
      <div style={{ margin: "2rem" }}>
        <Grid container spacing={2}>
          <Card
            style={{
              width: "100%",
              minHeight: "85vh",
              marginTop: "1rem",
              marginLeft: "1rem",
              padding: "1rem 0rem",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "1.5rem",
              }}
            >
              <h4 style={{ margin: "1rem 2rem" }}>QUICK CALCULATIONS</h4>
              <div
                style={{
                  margin: "0rem 2rem",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "end",
                }}
              >
                <Button
                  onClick={handleAddData}
                  type="submit"
                  variant="contained"
                  style={{
                    marginLeft: "2rem",
                    marginTop: "1rem",
                    marginBottom: "0.3rem",
                  }}
                >
                  View Report
                </Button>
                {errMessage ? (
                  <Alert severity="error">
                    Atleast one of the DST should be selected!
                  </Alert>
                ) : null}
              </div>
            </div>
            <div style={{ margin: "0rem 0rem" }}>
              <Grid>
                <div
                  style={{
                    margin: "0rem 0rem",
                    marginTop: "4rem",
                    marginRight: "2rem",
                    marginLeft: "2rem",
                  }}
                >
                  <Grid>
                    <div style={{ display: "flex", justifyContent: "end" }}>
                      <Button
                        onClick={handleAddRow}
                        type="submit"
                        variant="contained"
                        style={{
                          margin: "0rem 0rem",
                          padding: "0.1rem 0.5rem",
                          marginBottom: "0.5rem",
                        }}
                      >
                        Add DST
                      </Button>
                    </div>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            style={{
                              textAlign: "center",
                              borderBottom: "none",
                            }}
                          >
                            Deal
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                              borderBottom: "none",
                            }}
                          >
                            Client Equity ($)
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                              borderBottom: "none",
                            }}
                          >
                            Client Debt ($)
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                              borderBottom: "none",
                            }}
                          >
                            Estimated Y1 Return ($)
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                              borderBottom: "none",
                            }}
                          >
                            Estimated Y1 Return (%)
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                              borderBottom: "none",
                            }}
                          >
                            LTV
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                              borderBottom: "none",
                            }}
                          >
                            Delete
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {tableRows.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell
                              style={{
                                borderBottom: "none",
                                padding: "6px",
                                width: "17%",
                              }}
                            >
                              <TextField
                                select
                                size="small"
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                  style: { width: "15rem" },
                                  endAdornment: row.id ? (
                                    <IconButton
                                      aria-label="clear"
                                      size="small"
                                      onClick={() => handleClearField(index)}
                                    >
                                      <ClearIcon />
                                    </IconButton>
                                  ) : null,
                                }}
                                SelectProps={{
                                  IconComponent: row.id
                                    ? () => null
                                    : undefined,
                                  MenuProps: {
                                    PaperProps: {
                                      style: {
                                        maxHeight: 300,
                                      },
                                    },
                                  },
                                }}
                                label="Select DST"
                                value={row.id}
                                onChange={(event) =>
                                  handleDropdownChange(event, index)
                                }
                              >
                                {loading ? (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <CircularProgress size={20} />
                                  </Box>
                                ) : (
                                  dsts.map((option, ind) => (
                                    <MenuItem key={ind} value={option.id}>
                                      {option.basicInfo.fullLegalNameOfDst}
                                    </MenuItem>
                                  ))
                                )}
                              </TextField>
                            </TableCell>
                            <TableCell
                              style={{ borderBottom: "none", padding: "6px" }}
                            >
                              <div style={{ textAlign: "center" }}>
                                {row.id ? (
                                  <TextField
                                    type="text"
                                    size="small"
                                    variant="outlined"
                                    style={{
                                      width: "100%",
                                      textAlign: "center",
                                    }}
                                    value={row.clientEquity}
                                    onChange={(event) =>
                                      handleEquityChange(event, index)
                                    }
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          $
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                ) : (
                                  <TextField
                                    disabled
                                    size="small"
                                    variant="outlined"
                                    style={{
                                      width: "100%",
                                      textAlign: "center",
                                      cursor: "not-allowed",
                                    }}
                                    value={row.clientEquity}
                                    onChange={(event) =>
                                      handleEquityChange(event, index)
                                    }
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          $
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                )}
                                {}
                              </div>
                            </TableCell>
                            <TableCell
                              style={{ borderBottom: "none", padding: "6px" }}
                            >
                              <div>
                                <TextField
                                  type="text"
                                  size="small"
                                  variant="outlined"
                                  style={{
                                    width: "100%",
                                    textAlign: "center",
                                  }}
                                  value={row.clientDebt}
                                  InputProps={{
                                    readOnly: true,
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        $
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </div>
                            </TableCell>
                            <TableCell
                              style={{ borderBottom: "none", padding: "6px" }}
                            >
                              <div>
                                <TextField
                                  size="small"
                                  variant="outlined"
                                  style={{
                                    width: "100%",
                                    textAlign: "center",
                                  }}
                                  value={row.yieldDollar}
                                  InputProps={{
                                    readOnly: true,
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        $
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </div>
                            </TableCell>
                            <TableCell
                              style={{ borderBottom: "none", padding: "6px" }}
                            >
                              <div>
                                <TextField
                                  size="small"
                                  variant="outlined"
                                  style={{
                                    width: "100%",
                                    textAlign: "center",
                                  }}
                                  value={row.yieldPercent}
                                  InputProps={{
                                    readOnly: true,
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        %
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </div>
                            </TableCell>
                            <TableCell
                              style={{ borderBottom: "none", padding: "6px" }}
                            >
                              <div>
                                <TextField
                                  size="small"
                                  variant="outlined"
                                  style={{
                                    width: "100%",
                                    textAlign: "center",
                                  }}
                                  value={row.ltv}
                                  InputProps={{
                                    readOnly: true,
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        %
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </div>
                            </TableCell>
                            {index > 0 && (
                              <TableCell
                                style={{
                                  textAlign: "center",
                                  borderBottom: "none",
                                  padding: "6px",
                                }}
                              >
                                {index > 0 && (
                                  <IconButton
                                    aria-label="delete"
                                    size="large"
                                    color="#fff"
                                    onClick={() => handleRemoveRow(index)}
                                  >
                                    <DeleteIcon style={{ color: "#ff5555" }} />
                                  </IconButton>
                                )}
                              </TableCell>
                            )}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    {/* </TableContainer> */}
                  </Grid>
                </div>
              </Grid>
            </div>
            <div style={{ margin: "0rem 0rem" }}>
              <Grid>
                <div
                  style={{
                    margin: "0rem 0rem",
                    marginTop: "4rem",
                    marginRight: "2rem",
                    marginLeft: "2rem",
                  }}
                >
                  <Grid>
                    <div style={{ display: "flex", justifyContent: "end" }}>
                      <Button
                        onClick={handleAddRowSecond}
                        type="submit"
                        variant="contained"
                        style={{
                          margin: "0rem 0rem",
                          padding: "0.1rem 0.5rem",
                          marginBottom: "0.5rem",
                        }}
                      >
                        Add DST
                      </Button>
                    </div>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            style={{
                              textAlign: "center",
                              borderBottom: "none",
                            }}
                          >
                            Deal
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                              borderBottom: "none",
                            }}
                          >
                            (%) of Investment
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                              borderBottom: "none",
                            }}
                          >
                            Client Equity ($)
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                              borderBottom: "none",
                            }}
                          >
                            Client Debt ($)
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                              borderBottom: "none",
                            }}
                          >
                            Estimated Y1 Return ($)
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                              borderBottom: "none",
                            }}
                          >
                            Estimated Y1 Return (%)
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                              borderBottom: "none",
                            }}
                          >
                            Delete
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {tableRowsSecond.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell
                              style={{
                                borderBottom: "none",
                                padding: "6px",
                                width: "17%",
                              }}
                            >
                              <TextField
                                select
                                size="small"
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                  style: { width: "15rem" },
                                  endAdornment: row.id ? (
                                    <IconButton
                                      aria-label="clear"
                                      size="small"
                                      onClick={() =>
                                        handleClearFieldSecond(index)
                                      }
                                    >
                                      <ClearIcon />
                                    </IconButton>
                                  ) : null,
                                }}
                                SelectProps={{
                                  IconComponent: row.id
                                    ? () => null
                                    : undefined,
                                  MenuProps: {
                                    PaperProps: {
                                      style: {
                                        maxHeight: 300,
                                      },
                                    },
                                  },
                                }}
                                label="Select DST"
                                value={row.id}
                                onChange={(event) =>
                                  handleDropdownChangeSecond(event, index)
                                }
                              >
                                {loading ? (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <CircularProgress size={20} />
                                  </Box>
                                ) : (
                                  dsts.map((option, ind) => (
                                    <MenuItem key={ind} value={option.id}>
                                      {option.basicInfo.fullLegalNameOfDst}
                                    </MenuItem>
                                  ))
                                )}
                              </TextField>
                            </TableCell>
                            <TableCell
                              style={{ borderBottom: "none", padding: "6px" }}
                            >
                              <div style={{ textAlign: "center" }}>
                                {row.id ? (
                                  <TextField
                                    type="text"
                                    size="small"
                                    variant="outlined"
                                    name="investPercent"
                                    style={{
                                      width: "100%",
                                      textAlign: "center",
                                    }}
                                    value={row.investPercent}
                                    onChange={(event) =>
                                      handleEquityChangeSecond(event, index)
                                    }
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          %
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                ) : (
                                  <TextField
                                    disabled
                                    size="small"
                                    variant="outlined"
                                    name="investPercent"
                                    style={{
                                      width: "100%",
                                      textAlign: "center",
                                      cursor: "not-allowed",
                                    }}
                                    value={row.investPercent}
                                    onChange={(event) =>
                                      handleEquityChangeSecond(event, index)
                                    }
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          %
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                )}
                                {}
                              </div>
                            </TableCell>
                            <TableCell
                              style={{ borderBottom: "none", padding: "6px" }}
                            >
                              <div style={{ textAlign: "center" }}>
                                {row.id ? (
                                  <TextField
                                    type="text"
                                    size="small"
                                    variant="outlined"
                                    name="clientEquity"
                                    style={{
                                      width: "100%",
                                      textAlign: "center",
                                    }}
                                    value={row.clientEquity}
                                    onChange={(event) =>
                                      handleEquityChangeSecond(event, index)
                                    }
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          $
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                ) : (
                                  <TextField
                                    disabled
                                    size="small"
                                    variant="outlined"
                                    name="clientEquity"
                                    style={{
                                      width: "100%",
                                      textAlign: "center",
                                      cursor: "not-allowed",
                                    }}
                                    value={row.clientEquity}
                                    onChange={(event) =>
                                      handleEquityChangeSecond(event, index)
                                    }
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          $
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                )}
                                {}
                              </div>
                            </TableCell>
                            <TableCell
                              style={{ borderBottom: "none", padding: "6px" }}
                            >
                              <div>
                                <TextField
                                  size="small"
                                  variant="outlined"
                                  style={{
                                    width: "100%",
                                    textAlign: "center",
                                  }}
                                  value={row.clientDebt}
                                  InputProps={{
                                    readOnly: true,
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        $
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </div>
                            </TableCell>
                            <TableCell
                              style={{ borderBottom: "none", padding: "6px" }}
                            >
                              <div>
                                <TextField
                                  size="small"
                                  variant="outlined"
                                  style={{
                                    width: "100%",
                                    textAlign: "center",
                                  }}
                                  value={row.yieldDollar}
                                  InputProps={{
                                    readOnly: true,
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        $
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </div>
                            </TableCell>
                            <TableCell
                              style={{ borderBottom: "none", padding: "6px" }}
                            >
                              <div>
                                <TextField
                                  size="small"
                                  variant="outlined"
                                  style={{
                                    width: "100%",
                                    textAlign: "center",
                                  }}
                                  value={row.yieldPercent}
                                  InputProps={{
                                    readOnly: true,
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        %
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </div>
                            </TableCell>
                            {index > 0 && (
                              <TableCell
                                style={{
                                  textAlign: "center",
                                  borderBottom: "none",
                                  padding: "6px",
                                }}
                              >
                                {index > 0 && (
                                  <IconButton
                                    aria-label="delete"
                                    size="large"
                                    color="#fff"
                                    onClick={() => handleRemoveRowSecond(index)}
                                  >
                                    <DeleteIcon style={{ color: "#ff5555" }} />
                                  </IconButton>
                                )}
                              </TableCell>
                            )}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    {/* </TableContainer> */}
                  </Grid>
                </div>
              </Grid>
            </div>
          </Card>
        </Grid>
      </div>
    </>
  );
}

export default PortfolioIllustration;
