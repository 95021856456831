import React, { useContext, useState, useEffect } from "react";
import MyContext from "../context/appContext";
import { useNavigate } from "react-router-dom";

const ManageProperties = () => {
  const appContext = useContext(MyContext);
  const [properties, setProperties] = useState([]);

  useEffect(() => {
    if (appContext?.state?.dsts?.length) {
      let allProperties = [];
      appContext?.state?.dsts?.map((itm) => {
        if (itm?.property?.length) {
          allProperties.push(...itm.property);
        }
      });
      setProperties(allProperties);
    }
  }, [appContext]);

  return (
    <div style={{ padding: "0 20px" }}>
      <h2>Properties</h2>
      {properties?.map((pro, key) => (
        <div key={"dstslist" + key} className="Dst-align">
          <p onClick={() => {}} className="Dsts-Manage">
            {pro?.propertyName}
          </p>
        </div>
      ))}
    </div>
  );
};

export default ManageProperties;
