import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import tokenStorage from "../services/tokenStorage";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "../components/appBar";
import Drawer from "../components/drawer";
import Toolbar from "@mui/material/Toolbar";

function Protected({ children, allowedRoles }) {
  const message = localStorage.getItem("message");
  const token = tokenStorage.getToken();
  const [open, setOpen] = useState(true);
  const role = localStorage.getItem("role");

  if (!token) {
    return <Navigate to="/home" replace />;
  }
  const defaultTheme = createTheme();
  const userRole = localStorage.getItem("role");
  const isAuthorized = allowedRoles?.includes(userRole);

  if (!isAuthorized) {
    <Navigate to="/unauthorized" replace />;
    return (
      <>
        <ThemeProvider theme={defaultTheme}>
          <AppBar setOpen={setOpen} />
          <Box
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "light"
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              padding: "20px",
            }}
          >
            <h1 style={{ margin: "0px" }}>Unauthorized Access</h1>
            {message ? (
              <p style={{ width: "50%", textAlign: "center" }}>{message}</p>
            ) : (
              <p>You don't have access to this page.</p>
            )}
          </Box>
        </ThemeProvider>
      </>
    );
  }

  return (
    <>
      <ThemeProvider theme={defaultTheme}>
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar open={open} setOpen={setOpen} />
          {role.toLowerCase() !== "investor" && (
            <Drawer open={open} setOpen={setOpen} />
          )}
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "light"
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
            }}
          >
            <Toolbar />
            {children}
          </Box>
        </Box>
      </ThemeProvider>
    </>
  );
}
export default Protected;
