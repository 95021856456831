import React, { useState, useRef } from "react";
import {
  Grid,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import ReactToPrint from "react-to-print";
import { Button, Paper } from "@material-ui/core";
import USAMap from "react-usa-map";
import { alpha, makeStyles, styled } from "@material-ui/core/styles";
import {
  LandscapeOrientation,
  PortraitOrientation,
} from "../../utills/landscapeOrientation";
import { pink } from "@mui/material/colors";

const useStyles = makeStyles({
  customTable: {
    "& .MuiTableCell-sizeSmall": {
      padding: "10px 0px 10px 16px",
    },
  },
});

const PinkSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: pink[600],
    "&:hover": {
      backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: pink[600],
  },
}));

const QuickCalculationReport = () => {
  const classes = useStyles();
  const componentRef = useRef();
  const { state } = useLocation();
  const tableRows = state?.tableRows;
  const tableRowsSecond = state?.tableRowsSecond;
  const otherInfo = state?.otherInfo;
  const [portraitLayout, setPortraitLayout] = useState(false);

  console.log(tableRows, "tableRows");
  console.log(otherInfo, "otherInfo");

  const rows = tableRows?.map((row) => {
    // Remove commas and symbols like $, %, or any other symbol
    const cleanEquity = row.clientEquity.replace(/[\$,]/g, "");
    const cleanDebt = row.clientDebt.replace(/[\$,]/g, "");
    const cleanYieldDollar = row.yieldDollar.replace(/[\$,]/g, "");
    const cleanYieldPercent = row.yieldPercent.replace(/[\$, %]/g, "");
    const cleanLtv = row.ltv.replace(/[\$, %]/g, ""); // If ltv may contain symbols

    return {
      name: row.name,
      equity: cleanEquity,
      debt: cleanDebt,
      yieldDollar: cleanYieldDollar,
      yieldPercent: cleanYieldPercent,
      ltv: cleanLtv,
    };
  });

  const rowsSecond = tableRowsSecond?.map((row) => {
    const cleanInvestPercent = row.investPercent.replace(/[\$, %]/g, "");
    const cleanEquity = row.clientEquity.replace(/[\$,]/g, "");
    const cleanDebt = row.clientDebt.replace(/[\$,]/g, "");
    const cleanYieldDollar = row.yieldDollar.replace(/[\$,]/g, "");
    const cleanYieldPercent = row.yieldPercent.replace(/[\$, %]/g, "");
    return {
      name: row.name,
      investPercent: cleanInvestPercent,
      equity: cleanEquity,
      debt: cleanDebt,
      yieldDollar: cleanYieldDollar,
      yieldPercent: cleanYieldPercent,
    };
  });

  const equity = rows?.reduce((total, row) => +total + +row.equity, 0);
  const yieldDollar = rows?.reduce(
    (total, row) => (+total + +row.yieldDollar).toFixed(2),
    0
  );
  const debt = rows?.reduce((total, row) => +total + +row.debt, 0);

  const columnTotals = {
    equity: equity,
    debt: debt,
    yieldDollar: yieldDollar,
    yieldPercent: (yieldDollar / equity) * 100,
    ltv: (debt / (equity + debt)) * 100,
  };

  const numRows = rows ? rows.length : 0;
  columnTotals.yieldPercent =
    numRows > 0 ? columnTotals.yieldPercent.toFixed(2) : 0;
  columnTotals.ltv = numRows > 0 ? columnTotals.ltv.toFixed(2) : 0;


  const yieldDollar2 = rowsSecond?.reduce(
    (total, row) => (+total + +row.yieldDollar).toFixed(2),
    0
  )
  const equity2 = rowsSecond?.reduce((total, row) => +total + +row.equity, 0);


  const columnTotalsSecond = {
    equity: equity2,
    debt: rowsSecond?.reduce((total, row) => +total + +row.debt, 0),
    investPercent: rowsSecond?.reduce(
      (total, row) => +total + +row.investPercent,
      0
    ),
    yieldDollar: yieldDollar2,
    yieldPercent: (yieldDollar2 / equity2) * 100,
  };
  const numRowsSecond = rowsSecond ? rowsSecond.length : 0;
  columnTotalsSecond.yieldPercent =
    numRowsSecond > 0
      ? (columnTotalsSecond.yieldPercent).toFixed(2)
      : 0;

  const totalRow = {
    name: "Totals / Averages",
    equity: +columnTotals.equity,
    debt: +columnTotals.debt,
    yieldDollar: +columnTotals.yieldDollar,
    yieldPercent: +columnTotals.yieldPercent,
    ltv: +columnTotals.ltv,
  };

  const totalRowSecond = {
    name: "Totals / Averages",
    investPercent: +columnTotalsSecond.investPercent,
    equity: +columnTotalsSecond.equity,
    debt: +columnTotalsSecond.debt,
    yieldDollar: +columnTotalsSecond.yieldDollar,
    yieldPercent: +columnTotalsSecond.yieldPercent,
    ltv: +columnTotalsSecond.ltv,
  };

  if (tableRows.length > 0 && tableRows.id !== "") {
    rows?.push(totalRow);
  }
  if (tableRowsSecond.length > 0 && tableRowsSecond.id !== "") {
    rowsSecond?.push(totalRowSecond);
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          columnGap: "1.5rem",
          marginTop: "2rem",
          marginRight: "2rem",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          <Typography
            style={{ color: portraitLayout === false ? "#1565c0" : "#333" }}
          >
            Portrait
          </Typography>
          <PinkSwitch
            checked={!portraitLayout}
            onChange={() => setPortraitLayout(!portraitLayout)}
          />
          <Typography
            style={{
              color: portraitLayout === true ? "#1565c0" : "#333",
            }}
          >
            Landscape
          </Typography>
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {portraitLayout ? <PortraitOrientation /> : <LandscapeOrientation />}
          <ReactToPrint
            trigger={() => (
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#1976d2",
                  color: "#fff",
                }}
              >
                Print
              </Button>
            )}
            content={() => componentRef.current}
          />
        </div>
      </div>
      <div style={{ margin: "0rem 2rem" }} ref={componentRef}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h4>QUICK CALCULATIONS</h4>
        </div>
        {tableRows?.length > 0 && tableRows?.id !== "" ? (
          <div style={{ marginBottom: "3rem" }}>
            <TableContainer>
              <Table
                sx={{ minWidth: 650 }}
                aria-label="simple table"
                classes={{ root: classes.customTable }}
                size="small"
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        borderBottom: "none",
                        fontSize: "0.75rem",
                      }}
                    >
                      Deal
                    </TableCell>
                    <TableCell
                      style={{
                        textAlign: "center",
                        borderBottom: "none",
                        fontSize: "0.75rem",
                      }}
                    >
                      Client Equity ($)
                    </TableCell>
                    <TableCell
                      style={{
                        textAlign: "center",
                        borderBottom: "none",
                        fontSize: "0.75rem",
                      }}
                    >
                      Client Debt ($)
                    </TableCell>
                    <TableCell
                      style={{
                        textAlign: "center",
                        borderBottom: "none",
                        fontSize: "0.75rem",
                      }}
                    >
                      Estimated Y1 Return ($)
                    </TableCell>
                    <TableCell
                      style={{
                        textAlign: "center",
                        borderBottom: "none",
                        fontSize: "0.75rem",
                      }}
                    >
                      Estimated Y1 Return (%)
                    </TableCell>
                    <TableCell
                      style={{
                        textAlign: "center",
                        borderBottom: "none",
                        fontSize: "0.75rem",
                      }}
                    >
                      LTV
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows?.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        style={{
                          fontWeight: index === rows.length - 1 ? "bold" : null,
                          fontSize: "0.75rem",
                        }}
                      >
                        {row.name || "-"}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          fontWeight: index === rows.length - 1 ? "bold" : null,
                          fontSize: "0.75rem",
                        }}
                      >
                        {`$ ${Number(row.equity).toLocaleString("en-US") || 0}`}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          fontWeight: index === rows.length - 1 ? "bold" : null,
                          fontSize: "0.75rem",
                        }}
                      >
                        {`$ ${Number(row.debt).toLocaleString("en-US") || 0}`}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          fontWeight: index === rows.length - 1 ? "bold" : null,
                          fontSize: "0.75rem",
                        }}
                      >
                        {`$ ${
                          Number(row.yieldDollar).toLocaleString("en-US") || 0
                        }`}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          fontWeight: index === rows.length - 1 ? "bold" : null,
                          fontSize: "0.75rem",
                        }}
                      >
                        {`${row.yieldPercent || 0}%`}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          fontWeight: index === rows.length - 1 ? "bold" : null,
                          fontSize: "0.75rem",
                        }}
                      >
                        {`${row.ltv || 0}%`}
                      </TableCell>
                      <TableCell align="center" style={{ fontSize: "0.75rem" }}>
                        {row.sector}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ) : null}
        {/* <hr style={{ margin: "3rem 0rem", border: "1px solid #d0d0d0" }} /> */}
        {rowsSecond?.length > 0 && rowsSecond?.id !== "" ? (
          <div>
            <TableContainer>
              <Table
                sx={{ minWidth: 650 }}
                aria-label="simple table"
                classes={{ root: classes.customTable }}
                size="small"
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        borderBottom: "none",
                        fontSize: "0.75rem",
                      }}
                    >
                      Deal
                    </TableCell>
                    <TableCell
                      style={{
                        textAlign: "center",
                        borderBottom: "none",
                        fontSize: "0.75rem",
                      }}
                    >
                      (%) of Investment
                    </TableCell>
                    <TableCell
                      style={{
                        textAlign: "center",
                        borderBottom: "none",
                        fontSize: "0.75rem",
                      }}
                    >
                      Client Equity ($)
                    </TableCell>
                    <TableCell
                      style={{
                        textAlign: "center",
                        borderBottom: "none",
                        fontSize: "0.75rem",
                      }}
                    >
                      Client Debt ($)
                    </TableCell>
                    <TableCell
                      style={{
                        textAlign: "center",
                        borderBottom: "none",
                        fontSize: "0.75rem",
                      }}
                    >
                      Estimated Y1 Return ($)
                    </TableCell>
                    <TableCell
                      style={{
                        textAlign: "center",
                        borderBottom: "none",
                        fontSize: "0.75rem",
                      }}
                    >
                      Estimated Y1 Return (%)
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowsSecond?.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        style={{
                          fontWeight: index === rows.length - 1 ? "bold" : null,
                          fontSize: "0.75rem",
                        }}
                      >
                        {row.name || "-"}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          fontWeight: index === rows.length - 1 ? "bold" : null,
                          fontSize: "0.75rem",
                        }}
                      >
                        {`${row.investPercent || 0}%`}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          fontWeight: index === rows.length - 1 ? "bold" : null,
                          fontSize: "0.75rem",
                        }}
                      >
                        {`$ ${Number(row.equity).toLocaleString("en-US") || 0}`}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          fontWeight: index === rows.length - 1 ? "bold" : null,
                          fontSize: "0.75rem",
                        }}
                      >
                        {`$ ${Number(row.debt).toLocaleString("en-US") || 0}`}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          fontWeight: index === rows.length - 1 ? "bold" : null,
                          fontSize: "0.75rem",
                        }}
                      >
                        {`$ ${
                          Number(row.yieldDollar).toLocaleString("en-US") || 0
                        }`}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          fontWeight: index === rows.length - 1 ? "bold" : null,
                          fontSize: "0.75rem",
                        }}
                      >
                        {`${row.yieldPercent || 0}%`}
                      </TableCell>
                      <TableCell align="center" style={{ fontSize: "0.75rem" }}>
                        {row.sector}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ) : null}
      </div>
    </>
  );
};
export default QuickCalculationReport;
