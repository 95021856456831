import { useLocation } from "react-router-dom";

import TaxEstimator from "../components/taxEstimator";
import CashFlowQuestionnaire from "../components/cashFlowQuestionnaire";

const Analysis = () => {
  const { state } = useLocation();

  const customPropValue = state?.customProp;

  return customPropValue !== undefined ? (
    <TaxEstimator />
  ) : (
    <CashFlowQuestionnaire />
  );
};

export default Analysis;
