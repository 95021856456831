import React, { useContext, useState, useEffect } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MyContext from "../context/appContext";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import { toast } from "react-toastify";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { IconButton, InputAdornment } from "@material-ui/core";
import KeyIcon from '@mui/icons-material/Key';

const defaultTheme = createTheme();

const useStyles = makeStyles({
  backgroundScreen: {
    backgroundColor: "#000",
    height: "100vh",
  },
  signinCard: {
    padding: "10px 30px",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  boxAlign: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  LinkText: {
    textDecoration: "none",
    color: "#a48d31",
  },
  signUpText: {
    color: "#fff",
    textAlign: "center",
  },
});

export default function ResetPassword() {
  const appContext = useContext(MyContext);
  const { state } = useLocation();
  const email = state?.email;
  const classes = useStyles();
  let navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmationCode, setConfirmationCode] = useState("");

  const onResetPasswordConfirm = async (event) => {
    event.preventDefault();
    try {
      appContext.updateState("loading", true);
      if (!confirmationCode || !newPassword) {
        appContext.updateState("loading", false);
        toast(`Fields required`, { type: "error" });
        return;
      }
      await Auth.forgotPasswordSubmit(email, confirmationCode, newPassword);
      toast(`Password updated successfully`, { type: "success" });
      appContext.updateState("loading", false);
      navigate("/login");
    } catch (error) {
      appContext.updateState("loading", false);
      console.log(error.message);
      toast(error.message, { type: "error" });
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container
        component="main"
        className={classes.backgroundScreen}
        style={{ maxWidth: "100%" }}
      >
        <CssBaseline />
        <Box component={"form"} className={classes.boxAlign}>
          <div className={classes.signinCard}>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              <img src="/faviconPNG.png" style={{ height: "30px" }} />
            </Typography>
            <Box noValidate>
              <div className="login-field">
                <TextField
                  value={confirmationCode}
                  className="login-email-field"
                  size="small"
                  margin="normal"
                  id="confirmationCode"
                  placeholder="Enter code"
                  name="confirmationCode"
                  onChange={(e) => setConfirmationCode(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end">
                          <KeyIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  value={newPassword}
                  className="login-email-field"
                  size="small"
                  margin="normal"
                  name="newPassword"
                  placeholder="New Password"
                  type={showPassword ? "text" : "password"}
                  id="newPassword"
                  onChange={(e) => setNewPassword(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleTogglePasswordVisibility}
                          edge="end"
                        >
                          {showPassword ? (
                            <VisibilityOffIcon style={{ color: "#777" }} />
                          ) : (
                            <VisibilityIcon style={{ color: "#777" }} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>

              <div className="login-signin-btn">
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 1,
                    borderRadius: 0,
                    textTransform: "capitalize",
                    padding: "5px 25px",
                  }}
                  onClick={onResetPasswordConfirm}
                >
                  Confirm Password
                </Button>
              </div>
              <div>
                <p className={classes.signUpText}>
                  Remember your password?{" "}
                  <Link to="/login" className={classes.LinkText}>
                    <span>Login</span>
                  </Link>
                </p>
              </div>
            </Box>
          </div>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
