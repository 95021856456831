import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import {
  DataGrid,
  gridPageCountSelector,
  GridPagination,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import MuiPagination from "@mui/material/Pagination";
import MyContext from "../context/appContext";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { makeStyles } from "@material-ui/core";
import { toast } from "react-toastify";
import { API } from "aws-amplify";
import { all } from "axios";

function Pagination({ page, onPageChange, className }) {
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <MuiPagination
      color="primary"
      className={className}
      count={pageCount}
      page={page + 1}
      onChange={(event, newPage) => {
        onPageChange(event, newPage - 1);
      }}
    />
  );
}

function CustomPagination(props) {
  return <GridPagination ActionsComponent={Pagination} {...props} />;
}

const columns = [
  { field: "id", hide: true },
  { field: "name", headerName: "DST Name", align: "center", minWidth: 120 },
  { field: "sponsor", headerName: "Sponsor", align: "center", minWidth: 120 },
  {
    field: "sector",
    headerName: "Sector",
    minWidth: 120,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    field: "totalEquity",
    headerName: "Total Equity",
    minWidth: 120,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    field: "totalDebt",
    headerName: "Total Debt",
    minWidth: 120,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    field: "minimum",
    headerName: "Minimum",
    minWidth: 120,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    field: "yield",
    headerName: "Yields",
    minWidth: 120,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    field: "loanRate",
    headerName: "Loan Rate",
    minWidth: 120,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    field: "retained",
    headerName: "% Retained",
    minWidth: 120,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    field: "units",
    headerName: "Units",
    minWidth: 120,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    field: "p1name",
    headerName: "P1 Name",
    minWidth: 120,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    field: "p1street",
    headerName: "P1 Street",
    minWidth: 120,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    field: "p1csz",
    headerName: "P1 CSZ",
    minWidth: 120,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    field: "p2name",
    headerName: "P2 Name",
    minWidth: 120,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    field: "p2street",
    headerName: "P2 Street",
    minWidth: 120,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    field: "p2csz",
    headerName: "P2 CSZ",
    minWidth: 120,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    field: "p3name",
    headerName: "P3 Name",
    minWidth: 120,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    field: "p3street",
    headerName: "P3 Street",
    minWidth: 120,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    field: "p3csz",
    headerName: "P3 CSZ",
    minWidth: 120,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    field: "p4name",
    headerName: "P4 Name",
    minWidth: 120,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    field: "p4street",
    headerName: "P4 Street",
    minWidth: 120,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    field: "p4csz",
    headerName: "P4 CSZ",
    minWidth: 120,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    field: "p5name",
    headerName: "P5 Name",
    minWidth: 120,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    field: "p5street",
    headerName: "P5 Street",
    minWidth: 120,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    field: "p5csz",
    headerName: "P5 CSZ",
    minWidth: 120,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    field: "p6name",
    headerName: "P6 Name",
    minWidth: 120,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    field: "p6street",
    headerName: "P6 Street",
    minWidth: 120,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    field: "p6csz",
    headerName: "P6 CSZ",
    minWidth: 120,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    field: "p7name",
    headerName: "P7 Name",
    minWidth: 120,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    field: "p7street",
    headerName: "P7 Street",
    minWidth: 120,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    field: "p7csz",
    headerName: "P7 CSZ",
    minWidth: 120,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    field: "p8name",
    headerName: "P8 Name",
    minWidth: 120,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    field: "p8street",
    headerName: "P8 Street",
    minWidth: 120,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    field: "p8csz",
    headerName: "P8 CSZ",
    minWidth: 120,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    field: "p9name",
    headerName: "P9 Name",
    minWidth: 120,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    field: "p9street",
    headerName: "P9 Street",
    minWidth: 120,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    field: "p9csz",
    headerName: "P9 CSZ",
    minWidth: 120,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    field: "p10name",
    headerName: "P10 Name",
    minWidth: 120,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    field: "p10street",
    headerName: "P10 Street",
    minWidth: 120,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    field: "p10csz",
    headerName: "P10 CSZ",
    minWidth: 120,
    align: "center",
    format: (value) => value.toFixed(2),
  },
];

const useStyles = makeStyles({
  activeButton: {
    backgroundColor: "blue",
  },
});

const ViewAllDsts = () => {
  const appContext = useContext(MyContext);
  const [filterDsts, setFilterDsts] = React.useState([]);
  const [activeButton, setActiveButton] = useState("all");
  const [allDsts, setAllDsts] = useState([]);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  useEffect(() => {
    getDsts();
  }, []);

  useEffect(() => {
    handleAllDsts();
  }, [allDsts]);

  const getDsts = async () => {
    try {
      appContext.updateState("loading", true);
      const payload = {
        queryStringParameters: {
          tableName: "dsts_table-dev",
          id: "all",
          LastEvaluatedKey: "None",
        },
      };
      const res = await API.get("dynamoRW", "/dsts", payload);
      const sortedDsts = res.sort((a, b) => a?.basicInfo?.fullLegalNameOfDst?.localeCompare(b.basicInfo.fullLegalNameOfDst));
      setAllDsts(sortedDsts);
      appContext.updateState("loading", false);
    } catch (error) {
      appContext.updateState("loading", false);
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  console.log(allDsts, "A:LLLLLADAL");

  const rows = filterDsts?.map((row) => ({
    name: row?.basicInfo?.fullLegalNameOfDst,
    sponsor: row?.basicInfo?.nameOfTheSponsor || "-",
    sector: row?.basicInfo?.dstPropertiesSector || "-",
    totalEquity: row?.basicInfo?.offeringEquity.includes("$")
      ? row?.basicInfo?.offeringEquity
      : `$${row?.basicInfo?.offeringEquity || "0"}`,
    totalDebt: row?.basicInfo?.offeringDebt.includes("$")
      ? row?.basicInfo?.offeringDebt
      : `$${row?.basicInfo?.offeringDebt || "0"}`,
    minimum: row?.basicInfo?.minimum1031Investment.includes("$")
      ? row?.basicInfo?.minimum1031Investment
      : `$${row?.basicInfo?.minimum1031Investment || "0"}`,
    yield:
      `${Object.values(row.yields).reduce((acc, value) => {
        const parsedValue = parseInt(value);
        if (!isNaN(parsedValue)) {
          return acc + parsedValue;
        }
        return acc;
      }, 0)}%` || 0,
    loanRate:
      row?.basicInfo?.loanRate === "N/A"
        ? "N/A"
        : row?.basicInfo?.loanRate.includes("%")
        ? row?.basicInfo?.loanRate
        : `${row?.basicInfo?.loanRate || "0"}%`,
    retained:
      row?.basicInfo?.percentRetainedBySponsor === "N/A"
        ? "N/A"
        : row?.basicInfo?.percentRetainedBySponsor.includes("%")
        ? row?.basicInfo?.percentRetainedBySponsor
        : `${row?.basicInfo?.percentRetainedBySponsor || "0"}%`,
    units: row?.basicInfo?.totalUnitsInPortfolio || 0,
    p1name: row?.property?.[0]?.propertyName || "-",
    p1street: row?.property?.[0]?.streetAddress || "-",
    p1csz: row?.property?.[0]?.propertyName || "-",
    p2name: row?.property?.[1]?.propertyName || "-",
    p2street: row?.property?.[1]?.streetAddress || "-",
    p2csz: row?.property?.[1]?.propertyName || "-",
    p3name: row?.property?.[2]?.propertyName || "-",
    p3street: row?.property?.[2]?.streetAddress || "-",
    p3csz: row?.property?.[2]?.propertyName || "-",
    p4name: row?.property?.[3]?.propertyName || "-",
    p4street: row?.property?.[3]?.streetAddress || "-",
    p4csz: row?.property?.[3]?.propertyName || "-",
    p5name: row?.property?.[4]?.propertyName || "-",
    p5street: row?.property?.[4]?.streetAddress || "-",
    p5csz: row?.property?.[4]?.propertyName || "-",
    p6name: row?.property?.[5]?.propertyName || "-",
    p6street: row?.property?.[5]?.streetAddress || "-",
    p6csz: row?.property?.[5]?.propertyName || "-",
    p7name: row?.property?.[6]?.propertyName || "-",
    p7street: row?.property?.[6]?.streetAddress || "-",
    p7csz: row?.property?.[6]?.propertyName || "-",
    p8name: row?.property?.[7]?.propertyName || "-",
    p8street: row?.property?.[7]?.streetAddress || "-",
    p8csz: row?.property?.[7]?.propertyName || "-",
    p9name: row?.property?.[8]?.propertyName || "-",
    p9street: row?.property?.[8]?.streetAddress || "-",
    p9csz: row?.property?.[8]?.propertyName || "-",
    p10name: row?.property?.[9]?.propertyName || "-",
    p10street: row?.property?.[9]?.streetAddress || "-",
    p10csz: row?.property?.[9]?.propertyName || "-",
  }));

  const handleButtonChange = (event, newButton) => {
    setActiveButton(newButton);
  };

  const handleActiveDsts = () => {
    const activeDsts = allDsts?.filter((f) => f.is_active === true);
    setFilterDsts(activeDsts);
  };
  const handleInactiveDsts = () => {
    const inActiveDsts = allDsts?.filter((f) => f.is_active === false);
    setFilterDsts(inActiveDsts);
  };
  const handleAllDsts = () => {
    setFilterDsts(allDsts);
  };

  return (
    <div style={{ margin: "2rem 2rem" }}>
      <Paper sx={{ width: "100%", padding: "0rem 1rem" }}>
        <div
          style={{
            display: "flex",
            padding: "1rem 0rem",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>
            <h3>All DSTs Details</h3>
          </div>
          <ToggleButtonGroup
            color="primary"
            exclusive
            aria-label="Platform"
            value={activeButton}
            onChange={handleButtonChange}
          >
            <ToggleButton
              onClick={handleAllDsts}
              value="all"
              className={activeButton === "all" ? classes.activeButton : ""}
            >
              All
            </ToggleButton>
            <ToggleButton
              onClick={handleActiveDsts}
              value="active"
              className={activeButton === "active" ? classes.activeButton : ""}
            >
              Active
            </ToggleButton>
            <ToggleButton
              onClick={handleInactiveDsts}
              value="inactive"
              className={
                activeButton === "inactive" ? classes.activeButton : ""
              }
            >
              Inactive
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        <Box sx={{ width: "100%" }}>
          <DataGrid
            density="comfortable"
            getRowId={(row) => row.name}
            pagination
            slots={{
              pagination: CustomPagination,
            }}
            rows={rows}
            columns={columns.filter((column) => column.field !== "id")}
            initialState={{
              ...rows.initialState,
              pagination: { paginationModel: { pageSize: 25 } },
            }}
          />
        </Box>
      </Paper>
    </div>
  );
};

export default ViewAllDsts;
