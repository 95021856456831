import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import tokenStorage from "../services/tokenStorage";
import { ThemeProvider, createTheme } from "@mui/material/styles";

function NonProtected({ children }) {
  const token = tokenStorage.getToken();

  if (token) {
    return <Navigate to="/" replace />;
  }
  const defaultTheme = createTheme();

  return (
    <>
      <ThemeProvider theme={defaultTheme}>{children}</ThemeProvider>
    </>
  );
}
export default NonProtected;
