import React, { useEffect, useContext, useState } from "react";
import MyContext from "../../context/appContext";
import { API } from "aws-amplify";
import {
  Button,
  Card,
  Grid,
  InputAdornment,
  TextField,
  ThemeProvider,
} from "@material-ui/core";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { toast } from "react-toastify";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const FederalTax = () => {
  const appContext = useContext(MyContext);
  const [loading, setLoading] = useState(false);
  const [fedTaxList, setFedTaxList] = useState([]);
  const [values, setValues] = useState([]);

  useEffect(() => {
    getFedTaxDetails();
  }, []);

  const getFedTaxDetails = async () => {
    try {
      appContext.updateState("loading", true);
      const payload = {
        queryStringParameters: {
          tableName: "federal_tax-dev",
          id: "all",
          LastEvaluatedKey: "None",
        },
      };
      const res = await API.get("dynamoRW", "/dsts", payload);
      console.log(res, "RES");
      setFedTaxList(res);
      appContext.updateState("loading", false);
    } catch (error) {
      appContext.updateState("loading", false);
      console.log(error.message);
      toast(`No State Found! ${error.message}`, { type: "error" });
    } finally {
      setLoading(false);
    }
  };

  console.log(fedTaxList, "New State");

  const handleChange = (e, id) => {
    const newState = [...values];
    const index = newState.findIndex((x) => x.id === id);
    if (index >= 0) {
      if (e.target.value === "") {
        newState.splice(index, 1);
      } else {
        newState[index].value = e.target.value;
      }
    } else {
      if (e.target.value !== "") {
        newState.push({ id: id, value: e.target.value });
      }
    }
    setValues(newState);
  };

  console.log(values, "FEDERAL VALUES");

  const handleAddFedPercent = async () => {
    try {
      appContext.updateState("loading", true);
      for (const val of values) {
        const params = {
          body: {
            tableName: "federal_tax-dev",
            Key: {
              id: val.id,
            },
            AttributeUpdates: {
              percent: {
                Value: val.value,
                Action: "PUT",
              },
            },
          },
        };
        const res = await API.put("dynamoRW", "/dsts", params);
      }
      getFedTaxDetails();
      appContext.updateState("loading", false);
      toast("Saved successfully", { type: "success" });
    } catch (error) {
      appContext.updateState("loading", false);
      console.log(error.message);
      toast(`Error saving federal tax! ${error.message}`, { type: "error" });
    } finally {
      setLoading(false);
    }
  };

  const rows = fedTaxList.map((fed) => {
    return { type: fed.type, percent: fed.percent };
  });
  return (
    <Card sx={{ minWidth: 275 }}>
      <ThemeProvider>
        <div
          style={{
            display: "flex",
            columnGap: "10px",
            justifyContent: "end",
            padding: "1rem",
          }}
        >
          <Button
            onClick={handleAddFedPercent}
            type="submit"
            variant="contained"
            style={{ backgroundColor: "#1976d2", color: "#fff" }}
          >
            Save
          </Button>
        </div>
        <Grid container style={{ padding: "0rem 1rem" }} spacing={2}>
          {fedTaxList?.map((fed, ind) => (
            <Grid item xs={4} className="Grid-Gap" key={ind}>
              <TextField
                size="small"
                variant="outlined"
                margin="normal"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">%</InputAdornment>
                  ),
                }}
                type="number"
                label={`${fed?.type}`}
                onChange={(e) => {
                  handleChange(e, fed.id);
                }}
              />
            </Grid>
          ))}
        </Grid>
        <div style={{ marginTop: "3rem" }}>
          <h3 style={{ textAlign: "center" }}>
            Federal Tax Estimates For Illustrative Purposes
          </h3>
        </div>
        <TableContainer style={{ marginTop: "2rem" }}>
          <Table
            style={{ minWidth: 700, margin: "0 auto" }}
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">Tax Type</StyledTableCell>
                <StyledTableCell align="center">
                  Tax Percent&nbsp;(%)
                </StyledTableCell>
                {/* <StyledTableCell align="center">Limit&nbsp;(up to %)</StyledTableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map((row, ind) => (
                <StyledTableRow key={ind}>
                  <StyledTableCell align="center">{row.type}</StyledTableCell>
                  <StyledTableCell align="center">{`${row.percent}%`}</StyledTableCell>
                  {/* <StyledTableCell align="center">{row.fat}</StyledTableCell> */}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </ThemeProvider>
    </Card>
  );
};

export default FederalTax;
