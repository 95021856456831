import React, { useContext, useEffect, useState } from "react";
import { Dialog, ThemeProvider } from "@material-ui/core";
import { API } from "aws-amplify";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { toast } from "react-toastify";
import MyContext from "../context/appContext";
import Switch from "@mui/material/Switch";
import {
    Button,
    TextField,
    DialogTitle,
    DialogContent,
    DialogActions,
    List,
    ListItem,
    ListItemText,
    IconButton,
    Tooltip,
    Chip,
    Box,
    Typography,
} from "@mui/material";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import GroupIcon from '@mui/icons-material/Group';
import DeleteIcon from '@mui/icons-material/Delete';
import Checkbox from '@mui/material/Checkbox';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const ManageBrokerDealers = () => {
    const appContext = useContext(MyContext);
    const [show, setShow] = useState(false);
    const [brokerDealers, setBrokerDealers] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [currentBroker, setCurrentBroker] = useState({
        id: '',
        name: '',
        email: '',
        status: "true"
    });
    const [showUserDialog, setShowUserDialog] = useState(false);
    const [selectedBroker, setSelectedBroker] = useState(null);
    const [users, setUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState({});
    const [brokerUserCounts, setBrokerUserCounts] = useState({});
    const [userBrokerDealers, setUserBrokerDealers] = useState({});

    useEffect(() => {
        getBrokerDealers();
        fetchUsers();
    }, []);

    useEffect(() => {
        if (showUserDialog && selectedBroker) {
            // Initialize selected users based on existing assignments
            const initialSelections = {};
            users.forEach(user => {
                const userBrokerDealers = user.brokerDealerIds || [];
                initialSelections[user.id] = userBrokerDealers.includes(selectedBroker.id);
            });
            setSelectedUsers(prev => ({
                ...prev,
                [selectedBroker.id]: initialSelections
            }));
        }
    }, [showUserDialog, selectedBroker, users]);

    const getBrokerDealers = async () => {
        try {
            appContext.updateState("loading", true);

            const params = {
                headers: {
                    'Content-Type': 'application/json'
                }
            };

            const res = await API.get("dynamoRW", "/1031_broker_Dealer", params);

            if (res.items) {
                const sortedBrokers = res.items.sort((a, b) =>
                    a.name.localeCompare(b.name)
                );
                setBrokerDealers(sortedBrokers);
            } else {
                throw new Error('No items returned from server');
            }
        } catch (error) {
            console.error('Full error:', error);
            toast(`Error fetching broker dealers! ${error.message}`, { type: "error" });
        } finally {
            appContext.updateState("loading", false);
        }
    };

    const fetchUsers = async () => {
        try {
            appContext.updateState("loading", true);
            const params = {
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            let tablename = "approvedUsers-dev";
            const res = await API.get(
                "dynamoRW",
                `/notifications?tablename=${tablename}`
            );

            setUsers(res.Items);

            // Update counting logic to handle multiple broker dealer assignments
            const userCounts = res.Items.reduce((acc, user) => {
                // Get array of broker dealer IDs for the user
                const brokerDealerIds = user.brokerDealerIds || [];

                // Increment count for each broker dealer the user is assigned to
                brokerDealerIds.forEach(brokerId => {
                    acc[brokerId] = (acc[brokerId] || 0) + 1;
                });

                return acc;
            }, {});

            setBrokerUserCounts(userCounts);

        } catch (error) {
            console.error('Error fetching broker users:', error);
            toast.error(`Error fetching broker users! ${error.message}`);
        } finally {
            appContext.updateState("loading", false);
        }
    };

    const handleStatusChange = async (brokerId) => {
        try {
            appContext.updateState("loading", true);
            const broker = brokerDealers.find(b => b.id === brokerId);
            const params = {
                body: {
                    tableName: '1031_broker_dealer',
                    Key: { id: brokerId },
                    AttributeUpdates: {
                        status: { Value: !broker.status }
                    }
                }
            };
            const res = await API.put("dynamoRW", "/1031_broker_Dealer", params);
            if (res.message) {
                getBrokerDealers();
                toast("Status updated successfully", { type: "success" });
            }
        } catch (error) {
            toast(`Error updating status! ${error.message}`, { type: "error" });
        } finally {
            appContext.updateState("loading", false);
        }
    };
    const handleSubmit = async () => {
        try {
            appContext.updateState("loading", true);

            if (!editMode) {
                const newItem = {
                    id: Date.now().toString(),
                    name: currentBroker.name,
                    email: currentBroker.email,
                    status: true
                };

                const params = {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: {
                        Item: newItem
                    }
                };

                const res = await API.post("dynamoRW", "/1031_broker_Dealer", params);

                if (res.message) {
                    await getBrokerDealers();
                    handleClose();
                    toast("Broker dealer added successfully", { type: "success" });
                }
            } else {
                const params = {
                    body: {
                        Key: { id: currentBroker.id },
                        AttributeUpdates: {
                            name: { Value: currentBroker.name },
                            email: { Value: currentBroker.email },
                            status: { Value: currentBroker.status }
                        }
                    }
                };

                const res = await API.put("dynamoRW", "/1031_broker_Dealer", params);

                if (res.message) {
                    await getBrokerDealers();
                    handleClose();
                    toast("Broker dealer updated successfully", { type: "success" });
                }
            }
        } catch (error) {
            console.error('Full error:', error);
            toast(`Error ${editMode ? 'updating' : 'adding'} broker dealer! ${error.message}`, { type: "error" });
        } finally {
            appContext.updateState("loading", false);
        }
    };

    const handleEdit = (broker) => {
        setCurrentBroker(broker);
        setEditMode(true);
        setShow(true);
    };

    const handleDelete = async (brokerId) => {
        try {
            appContext.updateState("loading", true);
            const params = {
                body: {
                    tableName: '1031_broker_dealer',
                    Key: { id: brokerId }
                }
            };
            const res = await API.del("dynamoRW", "/1031_broker_Dealer", params);
            if (res.message === 'Item deleted successfully') {
                getBrokerDealers();
                toast("Broker dealer deleted successfully", { type: "success" });
            }
        } catch (error) {
            toast(`Error deleting broker dealer! ${error.message}`, { type: "error" });
        } finally {
            appContext.updateState("loading", false);
        }
    };

    const handleClose = () => {
        setShow(false);
        setEditMode(false);
        setCurrentBroker({ id: '', name: '', email: '', status: true });
    };

    const handleUserSelection = (brokerId, userId) => {
        setSelectedUsers(prev => ({
            ...prev,
            [brokerId]: {
                ...prev[brokerId],
                [userId]: !prev[brokerId]?.[userId]
            }
        }));
    };

    const handleAssignUsers = async (brokerId) => {
        try {
            appContext.updateState("loading", true);

            // Get only the selected users
            const selectedUserIds = Object.entries(selectedUsers[brokerId] || {})
                .filter(([_, isSelected]) => isSelected)
                .map(([userId]) => userId);

            const updatePromises = users.map(async (user) => {
                // Skip users who aren't selected and weren't previously assigned
                const wasAssigned = (user.brokerDealerIds || []).includes(brokerId);
                const isSelected = selectedUserIds.includes(user.id);

                // Only process if there's a change in assignment
                if (!wasAssigned && !isSelected) return null;

                let existingBrokerDealers = [...(user.brokerDealerIds || [])];
                let assignedDsts = [...(user.assignedDsts || [])];

                if (isSelected && !wasAssigned) {
                    // Add broker dealer for newly selected users
                    existingBrokerDealers.push(brokerId);
                } else if (!isSelected && wasAssigned) {
                    // Remove broker dealer and its DSTs for deselected users
                    existingBrokerDealers = existingBrokerDealers.filter(id => id !== brokerId);
                    assignedDsts = assignedDsts.filter(dst => dst.brokerDealerId !== brokerId);
                }

                const addParams = {
                    body: {
                        tableName: "approvedUsers-dev",
                        Item: {
                            ...user,
                            id: user.id,
                            username: user.username,
                            userEmail: user.userEmail,
                            status: true,
                            role: user.role,
                            brokerDealerIds: existingBrokerDealers,
                            assignedDsts: assignedDsts,
                            updatedAt: new Date().toISOString()
                        },
                    },
                };

                return API.post("dynamoRW", "/dsts", addParams);
            });

            // Filter out null promises and execute updates
            const filteredPromises = updatePromises.filter(promise => promise !== null);
            await Promise.all(filteredPromises);

            await fetchUsers();
            toast.success("Users updated successfully");
            setShowUserDialog(false);

        } catch (error) {
            console.error('Error updating users:', error);
            toast.error(`Error updating users! ${error.message}`);
        } finally {
            appContext.updateState("loading", false);
        }
    };

    const renderTableRow = (broker) => (
        <StyledTableRow key={broker.id}>
            <StyledTableCell align="center">
                {broker.name}
                <Chip
                    size="small"
                    icon={<GroupIcon />}
                    label={`${brokerUserCounts[broker.id] || 0} Users`}
                    sx={{ ml: 1 }}
                />
            </StyledTableCell>
            <StyledTableCell align="center">{broker.email}</StyledTableCell>
            <StyledTableCell align="center">
                <Switch
                    checked={broker.status}
                    onChange={() => handleStatusChange(broker.id)}
                    inputProps={{ "aria-label": "controlled" }}
                />
            </StyledTableCell>
            <StyledTableCell align="center">
                <Tooltip title="Manage Users">
                    <IconButton
                        onClick={() => {
                            setSelectedBroker(broker);
                            setShowUserDialog(true);
                        }}
                        color="primary"
                        sx={{ mr: 1 }}
                    >
                        <PersonAddIcon />
                    </IconButton>
                </Tooltip>
                <Button
                    sx={{ textTransform: "none", mr: 1 }}
                    variant="outlined"
                    onClick={() => handleEdit(broker)}
                >
                    Edit
                </Button>
                <Button
                    sx={{ textTransform: "none" }}
                    variant="outlined"
                    color="error"
                    onClick={() => handleDelete(broker.id)}
                >
                    Delete
                </Button>
            </StyledTableCell>
        </StyledTableRow>
    );

    const renderUserDialog = () => (
        <Dialog
            open={showUserDialog}
            onClose={() => {
                setShowUserDialog(false);
                setSelectedBroker(null);
                setSelectedUsers({});
            }}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle>
                Manage Users - {selectedBroker?.name}
            </DialogTitle>
            <DialogContent>
                <List>
                    {users.map((user) => (
                        <ListItem
                            key={user.id}
                            secondaryAction={
                                <Checkbox
                                    checked={!!selectedUsers[selectedBroker?.id]?.[user.id]}
                                    onChange={() => handleUserSelection(selectedBroker?.id, user.id)}
                                />
                            }
                        >
                            <ListItemText
                                primary={
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        {user.username}
                                        <Chip
                                            size="small"
                                            label={user.role}
                                            color={user.role === 'Super Admin' ? 'error' :
                                                user.role === 'Admin' ? 'primary' : 'default'}
                                        />
                                    </Box>
                                }
                                secondary={user.userEmail}
                            />
                        </ListItem>
                    ))}
                </List>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    onClick={() => handleAssignUsers(selectedBroker?.id)}
                    color="primary"
                >
                    Save Changes
                </Button>
                <Button onClick={() => {
                    setShowUserDialog(false);
                    setSelectedBroker(null);
                    setSelectedUsers({});
                }}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );

    return (
        <>
            <div style={{ padding: "20px 20px" }}>
                <ThemeProvider>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "1rem",
                        }}
                    >
                        <h3>Manage Broker Dealers</h3>
                        <Button
                            sx={{ textTransform: "none" }}
                            variant="contained"
                            onClick={() => setShow(true)}
                        >
                            Add Broker Dealer
                        </Button>
                    </div>
                    <TableContainer>
                        <Table
                            style={{ minWidth: 700, margin: "0 auto" }}
                            aria-label="customized table"
                        >
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="center">Name</StyledTableCell>
                                    <StyledTableCell align="center">Email</StyledTableCell>
                                    <StyledTableCell align="center">Status</StyledTableCell>
                                    <StyledTableCell align="center">Actions</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {brokerDealers.map((broker) => renderTableRow(broker))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </ThemeProvider>
            </div>

            <Dialog
                open={show}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {editMode ? "Edit Broker Dealer" : "Add Broker Dealer"}
                </DialogTitle>
                <DialogContent sx={{ width: "450px" }}>
                    <TextField
                        size="small"
                        margin="normal"
                        fullWidth
                        label="Name"
                        value={currentBroker.name}
                        onChange={(e) => setCurrentBroker({ ...currentBroker, name: e.target.value })}
                    />
                    <TextField
                        size="small"
                        margin="normal"
                        fullWidth
                        label="Email"
                        value={currentBroker.email}
                        onChange={(e) => setCurrentBroker({ ...currentBroker, email: e.target.value })}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        sx={{ textTransform: "none" }}
                        variant="outlined"
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        sx={{ textTransform: "none" }}
                        variant="contained"
                        onClick={handleSubmit}
                    >
                        {editMode ? "Update" : "Add"}
                    </Button>
                </DialogActions>
            </Dialog>
            {renderUserDialog()}
        </>
    );
};

export default ManageBrokerDealers;
