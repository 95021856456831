import React from "react";
import { Grid } from "@mui/material";

function DstDetailPrint1({ findDst }) {
  let hasCommercial = false;
  let properties = findDst?.property;
  for (let i = 0; i < properties?.length; i++) {
    if (properties[i].propertyTypeofDST === "Commercial") {
      properties[i].state = true;
      hasCommercial = true;
      break; // Assuming you want to stop after finding the first occurrence
    }
  }

  const rows = findDst?.property?.map((pro) => ({
    msaProjectedPopGrowth: pro.msaProjectedPopGrowth,
    msaRecentPopGrowth: pro.msaRecentPopGrowth,
    msaRecentRentGrowth: pro.msaRecentRentGrowth,
    subMarketProjectedPopGrowth: pro.subMarketProjectedPopGrowth,
    subMarketRecentPopGrowth: pro.subMarketRecentPopGrowth,
  }));
  return (
    <div>
      {findDst?.property?.map((pro, key) => (
        <div key={key} style={{ pageBreakAfter: "always" }}>
          <div
            style={{
              border: "1px solid black",
              borderRadius: "1px",
              margin: "0.5rem 0rem",
            }}
          >
            <Grid
              container
              spacing={0}
              columnGap={6}
              style={{ fontSize: "0.8rem" }}
            >
              <Grid item xs={3}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    margin: "5px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "1rem",
                    }}
                  >
                    <h4 style={{ margin: "0px" }}>
                      {pro?.propertyName || "None"}
                    </h4>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "1rem",
                    }}
                  >
                    <p style={{ margin: "0px" }}>
                      {`${pro?.city}, ${pro?.states}` || "None"}
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "1rem",
                    }}
                  >
                    <p style={{ margin: "0px" }}>{pro?.zip || "None"}</p>
                  </div>
                </div>
              </Grid>
              <Grid item xs={3}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    margin: "5px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      gap: "1rem",
                    }}
                  >
                    <h4 style={{ margin: "0px" }}>MSA:</h4>
                    <p style={{ margin: "0px" }}>{pro?.msa || "None"}</p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      gap: "1rem",
                    }}
                  >
                    <h4 style={{ margin: "0px" }}>Sub:</h4>
                    <p style={{ margin: "0px" }}>{pro?.submarket || "None"}</p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      gap: "1rem",
                    }}
                  >
                    <h4 style={{ margin: "0px" }}>Built:</h4>
                    <p style={{ margin: "0px" }}>{pro?.yearBuilt || "None"}</p>
                  </div>
                </div>
              </Grid>
              <Grid item xs={4}>
                <p style={{ textAlign: "end", margin: "0px" }}>
                  Property # <b>{key + 1}</b>
                </p>
              </Grid>
            </Grid>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                margin: "5px",
                fontSize: "0.8rem",
                marginTop: "0.5rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "start",
                  alignItems: "start",
                }}
              >
                <h4 style={{ margin: "0px" }}>Location Description</h4>
                <p style={{ margin: "0px" }}>
                  {pro?.locationDescription || "None"}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "start",
                  alignItems: "start",
                }}
              >
                <h4 style={{ margin: "0px" }}>Style</h4>
                <p style={{ margin: "0px" }}>{pro?.style || "None"}</p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "start",
                  alignItems: "start",
                }}
              >
                <h4 style={{ margin: "0px" }}>Class</h4>
                <p style={{ margin: "0px" }}>{pro?.class || "None"}</p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "start",
                  alignItems: "start",
                }}
              >
                <h4 style={{ margin: "0px" }}>Sector</h4>
                <p style={{ margin: "0px" }}>{pro?.sector || "None"}</p>
              </div>
            </div>
          </div>
          <div
            style={{
              border: "1px solid black",
              borderRadius: "1px",
              margin: "0rem 0rem",
            }}
          >
            <Grid
              container
              spacing={0}
              columnGap={4}
              style={{ fontSize: "0.8rem" }}
              wrap="wrap"
            >
              <Grid item xs={4}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    margin: "5px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <h4 style={{ margin: "0px" }}>Lease type:</h4>
                    <p style={{ margin: "0px" }}>{pro?.leaseType || "None"}</p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <h4 style={{ margin: "0px" }}>Investment strategy:</h4>
                    <p style={{ margin: "0px" }}>
                      {pro?.investmentStrategy || "None"}
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      flexWrap: "wrap",
                      width: "100%",
                    }}
                  >
                    <h4 style={{ margin: "0px", flexGrow: 0 }}>
                      Value-add notes:
                    </h4>

                    <p
                      style={{
                        margin: "0px",
                        padding: "0px",
                        minWidth: 0,
                        overflowWrap: "break-word",
                        marginLeft: "auto",
                        right: 0,
                      }}
                    >
                      {pro?.valueAddNotes || "None"}
                    </p>
                  </div>
                </div>
              </Grid>
              <Grid item xs={3}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    margin: "5px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <h4 style={{ margin: "0px" }}>Term:</h4>
                    <p style={{ margin: "0px" }}>{pro?.leaseTerm || "None"}</p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <h4 style={{ margin: "0px" }}>Remaining:</h4>
                    <p style={{ margin: "0px" }}>
                      {pro?.remainingYears || "None"}
                    </p>
                  </div>
                </div>
              </Grid>
              <Grid item xs={2}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    margin: "5px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      gap: "1rem",
                    }}
                  >
                    <h4 style={{ margin: "0px" }}>Extension:</h4>
                    <p style={{ margin: "0px" }}>{pro?.extensions || 0}</p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      gap: "1rem",
                    }}
                  >
                    <h4 style={{ margin: "0px" }}>Cr. Rating:</h4>
                    <p style={{ margin: "0px" }}>
                      {pro?.tenantCreditRating || null}
                    </p>
                  </div>
                </div>
              </Grid>
              <Grid item xs={2}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    margin: "5px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      gap: "1rem",
                    }}
                  >
                    <h4 style={{ margin: "0px" }}>Occup:</h4>
                    <p style={{ margin: "0px" }}>{`${
                      pro?.currentOccup || 0
                    }%`}</p>
                  </div>
                  {!hasCommercial && (
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          gap: "1rem",
                        }}
                      >
                        <h4 style={{ margin: "0px" }}>1-bed:</h4>
                        <p style={{ margin: "0px" }}>
                          {Number(pro?.oneBedrooms).toLocaleString("en-US") ||
                            0}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          gap: "1rem",
                        }}
                      >
                        <h4 style={{ margin: "0px" }}>2-bed:</h4>
                        <p style={{ margin: "0px" }}>
                          {Number(pro?.twoBedrooms).toLocaleString("en-US") ||
                            0}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          gap: "1rem",
                        }}
                      >
                        <h4 style={{ margin: "0px" }}>3-bed:</h4>
                        <p style={{ margin: "0px" }}>
                          {Number(pro?.threeBedrooms).toLocaleString("en-US") ||
                            0}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          gap: "1rem",
                        }}
                      >
                        <h4 style={{ margin: "0px" }}>Other:</h4>
                        <p style={{ margin: "0px" }}>
                          {Number(pro?.otherUnits).toLocaleString("en-US") || 0}
                        </p>
                      </div>
                    </>
                  )}

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      gap: "1rem",
                    }}
                  >
                    <h4 style={{ margin: "0px" }}>Total units:</h4>
                    <p style={{ margin: "0px" }}>
                      {Number(pro?.totalUnits).toLocaleString("en-US") || 0}
                    </p>
                  </div>
                </div>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={0}
              columnGap={5}
              style={{ fontSize: "0.8rem" }}
            >
              <Grid item xs={3}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    margin: "5px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <h4 style={{ margin: "0px" }}>Total SF:</h4>
                    <p style={{ margin: "0px" }}>
                      {`${Number(pro?.totalSf).toLocaleString("en-US")}` || 0}
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <h4 style={{ margin: "0px" }}>Buildings:</h4>
                    <p style={{ margin: "0px" }}>
                      {Number(pro?.NumberOfBuildings).toLocaleString("en-US") ||
                        0}
                    </p>
                  </div>
                </div>
              </Grid>
              <Grid item xs={3}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    margin: "5px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <h4 style={{ margin: "0px" }}>Ave. SF:</h4>
                    <p style={{ margin: "0px" }}>
                      {Number(
                        (+pro?.totalSf / +pro?.totalUnits).toFixed()
                      ).toLocaleString("en-US") || 0}
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <h4 style={{ margin: "0px" }}>Max Floors:</h4>
                    <p style={{ margin: "0px" }}>{pro?.maxFloors || null}</p>
                  </div>
                </div>
              </Grid>
              <Grid item xs={3}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    margin: "5px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <h4 style={{ margin: "0px" }}>PM:</h4>
                    <p style={{ margin: "0px" }}>
                      {pro?.propertyManager || null}
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <h4 style={{ margin: "0px" }}>Acres:</h4>
                    <p style={{ margin: "0px" }}>
                      {Number(pro?.acres).toLocaleString("en-US") || 0}
                    </p>
                  </div>
                </div>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={0}
              columnGap={10}
              style={{ fontSize: "0.8rem" }}
            >
              <Grid item xs={2}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    margin: "5px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <h4 style={{ margin: "0px" }}>Top employers:</h4>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <p style={{ margin: "0px" }}>{pro?.topEmployerA || null}</p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <p style={{ margin: "0px" }}>{pro?.topEmployerB || null}</p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <p style={{ margin: "0px" }}>{pro?.topEmployerC || null}</p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <p style={{ margin: "0px" }}>{pro?.topEmployerD || null}</p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <p style={{ margin: "0px" }}>{pro?.topEmployerE || null}</p>
                  </div>
                </div>
              </Grid>
              {hasCommercial ? (
                <>
                  {rows.map((row) => (
                    <div style={{ flexDirection: "column" }}>
                      <p>{row.msaRecentPopGrowth}</p>
                      <p>{row.subMarketRecentPopGrowth}</p>
                      <p>{row.msaProjectedPopGrowth}</p>
                      <p>{row.subMarketProjectedPopGrowth}</p>
                      <p>{row.msaRecentRentGrowth}</p>
                    </div>
                  ))}
                </>
              ) : (
                <>
                  <Grid item xs={2}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        margin: "5px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <h4
                          style={{ margin: "0px", textDecoration: "underline" }}
                        >
                          Popul.
                        </h4>
                        <h4
                          style={{ margin: "0px", textDecoration: "underline" }}
                        >
                          MSA / metro
                        </h4>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <h4 style={{ margin: "0px" }}>Recent:</h4>
                        <p style={{ margin: "0px" }}>
                          &nbsp;&nbsp; {pro?.msaRecentPopGrowth || null}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <h4 style={{ margin: "0px" }}>Forecast:</h4>
                        <p style={{ margin: "0px" }}>
                          {" "}
                          &nbsp;&nbsp;
                          {pro?.msaProjectedPopGrowth
                            ? `${pro?.msaProjectedPopGrowth}`
                            : null}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <h4
                          style={{ margin: "0px", textDecoration: "underline" }}
                        >
                          Rents.
                        </h4>
                        <h4
                          style={{ margin: "0px", textDecoration: "underline" }}
                        >
                          MSA / metro
                        </h4>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <h4 style={{ margin: "0px" }}>Recent:</h4>
                        <p style={{ margin: "0px" }}>
                          {" "}
                          &nbsp;&nbsp;
                          {pro?.msaRecentRentGrowth
                            ? pro?.msaRecentRentGrowth
                            : null}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <h4 style={{ margin: "0px" }}>Forecast:</h4>
                        <p style={{ margin: "0px" }}>
                          {" "}
                          &nbsp;&nbsp;
                          {pro?.msaProjectedRentGrowth
                            ? pro?.msaProjectedRentGrowth
                            : null}
                        </p>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={2}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        margin: "5px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <h4
                          style={{ margin: "0px", textDecoration: "underline" }}
                        >
                          Sub-market / local
                        </h4>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <p style={{ margin: "0px" }}>
                          {pro?.subMarketRecentPopGrowth
                            ? pro?.subMarketRecentPopGrowth
                            : null}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <p style={{ margin: "0px" }}>
                          {pro?.subMarketProjectedPopGrowth
                            ? pro?.subMarketProjectedPopGrowth
                            : null}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <h4
                          style={{ margin: "0px", textDecoration: "underline" }}
                        >
                          Sub-market / local
                        </h4>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <p style={{ margin: "0px" }}>
                          {pro?.subMarketRecentRentGrowth
                            ? pro?.subMarketRecentRentGrowth
                            : null}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <p style={{ margin: "0px" }}>
                          {pro?.subProjectedRentGrowth
                            ? pro?.subProjectedRentGrowth
                            : null}
                        </p>
                      </div>
                    </div>
                  </Grid>
                </>
              )}
            </Grid>
            <Grid
              container
              spacing={0}
              style={{
                padding: "0.2rem 0.2rem",
                fontSize: "0.8rem",
              }}
            >
              <Grid item xs={6}>
                <div>
                  <h4 style={{ margin: "0px" }}>
                    Other general economic notes:
                  </h4>
                  {/* <p style={{ margin: "0px" }}>
                zz notes economics
              </p> */}
                  <p>{pro?.otherEconomicNotes || null}</p>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div>
                  <h4 style={{ margin: "0px" }}>
                    Other general demographic notes:
                  </h4>
                  {/* <p style={{ margin: "0px" }}>
                zz notes demographics
              </p> */}
                  <p>{pro?.otherDemographicNotes || null}</p>
                </div>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={0}
              columnGap={1}
              style={{ fontSize: "0.8rem" }}
            >
              <Grid item xs={3}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    margin: "5px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <h4 style={{ margin: "0px" }}>
                      {hasCommercial ? "Tenant Highlights" : "Unit amenities"}
                    </h4>
                    <p style={{ margin: "0px" }}>
                      {pro?.unitAmenities || null}
                    </p>
                  </div>
                </div>
              </Grid>
              <Grid item xs={3}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    margin: "5px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <h4 style={{ margin: "0px" }}>
                      {hasCommercial ? "Property Notes" : "Common amenities"}
                    </h4>
                    <p style={{ margin: "0px" }}>
                      {pro?.commonAmenities || null}
                    </p>
                  </div>
                </div>
              </Grid>
              <Grid item xs={3}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    margin: "5px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <h4 style={{ margin: "0px" }}>Location notes:</h4>
                    <p style={{ margin: "0px" }}>
                      {pro?.locationNotes || null}
                    </p>
                  </div>
                </div>
              </Grid>
              <Grid item xs={2}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    margin: "5px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <h4 style={{ margin: "0px" }}>Misc. notes:</h4>
                    <p style={{ margin: "0px" }}>{pro?.miscNotes || null}</p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      ))}
    </div>
  );
}

export default DstDetailPrint1;
