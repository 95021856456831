import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import AddDstBasicInfo from "../components/addDstBasicInfo";
import AddDstProperties from "../components/addDstProperties";
import { useParams } from "react-router-dom";
import PropertyImages from "../components/PropertyImages";
import AWS from "aws-sdk";
import AddDstCommericalProperty from "../components/addDstCommericalPropertity";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const AddDST = () => {
  let { id } = useParams();
  const [value, setValue] = useState(0);
  const [session, setSession] = useState(null);
  const [formDataProperty, setFormDataProperty] = useState({
    selectDst: "",
    propertyName: "",
    streetAddress: "",
    states: "",
    city: "",
    zip: "",
    locationDescription: "",
    msa: "",
    submarket: "",
    yearBuilt: "",
    style: "",
    class: "",
    sector: "",
    investmentStrategy: "",
    primaryTenant: "",
    valueAddNotes: "",
    leaseType: "",
    leaseTerm: "",
    remainingYears: "",
    extensions: "",
    tenantCreditRating: "",
    currentOccup: "",
    oneBedrooms: "",
    twoBedrooms: "",
    threeBedrooms: "",
    otherUnits: "",
    totalSf: "",
    totalUnits: "",
    NumberOfBuildings: "",
    maxFloors: "",
    propertyManager: "",
    pmDescription: "",
    propertyWebsite: "",
    acres: "",
    topEmployerA: "",
    topEmployerB: "",
    topEmployerC: "",
    topEmployerD: "",
    topEmployerE: "",
    msaRecentPopGrowth: "",
    subMarketRecentPopGrowth: "",
    msaProjectedPopGrowth: "",
    subMarketProjectedPopGrowth: "",
    msaRecentRentGrowth: "",
    subMarketRecentRentGrowth: "",
    msaProjectedRentGrowth: "",
    subProjectedRentGrowth: "",

    otherEconomicNotes: "",
    otherDemographicNotes: "",
    locationNotes: "",
    miscNotes: "",
    commonAmenities: "",
    unitAmenities: "",
    images: [],
  });

  const [formData, setFormData] = useState({
    fullLegalNameOfDst: "",
    equityAvailability: "",
    numberOfProperties: "",
    offeringEquity: "",
    nameOfTheSponsor: "",
    percentRetainedBySponsor: "",
    offeringDebt: "",
    MSA: "",
    totalUnitsInPortfolio: "",
    loanTerm: "",
    yearsPropertiesWereBuilt: "",
    interestOnlyPeriod: "",
    generalLocationRelativeToMajorMarket: "",
    states: [],
    currentDstOccupancy: "",
    loanRate: "",
    dstPropertiesSector: "",
    lender: "",
    dstPropertiesClass: "",
    purchasePrice: "",
    year1TotalDebtService: "",
    dstPropertiesStyle: "",
    appraisedValue: "",
    concordeApprovalDate: "",
    year1NetOperatingIncome: "",
    minimum1031Investment: "",
    rentGrowthAssumption: "",
    year1EffectiveGrossRevenue: "",
    msaTier: "",
    fixedVariable: "",
    upreit: "",
    BC: "",
    sponsorBullet1: "",
    sponsorBullet2: "",
    sponsorBullet3: "",
    uniqueDealRisk1: "",
    uniqueDealRisk2: "",
    uniqueDealRisk3: "",
    uniqueDealRisk4: "",
    uniqueDealRisk5: "",
    repCommission: "",
  });

  const [highlights, setHighlights] = useState({
    marketGrowthPoint1: "",
    marketGrowthPoint2: "",
    marketGrowthPoint3: "",
    marketEmploymentPoint: "",
    marketEconomicPoint: "",
    propertyLocationPoint1: "",
    propertyLocationPoint2: "",
    propertyLocationPoint3: "",
    propertyFeaturePoint1: "",
    propertyFeaturePoint2: "",
  });

  const [yields, setYields] = useState({
    year1Yield: "",
    year2Yield: "",
    year3Yield: "",
    year4Yield: "",
    year5Yield: "",
    year6Yield: "",
    year7Yield: "",
    year8Yield: "",
    year9Yield: "",
    year10Yield: "",
  });
  const [proceeds, setProceeds] = useState({
    OOCosts: "",
    brokerDealerCommissions: "",
    wholesalingCosts: "",
    transactionCommission: "",
    financingFee: "",
    otherFees: "",
    thirdPartyClosingCosts: "",
    totalDstReserves: "",
    year5ReservesBalance: "",
  });
  const [otherInfo, setOtherInfo] = useState({
    useOfProceedsPage: "",
    cashFlowPage: "",
    pppRiskFactorsPage: "",
    assumptionsPage: "",
    averageRemainingLeaseDuration: "",
    dstOfferingStrengthAndOppertunities: "",
    dstOfferingWeaknessesAndThreats: "",
    generalNotes: "",
    keyOverallMarketBulletPoints: "",
    dispositionFee: "",
  });
  const handleChange = (event, newValue) => {
    if (newValue == "0") {
      const formState = {
        id: AWS.util.uuid.v4(),
        is_active: true,
        is_deleted: false,
        formData: { ...formData },
        highlights: { ...highlights },
        yields: { ...yields },
        proceeds: { ...proceeds },
        otherInfo: { ...otherInfo },
      };
      sessionStorage.setItem("formStateDst", JSON.stringify(formState));
    } else {
      const formDataProperty = {
        Value: [{ ...formData }],
      };
    }
    setValue(newValue);
  };
  return (
    <div style={{ padding: "20px 20px" }}>
      <div>
        <h4>{id ? "EDIT DST" : "ADD DST"}</h4>
      </div>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="DST Basic info" />
            <Tab label="Commercial Properties" />
            <Tab label="Housing Properties" />
            {id ? <Tab label="Images" /> : null}
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <AddDstBasicInfo
            formData={formData}
            setFormData={setFormData}
            highlights={highlights}
            setHighlights={setHighlights}
            yields={yields}
            setYields={setYields}
            proceeds={proceeds}
            setProceeds={setProceeds}
            otherInfo={otherInfo}
            setOtherInfo={setOtherInfo}
            session={session}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <AddDstCommericalProperty
            formData={formDataProperty}
            setFormData={setFormDataProperty}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <AddDstProperties
            formData={formDataProperty}
            setFormData={setFormDataProperty}
          />
        </TabPanel>
        {id ? (
          <TabPanel value={value} index={3}>
            <PropertyImages />
          </TabPanel>
        ) : null}
      </Box>
    </div>
  );
};

export default AddDST;
