import React, { useContext, useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MyContext from "../context/appContext";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate, useLocation } from "react-router-dom";
import { Auth, API } from "aws-amplify";
import { toast } from "react-toastify";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { IconButton, InputAdornment } from "@material-ui/core";
import EmailIcon from "@mui/icons-material/Email";
const defaultTheme = createTheme();

const useStyles = makeStyles({
  backgroundScreen: {
    backgroundColor: "#000",
    height: "100vh",
  },
  signinCard: {
    padding: "10px 30px",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  boxAlign: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  LinkText: {
    textDecoration: "none",
    color: "#a48d31",
  },
  signUpText: {
    color: "#fff",
    textAlign: "center",
  },
});

export default function SignIn() {
  const appContext = useContext(MyContext);
  const classes = useStyles();
  let navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");

  const onSignIn = async (event) => {
    event.preventDefault();
    try {
      appContext.updateState("loading", true);
      const user = await Auth.signIn({
        username: email,
        password: password,
        token: password,
      });

      const firmName = user?.attributes?.profile;
      localStorage.setItem("companyName", firmName);
      const userGroups =
        user?.signInUserSession?.idToken?.payload["cognito:groups"];
      const newUser = {
        ...user,
        token: user?.userDataKey,
        role: userGroups ? userGroups[0] : null,
      };
      const __set = JSON.stringify(newUser);
      localStorage.setItem("__set", __set);
      appContext.setTokensFromLocalStorage(__set);

      if (newUser) {
        let tablename = "altsnotifications-dev";
        let id = email;
        const res = await API.get(
          "dynamoRW",
          `/notifications?tablename=${tablename}&id=${id}`
        );
        if (res) {
          localStorage.setItem(
            "message",
            "Your account has been registered and access will be granted within 24 hours"
          );
        } else {
          let tablename = "approvedUsers-dev";
          let id = email;
          const res = await API.get(
            "dynamoRW",
            `/notifications?tablename=${tablename}&id=${id}`
          );
          if (res && res.status === false) {
            localStorage.setItem(
              "message",
              "Your access request has been declined by the administrator, and you will not have access to the system"
            );
          } else if (res && res.status === true) {
            localStorage.setItem("role", res.role);
            localStorage.setItem("userEmail", res.userEmail);
            const role = localStorage.getItem("role");
            if (role === "Investor") {
              navigate("/dst-list");
              return;
            }
          }
        }
      }
      appContext.updateState("loading", false);
    } catch (error) {
      appContext.updateState("loading", false);
      console.log(error.message);
      toast(`Login Failed! ${error.message}`, { type: "error" });
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container
        component="main"
        className={classes.backgroundScreen}
        style={{ maxWidth: "100%" }}
      >
        <CssBaseline />
        <Box component={"form"} className={classes.boxAlign}>
          <div className={classes.signinCard}>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              <img src="/faviconPNG.png" style={{ height: "30px" }} />
            </Typography>
            <Box noValidate>
              <div className="login-field">
                <TextField
                  value={email}
                  className="login-email-field"
                  size="small"
                  margin="normal"
                  id="email"
                  placeholder="Email Address"
                  name="email"
                  autoComplete="email"
                  onChange={(e) => setEmail(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end">
                          <EmailIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  value={password}
                  className="login-email-field"
                  size="small"
                  margin="normal"
                  name="password"
                  placeholder="Password"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  autoComplete="current-password"
                  onChange={(e) => setPassword(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleTogglePasswordVisibility}
                          edge="end"
                        >
                          {showPassword ? (
                            <VisibilityOffIcon style={{ color: "#777" }} />
                          ) : (
                            <VisibilityIcon style={{ color: "#777" }} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <div style={{ width: "100%", textAlign: "end" }}>
                  <p
                    onClick={() => navigate("/password-request")}
                    style={{
                      color: "#a48d31",
                      margin: "0px",
                      display: "inline-block",
                      cursor: "pointer",
                    }}
                  >
                    Forgot Password
                  </p>
                </div>
              </div>
              <div className="login-signin-btn">
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 1,
                    borderRadius: 0,
                    textTransform: "capitalize",
                    padding: "5px 25px",
                  }}
                  onClick={onSignIn}
                >
                  Sign In
                </Button>
              </div>
            </Box>
          </div>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
