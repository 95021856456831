import React, { useContext, useEffect, useState } from "react";
import MyContext from "../context/appContext";
import { useNavigate } from "react-router-dom";
import "../css/styles.css";
import { toast } from "react-toastify";
import { API, Storage } from "aws-amplify";
import { Button, Card, Grid, InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@material-ui/icons/Search";

const DstList = () => {
  let navigate = useNavigate();
  const appContext = useContext(MyContext);
  const [dsts, setDsts] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [rows, setRows] = useState([]);
  const [pdfURLs, setPdfURLs] = useState([]);
  const [pdfURLsThumbnails, setPdfURLsThumbnails] = useState([]);

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchText(query);

    if (!query) {
      setRows(
        dsts?.map((dst) => ({
          name: dst?.basicInfo?.fullLegalNameOfDst,
          sponsor: dst?.basicInfo?.nameOfTheSponsor,
          id: dst.id,
          location: dst?.basicInfo?.states,
          propertyType: dst?.basicInfo?.dstPropertiesSector,
          offeringLoanRatio: dst?.basicInfo?.loanRate,
          offeringEquity: dst?.basicInfo?.offeringEquity,
          offeringDebt: dst?.basicInfo?.offeringDebt,
          cashFlowRate: dst?.basicInfo?.purchasePrice,
          type: dst?.uploaded_pdf__thumbnails,
          yield: dst?.yields?.year1Yield
            ? parseFloat(dst.yields.year1Yield.replace("%", "")).toFixed(2)
            : dst?.yields?.year1Yield,
          imageUrl:
            pdfURLs?.find((pdf) => pdf.key === dst.uploaded_pdf__thumbnails)
              ?.url || null,
          thumbnailUrl:
            pdfURLsThumbnails
              .filter((thumbnail) =>
                thumbnail.key.startsWith(dst.basicInfo.fullLegalNameOfDst)
              )
              .map((thumbnail) => ({
                url: thumbnail.url,
                fileName: thumbnail.key.split("/").pop(),
              })) || null,
        }))
      );
    } else {
      const filteredRows = dsts?.filter((dst) => {
        const dstName = dst?.basicInfo?.fullLegalNameOfDst || "";
        return dstName.toLowerCase().includes(query.toLowerCase());
      });

      setRows(
        filteredRows?.map((dst) => ({
          name: dst?.basicInfo?.fullLegalNameOfDst,
          sponsor: dst?.basicInfo?.nameOfTheSponsor,
          id: dst.id,
          location: dst?.basicInfo?.states,
          propertyType: dst?.basicInfo?.dstPropertiesSector,
          offeringLoanRatio: dst?.basicInfo?.loanRate,
          offeringEquity: dst?.basicInfo?.offeringEquity,
          offeringDebt: dst?.basicInfo?.offeringDebt,
          cashFlowRate: dst?.basicInfo?.purchasePrice,
          type: dst?.uploaded_pdf__thumbnails,
          yield: dst?.yields?.year1Yield
            ? parseFloat(dst.yields.year1Yield.replace("%", "")).toFixed(2)
            : dst?.yields?.year1Yield,
          imageUrl:
            pdfURLs?.find((pdf) => pdf.key === dst.uploaded_pdf__thumbnails)
              ?.url || null,
          thumbnailUrl:
            pdfURLsThumbnails
              .filter((thumbnail) =>
                thumbnail.key.startsWith(dst.basicInfo.fullLegalNameOfDst)
              )
              .map((thumbnail) => ({
                url: thumbnail.url,
                fileName: thumbnail.key.split("/").pop(),
              })) || [],
        }))
      );
    }
  };

  useEffect(() => {
    getIdData();
  }, []);

  useEffect(() => {
    if (dsts?.length > 0) {
      fetchData();
    }
  }, [dsts]);

  useEffect(() => {
    if (dsts?.length > 0 && pdfURLs?.length > 0) {
      appContext.updateState("loading", true);

      const filteredRows = dsts?.filter((dst) => dst);

      const updatedRows = filteredRows?.map((dst) => {
        const matchedPdfUrl = pdfURLs?.find(
          (pdf) => pdf.key === dst.uploaded_pdf__thumbnails
        );
        const matchedThumbnailUrls = pdfURLsThumbnails?.filter((thumbnail) =>
          thumbnail.key.startsWith(dst.basicInfo.fullLegalNameOfDst)
        );

        return {
          name: dst?.basicInfo?.fullLegalNameOfDst,
          sponsor: dst?.basicInfo?.nameOfTheSponsor,
          id: dst.id,
          location: dst?.basicInfo?.states,
          propertyType: dst?.basicInfo?.dstPropertiesSector,
          offeringLoanRatio: dst?.basicInfo?.loanRate,
          offeringEquity: dst?.basicInfo?.offeringEquity,
          offeringDebt: dst?.basicInfo?.offeringDebt,
          cashFlowRate: dst?.basicInfo?.purchasePrice,
          type: dst?.uploaded_pdf__thumbnails,
          yield: dst?.yields?.year1Yield
            ? parseFloat(dst.yields.year1Yield.replace("%", "")).toFixed(2)
            : dst?.yields?.year1Yield,
          imageUrl: matchedPdfUrl ? matchedPdfUrl.url : null,
          thumbnailUrl:
            matchedThumbnailUrls.length > 0
              ? matchedThumbnailUrls.map((thumbnail) => ({
                url: thumbnail.url,
                fileName: thumbnail.key.split("/").pop(),
              }))
              : [],
        };
      });

      setRows(updatedRows);
      appContext.updateState("loading", false);
    }
  }, [dsts, pdfURLs]);

  const getIdData = async () => {
    try {
      appContext.updateState("loading", true);
      const payload = {
        queryStringParameters: {
          tableName: "dsts_table-dev",
          id: "id-name",
        },
      };
      const res = await API.get("dynamoRW", "/dsts", payload);

      // Get user role and email
      const userRole = localStorage.getItem("userRole");
      const userEmail = localStorage.getItem("userEmail");

      // Fetch user's assigned DSTs
      const userRes = await API.get(
        "dynamoRW",
        `/notifications?tablename=approvedUsers-dev`
      );

      const currentUser = userRes.Items.find(user => user.userEmail === userEmail);

      // Get all DSTs assigned to any of user's broker dealers
      let allAssignedDsts = [];
      if (currentUser?.brokerDealerIds?.length > 0) {
        // Get all users that share any of the current user's broker dealers
        const relatedUsers = userRes.Items.filter(user =>
          user.brokerDealerIds?.some(id =>
            currentUser.brokerDealerIds.includes(id)
          )
        );

        // Combine all assigned DSTs from related users
        allAssignedDsts = [...new Set(
          relatedUsers.flatMap(user => user.assignedDsts || [])
        )];
      }

      let filteredDsts = res;

      // Show filtered DSTs only if:
      // 1. User is not Super Admin
      // 2. User has broker dealers with assigned DSTs
      if (userRole !== "Super Admin" && allAssignedDsts.length > 0) {
        filteredDsts = res.filter(dst => allAssignedDsts.includes(dst.id));
      }
      // If no DSTs are assigned or user is Super Admin, show all DSTs

      // Filter active DSTs
      const activeDsts = filteredDsts.filter((item) => item.is_active !== false);

      // Sort by concordeApprovalDate (newest to oldest)
      activeDsts.sort((a, b) => {
        // Check if dates exist
        const hasDateA = a.basicInfo?.concordeApprovalDate;
        const hasDateB = b.basicInfo?.concordeApprovalDate;

        // If both items don't have dates, maintain their original order
        if (!hasDateA && !hasDateB) return 0;

        // If only one item has a date, put the item without date at the end
        if (!hasDateA) return 1;  // a goes after b
        if (!hasDateB) return -1; // b goes after a

        // If both have dates, compare them
        const dateA = new Date(a.basicInfo.concordeApprovalDate);
        const dateB = new Date(b.basicInfo.concordeApprovalDate);
        return dateB - dateA; // Descending order (newest first)
      });

      setDsts(activeDsts);

      // Fetch images for the filtered DSTs
      const images = await fetchData(activeDsts);

    } catch (error) {
      console.log(error.message);
      toast(`No DST Found! ${error.message}`, { type: "error" });
    } finally {
      appContext.updateState("loading", false);
    }
  };

  const fetchData = async (res) => {
    try {
      appContext.updateState("loading", true);
      Storage.configure({
        AWSS3: {
          bucket: process.env.REACT_APP_S3_BUCKET,
          region: process.env.REACT_APP_LAMBDA_REGION,
        },
      });

      const result = await Storage.list("", { level: "public" });

      // Filter the files based on their type and criteria
      const imageExtensions = [".png", ".jpg", ".jpeg", ".gif", ".bmp", ".svg"];

      const pdfFiles = result.filter((item) =>
        imageExtensions.some(ext => item.key.endsWith(ext))
      ); const PPM = result.filter((item) => item.key.includes("thumbnail_pdf"));
      const imageNames = res
        ?.map((dst) => dst.uploaded_pdf__thumbnails)
        ?.filter(Boolean);
      const matchedImages = result.filter((item) =>
        imageNames?.includes(item.key)
      );

      const imageUrls = await Promise.all(
        matchedImages.map(async (image) => {
          const url = await Storage.get(image.key, { level: "public" });
          return { key: image.key, url, name: image.key.split("/").pop() };
        })
      );

      imageUrls.sort((a, b) =>
        a.key.localeCompare(b.key, undefined, { sensitivity: "base" })
      );

      const pdfURLss = await Promise.all(
        pdfFiles.map(async (pdfFile) => {
          const fileName = pdfFile.key;
          const url = await Storage.get(pdfFile.key, { level: "public" });
          return {
            key: fileName,
            url,
            name: fileName.split("/").pop(),
          };
        })
      );

      // Fetch URLs and names for the PPM files
      const PPMfiles = await Promise.all(
        PPM.map(async (pdfFile) => {
          const fileName = pdfFile.key;
          const url = await Storage.get(pdfFile.key, { level: "public" });
          return {
            key: fileName,
            url,
            name: fileName.split("/").pop(),
          };
        })
      );

      pdfURLss.sort((a, b) =>
        a.key.localeCompare(b.key, undefined, { sensitivity: "base" })
      );

      setPdfURLs(imageUrls);
      setPdfURLsThumbnails(PPMfiles);
      appContext.updateState("loading", false);
    } catch (error) {
      appContext.updateState("loading", false);
      console.error("Error retrieving PDFs:", error);
    }
  };
  const handleViewRow = (row) => {
    if (
      row.thumbnailUrl === null ||
      row.thumbnailUrl === undefined ||
      row.thumbnailUrl.length === 0
    ) {
      toast(`There is no pdf available for ${row.name}!`, { type: "info" });
      return;
    }
    navigate(`/view-properties-pdf/${row.name.replace(/\s+/g, '-')}`, { state: { row: row } });
  }

  return (
    <div style={{ margin: "2rem" }}>
      <Card
        style={{
          padding: "1rem 2rem",
        }}
      >
        <div>
          <h2 style={{ fontSize: "2rem" }}>Current Listings</h2>
          <p>
            Properties are listed from newest to oldest. Older or smaller
            offerings are likely to be fully reserved and now taking "backup"
            applications. <br /> Listings are removed only after they are
            officially closed. Please call your advisor for current
            availability.
          </p>
          <TextField
            size="small"
            margin="normal"
            fullWidth
            style={{
              width: "25%",
              backgroundColor: "#fff",
            }}
            // disabled={pdfURLs?.length === 0 || pdfURLsThumbnails?.length === 0}
            type="text"
            placeholder="Search..."
            value={searchText}
            onChange={(event) => handleSearch(event)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div style={{ width: "100%" }}>
          <Grid container sx={{ py: 6 }} spacing={2}>
            {rows?.map((row, index) => (
              <Grid item xs={4} className="Grid-Gap" key={index}>
                <div>
                  <div style={{ marginTop: "1rem" }}>
                    <img
                      src={
                        row?.imageUrl
                          ? row.imageUrl
                          : "https://crazyneedles.com.pk/wp-content/themes/claue/assets/images/placeholder.png"
                      }
                      height={"250px"}
                      width={"100%"}
                      alt="No Image"
                      style={{ objectFit: "cover" }}
                    />
                  </div>
                  <h3
                    style={{
                      textAlign: "center",
                      margin: "0px",
                      padding: "1rem",
                      background: "#000",
                      color: "#fff",
                      maxWidth: "100%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      fontSize: "14px",
                    }}
                  >
                    {row.name}
                  </h3>
                  <div
                    style={{
                      fontSize: "0.8rem",
                      border: "1px solid #0000001f",
                      padding: " 0rem 0.5rem",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <p>Location (MSA)</p>
                      <p>
                        {row.location && row.location.length > 0
                          ? row.location.join(", ")
                          : "N/A"}
                      </p>
                    </div>
                  </div>
                  <div
                    style={{
                      fontSize: "0.8rem",
                      border: "1px solid #0000001f",
                      padding: " 0rem 0.5rem",

                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <p>Property Type</p>
                      <p>{row.propertyType}</p>
                    </div>
                  </div>

                  <div
                    style={{
                      fontSize: "0.8rem",
                      border: "1px solid #0000001f",
                      padding: " 0rem 0.5rem",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <p>Loan-to-Offering Price Ratio</p>
                      <p style={{ margin: "0px" }}>{`${(
                        (Number(row?.offeringDebt?.replace(/[^0-9.]/g, "")) /
                          (Number(
                            row?.offeringDebt?.replace(/[^0-9.]/g, "")
                          ) +
                            Number(
                              row?.offeringEquity?.replace(/[^0-9.]/g, "")
                            ))) *
                        100
                      ).toFixed(2) || 0
                        }%`}</p>
                    </div>
                  </div>
                  <div
                    style={{
                      fontSize: "0.8rem",
                      border: "1px solid #0000001f",
                      padding: " 0rem 0.5rem",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <p>Offering Size</p>
                      <p>{`$ ${Number(
                        row?.offeringEquity?.replace(/[^0-9.]/g, "")
                      ).toLocaleString("en-US")}`}</p>
                    </div>
                  </div>
                  <div
                    style={{
                      fontSize: "0.8rem",
                      border: "1px solid #0000001f",
                      padding: " 0rem 0.5rem",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <p>Current Cashflow</p>
                      <p>
                        {row?.yield ? `${row.yield.replace("%", "")}%` : ""}
                      </p>
                    </div>
                  </div>
                  <div>
                    <Button
                      onClick={() => handleViewRow(row)}
                      type="submit"
                      variant="contained"
                      sx={{
                        borderRadius: 0,
                        textTransform: "capitalize",
                        padding: "10px 0px",
                        width: "100%",
                        backgroundColor: "#a48d31",
                        color: "#fff",
                        "&:hover": {
                          backgroundColor: "#a48d31",
                          color: "#fff",
                          opacity: 0.8,
                        },
                      }}
                    >
                      View
                    </Button>
                  </div>
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
      </Card>
    </div>
  );
};

export default DstList;
