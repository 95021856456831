import React, { useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core/styles";
import { Auth } from "aws-amplify";
import { toast } from "react-toastify";
import MyContext from "../context/appContext";
import { Card, Grid } from "@mui/material";

const useStyles = makeStyles({
  profileCard: {
    padding: "10px 30px",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  boxAlign: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  LinkText: {
    textDecoration: "none",
    color: "#a48d31",
  },
  profileText: {
    color: "#fff",
    textAlign: "center",
  },
});

const Profile = () => {
  const appContext = useContext(MyContext);
  const [newUsername, setNewUsername] = useState("");
  const [newUserFirm, setNewUserFirm] = useState("");
  const [emailAcc, setEmailAcc] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [username, setUsername] = useState("");

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const fetchedUsername = user.attributes.name;
      setEmailAcc(user.attributes.email);
      setNewUserFirm(user.attributes.profile);
      setNewUsername(fetchedUsername);
      setUsername(fetchedUsername);
    } catch (error) {
      console.error("Error fetching user data:", error.message);
    }
  };

  const onUpdateProfile = async () => {
    try {
      appContext.updateState("loading", true);
      const currentUser = await Auth.currentAuthenticatedUser();

      if (newUsername === "") {
        appContext.updateState("loading", false);
        toast("Username required", { type: "error" });
        return;
      }

      if (newPassword !== confirmPassword) {
        appContext.updateState("loading", false);
        toast("Password not matched", { type: "error" });
        return;
      }

      if (newUserFirm !== "") {
        localStorage.setItem("companyName", newUserFirm);
        await Auth.updateUserAttributes(currentUser, {
          profile: newUserFirm,
        });
      }

      if (newUsername !== username) {
        await Auth.updateUserAttributes(currentUser, {
          name: newUsername,
        });
      }

      if (oldPassword !== "" && newPassword !== "") {
        await Auth.changePassword(currentUser, oldPassword, newPassword);
      }

      appContext.updateState("loading", false);
      toast("Profile updated successfully", { type: "success" });
    } catch (error) {
      appContext.updateState("loading", false);
      console.error("Profile update error", error.message);
      toast(`Profile update failed: ${error.message}`, { type: "error" });
    }
  };

  return (
    <div style={{ margin: "2rem" }}>
      <Grid container spacing={2}>
        <Card
          style={{
            width: "100%",
            marginTop: "1rem",
            marginLeft: "1rem",
            padding: "1rem 0rem",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "1.5rem",
            }}
          >
            <h3 style={{ margin: "0rem 4rem" }}>PROFILE SETTING</h3>
          </div>
          <Grid
            container
            style={{
              paddingLeft: "4rem",
              paddingRight: "4rem",
              paddingTop: "2rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            spacing={2}
          >
            <Grid item xs={2} className="Grid-Gap">
              <p>User Name</p>
            </Grid>
            <Grid item xs={4} className="Grid-Gap">
              <TextField
                value={newUsername}
                fullWidth
                size="small"
                name="newUsername"
                placeholder="New Username"
                onChange={(e) => setNewUsername(e.target.value)}
              />
            </Grid>
            <Grid item xs={6} className="Grid-Gap"></Grid>
          </Grid>
          <Grid
            container
            style={{
              paddingLeft: "4rem",
              paddingRight: "4rem",
              paddingTop: "0.8rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            spacing={2}
          >
            <Grid item xs={2} className="Grid-Gap">
              <p>Email</p>
            </Grid>
            <Grid item xs={4} className="Grid-Gap">
              <TextField
                value={emailAcc}
                fullWidth
                size="small"
                name="email"
                placeholder="Email"
                disabled
              />
            </Grid>
            <Grid item xs={6} className="Grid-Gap"></Grid>
          </Grid>
          <Grid
            container
            style={{
              paddingLeft: "4rem",
              paddingRight: "4rem",
              paddingTop: "0.8rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            spacing={2}
          >
            <Grid item xs={2} className="Grid-Gap">
              <p>Firm Name</p>
            </Grid>
            <Grid item xs={4} className="Grid-Gap">
              <TextField
                value={newUserFirm}
                size="small"
                fullWidth
                name="companyName"
                placeholder="Firm Name"
                onChange={(e) => setNewUserFirm(e.target.value)}
              />
            </Grid>
            <Grid item xs={6} className="Grid-Gap"></Grid>
          </Grid>
          <Grid
            container
            style={{
              paddingLeft: "4rem",
              paddingRight: "4rem",
              paddingTop: "0.8rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            spacing={2}
          >
            <Grid item xs={2} className="Grid-Gap">
              <p>Old Password</p>
            </Grid>
            <Grid item xs={4} className="Grid-Gap">
              <TextField
                value={oldPassword}
                size="small"
                fullWidth
                name="oldPassword"
                placeholder="Old Password"
                onChange={(e) => setOldPassword(e.target.value)}
              />
            </Grid>
            <Grid item xs={6} className="Grid-Gap"></Grid>
          </Grid>
          <Grid
            container
            style={{
              paddingLeft: "4rem",
              paddingRight: "4rem",
              paddingTop: "0.8rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            spacing={2}
          >
            <Grid item xs={2} className="Grid-Gap">
              <p>New Password</p>
            </Grid>
            <Grid item xs={4} className="Grid-Gap">
              <TextField
                value={newPassword}
                size="small"
                name="newPassword"
                fullWidth
                placeholder="New Password"
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </Grid>
            <Grid item xs={6} className="Grid-Gap"></Grid>
          </Grid>
          <Grid
            container
            style={{
              paddingLeft: "4rem",
              paddingRight: "4rem",
              paddingTop: "0.8rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            spacing={2}
          >
            <Grid item xs={2} className="Grid-Gap">
              <p>Confirm Password</p>
            </Grid>
            <Grid item xs={4} className="Grid-Gap">
              <TextField
                value={confirmPassword}
                size="small"
                name="confirmPassword"
                fullWidth
                placeholder="Confirm Password"
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </Grid>
            <Grid item xs={6} className="Grid-Gap"></Grid>
          </Grid>
          <div
            style={{
              display: "flex",
              columnGap: "10px",
              justifyContent: "end",
              alignItems: "center",
              width: "100%",
              marginTop: "1rem",
            }}
          >
            <Button
              type="submit"
              variant="contained"
              style={{ margin: "1rem 2rem", textTransform: "inherit" }}
              onClick={onUpdateProfile}
            >
              Update Profile
            </Button>
          </div>
        </Card>
      </Grid>
    </div>
  );
};

export default Profile;
