import React, { useState, useContext, useEffect, useRef } from "react";
import { Grid, Switch, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import MyContext from "../../context/appContext";
import { toast } from "react-toastify";
import { API } from "aws-amplify";
import ReactToPrint from "react-to-print";
import { Button, alpha, styled } from "@material-ui/core";
import { pink } from "@mui/material/colors";
import {
  PortraitOrientation,
} from "../../utills/landscapeOrientation";

const PinkSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: pink[600],
    "&:hover": {
      backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: pink[600],
  },
}));

const IdLetterReport = () => {
  const { state } = useLocation();
  const componentRef = useRef();
  const dstsArray = state?.dstsArray;
  const letterData = state?.letterData;
  const appContext = useContext(MyContext);
  const [selectedDsts, setSelectedDsts] = useState([]);
  const [portraitLayout, setPortraitLayout] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    handleAddData();
  }, []);

  const handleAddData = async () => {
    try {
      if (!dstsArray?.length) {
        return;
      }
      appContext.updateState("loading", true);
      const promises = dstsArray.map(async (val) => {
        const payload = {
          queryStringParameters: {
            tableName: "dsts_table-dev",
            id: val.id,
          },
        };
        const res = await API.get("dynamoRW", "/dsts", payload);
        return res.Item;
      });

      const results = await Promise.all(promises);
      setSelectedDsts(results);
      appContext.updateState("loading", false);
    } catch (error) {
      appContext.updateState("loading", false);
      console.log(error.message);
      toast(`No Data Found! ${error.message}`, { type: "error" });
    }
  };

  for (let i = 0; i < dstsArray?.length && i < selectedDsts?.length; i++) {
    selectedDsts[i].clientEquity = dstsArray[i].clientEquity;
  }

  useEffect(() => {
    if (selectedDsts && Array.isArray(selectedDsts)) {
      const updatedSelectedDsts = selectedDsts.map((dst) => {
        if (dst && dst.basicInfo) {
          const dynamicValues = {
            offeringEquity: dst.basicInfo.offeringEquity,
            offeringDebt: dst.basicInfo.offeringDebt,
            percentRetainedBySponsor: dst.basicInfo.percentRetainedBySponsor,
          };

          const updatedBasicInfo = updateNumericFields(
            dst.basicInfo,
            dynamicValues
          );
          if (updatedBasicInfo !== dst.basicInfo) {
            setHasChanges(true);
          }

          return {
            ...dst,
            basicInfo: updatedBasicInfo,
          };
        }
        return dst;
      });
      if (hasChanges) {
        setSelectedDsts(updatedSelectedDsts);
        setHasChanges(false);
      }
    }
  }, [selectedDsts, hasChanges]);

  function updateNumericFields(basicInfo, updates) {
    let updatedBasicInfo = { ...basicInfo };
    let hasChanges = false;

    for (const fieldName in updates) {
      if (updates.hasOwnProperty(fieldName)) {
        const newValue = updates[fieldName];
        const cleanedValue = newValue.toString().replace(/[^0-9.]/g, "");

        if (basicInfo[fieldName] !== cleanedValue) {
          updatedBasicInfo[fieldName] = cleanedValue;
          hasChanges = true;
        }
      }
    }

    return hasChanges ? updatedBasicInfo : basicInfo;
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          columnGap: "1.5rem",
        }}
      >
        {portraitLayout ? <PortraitOrientation /> : <null />}
        <ReactToPrint
          trigger={() => (
            <Button
              variant="contained"
              style={{
                marginTop: "2rem",
                marginRight: "2rem",
                backgroundColor: "#1976d2",
                color: "#fff",
              }}
            >
              Print
            </Button>
          )}
          content={() => componentRef.current}
        />
      </div>
      <div style={{ padding: "0rem 2.5rem" }} ref={componentRef}>
        <div>
          <h4>Exhibit A - Attachment to Identification Letter</h4>
        </div>

        <Grid container rowSpacing={0}>
          <Grid item xs={2.3}>
            <div>
              <p style={{ margin: "0px", fontSize: "0.8rem" }}>
                <b>Taxpayer Name:</b>
              </p>
            </div>
          </Grid>
          <Grid item xs={9.7}>
            <div>
              <p style={{ margin: "0px", fontSize: "0.8rem" }}>
                {letterData?.clientName}
              </p>
            </div>
          </Grid>
          <Grid item xs={2.3}>
            <div>
              <p style={{ margin: "0px", fontSize: "0.8rem" }}>
                <b>Relinquished Property:</b>
              </p>
            </div>
          </Grid>
          <Grid item xs={9.7}>
            <div>
              <p style={{ margin: "0px", fontSize: "0.8rem" }}>
                {letterData?.clientRelinquished}
              </p>
            </div>
          </Grid>
        </Grid>

        {selectedDsts?.map((val, ind) => (
          <div>
            <p style={{ marginTop: "1rem", fontSize: "0.8rem" }}>
              <b>Property # {+letterData?.propertyOnPage + ind + 1}</b>
            </p>
            <hr />
            <Grid container style={{ padding: "2rem 0rem" }} spacing={2}>
              <Grid item xs={4} className="Grid-Gap">
                <p style={{ margin: "0px", fontSize: "0.9rem" }}>
                  <b>{val.basicInfo.fullLegalNameOfDst}</b>
                </p>
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <p
                  style={{
                    margin: "0px",
                    marginBottom: "0.2rem",
                    fontSize: "0.8rem",
                  }}
                >
                  Equity:
                </p>
                <p
                  style={{
                    margin: "0px",
                    marginBottom: "0.2rem",
                    fontSize: "0.8rem",
                  }}
                >
                  Debt:
                </p>
                <p
                  style={{
                    margin: "0px",
                    marginBottom: "0.2rem",
                    fontSize: "0.8rem",
                  }}
                >
                  Total value:
                </p>
                <p
                  style={{
                    margin: "0px",
                    marginBottom: "0.2rem",
                    fontSize: "0.8rem",
                  }}
                >
                  Ownership interest:
                </p>
              </Grid>
              <Grid item xs={1} className="Grid-Gap">
                <p
                  style={{
                    margin: "0px",
                    marginBottom: "0.2rem",
                    fontSize: "0.8rem",
                  }}
                >
                  $
                </p>
                <p
                  style={{
                    margin: "0px",
                    marginBottom: "0.2rem",
                    fontSize: "0.8rem",
                  }}
                >
                  $
                </p>
                <p
                  style={{
                    margin: "0px",
                    marginBottom: "0.2rem",
                    fontSize: "0.8rem",
                  }}
                >
                  $
                </p>
              </Grid>
              <Grid item xs={3} className="Grid-Gap">
                <div>
                  <p
                    style={{
                      margin: "0px",
                      marginBottom: "0.2rem",
                      fontSize: "0.8rem",
                    }}
                  >
                    {(Number(+val.clientEquity) || 0)
                      .toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </p>
                  <p
                    style={{
                      margin: "0px",
                      marginBottom: "0.2rem",
                      fontSize: "0.8rem",
                    }}
                  >
                    {Number(
                      (+val.clientEquity * +val?.basicInfo?.offeringDebt) /
                        +val?.basicInfo?.offeringEquity
                    )
                      .toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || "0.00"}
                  </p>
                  <p
                    style={{
                      margin: "0px",
                      marginBottom: "0.2rem",
                      fontSize: "0.8rem",
                    }}
                  >
                    {Number(
                      +val.clientEquity +
                        (+val.clientEquity * +val?.basicInfo?.offeringDebt) /
                          +val?.basicInfo?.offeringEquity
                    )
                      .toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || "0.00"}
                  </p>
                  <p
                    style={{
                      margin: "0px",
                      marginBottom: "0.2rem",
                      fontSize: "0.8rem",
                    }}
                  >
                    {`${
                      (
                        (+val.clientEquity / (+val.basicInfo?.offeringEquity / (1 - +(+val.basicInfo?.percentRetainedBySponsor / 100)))) *
                        100
                      ).toFixed(4) || 0
                    }%`}
                  </p>
                </div>
              </Grid>
            </Grid>
            <Grid container style={{ padding: "0.5rem 0rem" }} spacing={2}>
              <Grid item xs={4} className="Grid-Gap"></Grid>
              <Grid item xs={4} className="Grid-Gap">
                <div>
                  <p
                    style={{
                      margin: "0px",
                      marginBottom: "0.3rem",
                      fontSize: "0.8rem",
                      textDecoration: "underline",
                    }}
                  >
                    Portfolio property(ies)
                  </p>
                  {val.property?.map((prop) => (
                    <div>
                      <p
                        style={{
                          margin: "0px",
                          marginBottom: "0.3rem",
                          fontSize: "0.8rem",
                        }}
                      >
                        {prop.propertyName}
                      </p>
                      <p
                        style={{
                          margin: "0px",
                          marginBottom: "0.3rem",
                          fontSize: "0.8rem",
                        }}
                      >
                        {prop.streetAddress}
                      </p>
                      <p
                        style={{
                          margin: "0px",
                          marginBottom: "0.5rem",
                          fontSize: "0.8rem",
                        }}
                      >
                        {`${prop.city}, ${prop.states} ${prop.zip}`}
                      </p>
                    </div>
                  ))}
                </div>
              </Grid>
              <Grid item xs={1} className="Grid-Gap"></Grid>
              <Grid item xs={3} className="Grid-Gap"></Grid>
            </Grid>
          </div>
        ))}
      </div>
    </>
  );
};
export default IdLetterReport;
