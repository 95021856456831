import React, { useContext, useEffect, useState } from "react";
import MyContext from "../../context/appContext";
import {
  Box,
  Button,
  Card,
  Dialog,
  Grid,
  InputAdornment,
  TextField,
  ThemeProvider,
} from "@material-ui/core";
import { API } from "aws-amplify";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { toast } from "react-toastify";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@mui/material/IconButton";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StateTax = () => {
  const appContext = useContext(MyContext);
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [showEditCapitalGain, setEditCapitalGain] = useState(false);
  const [loading, setLoading] = useState(false);
  const [stateList, setStateList] = useState([]);
  const [values, setValues] = useState({});
  useEffect(() => {
    getStates();
  }, []);

  console.log(values, "values");

  const getStates = async () => {
    try {
      appContext.updateState("loading", true);
      const payload = {
        queryStringParameters: {
          tableName: "state_info-dev",
          id: "all",
          LastEvaluatedKey: "None",
        },
      };
      const res = await API.get("dynamoRW", "/dsts", payload);
      console.log(res, "RES");
      setStateList(res);
      appContext.updateState("loading", false);
    } catch (error) {
      appContext.updateState("loading", false);
      console.log(error.message);
      toast(`No State Found! ${error.message}`, { type: "error" });
    } finally {
      setLoading(false);
    }
  };

  console.log(stateList, "New State");

  const handleUpdateData = async () => {
    try {
      setEditCapitalGain(false);
      appContext.updateState("loading", true);
      const params = {
        body: {
          tableName: "state_info-dev",
          Key: {
            id: values.id,
          },
          AttributeUpdates: {
            code: {
              Value: values.code,
              Action: "PUT",
            },
            state: {
              Value: values.state,
              Action: "PUT",
            },
            percent: {
              Value: values.percent,
              Action: "PUT",
            },
            gainTax: {
              Value: values.gainTax,
              Action: "PUT",
            },
          },
        },
      };
      const res = await API.put("dynamoRW", "/dsts", params);
      getStates();
      setValues([]);
      appContext.updateState("loading", false);
      toast("State updated successfully", { type: "success" });
    } catch (error) {
      appContext.updateState("loading", false);
      console.log(error.message);
      toast(`Error saving state tax! ${error.message}`, { type: "error" });
    } finally {
      setLoading(false);
    }
  };

  const rows = stateList
    .map((state) => ({
      id: state.id,
      state: state.state,
      percent: state.percent,
      code: state.code,
      gainTax: state.gainTax,
    }))
    .sort((a, b) => a.state.localeCompare(b.state));

  console.log(rows, "ROWSS");

  return (
    <Card sx={{ minWidth: 275 }}>
      <ThemeProvider>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "1rem",
          }}
        >
          <div>
            <h3 style={{ textAlign: "center" }}>
              State Tax Estimates For Illustrative Purposes
            </h3>
          </div>

          <BootstrapDialog
            onClose={() => setEditCapitalGain(false)}
            aria-labelledby="customized-dialog-title"
            open={showEditCapitalGain}
            disableBackdropClick
          >
            <Card
              style={{
                minWidth: 600,
                padding: 5,
                height: "35vh",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <h4 style={{ padding: "0rem 1rem" }}> Edit State</h4>
              </div>

              <Box
                style={{
                  marginTop: 20,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Grid container style={{ padding: "0rem 1rem" }} spacing={2}>
                  <Grid item xs={6} className="Grid-Gap">
                    <TextField
                      size="small"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label="State"
                      value={values.state}
                      onChange={(e) =>
                        setValues({ ...values, state: e.target.value })
                      }
                    />
                  </Grid>
                  <Grid item xs={6} className="Grid-Gap">
                    <TextField
                      size="small"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label="Code"
                      value={values.code}
                      onChange={(e) =>
                        setValues({ ...values, code: e.target.value })
                      }
                    />
                  </Grid>
                  <Grid item xs={6} className="Grid-Gap">
                    <TextField
                      size="small"
                      variant="outlined"
                      margin="normal"
                      type="number"
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">%</InputAdornment>
                        ),
                      }}
                      label="Property Tax"
                      value={values.percent}
                      onChange={(e) =>
                        setValues({ ...values, percent: e.target.value })
                      }
                    />
                  </Grid>
                  <Grid item xs={6} className="Grid-Gap">
                    <TextField
                      size="small"
                      variant="outlined"
                      margin="normal"
                      type="number"
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">%</InputAdornment>
                        ),
                      }}
                      label="Capital Gain Tax"
                      value={values.gainTax}
                      onChange={(e) =>
                        setValues({ ...values, gainTax: e.target.value })
                      }
                    />
                  </Grid>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "end",
                      padding: "1rem 0.5rem",
                      textAlign: "end",
                      gap:"0.5rem",
                      width: "100%",
                    }}
                  >
                    <Button
                      onClick={() => {
                        setEditCapitalGain(false);
                      }}
                      type="submit"
                      variant="contained"
                      style={{
                        marginTop: 3,
                        marginBottom: 2,
                        backgroundColor: "#1976d2",
                        color: "#fff",
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={handleUpdateData}
                      type="submit"
                      variant="contained"
                      style={{
                        marginTop: 3,
                        marginBottom: 2,
                        backgroundColor: "#1976d2",
                        color: "#fff",
                      }}
                    >
                      Save
                    </Button>
                  </div>
                </Grid>
              </Box>
            </Card>
          </BootstrapDialog>
        </div>
        <TableContainer>
          <Table
            style={{ minWidth: 700, margin: "0 auto" }}
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">State</StyledTableCell>
                <StyledTableCell align="center">Code</StyledTableCell>
                <StyledTableCell align="center">
                  Property Tax&nbsp;(%)
                </StyledTableCell>
                <StyledTableCell align="center">
                  Capital Gains Tax&nbsp;(%)
                </StyledTableCell>
                <StyledTableCell align="center">Edit</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map((row, ind) => (
                <StyledTableRow key={ind}>
                  <StyledTableCell align="center">{row.state}</StyledTableCell>
                  <StyledTableCell align="center">{row.code}</StyledTableCell>
                  <StyledTableCell align="center">{`${row.percent}%`}</StyledTableCell>
                  <StyledTableCell align="center">{`${row.gainTax}%`}</StyledTableCell>
                  <StyledTableCell align="center">
                    <IconButton>
                      <EditIcon
                        onClick={() => {
                          setValues(row);
                          setEditCapitalGain(true);
                        }}
                        style={{
                          cursor: "pointer",
                          fontSize: "1.3rem",
                          marginTop: "0.2rem",
                          color: "#1976d2",
                        }}
                      />
                    </IconButton>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </ThemeProvider>
    </Card>
  );
};

export default StateTax;
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
