import React, { useContext, useEffect, useState } from "react";
import MyContext from "../context/appContext";
import { useNavigate } from "react-router-dom";
import "../css/styles.css";
import { toast } from "react-toastify";
import { API } from "aws-amplify";
import { Button, TextField } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import AWS from "aws-sdk";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const ManageState = () => {
  const appContext = useContext(MyContext);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  let navigate = useNavigate();
  const [stateList, setStateList] = useState([]);
  const [states, setStates] = useState("");
  const [stateCode, setStateCode] = useState("");
  const [errStates, setErrStates] = useState(false);
  const [errCode, setCode] = useState(false);

  const { state } = appContext;
  const style = {
    width: "100%",
    maxWidth: 360,
    bgcolor: "background.paper",
  };

  const styleModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    padding: "2rem 2rem",
  };

  useEffect(() => {
    getStates();
  }, []);

  const getStates = async () => {
    try {
      appContext.updateState("loading", true);
      const payload = {
        queryStringParameters: {
          tableName: "state_info-dev",
          id: "all",
          LastEvaluatedKey: "None",
        },
      };
      const res = await API.get("dynamoRW", "/dsts", payload);
      console.log(res, "RES");
      setStateList(res);
      appContext.updateState("loading", false);
    } catch (error) {
      appContext.updateState("loading", false);
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleAddState = async () => {
    try {
      if (!stateCode) {
        setCode(true);
        return;
      }
      if (!states) {
        setErrStates(true);
        return;
      }
      appContext.updateState("loading", true);
      setOpenModal();
      const res = await API.post("dynamoRW", "/dsts", {
        body: {
          tableName: "state_info-dev",
          Item: {
            id: AWS.util.uuid.v4(),
            is_deleted: false,
            state: states,
            code: stateCode,
            percent: "0",
          },
        },
      });
      appContext.updateState("loading", true);
      getStates();
      setStates("");
      setStateCode("");
      appContext.updateState("loading", false);
      toast(`${states} Added Successfully!`, { type: "success" });
    } catch (error) {
      appContext.updateState("loading", false);
      console.log(error.message);
      toast(`Error Posting State! ${error.message}`, { type: "error" });
    } finally {
      setLoading(false);
    }
  };

  const rows = stateList?.map((row) => {
    return {
      id: row.id,
      state: row.state,
      code: row.code,
    };
  });

  rows?.sort((a, b) => a.state.localeCompare(b.state));

  const handleDeleteDst = async (id) => {
    try {
      appContext.updateState("loading", true);
      const params = {
        body: {
          tableName: "state_info-dev",
          Key: {
            id: id,
          },
          AttributeUpdates: {
            is_deleted: {
              Value: true,
              Action: "PUT",
            },
          },
        },
      };
      const res = await API.put("dynamoRW", "/dsts", params);
      appContext.updateState("loading", true);
      getStates();
      appContext.updateState("loading", false);
      toast(`State Deleted Successfully!`, { type: "success" });
    } catch (error) {
      appContext.updateState("loading", false);
      console.log(error.message);
      toast(`Error Deleting State! ${error.message}`, { type: "error" });
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteRow = async (index) => {
    const idToDelete = rows[index].id;
    await handleDeleteDst(idToDelete);
  };

  return (
    <div style={{ padding: "20px 20px" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h2>States</h2>
        <Button
          onClick={handleOpenModal}
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          style={{ backgroundColor: "#1976d2", color: "#fff" }}
        >
          Add State
        </Button>
      </div>
      <div>
        <TableContainer>
          <Table
            sx={{ minWidth: 700 }}
            stickyHeader
            aria-label="customized table"
          >
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>State Name</StyledTableCell>
                <StyledTableCell>State Code</StyledTableCell>
                <StyledTableCell>Delete</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {rows?.map((row, index) => (
                <StyledTableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <StyledTableCell component="th" scope="row">
                    {row.state}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {row.code}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    <IconButton
                      aria-label="delete"
                      size="small"
                      color="#fff"
                      onClick={() => handleDeleteRow(index)}
                    >
                      <DeleteIcon style={{ color: "#ff5555" }} />
                    </IconButton>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal}>
          <h4 style={{ margin: "0rem 0rem" }}>Add New State</h4>
          <TextField
            size="small"
            margin="normal"
            error={errCode === true}
            helperText={errCode === true ? "Required field" : null}
            variant="outlined"
            fullWidth
            name="stateCode"
            label="State Code"
            value={stateCode}
            onChange={(e) => {
              setStateCode(e.target.value);
              setCode(false);
            }}
          />
          <TextField
            size="small"
            margin="normal"
            error={errStates === true}
            helperText={errStates === true ? "Required field" : null}
            variant="outlined"
            fullWidth
            name="stateName"
            label="State Name"
            value={states}
            onChange={(e) => {
              setStates(e.target.value);
              setErrStates(false);
            }}
          />
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <Button
              onClick={handleAddState}
              type="submit"
              variant="contained"
              style={{
                marginTop: "1rem",
                marginRight: "10px",
                backgroundColor: "#1976d2",
                color: "#fff",
              }}
            >
              Save
            </Button>
            <Button
              onClick={handleCloseModal}
              variant="contained"
              style={{
                marginTop: "1rem",
                backgroundColor: "#1976d2",
                color: "#fff",
              }}
            >
              Back
            </Button>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default ManageState;
