import React, { useState, useEffect, useContext } from "react";
import TableContainer from "@mui/material/TableContainer";
import Button from "@mui/material/Button";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { useNavigate, useParams } from "react-router-dom";
import MyContext from "../context/appContext";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import { toast } from "react-toastify";
import "../css/styles.css";
import { API, Storage, Amplify } from "aws-amplify";
import { Container, IconButton, Switch } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import awsmobile from "../aws-exports";

const defaultTheme = createTheme();

const selectedImagesStyle = {
  display: "flex",
  flexWrap: "wrap",
  gap: "10px",
  marginTop: "1rem",
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function AddDstCommericalProperty({ formData, setFormData }) {
  Amplify.configure(awsmobile);

  let { id } = useParams();
  const appContext = useContext(MyContext);
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [selectedDst, setSelectedDst] = useState([]);
  const [selectedCommercialDst, setSelecteCommerciadDst] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [dstsName, setDstsName] = useState([]);
  const [selectedPropertyData, setSelectedPropertyData] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openAddPropModal, setOpenAddPropModal] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [imageUrl, setImageUrl] = useState([]);
  const [imageFiles, setImageFiles] = useState([]);
  const [existingImages, setExistingImages] = useState([]);
  const [removedImages, setRemovedImages] = useState([]);

  useEffect(() => {
    if (id) {
      getPropertyData();
    }
    getIdData();
    getStates();
  }, [id]);

  useEffect(() => {
    const storedFormState = sessionStorage.getItem(
      "addCommericalPropertySession"
    );
    if (storedFormState) {
      const parsedFormState = JSON.parse(storedFormState);
      const { formData: storedFormData } = parsedFormState;

      setFormData(storedFormData);
    }
  }, []);

  useEffect(() => {
    if (selectedPropertyData && selectedPropertyData.images) {
      setExistingImages(selectedPropertyData.images);
      fetchImages(selectedPropertyData.images);
    }
  }, [selectedPropertyData]);

  const fetchImages = async (imageNames) => {
    try {
      const imageUrls = await Promise.all(
        imageNames.map(async (imageName) => {
          const signedURL = await Storage.get(imageName, { expires: 60 });
          return signedURL;
        })
      );
      setImageUrl(imageUrls);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };


  const handleRemoveImage = (indexToRemove) => {
    const removedImage = existingImages[indexToRemove] || imageFiles[indexToRemove - existingImages.length]?.name;

    if (existingImages.includes(removedImage)) {
      setRemovedImages((prev) => [...prev, removedImage]);
      setExistingImages((prev) => prev.filter((_, index) => index !== indexToRemove));
    } else {
      setImageFiles((prev) => prev.filter((_, index) => index !== (indexToRemove - existingImages.length)));
    }

    setImageUrl((prevImageUrl) =>
      prevImageUrl.filter((_, index) => index !== indexToRemove)
    );

    setFormData((prevFormData) => {
      const updatedImages = prevFormData?.images?.filter(
        (_, index) => index !== indexToRemove
      );
      return { ...prevFormData, images: updatedImages };
    });
  };

  const getPropertyData = async () => {
    try {
      appContext.updateState("loading", true);
      const payload = {
        queryStringParameters: {
          tableName: "dsts_table-dev",
          id: id,
        },
      };
      const res = await API.get("dynamoRW", "/dsts", payload);
      setSelectedDst(res.Item);
      let properties = res.Item?.property || [];

      setSelecteCommerciadDst((prev) => ({
        ...prev,
        property: properties,
      }));

      appContext.updateState("loading", false);
    } catch (error) {
      appContext.updateState("loading", false);
      console.log(error.message);
      toast(`No DST Found! ${error.message}`, { type: "error" });
    } finally {
      setLoading(false);
    }
  };

  const checkDuplicateNameOfProperty = async (dstId) => {
    try {
      appContext.updateState("loading", true);
      const payload = {
        queryStringParameters: {
          tableName: "dsts_table-dev",
          id: dstId,
        },
      };
      const res = await API.get("dynamoRW", "/dsts", payload);
      let properties = res.Item?.property || [];
      appContext.updateState("loading", false);
      return properties;
    } catch (error) {
      appContext.updateState("loading", false);
      console.log(error.message);
      toast(`No DST Found! ${error.message}`, { type: "error" });
    } finally {
      setLoading(false);
    }
  };

  const getStates = async () => {
    try {
      const payload = {
        queryStringParameters: {
          tableName: "state_info-dev",
          id: "all",
          LastEvaluatedKey: "None",
        },
      };
      const res = await API.get("dynamoRW", "/dsts", payload);
      setStateList(res);
    } catch (error) {
      console.log(error.message);
      toast(`No State Found! ${error.message}`, { type: "error" });
    } finally {
      setLoading(false);
    }
  };

  const stateOption = stateList
    ?.map((st) => ({
      state: st.state,
      code: st.code,
    }))
    .sort((a, b) => a.state.localeCompare(b.state));

  const clearFormData = () => {
    const emptyFormData = Object.fromEntries(
      Object.keys(formData).map((key) => [key, ""])
    );
    setFormData(emptyFormData);
  };

  const getIdData = async () => {
    try {
      const payload = {
        queryStringParameters: {
          tableName: "dsts_table-dev",
          id: "id-name",
        },
      };
      const res = await API.get("dynamoRW", "/dsts", payload);
      const activeDsts = res.filter((item) => item.is_active !== false);
      const sortedDsts = activeDsts.sort((a, b) =>
        a?.basicInfo?.fullLegalNameOfDst?.localeCompare(
          b.basicInfo.fullLegalNameOfDst
        )
      );
      setDstsName(sortedDsts);
    } catch (error) {
      console.log(error.message);
      toast(`No DST Found! ${error.message}`, { type: "error" });
    }
  };

  const handleSubmit = async () => {
    if (id) {
      const checkDuplicateName = selectedDst?.property?.find(
        (item) => item.propertyName === formData?.propertyName
      );
      if (checkDuplicateName) {
        toast(`Property name already exist. Please choose a different name`, {
          type: "error",
        });
        return;
      }
      setOpenAddPropModal(false);
      appContext.updateState("loading", true);
      try {
        await Promise.all(
          imageFiles.map(async (file) => await uploadImage(file))
        );

        const params = {
          body: {
            tableName: "dsts_table-dev",
            Key: {
              id: id ? id : formData.selectDst,
            },
            AttributeUpdates: {
              property: {
                Value: [
                  {
                    ...formData,
                    propertyTypeofDST: "Commercial",
                    is_primary: false,
                  },
                ],
                Action: "ADD",
              },
            },
          },
        };
        const res = await API.put("dynamoRW", "/dsts", params);
        getPropertyData();
        clearFormData();
        setImageUrl([]);
        appContext.updateState("loading", false);
      } catch (error) {
        appContext.updateState("loading", false);
        console.log(error.message);
        toast(`Error Adding Property! ${error.message}`, {
          type: "error",
        });
      } finally {
        setLoading(false);
      }
      toast("Property Added Successfully", { type: "success" });
    } else {
      try {
        if (formData?.selectDst === "") {
          toast(`Property select any DST first`, {
            type: "error",
          });
          return;
        }
        const getDST = await checkDuplicateNameOfProperty(formData?.selectDst);
        const checkDuplicateNameInDst = getDST?.find(
          (item) => item.propertyName === formData?.propertyName
        );
        if (checkDuplicateNameInDst) {
          toast(
            `Property name already exist in this DST. Please choose a different name`,
            {
              type: "error",
            }
          );
          return;
        }
        appContext.updateState("loading", true);
        await Promise.all(
          imageFiles.map(async (file) => await uploadImage(file))
        );

        const params = {
          body: {
            tableName: "dsts_table-dev",
            Key: {
              id: formData.selectDst,
            },
            AttributeUpdates: {
              property: {
                Value: [
                  {
                    ...formData,
                    propertyTypeofDST: "Commercial",
                    is_primary: false,
                  },
                ],
                Action: "ADD",
              },
            },
          },
        };
        const res = await API.put("dynamoRW", "/dsts", params);
        clearFormData();
        setImageUrl([]);
        appContext.updateState("loading", false);
      } catch (error) {
        appContext.updateState("loading", false);
        console.log(error.message);
        toast(`Error Adding Property! ${error.message}`, { type: "error" });
      } finally {
        setLoading(false);
      }
      toast("Property Added Successfully", { type: "success" });
    }
  };
  const handleSessionSave = async () => {
    setFormData((prevFormData) => {
      const clearedState = Object.fromEntries(
        Object.keys(prevFormData).map((key) => [key, ""])
      );
      return clearedState;
    });
  };

  const handleSave = async () => {
    try {
      setOpenEditModal(false);
      appContext.updateState("loading", true);

      await Promise.all(
        imageFiles.map(async (file) => await uploadImage(file))
      );

      if (
        selectedIndex === -1 ||
        selectedIndex >= (selectedCommercialDst?.property?.length || 0)
      ) {
        throw new Error("Invalid property selection.");
      }

      const allProperties = [...selectedCommercialDst.property];
      const updatedProperty = { ...selectedPropertyData };

      updatedProperty.images = [
        ...existingImages,
        ...imageFiles.map((file) => file.name)
      ];

      allProperties[selectedIndex] = updatedProperty;

      const params = {
        body: {
          tableName: "dsts_table-dev",
          Key: {
            id: id || formData.selectDst,
          },
          UpdateExpression: "SET #prop = :updatedProperties",
          ExpressionAttributeNames: {
            "#prop": "property",
          },
          ExpressionAttributeValues: {
            ":updatedProperties": allProperties,
          },
        },
      };

      const res = await API.put("dynamoRW", "/dsts", params);

      await Promise.all(
        removedImages?.map(async (imageName) => await Storage.remove(imageName))
      );

      toast(`Property Edited Successfully!`, {
        type: "success",
      });

      getPropertyData();
      setImageUrl([]);
      setImageFiles([]);
      setExistingImages([]);
      setRemovedImages([]);

      appContext.updateState("loading", false);
    } catch (error) {
      appContext.updateState("loading", false);
      console.log(error.message);
      toast(`Error posting DST property! ${error.message}`, {
        type: "error",
      });
    } finally {
      setLoading(false);
      setOpenEditModal(false);
      setSelectedIndex(-1);
    }
  };

  const handleChange = async (e) => {
    const { name, files } = e.target;

    if (name === "images") {
      const selectedFiles = Array.from(files);

      const objectURLs = selectedFiles.map((file) => URL.createObjectURL(file));
      setImageUrl((prevImageUrl) => [...prevImageUrl, ...objectURLs]);

      setImageFiles((prevImageFiles) => [...prevImageFiles, ...selectedFiles]);

      try {
        const uploadedImageNames = selectedFiles.map((file) => file.name);

        setFormData((prevFormData) => {
          const newFormData = {
            ...prevFormData,
            images: [...prevFormData.images, ...uploadedImageNames],
          };
          return newFormData;
        });
      } catch (error) {
        console.error("Error uploading images: ", error);
      }
    } else {
      const value = e.target.value;
      let maskedInput = value;

      if (name === "totalSf") {
        maskedInput = applyMask(value);
      }
      setFormData((prevData) => {
        const newFormData = { ...prevData, [name]: maskedInput };
        handleSessionState(newFormData);
        return newFormData;
      });
    }
  };

  const handleSessionState = (updatedFormData = formData) => {
    const formState = {
      formData: { ...updatedFormData, images: [] },
    };
    try {
      sessionStorage.setItem(
        "addCommericalPropertySession",
        JSON.stringify(formState)
      );
    } catch (error) {
      console.error("Error updating session storage: ", error);
    }
  };
  const uploadImage = async (file) => {
    try {
      Storage.configure({
        AWSS3: {
          bucket: process.env.REACT_APP_S3_BUCKET,
          region: process.env.REACT_APP_LAMBDA_REGION,
        },
      });
      const response = await Storage.put(file.name, file, {
        contentType: file.type,
      });

      return response.key;
    } catch (error) {
      console.error("Error uploading image:", error);
      return null;
    }
  };

  const handleEditClick = (propertyData, index) => {
    setOpenAddPropModal(false);
    setOpenEditModal(true);
    setSelectedPropertyData(propertyData);
    setSelectedIndex(index);
  };

  const handleEditProperty = async (event) => {
    const { name, files } = event.target;

    if (name === "images") {
      const selectedFiles = Array.from(files);

      const objectURLs = selectedFiles.map((file) => URL.createObjectURL(file));
      setImageUrl((prevImageUrl) => [...prevImageUrl, ...objectURLs]);

      try {
        const uploadedImageNames = await Promise.all(
          selectedFiles.map(async (file) => {
            return file.name;
          })
        );

        setImageFiles(selectedFiles);
        setSelectedPropertyData((prevData) => ({
          ...prevData,
          images: [...prevData.images, ...uploadedImageNames],
        }));
      } catch (error) {
        console.error("Error uploading images: ", error);
      }
    } else {
      const { value } = event.target;
      let maskedInput = value;

      if (name === "totalSf") {
        maskedInput = applyMask(value);
      }
      setFormData((prevData) => {
        const newFormData = { ...prevData, [name]: maskedInput };
        handleSessionState(newFormData);
        return newFormData;
      });
      setSelectedPropertyData((prevData) => ({
        ...prevData,
        [name]: maskedInput,
      }));
    }
  };

  if (id) {
    delete formData.selectDst;
  }

  const capitalizeWords = (str) => {
    return str
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  const handleDeleteDst = async (propertyName) => {
    try {
      setOpen(false);
      appContext.updateState("loading", true);

      if (!Array.isArray(selectedDst?.property)) {
        throw new Error("Invalid properties data");
      }
      const updatedProperties = selectedDst.property.filter(
        (property) =>
          !(
            property.propertyName === propertyName &&
            property.propertyTypeofDST == "Commercial"
          )
      );

      const params = {
        body: {
          tableName: "dsts_table-dev",
          Key: {
            id: id,
          },
          UpdateExpression: "SET #prop = :updatedProperties",
          ExpressionAttributeNames: {
            "#prop": "property",
          },
          ExpressionAttributeValues: {
            ":updatedProperties": updatedProperties,
          },
        },
      };

      await API.put("dynamoRW", "/dsts", params);
      getPropertyData();
      appContext.updateState("loading", false);
      toast("Property Deleted Successfully!", { type: "success" });
    } catch (error) {
      appContext.updateState("loading", false);
      console.log(error.message);
      toast(`Error Deleting Property! ${error.message}`, { type: "error" });
    } finally {
      setLoading(false);
    }
  };

  const applyMask = (inputVal) => {
    const cleanInput = inputVal.replace(/,/g, "").replace(/\.(?=.*\.)/g, "");
    const [wholePart, decimalPart] = cleanInput.split(".");
    const formattedWholePart = wholePart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    if (decimalPart !== undefined) {
      return `${formattedWholePart}.${decimalPart}`;
    } else {
      return formattedWholePart;
    }
  };

  const handleAddPropertyModel = () => {
    setOpenAddPropModal(true);
    setOpenEditModal(false);
    setSelectedPropertyData({});
    setSelectedIndex(-1);
    setImageUrl([]);
    setImageFiles([]);
    setExistingImages([]);
    setRemovedImages([]);
  };

  const handleTogglePrimary = async (property, originalIndex) => {
    try {
      setOpenEditModal(false);
      appContext.updateState("loading", true);

      const allProperties = [...selectedCommercialDst.property];

      allProperties.forEach((prop) => {
        prop.is_primary = false;
      });

      allProperties[originalIndex] = {
        ...property,
        is_primary: true,
      };

      const params = {
        body: {
          tableName: "dsts_table-dev",
          Key: {
            id: id || formData.selectDst,
          },
          UpdateExpression: "SET #prop = :updatedProperties",
          ExpressionAttributeNames: {
            "#prop": "property",
          },
          ExpressionAttributeValues: {
            ":updatedProperties": allProperties,
          },
        },
      };

      const res = await API.put("dynamoRW", "/dsts", params);
      toast(`Property marked as primary successfully!`, {
        type: "success",
      });
      await getPropertyData();
      appContext.updateState("loading", false);
    } catch (error) {
      appContext.updateState("loading", false);
      console.log(error.message);
      toast(`Error updating DST property! ${error.message}`, {
        type: "error",
      });
    } finally {
      setLoading(false);
      setOpenEditModal(false);
      setSelectedIndex(-1);
    }
  };

  const handleCloseEditModal = () => {
    setOpenAddPropModal(false);
    setOpenEditModal(false);
    setSelectedPropertyData({});
    setSelectedIndex(-1);
    setImageUrl([]);
    setImageFiles([]);
    setExistingImages([]);
    setRemovedImages([]);
  }

  return (
    <>
      {id ? (
        <div>
          {!openAddPropModal && (
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginBottom: "1.5rem",
              }}
            >
              <Button
                type="submit"
                variant="contained"
                style={{
                  backgroundColor: "#1976d2",
                  color: "#fff",
                }}
                onClick={() => handleAddPropertyModel()}
              >
                Add Property
              </Button>
            </div>
          )}
          {!openEditModal && !openAddPropModal ? (
            <TableContainer>
              <Table
                sx={{ minWidth: 700 }}
                stickyHeader
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell align="left">Address</StyledTableCell>
                    <StyledTableCell align="left">Mark Primary</StyledTableCell>
                    <StyledTableCell align="left">Edit</StyledTableCell>
                    <StyledTableCell align="left">Delete</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedCommercialDst?.property &&
                    selectedCommercialDst?.property.map(
                      (property, originalIndex) =>
                        property.propertyTypeofDST === "Commercial" ? (
                          <StyledTableRow
                            key={"dstDetails" + property.propertyName}
                          >
                            <StyledTableCell>
                              {property.propertyName}
                            </StyledTableCell>
                            <StyledTableCell
                              component="th"
                              scope="row"
                              align="left"
                            >
                              {property.streetAddress}
                            </StyledTableCell>
                            <StyledTableCell
                              component="th"
                              scope="row"
                              align="left"
                            >
                              <Switch
                                checked={property.is_primary}
                                onChange={() =>
                                  handleTogglePrimary(property, originalIndex)
                                }
                              />
                            </StyledTableCell>
                            <StyledTableCell
                              component="th"
                              scope="row"
                              align="left"
                            >
                              <IconButton
                                aria-label="edit"
                                size="small"
                                style={{ color: "#1976d2" }}
                                onClick={() =>
                                  handleEditClick(property, originalIndex)
                                }
                              >
                                <EditIcon />
                              </IconButton>
                            </StyledTableCell>
                            <StyledTableCell
                              component="th"
                              scope="row"
                              align="left"
                            >
                              <IconButton
                                aria-label="delete"
                                size="small"
                                onClick={() => setOpen(true)}
                              >
                                <DeleteIcon
                                  style={{ color: "#ff5555" }}
                                  onClick={() =>
                                    handleDeleteDst(property.propertyName)
                                  }
                                />
                              </IconButton>
                            </StyledTableCell>
                          </StyledTableRow>
                        ) : null
                    )}
                </TableBody>
              </Table>
            </TableContainer>
          ) : null}
          {openAddPropModal && (
            <Card
              style={{
                minWidth: 600,
                padding: "0rem 1rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                  padding: "1rem",
                }}
              >
                <h3>Add Property</h3>
              </div>
              <Grid container spacing={2}>
                {Object.keys(formData).map(
                  (key) =>
                    key !== "images" &&
                    key !== "oneBedrooms" &&
                    key !== "twoBedrooms" &&
                    key !== "threeBedrooms" &&
                    key !== "propertyManager" &&
                    key !== "pmDescription" &&
                    key !== "subMarketRecentRentGrowth" &&
                    key !== "msaProjectedRentGrowth" && (
                      <Grid
                        item
                        xs={
                          key === "unitAmenities" || key === "commonAmenities"
                            ? 12
                            : 4
                        }
                        key={key}
                      >
                        {key == "msaRecentRentGrowth" ? (
                          <TextField
                            size="small"
                            margin="normal"
                            fullWidth
                            style={{ margin: "0px" }}
                            name="msaRecentRentGrowth"
                            label="Economic Bullet Point 5"
                            value={formData.msaRecentRentGrowth || ""}
                            onChange={handleEditProperty}
                          />
                        ) : key == "subMarketProjectedPopGrowth" ? (
                          <TextField
                            size="small"
                            margin="normal"
                            fullWidth
                            style={{ margin: "0px" }}
                            name="subMarketProjectedPopGrowth"
                            label="Economic Bullet Point 4"
                            value={formData.subMarketProjectedPopGrowth || ""}
                            onChange={handleEditProperty}
                          />
                        ) : key == "msaProjectedPopGrowth" ? (
                          <TextField
                            size="small"
                            margin="normal"
                            fullWidth
                            style={{ margin: "0px" }}
                            name="msaProjectedPopGrowth"
                            label="Economic Bullet Point 3"
                            value={formData.msaProjectedPopGrowth || ""}
                            onChange={handleEditProperty}
                          />
                        ) : key == "subMarketRecentPopGrowth" ? (
                          <TextField
                            size="small"
                            margin="normal"
                            fullWidth
                            style={{ margin: "0px" }}
                            name="subMarketRecentPopGrowth"
                            label="Economic Bullet Point 2"
                            value={formData.subMarketRecentPopGrowth || ""}
                            onChange={handleEditProperty}
                          />
                        ) : key == "msaRecentPopGrowth" ? (
                          <TextField
                            size="small"
                            margin="normal"
                            fullWidth
                            style={{ margin: "0px" }}
                            name="msaRecentPopGrowth"
                            label="Economic Bullet Point 1"
                            value={formData.msaRecentPopGrowth || ""}
                            onChange={handleEditProperty}
                          />
                        ) : key === "propertyWebsite" ? (
                          <TextField
                            size="small"
                            margin="normal"
                            fullWidth
                            style={{ margin: "0px" }}
                            name="propertyWebsite"
                            label="Tenant Website"
                            value={formData.propertyWebsite}
                            onChange={handleChange}
                          />
                        ) : key === "commonAmenities" ? (
                          <Grid item xs={12}>
                            <TextField
                              size="small"
                              margin="normal"
                              fullWidth
                              multiline
                              rows={2}
                              style={{ margin: "0px" }}
                              name="commonAmenities"
                              label="Property Notes"
                              value={formData.commonAmenities}
                              onChange={handleChange}
                            />
                          </Grid>
                        ) : key === "states" ? (
                          <TextField
                            select
                            fullWidth
                            size="small"
                            margin="normal"
                            name="states"
                            id="userRoles"
                            variant="outlined"
                            label="Select State"
                            style={{ margin: "0px" }}
                            value={formData.states}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                states: e.target.value,
                              })
                            }
                            SelectProps={{
                              MenuProps: {
                                PaperProps: {
                                  style: {
                                    maxHeight: "300px",
                                  },
                                },
                              },
                            }}
                          >
                            <MenuItem value="" key="">
                              Clear
                            </MenuItem>
                            {stateOption?.map((option) => (
                              <MenuItem key={option.code} value={option.code}>
                                {`${option.state} - ${option.code}`}
                              </MenuItem>
                            ))}
                          </TextField>
                        ) : key === "investmentStrategy" ? (
                          <TextField
                            select
                            size="small"
                            margin="normal"
                            fullWidth
                            style={{ margin: "0px" }}
                            name="investmentStrategy"
                            label="Investment Strategy"
                            value={formData.investmentStrategy}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                investmentStrategy: e.target.value,
                              })
                            }
                          >
                            <MenuItem value="" key="">
                              Clear
                            </MenuItem>
                            {["Buy and Hold", "Some Value Add"].map(
                              (option) => (
                                <MenuItem key={option} value={option}>
                                  {option}
                                </MenuItem>
                              )
                            )}
                          </TextField>
                        ) : (
                          <TextField
                            size="small"
                            margin="normal"
                            fullWidth
                            multiline={key === "unitAmenities"}
                            rows={key === "unitAmenities" ? 4 : 0}
                            style={{
                              height: key === "unitAmenities" ? "100px" : null,
                              margin: "0px",
                            }}
                            name={key}
                            label={
                              key === "unitAmenities"
                                ? "Tenant Highlights"
                                : capitalizeWords(key)
                            }
                            value={formData[key] || ""}
                            onChange={handleChange}
                          />
                        )}
                      </Grid>
                    )
                )}

                <Grid item xs={12}>
                  <div
                    style={{
                      border: "1px solid #ccc",
                      padding: "10px",
                      height: "140px",
                      overflowY: "auto",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <label
                      htmlFor="fileInput"
                      style={{
                        textAlign: "center",
                        cursor: "pointer",
                        backgroundColor: "#007bff",
                        color: "#fff",
                        padding: "8px 12px",
                        borderRadius: "4px",
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                        transition: "background-color 0.3s",
                      }}
                    >
                      Select Images
                    </label>
                    <input
                      id="fileInput"
                      name="images"
                      type="file"
                      multiple
                      accept="image/*"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      style={{ display: "none" }}
                    />
                    <div style={selectedImagesStyle}>
                      {imageUrl?.length
                        ? imageUrl?.map((image, index) => (
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                alignItems: "flex-start",
                                marginBottom: "5px",
                                position: "relative",
                              }}
                            >
                              <img
                                src={image}
                                alt={`Selected ${index}`}
                                style={{
                                  width: "70px",
                                  height: "70px",
                                  objectFit: "cover",
                                  borderRadius: "4px",
                                  marginRight: "5px",
                                }}
                              />
                              <span
                                style={{
                                  cursor: "pointer",
                                  fontSize: "10px",
                                  position: "absolute",
                                  top: "3px",
                                  right: "7px",
                                  color: "#fff",
                                  borderRadius: "50%",
                                  padding: "4px",
                                  transition: "background-color 0.3s",
                                }}
                                onClick={() => handleRemoveImage(index)}
                              >
                                &#10060;
                              </span>
                            </div>
                          ))
                        : null}
                    </div>
                  </div>
                </Grid>
              </Grid>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    columnGap: "10px",
                    padding: "2rem 0rem",
                  }}
                >
                  <Button
                    onClick={() => {
                      setOpenAddPropModal(false);
                    }}
                    type="submit"
                    variant="contained"
                    style={{
                      backgroundColor: "#1976d2",
                      color: "#fff",
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    style={{
                      backgroundColor: "#1976d2",
                      color: "#fff",
                    }}
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </Card>
          )}
          {openEditModal && (
            <Card
              style={{
                minWidth: 600,
                padding: "0rem 1rem",
              }}
            >
              <div
                style={{
                  padding: "1rem 0rem",
                }}
              >
                <h3>Edit Property</h3>
              </div>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <TextField
                    size="small"
                    margin="normal"
                    fullWidth
                    style={{ margin: "0px" }}
                    name="propertyName"
                    label="Property Name"
                    value={selectedPropertyData.propertyName || ""}
                    onChange={handleEditProperty}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    size="small"
                    margin="normal"
                    fullWidth
                    style={{ margin: "0px" }}
                    name="streetAddress"
                    label="Street Address"
                    value={selectedPropertyData.streetAddress || ""}
                    onChange={handleEditProperty}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    select
                    fullWidth
                    size="small"
                    margin="normal"
                    name="states"
                    id="userRoles"
                    variant="outlined"
                    label="Select State"
                    style={{ margin: "0px" }}
                    value={selectedPropertyData.states || ""}
                    onChange={(e) =>
                      setSelectedPropertyData((prevData) => {
                        const newFormData = {
                          ...prevData,
                          states: e.target.value,
                        };
                        handleSessionState(newFormData);
                        return newFormData;
                      })
                    }
                    SelectProps={{
                      MenuProps: {
                        PaperProps: {
                          style: {
                            maxHeight: "300px",
                          },
                        },
                      },
                    }}
                  >
                    <MenuItem value="" key="">
                      Clear
                    </MenuItem>
                    {stateOption?.map((option) => (
                      <MenuItem key={option.code} value={option.code}>
                        {`${option.state} - ${option.code}`}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    size="small"
                    margin="normal"
                    fullWidth
                    style={{ margin: "0px" }}
                    name="city"
                    label="City"
                    value={selectedPropertyData.city || ""}
                    onChange={handleEditProperty}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    size="small"
                    margin="normal"
                    fullWidth
                    style={{ margin: "0px" }}
                    name="zip"
                    label="Zip"
                    value={selectedPropertyData.zip || ""}
                    onChange={handleEditProperty}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    size="small"
                    margin="normal"
                    fullWidth
                    style={{ margin: "0px" }}
                    name="locationDescription"
                    label="Location Description"
                    value={selectedPropertyData.locationDescription || ""}
                    onChange={handleEditProperty}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    size="small"
                    margin="normal"
                    fullWidth
                    style={{ margin: "0px" }}
                    name="msa"
                    label="MSA"
                    value={selectedPropertyData.msa || ""}
                    onChange={handleEditProperty}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    size="small"
                    margin="normal"
                    fullWidth
                    style={{ margin: "0px" }}
                    name="submarket"
                    label="Sub Market"
                    value={selectedPropertyData.submarket || ""}
                    onChange={handleEditProperty}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    size="small"
                    margin="normal"
                    fullWidth
                    style={{ margin: "0px" }}
                    name="yearBuilt"
                    label="Year Built"
                    value={selectedPropertyData.yearBuilt || ""}
                    onChange={handleEditProperty}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    size="small"
                    margin="normal"
                    fullWidth
                    style={{ margin: "0px" }}
                    name="style"
                    label="Style"
                    value={selectedPropertyData.style || ""}
                    onChange={handleEditProperty}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    size="small"
                    margin="normal"
                    fullWidth
                    style={{ margin: "0px" }}
                    name="class"
                    label="Class"
                    value={selectedPropertyData.class || ""}
                    onChange={handleEditProperty}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    size="small"
                    margin="normal"
                    fullWidth
                    style={{ margin: "0px" }}
                    name="sector"
                    label="Sector"
                    value={selectedPropertyData.sector || ""}
                    onChange={handleEditProperty}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    select
                    size="small"
                    margin="normal"
                    fullWidth
                    style={{ margin: "0px" }}
                    name="investmentStrategy"
                    label="Investment Strategy"
                    value={selectedPropertyData.investmentStrategy || ""}
                    onChange={(e) =>
                      setSelectedPropertyData({
                        ...selectedPropertyData,
                        investmentStrategy: e.target.value,
                      })
                    }
                  >
                    <MenuItem value="" key="">
                      Clear
                    </MenuItem>
                    {["Buy and Hold", "Some Value Add"].map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    size="small"
                    margin="normal"
                    fullWidth
                    style={{ margin: "0px" }}
                    name="primaryTenant"
                    label="Primary Tenant"
                    value={selectedPropertyData.primaryTenant || ""}
                    onChange={handleEditProperty}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    size="small"
                    margin="normal"
                    fullWidth
                    style={{ margin: "0px" }}
                    name="valueAddNotes"
                    label="Value Add Notes"
                    value={selectedPropertyData.valueAddNotes || ""}
                    onChange={handleEditProperty}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    size="small"
                    margin="normal"
                    fullWidth
                    style={{ margin: "0px" }}
                    name="leaseType"
                    label="Lease Type"
                    value={selectedPropertyData.leaseType || ""}
                    onChange={handleEditProperty}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    size="small"
                    margin="normal"
                    fullWidth
                    style={{ margin: "0px" }}
                    name="leaseTerm"
                    label="Lease Term"
                    value={selectedPropertyData.leaseTerm || ""}
                    onChange={handleEditProperty}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    size="small"
                    margin="normal"
                    fullWidth
                    style={{ margin: "0px" }}
                    name="remainingYears"
                    label="Remaining Years"
                    value={selectedPropertyData.remainingYears || ""}
                    onChange={handleEditProperty}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    size="small"
                    margin="normal"
                    fullWidth
                    style={{ margin: "0px" }}
                    name="extensions"
                    label="Extensions"
                    value={selectedPropertyData.extensions || ""}
                    onChange={handleEditProperty}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    size="small"
                    margin="normal"
                    fullWidth
                    style={{ margin: "0px" }}
                    name="tenantCreditRating"
                    label="Tenant Credit Rating"
                    value={selectedPropertyData.tenantCreditRating || ""}
                    onChange={handleEditProperty}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    size="small"
                    margin="normal"
                    fullWidth
                    style={{ margin: "0px" }}
                    name="currentOccup"
                    label="Current Occupancy"
                    value={selectedPropertyData.currentOccup || ""}
                    onChange={handleEditProperty}
                  />
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    size="small"
                    margin="normal"
                    fullWidth
                    style={{ margin: "0px" }}
                    name="totalSf"
                    label="Total SF"
                    value={selectedPropertyData.totalSf || ""}
                    onChange={handleEditProperty}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    size="small"
                    margin="normal"
                    fullWidth
                    style={{ margin: "0px" }}
                    name="totalUnits"
                    label="Total Units"
                    value={selectedPropertyData.totalUnits || ""}
                    onChange={handleEditProperty}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    size="small"
                    margin="normal"
                    fullWidth
                    style={{ margin: "0px" }}
                    name="NumberOfBuildings"
                    label="Number Of Buildings"
                    value={selectedPropertyData.NumberOfBuildings || ""}
                    onChange={handleEditProperty}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    size="small"
                    margin="normal"
                    fullWidth
                    style={{ margin: "0px" }}
                    name="maxFloors"
                    label="Max Floors"
                    value={selectedPropertyData.maxFloors || ""}
                    onChange={handleEditProperty}
                  />
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    size="small"
                    margin="normal"
                    fullWidth
                    style={{ margin: "0px" }}
                    name="propertyWebsite"
                    label="Property Website"
                    value={selectedPropertyData.propertyWebsite || ""}
                    onChange={handleEditProperty}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    size="small"
                    margin="normal"
                    fullWidth
                    style={{ margin: "0px" }}
                    name="acres"
                    label="Acres"
                    value={selectedPropertyData.acres}
                    onChange={handleEditProperty}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    size="small"
                    margin="normal"
                    fullWidth
                    style={{ margin: "0px" }}
                    name="topEmployerA"
                    label="Top Employer A"
                    value={selectedPropertyData.topEmployerA || ""}
                    onChange={handleEditProperty}
                  />
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    size="small"
                    margin="normal"
                    fullWidth
                    style={{ margin: "0px" }}
                    name="topEmployerB"
                    label="Top Employer B"
                    value={selectedPropertyData.topEmployerB || ""}
                    onChange={handleEditProperty}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    size="small"
                    margin="normal"
                    fullWidth
                    style={{ margin: "0px" }}
                    name="topEmployerC"
                    label="Top Employer C"
                    value={selectedPropertyData.topEmployerC || ""}
                    onChange={handleEditProperty}
                  />
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    size="small"
                    margin="normal"
                    fullWidth
                    style={{ margin: "0px" }}
                    name="topEmployerD"
                    label="Top Employer D"
                    value={selectedPropertyData.topEmployerD || ""}
                    onChange={handleEditProperty}
                  />
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    size="small"
                    margin="normal"
                    fullWidth
                    style={{ margin: "0px" }}
                    name="topEmployerE"
                    label="Top Employer E"
                    value={selectedPropertyData.topEmployerE || ""}
                    onChange={handleEditProperty}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    size="small"
                    margin="normal"
                    fullWidth
                    style={{ margin: "0px" }}
                    name="msaRecentPopGrowth"
                    label="Economic Bullet Point 1"
                    value={selectedPropertyData.msaRecentPopGrowth || ""}
                    onChange={handleEditProperty}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    size="small"
                    margin="normal"
                    fullWidth
                    style={{ margin: "0px" }}
                    name="subMarketRecentPopGrowth"
                    label="Economic Bullet Point 2"
                    value={selectedPropertyData.subMarketRecentPopGrowth || ""}
                    onChange={handleEditProperty}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    size="small"
                    margin="normal"
                    fullWidth
                    style={{ margin: "0px" }}
                    name="msaProjectedPopGrowth"
                    label="Economic Bullet Point 3"
                    value={selectedPropertyData.msaProjectedPopGrowth || ""}
                    onChange={handleEditProperty}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    size="small"
                    margin="normal"
                    fullWidth
                    style={{ margin: "0px" }}
                    name="subMarketProjectedPopGrowth"
                    label="Economic Bullet Point 4"
                    value={
                      selectedPropertyData.subMarketProjectedPopGrowth || ""
                    }
                    onChange={handleEditProperty}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    size="small"
                    margin="normal"
                    fullWidth
                    style={{ margin: "0px" }}
                    name="msaRecentRentGrowth"
                    label="Economic Bullet Point 5"
                    value={selectedPropertyData.msaRecentRentGrowth || ""}
                    onChange={handleEditProperty}
                  />
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    size="small"
                    margin="normal"
                    fullWidth
                    style={{ margin: "0px" }}
                    name="subProjectedRentGrowth"
                    label="Sub Projected Rent Growth"
                    value={selectedPropertyData.subProjectedRentGrowth || ""}
                    onChange={handleEditProperty}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    size="small"
                    margin="normal"
                    fullWidth
                    style={{ margin: "0px" }}
                    name="otherEconomicNotes"
                    label="Other Economic Notes"
                    value={selectedPropertyData.otherEconomicNotes || ""}
                    onChange={handleEditProperty}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    size="small"
                    margin="normal"
                    fullWidth
                    style={{ margin: "0px" }}
                    name="otherDemographicNotes"
                    label="Other Demographic Notes"
                    value={selectedPropertyData.otherDemographicNotes || ""}
                    onChange={handleEditProperty}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    size="small"
                    margin="normal"
                    fullWidth
                    style={{ margin: "0px" }}
                    name="locationNotes"
                    label="Location Notes"
                    value={selectedPropertyData.locationNotes || ""}
                    onChange={handleEditProperty}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    size="small"
                    margin="normal"
                    fullWidth
                    style={{ margin: "0px" }}
                    name="miscNotes"
                    label="Misc Notes"
                    value={selectedPropertyData.miscNotes || ""}
                    onChange={handleEditProperty}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    margin="normal"
                    fullWidth
                    multiline
                    style={{ margin: "0px" }}
                    name="commonAmenities"
                    label="Property Notes"
                    value={formData.commonAmenities}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    margin="normal"
                    fullWidth
                    multiline
                    rows={4}
                    style={{ height: "100px", margin: "0px" }}
                    name="unitAmenities"
                    label="Tenant Highlights"
                    value={selectedPropertyData.unitAmenities || ""}
                    onChange={handleEditProperty}
                  />
                </Grid>
                 <Grid item xs={12}>
      <div
        style={{
          border: "1px solid #ccc",
          padding: "10px",
          height: "140px",
          overflowY: "auto",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        <label
          htmlFor="fileInput"
          style={{
            textAlign: "center",
            cursor: "pointer",
            backgroundColor: "#007bff",
            color: "#fff",
            padding: "8px 12px",
            borderRadius: "4px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            transition: "background-color 0.3s",
          }}
        >
          Select Images
        </label>
        <input
          id="fileInput"
          name="images"
          type="file"
          multiple
          accept="image/*"
          onChange={(e) => {
            handleChange(e);
          }}
          style={{ display: "none" }}
        />
        <div style={selectedImagesStyle}>
          {imageUrl.map((image, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                alignItems: "flex-start",
                marginBottom: "5px",
                position: "relative",
              }}
            >
              <img
                src={image}
                alt={`Selected ${index}`}
                style={{
                  width: "70px",
                  height: "70px",
                  objectFit: "cover",
                  borderRadius: "4px",
                  marginRight: "5px",
                }}
              />
              <span
                style={{
                  cursor: "pointer",
                  fontSize: "10px",
                  position: "absolute",
                  top: "3px",
                  right: "7px",
                  color: "#fff",
                  borderRadius: "50%",
                  padding: "4px",
                  transition: "background-color 0.3s",
                }}
                onClick={() => handleRemoveImage(index)}
              >
                &#10060;
              </span>
            </div>
          ))}
        </div>
      </div>
    </Grid>
              </Grid>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    columnGap: "10px",
                    padding: "2rem 0rem",
                  }}
                >
                  <Button
                    onClick={() => handleCloseEditModal()}
                    type="submit"
                    variant="contained"
                    style={{
                      backgroundColor: "#1976d2",
                      color: "#fff",
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    style={{
                      backgroundColor: "#1976d2",
                      color: "#fff",
                    }}
                    onClick={handleSave}
                  >
                    Update
                  </Button>
                </div>
              </div>
            </Card>
          )}
        </div>
      ) : (
        <Card sx={{ minWidth: 275 }}>
          <ThemeProvider theme={defaultTheme}>
            <Container component="main">
              <h3>Add Property</h3>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Grid container spacing={2}>
                  {!id ? (
                    <Grid item xs={4}>
                      <TextField
                        select
                        size="small"
                        margin="normal"
                        fullWidth
                        style={{ margin: "0px" }}
                        name="selectDst"
                        label="Select DST"
                        SelectProps={{
                          MenuProps: {
                            PaperProps: {
                              style: {
                                maxHeight: "300px",
                              },
                            },
                          },
                        }}
                        value={formData.selectDst}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            selectDst: e.target.value,
                          })
                        }
                      >
                        <MenuItem value="" key="">
                          Clear
                        </MenuItem>
                        {dstsName?.map((option, ind) => (
                          <MenuItem key={ind} value={option.id}>
                            {option.basicInfo?.fullLegalNameOfDst}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  ) : null}
                  {/* ALL FIELDS ARE STARTING FROM HERE ------------------------------------------------- */}
                  <Grid item xs={4}>
                    <TextField
                      size="small"
                      margin="normal"
                      fullWidth
                      style={{ margin: "0px" }}
                      name="acres"
                      label="Acres"
                      value={formData.acres}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <TextField
                      size="small"
                      margin="normal"
                      fullWidth
                      style={{ margin: "0px" }}
                      name="msaRecentPopGrowth"
                      label="Economic Bullet Point 1"
                      value={formData.msaRecentPopGrowth}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      size="small"
                      margin="normal"
                      fullWidth
                      style={{ margin: "0px" }}
                      name="propertyName"
                      label="Property Name"
                      value={formData.propertyName}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      size="small"
                      margin="normal"
                      fullWidth
                      style={{ margin: "0px" }}
                      name="streetAddress"
                      label="Street Address"
                      value={formData.streetAddress}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      size="small"
                      margin="normal"
                      fullWidth
                      style={{ margin: "0px" }}
                      name="subMarketRecentPopGrowth"
                      label="Economic Bullet Point 2"
                      value={formData.subMarketRecentPopGrowth}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      select
                      fullWidth
                      size="small"
                      margin="normal"
                      name="states"
                      id="userRoles"
                      variant="outlined"
                      label="Select State"
                      style={{ margin: "0px" }}
                      value={formData.states}
                      onChange={(e) =>
                        setFormData((prevData) => {
                          const newFormData = {
                            ...prevData,
                            states: e.target.value,
                          };
                          handleSessionState(newFormData);
                          return newFormData;
                        })
                      }
                      SelectProps={{
                        MenuProps: {
                          PaperProps: {
                            style: {
                              maxHeight: "300px",
                            },
                          },
                        },
                      }}
                    >
                      <MenuItem value="" key="">
                        Clear
                      </MenuItem>
                      {stateOption?.map((option) => (
                        <MenuItem key={option.code} value={option.code}>
                          {`${option.state} - ${option.code}`}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item xs={4}>
                    <TextField
                      size="small"
                      margin="normal"
                      fullWidth
                      style={{ margin: "0px" }}
                      name="city"
                      label="City"
                      value={formData.city}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      size="small"
                      margin="normal"
                      fullWidth
                      style={{ margin: "0px" }}
                      name="msaProjectedPopGrowth"
                      label="Economic Bullet Point 3"
                      value={formData.msaProjectedPopGrowth}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      size="small"
                      margin="normal"
                      fullWidth
                      style={{ margin: "0px" }}
                      name="zip"
                      label="Zip"
                      value={formData.zip}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      size="small"
                      margin="normal"
                      fullWidth
                      style={{ margin: "0px" }}
                      name="submarket"
                      label="Sub Market"
                      value={formData.submarket}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      size="small"
                      margin="normal"
                      fullWidth
                      style={{ margin: "0px" }}
                      name="subMarketProjectedPopGrowth"
                      label="Economic Bullet Point 4"
                      value={formData.subMarketProjectedPopGrowth}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <TextField
                      size="small"
                      margin="normal"
                      fullWidth
                      style={{ margin: "0px" }}
                      name="totalUnits"
                      label="Total Units"
                      value={formData.totalUnits}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <TextField
                      size="small"
                      margin="normal"
                      fullWidth
                      style={{ margin: "0px" }}
                      name="msa"
                      label="MSA"
                      value={formData.msa}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      size="small"
                      margin="normal"
                      fullWidth
                      style={{ margin: "0px" }}
                      name="msaRecentRentGrowth"
                      label="Economic Bullet Point 5"
                      value={formData.msaRecentRentGrowth}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <TextField
                      select
                      size="small"
                      margin="normal"
                      fullWidth
                      style={{ margin: "0px" }}
                      name="investmentStrategy"
                      label="Investment Strategy"
                      value={formData.investmentStrategy}
                      onChange={(e) =>
                        setFormData((prevData) => {
                          const newFormData = {
                            ...prevData,
                            investmentStrategy: e.target.value,
                          };
                          handleSessionState(newFormData);
                          return newFormData;
                        })
                      }
                    >
                      <MenuItem value="" key="">
                        Clear
                      </MenuItem>
                      {["Buy and Hold", "Some Value Add"].map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      size="small"
                      margin="normal"
                      fullWidth
                      style={{ margin: "0px" }}
                      name="primaryTenant"
                      label="Primary Tenant"
                      value={formData.primaryTenant}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      size="small"
                      margin="normal"
                      fullWidth
                      style={{ margin: "0px" }}
                      name="subProjectedRentGrowth"
                      label="Sub Market Projected Rent Growth"
                      value={formData.subProjectedRentGrowth}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      size="small"
                      margin="normal"
                      fullWidth
                      style={{ margin: "0px" }}
                      name="locationDescription"
                      label="Location Description"
                      value={formData.locationDescription}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      size="small"
                      margin="normal"
                      fullWidth
                      style={{ margin: "0px" }}
                      name="valueAddNotes"
                      label="Value Add Notes"
                      value={formData.valueAddNotes}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      size="small"
                      margin="normal"
                      fullWidth
                      style={{ margin: "0px" }}
                      name="otherDemographicNotes"
                      label="Other Demographic Notes"
                      value={formData.otherDemographicNotes}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      size="small"
                      margin="normal"
                      fullWidth
                      style={{ margin: "0px" }}
                      name="style"
                      label="Style"
                      value={formData.style}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      size="small"
                      margin="normal"
                      fullWidth
                      style={{ margin: "0px" }}
                      name="currentOccup"
                      label="Current Occupancy"
                      value={formData.currentOccup}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      size="small"
                      margin="normal"
                      fullWidth
                      style={{ margin: "0px" }}
                      name="otherEconomicNotes"
                      label="Other Economic Notes"
                      value={formData.otherEconomicNotes}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <TextField
                      size="small"
                      margin="normal"
                      fullWidth
                      style={{ margin: "0px" }}
                      name="class"
                      label="Class"
                      value={formData.class}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <TextField
                      size="small"
                      margin="normal"
                      fullWidth
                      style={{ margin: "0px" }}
                      name="leaseType"
                      label="Lease Type"
                      value={formData.leaseType}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      size="small"
                      margin="normal"
                      fullWidth
                      style={{ margin: "0px" }}
                      name="topEmployerA"
                      label="Top Employer A"
                      value={formData.topEmployerA}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      size="small"
                      margin="normal"
                      fullWidth
                      style={{ margin: "0px" }}
                      name="sector"
                      label="Sector"
                      value={formData.sector}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      size="small"
                      margin="normal"
                      fullWidth
                      style={{ margin: "0px" }}
                      name="leaseTerm"
                      label="Lease Term"
                      value={formData.leaseTerm}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      size="small"
                      margin="normal"
                      fullWidth
                      style={{ margin: "0px" }}
                      name="topEmployerB"
                      label="Top Employer B"
                      value={formData.topEmployerB}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      size="small"
                      margin="normal"
                      fullWidth
                      style={{ margin: "0px" }}
                      name="yearBuilt"
                      label="Year Built"
                      value={formData.yearBuilt}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      size="small"
                      margin="normal"
                      fullWidth
                      style={{ margin: "0px" }}
                      name="extensions"
                      label="Extensions"
                      value={formData.extensions}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      size="small"
                      margin="normal"
                      fullWidth
                      style={{ margin: "0px" }}
                      name="topEmployerC"
                      label="Top Employer C"
                      value={formData.topEmployerC}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      size="small"
                      margin="normal"
                      fullWidth
                      style={{ margin: "0px" }}
                      name="NumberOfBuildings"
                      label="Number Of Buildings"
                      value={formData.NumberOfBuildings}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      size="small"
                      margin="normal"
                      fullWidth
                      style={{ margin: "0px" }}
                      name="remainingYears"
                      label="Remaining Years"
                      value={formData.remainingYears}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      size="small"
                      margin="normal"
                      fullWidth
                      style={{ margin: "0px" }}
                      name="topEmployerD"
                      label="Top Employer D"
                      value={formData.topEmployerD}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <TextField
                      size="small"
                      margin="normal"
                      fullWidth
                      style={{ margin: "0px" }}
                      name="maxFloors"
                      label="Max Floors"
                      value={formData.maxFloors}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      size="small"
                      margin="normal"
                      fullWidth
                      style={{ margin: "0px" }}
                      name="tenantCreditRating"
                      label="Tenant Credit Rating"
                      value={formData.tenantCreditRating}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      size="small"
                      margin="normal"
                      fullWidth
                      style={{ margin: "0px" }}
                      name="topEmployerE"
                      label="Top Employer E"
                      value={formData.topEmployerE}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      size="small"
                      margin="normal"
                      fullWidth
                      style={{ margin: "0px" }}
                      name="totalSf"
                      label="Total SF"
                      value={formData.totalSf}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <TextField
                      size="small"
                      margin="normal"
                      fullWidth
                      style={{ margin: "0px" }}
                      name="propertyWebsite"
                      label="Tenant Website"
                      value={formData.propertyWebsite}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      size="small"
                      margin="normal"
                      fullWidth
                      style={{ margin: "0px" }}
                      name="locationNotes"
                      label="Location Notes"
                      value={formData.locationNotes}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      size="small"
                      margin="normal"
                      fullWidth
                      style={{ margin: "0px" }}
                      name="miscNotes"
                      label="Misc Notes"
                      value={formData.miscNotes}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      size="small"
                      margin="normal"
                      fullWidth
                      multiline
                      style={{ margin: "0px" }}
                      name="commonAmenities"
                      label="Property Notes"
                      value={formData.commonAmenities}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      size="small"
                      margin="normal"
                      fullWidth
                      multiline
                      rows={3}
                      style={{ height: "100px", margin: "0px" }}
                      name="unitAmenities"
                      label="Tenant Highlights"
                      value={formData.unitAmenities}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <div
                      style={{
                        border: "1px solid #ccc",
                        padding: "10px",
                        height: "140px",
                        overflowY: "auto",
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <label
                        htmlFor="fileInput"
                        style={{
                          textAlign: "center",
                          cursor: "pointer",
                          backgroundColor: "#007bff",
                          color: "#fff",
                          padding: "8px 12px",
                          borderRadius: "4px",
                          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                          transition: "background-color 0.3s",
                        }}
                      >
                        Select Images
                      </label>
                      <input
                        id="fileInput"
                        name="images"
                        type="file"
                        multiple
                        accept="image/*"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        style={{ display: "none" }}
                      />
                      <div style={selectedImagesStyle}>
                        {imageUrl?.length
                          ? imageUrl?.map((image, index) => (
                              <div
                                key={index}
                                style={{
                                  display: "flex",
                                  alignItems: "flex-start",
                                  marginBottom: "5px",
                                  position: "relative",
                                }}
                              >
                                <img
                                  src={image}
                                  alt={`Selected ${index}`}
                                  style={{
                                    width: "70px",
                                    height: "70px",
                                    objectFit: "cover",
                                    borderRadius: "4px",
                                    marginRight: "5px",
                                  }}
                                />
                                <span
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "10px",
                                    position: "absolute",
                                    top: "3px",
                                    right: "7px",
                                    color: "#fff",
                                    borderRadius: "50%",
                                    padding: "4px",
                                    transition: "background-color 0.3s",
                                  }}
                                  onClick={() => handleRemoveImage(index)}
                                >
                                  &#10060;
                                </span>
                              </div>
                            ))
                          : null}
                      </div>
                    </div>
                  </Grid>
                </Grid>
                {/* ALL FIELDS ARE ENDS HERE ---------------------------------------------------------- */}
                <div
                  style={{
                    display: "flex",
                    columnGap: "10px",
                    justifyContent: "end",
                    alignItems: "center",
                    width: "100%",
                    marginTop: "1rem",
                    marginBottom: "1rem",
                  }}
                >
                  <Button
                    onClick={handleSubmit}
                    type="submit"
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Save
                  </Button>
                  <Button
                    onClick={() => {
                      setShowAddEditModal(false);
                    }}
                    type="submit"
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => {
                      handleSessionSave();
                    }}
                    type="submit"
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Reset
                  </Button>
                </div>
              </Box>
            </Container>
          </ThemeProvider>
        </Card>
      )}
    </>
  );
}
