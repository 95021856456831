import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function CustomizedTables({ selectedDst }) {
  console.log("the selected DSt is .....", selectedDst);
  function removePercentageSignFromObject(obj) {
    for (const key in obj) {
      if (typeof obj[key] === "string" && obj[key].includes("%")) {
        obj[key] = obj[key].replace(/%/g, "");
      }
    }
  }

  // Apply the function to selectedDst
  removePercentageSignFromObject(selectedDst);
  const selectedDstArray = Object.entries(selectedDst);
  selectedDstArray.sort(
    (a, b) =>
      parseInt(a[0].replace("year", "")) - parseInt(b[0].replace("year", ""))
  );

  console.log("The Selected Dst.....", selectedDst);
  return (
    <Paper sx={{ margin: "0 auto" }}>
      <TableContainer>
        <Table
          sx={{ minWidth: 700 }}
          stickyHeader
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell>Year</StyledTableCell>
              <StyledTableCell align="center">Yield</StyledTableCell>
              <StyledTableCell align="right">Value</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedDstArray.map(([key, value], index) => (
              <StyledTableRow key={"yieldviewlist" + index}>
                <StyledTableCell component="th" scope="row">
                  {index + 1}
                </StyledTableCell>
                <StyledTableCell align="center" component="th" scope="row">{`Y${
                  index + 1
                } Yield`}</StyledTableCell>
                <StyledTableCell align="right" component="th" scope="row">
                  <b>{`${value || 0}%`}</b>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
