/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_mobile_analytics_app_id": "69d3f05c9edb46c7babe3a2f490d0f35",
    "aws_mobile_analytics_app_region": "us-east-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "69d3f05c9edb46c7babe3a2f490d0f35",
            "region": "us-east-1"
        }
    },
    "Notifications": {
        "Push": {
            "AWSPinpoint": {
                "appId": "69d3f05c9edb46c7babe3a2f490d0f35",
                "region": "us-east-1"
            }
        }
    },
    "aws_cloud_logic_custom": [
        {
            "name": "dynamoRW",
            "endpoint": "https://h4ib2qgid8.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "signUpAuthentication",
            "endpoint": "https://t1rqdei2a2.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:dc07083d-b87a-4856-b1c2-8c494b931c56",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_RmAZiQrgL",
    "aws_user_pools_web_client_id": "32ag42oh8p6ffh7t96crs7j4gk",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "altsnotifications-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "approvedUsers-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "dsts_table-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "federal_tax-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "state_info-dev",
            "region": "us-east-1"
        }
    ],
    "aws_user_files_s3_bucket": "property-images214520-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
