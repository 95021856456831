import React, { useContext, useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MyContext from "../context/appContext";
import { makeStyles } from "@material-ui/core/styles";
import { Navigate, useNavigate, Link } from "react-router-dom";
import { API, Amplify, Auth } from "aws-amplify";
import { AppContext } from "../context/appContext";
import awsmobile from "../aws-exports";
import { toast } from "react-toastify";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { IconButton, InputAdornment } from "@material-ui/core";
import EmailIcon from "@mui/icons-material/Email";
import PersonIcon from "@mui/icons-material/Person";

Amplify.configure(awsmobile);
const defaultTheme = createTheme();

const useStyles = makeStyles({
    backgroundScreen: {
        backgroundColor: "#000",
        height: "100vh",
    },
    signinCard: {
        padding: "10px 30px",
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    boxAlign: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
    },
    LinkText: {
        textDecoration: "none",
        color: "#a48d31",
    },
    signUpText: {
        color: "#fff",
        textAlign: "center",
    },
});

export default function InvestorSignup() {
    const classes = useStyles();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [retypePassword, setRetypePassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const onSignup = async () => {
        try {
            if (retypePassword !== password) {
                toast("Both passwords should be the same", { type: "error" });
                return;
            } else if (username === "") {
                toast("Username cannot be empty", { type: "error" });
                return;
            } else {
                const { user } = await Auth.signUp({
                    username: email,
                    password: password,
                    autoSignIn: {
                        enabled: true,
                    },
                    attributes: {
                        name: username,
                    },
                });
                console.log("the user are:::", user);
                //   const res = await API.post("signUpAuthentication", "/signUpAuth", {
                //   body: {
                //     tableName: "altsnotifications-dev",
                //     Item: {
                //       id: user.username,
                //       userEmail: user.username,
                //       username:username,
                //       status:false,
                //       role:"Analytics"
                //      },

                //   },
                //  });
                // console.log("THe data is inserted :::",res)
                navigate("/verifyCode", {
                    state: { username: user.username, name: username, role: "Agent" },
                });
            }
        } catch (error) {
            toast(error.message, { type: "error" });
            console.log("Error signing up", error.message);
        }
    };

    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Container
                component="main"
                className={classes.backgroundScreen}
                style={{ maxWidth: "100%" }}
            >
                <CssBaseline />
                <Box className={classes.boxAlign}>
                    <div className={classes.signinCard}>
                        <Typography
                            component="h1"
                            variant="h6"
                            color="inherit"
                            noWrap
                            sx={{ flexGrow: 1 }}
                        >
                            <img src="/faviconPNG.png" style={{ height: "30px" }} />
                        </Typography>
                        <Box component="form" noValidate>
                            <div className="login-field">
                                <TextField
                                    value={username}
                                    className="login-email-field"
                                    size="small"
                                    margin="normal"
                                    id="username"
                                    placeholder="Username"
                                    name="username"
                                    autoComplete="username"
                                    onChange={(e) => setUsername(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton edge="end">
                                                    <PersonIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <TextField
                                    value={email}
                                    className="login-email-field"
                                    size="small"
                                    margin="normal"
                                    id="email"
                                    placeholder="Email Address"
                                    name="email"
                                    autoComplete="email"
                                    onChange={(e) => setEmail(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton edge="end">
                                                    <EmailIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <TextField
                                    className="login-email-field"
                                    size="small"
                                    margin="normal"
                                    value={password}
                                    name="password"
                                    placeholder="Password"
                                    type={showPassword ? "text" : "password"}
                                    id="password"
                                    autoComplete="current-password"
                                    onChange={(e) => setPassword(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={handleTogglePasswordVisibility}
                                                    edge="end"
                                                >
                                                    {showPassword ? (
                                                        <VisibilityOffIcon style={{ color: "#777" }} />
                                                    ) : (
                                                        <VisibilityIcon style={{ color: "#777" }} />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <TextField
                                    className="login-email-field"
                                    size="small"
                                    margin="normal"
                                    value={retypePassword}
                                    name="retypePassword"
                                    placeholder="Re-type Password"
                                    type={showConfirmPassword ? "text" : "password"}
                                    id="retypePassword"
                                    autoComplete="current-password"
                                    onChange={(e) => setRetypePassword(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={handleConfirmPasswordVisibility}
                                                    edge="end"
                                                >
                                                    {showConfirmPassword ? (
                                                        <VisibilityOffIcon style={{ color: "#777" }} />
                                                    ) : (
                                                        <VisibilityIcon style={{ color: "#777" }} />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>

                            <div className="login-signin-btn">
                                <Button
                                    // type="submit"
                                    variant="contained"
                                    sx={{
                                        mt: 3,
                                        mb: 1,
                                        borderRadius: 0,
                                        textTransform: "capitalize",
                                        padding: "5px 25px",
                                    }}
                                    onClick={onSignup}
                                >
                                    Sign Up
                                </Button>
                            </div>
                            <div>
                                <p className={classes.signUpText}>
                                    You Already have an account?{" "}
                                    <Link to="/login" className={classes.LinkText}>
                                        <span>Login</span>
                                    </Link>
                                </p>
                            </div>
                        </Box>
                    </div>
                </Box>
            </Container>
        </ThemeProvider>
    );
}
