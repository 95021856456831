import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function CustomizedTables({ selectedDst }) {
  function formatNumberWithCommas(value) {
    if (!isNaN(value) && value !== "") {
      return parseFloat(value).toLocaleString();
    }
    return value;
  }
  function removeDollarSign(value) {
    if (typeof value === "string") {
      const cleanedValue = value.replace(/[^0-9.]/g, "");
      if (cleanedValue !== "") {
        const formattedValue = Number(cleanedValue).toLocaleString();
        return formattedValue;
      } else {
        return "";
      }
    }
    return value;
  }
  for (const key in selectedDst) {
    if (
      key === "appraisedValue" ||
      key === "minimum1031Investment" ||
      key === "offeringDebt" ||
      key === "offeringEquity" ||
      key === "purchasePrice" ||
      key === "year1EffectiveGrossRevenue" ||
      key === "year1NetOperatingIncome" ||
      key === "year1TotalDebtService"
    ) {
      selectedDst[key] = formatNumberWithCommas(selectedDst[key]);
      selectedDst[key] = removeDollarSign(selectedDst[key]);
    }
    if (key === "concordeApprovalDate") {
      const newDate = new Date(selectedDst[key]);
      selectedDst[key] =
        newDate == "Invalid Date"
          ? "N/A"
          : newDate.toLocaleDateString("en-US") || "";
    }
    if (key === "states" && Array.isArray(selectedDst[key])) {
      selectedDst[key] = selectedDst[key].join(", ");
    }
  }
  const splitCamelCase = (word) => {
    const words = word.split(/(?=[A-Z])/);
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    const result = capitalizedWords.join(" ");
    return result;
  };

  const desiredOrder = ["sponsorBullet1", "sponsorBullet2", "sponsorBullet3"];
  const uniqueDealRiskOrder = [
    "uniqueDealRisk1",
    "uniqueDealRisk2",
    "uniqueDealRisk3",
    "uniqueDealRisk4",
    "uniqueDealRisk5",
  ];

  const remainingKeys = Object.keys(selectedDst).filter(
    (key) => !desiredOrder.includes(key) && !uniqueDealRiskOrder.includes(key)
  );

  const allKeys = [...desiredOrder, ...uniqueDealRiskOrder, ...remainingKeys];
  return (
    <Paper sx={{ margin: "0 auto" }}>
      <TableContainer>
        <Table
          sx={{ minWidth: 700 }}
          stickyHeader
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Details</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allKeys.map((key) => (
              <StyledTableRow key={"dstDetails" + key}>
                <StyledTableCell>
                  <b> {splitCamelCase(key)}</b>
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                  {selectedDst[key]}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
