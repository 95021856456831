import React, { useState, useRef } from "react";
import {
  Grid,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import ReactToPrint from "react-to-print";
import { Button, Paper } from "@material-ui/core";
import USAMap from "react-usa-map";
import { alpha, makeStyles, styled } from "@material-ui/core/styles";
import {
  LandscapeOrientation,
  PortraitOrientation,
} from "../../utills/landscapeOrientation";
import { pink } from "@mui/material/colors";

const useStyles = makeStyles({
  customTable: {
    "& .MuiTableCell-sizeSmall": {
      padding: "10px 0px 10px 16px",
    },
  },
});

const PinkSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: pink[600],
    "&:hover": {
      backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: pink[600],
  },
}));

const SniffReport = () => {
  const classes = useStyles();
  const componentRef = useRef();
  const { state } = useLocation();
  const tableRows = state?.tableRows;
  const otherInfo = state?.otherInfo;
  const [portraitLayout, setPortraitLayout] = useState(false);

  console.log(tableRows, "tableRows");
  console.log(otherInfo, "otherInfo");
  const mapFillColor = "#212A3E";
  const statesCustomConfig = () => {
    return stateCount;
  };

  const cleanNumeric = (value) => {
    if (typeof value !== "string") {
      value = String(value);
    }
    return parseFloat(value.replace(/[^0-9.]/g, ""));
  };

  let statesArray = [];
  const rows = tableRows?.map((row) => {
    let stateValue = Array.isArray(row.state)
      ? row.state.join(", ")
      : row.state;

    if (stateValue && stateValue !== "N/A") {
      if (stateValue.split(",")[1]) {
        let split = stateValue.split(",");
        statesArray.push(split[0].trim());
        statesArray.push(split[1].trim());
      } else {
        statesArray.push(stateValue.trim());
      }
    }

    return {
      name: row.name,
      equity: cleanNumeric(row.equity),
      debt: cleanNumeric(row.debt),
      total: cleanNumeric(row.total),
      ltv: +row.ltv,
      sector: row.sector,
      yield: cleanNumeric(row.yield),
      unit: cleanNumeric(row.unit),
      state: stateValue,
    };
  });
  let stateCount = {};
  for (var i = 0; i < statesArray.length; i++) {
    stateCount[statesArray[i]] = {
      count: 1 + (stateCount[statesArray[i]]?.count || 0),
      fill: mapFillColor,
    };
  }

  const columnTotals = {
    equity: cleanNumeric(
      rows?.reduce((total, row) => +total + cleanNumeric(row.equity), 0)
    ),
    debt: cleanNumeric(
      rows?.reduce((total, row) => +total + cleanNumeric(row.debt), 0)
    ),
    total: cleanNumeric(
      rows?.reduce((total, row) => +total + cleanNumeric(row.total), 0)
    ),
    ltv: +(
      (cleanNumeric(
        rows?.reduce((total, row) => +total + cleanNumeric(row.debt), 0)
      ) /
        cleanNumeric(
          rows?.reduce((total, row) => +total + cleanNumeric(row.total), 0)
        )) *
      100
    ).toFixed(2),
    yield: (
      cleanNumeric(
        rows?.reduce((total, row) => +total + cleanNumeric(row.yield), 0)
      ) / tableRows?.length
    ).toFixed(2),
    unit: cleanNumeric(
      rows?.reduce((total, row) => +total + cleanNumeric(row.unit), 0)
    ),
  };

  const totalRow = {
    name: "Portfolio Results (Weighted):",
    equity: +columnTotals.equity,
    debt: +columnTotals.debt,
    total: +columnTotals.total,
    ltv: +columnTotals.ltv,
    yield: +columnTotals.yield,
    unit: +columnTotals.unit,
  };

  rows?.push(totalRow);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          columnGap: "1.5rem",
          marginTop: "2rem",
          marginRight: "2rem",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          <Typography
            style={{ color: portraitLayout === false ? "#1565c0" : "#333" }}
          >
            Portrait
          </Typography>
          <PinkSwitch
            checked={!portraitLayout}
            onChange={() => setPortraitLayout(!portraitLayout)}
          />
          <Typography
            style={{
              color: portraitLayout === true ? "#1565c0" : "#333",
            }}
          >
            Landscape
          </Typography>
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {portraitLayout ? <PortraitOrientation /> : <LandscapeOrientation />}
          <ReactToPrint
            trigger={() => (
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#1976d2",
                  color: "#fff",
                }}
              >
                Print
              </Button>
            )}
            content={() => componentRef.current}
          />
        </div>
      </div>
      <div style={{ margin: "0rem 2rem" }} ref={componentRef}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h4>SNIFF REPORT</h4>
        </div>
        <div>
          <TableContainer>
            <Table
              sx={{ minWidth: 650 }}
              aria-label="simple table"
              classes={{ root: classes.customTable }}
              size="small"
            >
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontSize: "0.6rem" }}>DST Name</TableCell>
                  <TableCell align="center" style={{ fontSize: "0.6rem" }}>
                    CIS Approval Date
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontSize: "0.6rem" }}
                  ></TableCell>
                  <TableCell align="center" style={{ fontSize: "0.6rem" }}>
                    Sponsor
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: "0.6rem" }}>
                    Sector
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: "0.6rem" }}>
                    LTV
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: "0.6rem" }}>
                    UPREIT
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: "0.6rem" }}>
                    Y1 Yield %
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: "0.6rem" }}>
                    Y1 Payout Ratio Before DST Fees
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: "0.6rem" }}>
                    I/O Period
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: "0.6rem" }}>
                    Loan Rate %
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: "0.6rem" }}>
                    Loan Type
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: "0.6rem" }}>
                    Y1 DSCR
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: "0.6rem" }}>
                    Fully Loaded Cap Rate %
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: "0.6rem" }}>
                    Purchase Cap Rate %
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: "0.6rem" }}>
                    Load on Equity (ex-Reserves) %
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: "0.6rem" }}>
                    Load on Purchase (ex-Reserves) %
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: "0.6rem" }}>
                    Initial Reserves % of Offering
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: "0.6rem" }}>
                    Year 5 Reserves Balance % of initial
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: "0.6rem" }}>
                    Pro Forma Rent Growth Assump. %
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: "0.6rem" }}>
                    7 Year Break-even Growth Rate %
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: "0.6rem" }}>
                    MSA Tier 1-3
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows?.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      style={{
                        fontWeight: index === rows.length - 1 ? "bold" : null,
                        fontSize: "0.7rem",
                      }}
                    >
                      {row.name || "-"}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        color: index === rows.length - 1 ? "red" : "#000000de",
                        fontWeight: index === rows.length - 1 ? "bold" : null,
                        fontSize: "0.7rem",
                      }}
                    >
                      {`$ ${row.equity.toLocaleString() || 0}`}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        fontWeight: index === rows.length - 1 ? "bold" : null,
                        fontSize: "0.7rem",
                      }}
                    >
                      {`$ ${Number(row.debt).toLocaleString("en-US") || 0}`}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        fontWeight: index === rows.length - 1 ? "bold" : null,
                        fontSize: "0.7rem",
                      }}
                    >
                      {`$ ${Number(row.total).toLocaleString("en-US") || 0}`}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        fontWeight: index === rows.length - 1 ? "bold" : null,
                        fontSize: "0.7rem",
                      }}
                    >
                      {`${row.ltv || 0}%`}
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: "0.7rem" }}>
                      {row.sector}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        fontWeight: index === rows.length - 1 ? "bold" : null,
                        fontSize: "0.7rem",
                      }}
                    >
                      {`${row.yield || 0}%`}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        fontWeight: index === rows.length - 1 ? "bold" : null,
                        fontSize: "0.7rem",
                      }}
                    >
                      {Number(row.unit).toLocaleString("en-US") || 0}
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: "0.7rem" }}>
                      {row.state}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
  );
};
export default SniffReport;
