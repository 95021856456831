import React, { useState, useContext, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useParams, useNavigate } from "react-router-dom";
import MyContext from "../context/appContext";
import ViewDetails from "../components/viewDstDetails";
import ViewDstYield from "../components/viewDstYield";
import ViewDstProperties from "../components/viewDstProperties";
import ViewDstOffering from "../components/viewDstOffering";
import Button from "@mui/material/Button";
import "../css/styles.css";
import { alpha, styled } from "@mui/material/styles";
import { pink } from "@mui/material/colors";
import Switch from "@mui/material/Switch";
import { toast } from "react-toastify";
import { API } from "aws-amplify";
import Modal from "@mui/material/Modal";
import ViewDstPropertiesThumbnails from "../components/viewDstPropertiesThumbnails";

const PinkSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: pink[600],
    "&:hover": {
      backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: pink[600],
  },
}));

const label = { inputProps: { "aria-label": "Color switch demo" } };

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const style = {
  position: "absolute",
  top: "17%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const Details = () => {
  let { id } = useParams();
  let navigate = useNavigate();
  const [checked, setChecked] = React.useState();
  const [value, setValue] = useState(0);
  const appContext = useContext(MyContext);
  const [selectedDst, setSelectedDst] = useState({});
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  useEffect(() => {
    if (id) {
      getDstById();
    }
  }, [id]);

  const getDstById = async () => {
    try {
      appContext.updateState("loading", true);
      const payload = {
        queryStringParameters: {
          tableName: "dsts_table-dev",
          id: id,
        },
      };
      const res = await API.get("dynamoRW", "/dsts", payload);

      console.log("The Selected dst is.......!", res);
      setSelectedDst(res.Item);
      appContext.updateState("loading", false);
    } catch (error) {
      appContext.updateState("loading", false);
      console.log(error.message);
      toast(`No DST Found! ${error.message}`, { type: "error" });
    } finally {
      setLoading(false);
    }
  };
  console.log(selectedDst, "SELECTED DST");

  const handleStatus = async () => {
    try {
      if (id) {
        const newStatus = !selectedDst.is_active; // Invert the current status
        const params = {
          body: {
            tableName: "dsts_table-dev",
            Key: {
              id: id,
            },
            AttributeUpdates: {
              is_active: {
                Value: newStatus,
                Action: "PUT",
              },
            },
          },
        };
        const res = await API.put("dynamoRW", "/dsts", params);
        setChecked(newStatus);
        toast(`Status changed to ${newStatus ? "Active" : "Inactive"}`, {
          type: "success",
        });

        // Fetch the updated data from DynamoDB
        await getDstById();
      }
    } catch (error) {
      console.log(error.message);
      toast(`Error Updating DST Status! ${error.message}`, { type: "error" });
    }
  };

  const handleDeleteDst = async () => {
    try {
      appContext.updateState("loading", true);
      const params = {
        body: {
          tableName: "dsts_table-dev",
          Key: {
            id: id,
          },
          AttributeUpdates: {
            is_deleted: {
              Value: true,
              Action: "PUT",
            },
          },
        },
      };
      const res = await API.put("dynamoRW", "/dsts", params);
      appContext.updateState("loading", false);
      navigate("/manage-dst");
    } catch (error) {
      appContext.updateState("loading", false);
      console.log(error.message);
      toast(`Error Deleting DST! ${error.message}`, { type: "error" });
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div style={{ padding: "0px 20px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h4>{selectedDst?.basicInfo?.fullLegalNameOfDst}</h4>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "7px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            <Typography
              style={{
                color: selectedDst?.is_active === false ? "#1565c0" : "#333",
              }}
            >
              Inactive
            </Typography>
            <PinkSwitch
              checked={selectedDst?.is_active === true ? true : false}
              onChange={handleStatus}
            />
            <Typography
              style={{
                color: selectedDst?.is_active === true ? "#1565c0" : "#333",
              }}
            >
              Active
            </Typography>
          </div>
          <Button
            onClick={() => navigate(`/print-dst/${id}`)}
            variant="contained"
            sx={{ mt: 3, mb: 2, ml: 5, textTransform: "inherit" }}
          >
            Generate Offering Summary
          </Button>
          <Button
            onClick={() => {
              navigate(`/add-dst/${id}`);
            }}
            type="submit"
            variant="contained"
            sx={{ mt: 3, mb: 2, textTransform: "inherit" }}
          >
            Edit
          </Button>
          <Button
            onClick={handleOpenModal}
            type="submit"
            variant="contained"
            sx={{ mt: 3, mb: 2, textTransform: "inherit" }}
            className="delButton"
          >
            Delete
          </Button>
        </div>
      </div>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Details" />
            <Tab label="Yields" />
            <Tab label="Properties" />
            <Tab label="Offering Summary" />
            <Tab label="Property Listing" />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <ViewDetails selectedDst={selectedDst?.basicInfo || {}} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ViewDstYield selectedDst={selectedDst?.yields || []} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <ViewDstProperties selectedDst={selectedDst?.property || []} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <ViewDstOffering selectedDst={selectedDst?.basicInfo || []} />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <ViewDstPropertiesThumbnails
            selectedDst={selectedDst?.basicInfo || []}
          />
        </TabPanel>
      </Box>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" component="h2">
            Are you sure you want to delete{" "}
            <b>{selectedDst?.basicInfo?.fullLegalNameOfDst}</b>?
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <Button
              onClick={handleDeleteDst}
              type="submit"
              variant="contained"
              sx={{ mt: 2, mb: 2 }}
              className="delButton"
            >
              Delete
            </Button>
            <Button
              onClick={handleCloseModal}
              variant="contained"
              sx={{ m: 1 }}
            >
              Cancel
            </Button>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default Details;
