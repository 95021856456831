import React, { useContext, useState, useEffect } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MyContext from "../context/appContext";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import { API, Amplify, Auth } from "aws-amplify";
import { AppContext } from "../context/appContext";
import awsmobile from "../aws-exports";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

Amplify.configure(awsmobile);
const defaultTheme = createTheme();

const useStyles = makeStyles({
  backgroundScreen: {
    backgroundColor: "#000",
    height: "100vh",
  },
  signinCard: {
    padding: "10px 30px",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  boxAlign: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  LinkText: {
    textDecoration: "none",
    color: "#a48d31",
  },
  signUpText: {
    color: "#fff",
    textAlign: "center",
  },
});

export default function VerficationCode() {
  const classes = useStyles();
  const appContext = useContext(MyContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const { state } = useLocation();
  console.log("The state is:::", state);

  const onVerifyCode = async () => {
    try {
      appContext.updateState("loading", true);
      const confirmResult = await Auth.confirmSignUp(
        state?.username,
        verificationCode
      );
      try {
        const res = await API.post("signUpAuthentication", "/signUpAuth", {
          body: {
            tableName: "altsnotifications-dev",
            Item: {
              id: state?.username,
              userEmail: state?.username,
              username: state?.name,
              status: false,
              role: state?.role,
            },
          },
        });
        navigate("/login", { state: { profile_name: state?.name } });
        appContext.updateState("loading", false);
        toast(`New user created successfully`, { type: "success" });
      } catch (error) {
        console.log(
          "The error  of send data to db after verification is::",
          error
        );
      }
    } catch (error) {
      appContext.updateState("loading", false);
      console.log("Code Error", error.message);
      toast(error.message, { type: "error" });
    } finally {
      setLoading(false);
    }
  };
  const onResendCode = async () => {
    try {
      appContext.updateState("loading", true);
      const confirmResult = await Auth.resendSignUp(state?.username);
      console.log("confirmResult", confirmResult);
      toast(`Code Resent to ${state?.username}`, { type: "success" });
    } catch (error) {
      appContext.updateState("loading", false);
      console.log("Resend Code Error", error.message);
      toast(error.message, { type: "error" });
    } finally {
      appContext.updateState("loading", false);
    }
  };
  return (
    <ThemeProvider theme={defaultTheme}>
      <Container
        component="main"
        className={classes.backgroundScreen}
        style={{ maxWidth: "100%" }}
      >
        <CssBaseline />
        <Box className={classes.boxAlign}>
          <div className={classes.signinCard}>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              <img src="/faviconPNG.png" style={{ height: "30px" }} />
            </Typography>
            <Box component="form" noValidate>
              <div className="login-field">
                <TextField
                  value={verificationCode}
                  className="login-email-field"
                  size="small"
                  margin="normal"
                  name="verficationcode"
                  placeholder="Verfication Code"
                  type="text"
                  id="verficationcode"
                  onChange={(e) => setVerificationCode(e.target.value)}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  color: "white",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="text"
                  sx={{
                    borderRadius: 0,
                    textTransform: "capitalize",
                    color: "#a48d31",
                    fontSize: "1rem",
                  }}
                  onClick={onResendCode}
                >
                  Resend code?
                </Button>
              </div>

              <div className="login-signin-btn">
                <Button
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 1,
                    borderRadius: 0,
                    textTransform: "capitalize",
                    padding: "5px 25px",
                  }}
                  onClick={onVerifyCode}
                >
                  Confirm
                </Button>
              </div>
            </Box>
          </div>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
