import React from "react";

const tableCellStyle = {
  // fontSize: "0.8rem",
  padding: "10px 2px",
  border: "1px solid #656565",
  textAlign: "center",
};

const tableHeading = {
  // fontSize: "0.8rem",
  padding: "10px 2px",
  border: "1px solid #656565",
  textAlign: "center",
  backgroundColor: "#1d1d1d",
  color: "#fff",
};

const printStyles = `
  @media print {
    body {
      font-size: 12pt;
      margin: 0;
      padding: 0;
    }

    .print-table {
      font-size: 0.5rem;
    }

    table.print-table {
      font-size: 0.5rem !important; 
      width: 100%;
      border-collapse: collapse;
      overflow: hidden; /* Prevent table from scrolling */
    }

    .print-table-container {
      border: 1px solid black;
      border-radius: 1px;
      margin: 0.5rem 0rem;
      width: 100%;
      overflow-x: auto;
    }

    .print-table-row:last-child {
      page-break-inside: avoid;
    }
    .property-web {
      font-size: 0.58rem;
    }
    h4 {
      font-size: 0.67rem;
    }
    p {
      font-size: 0.6rem;
    }
    h3 {
      font-size: 0.8rem;
    }
    li {
      font-size: 0.7rem;
    }
    div {
      font-size: 0.7rem;
    }

    /* Landscape styles for DstDetailPrint3 */
  //   .landscape-view {
  //     width: 100vh; /* Swap width and height for landscape */
  //     height: 100vw;
  //     transform: rotate(-90deg) translateX(-100%);
  //     transform-origin: top left;
  //     page-break-after: always;
  //   }
  // }
`;

function DstDetailPrint3({ findDst }) {
  let hasCommercial = false;
  let properties = findDst?.property;
  for (let i = 0; i < properties?.length; i++) {
    if (properties[i].propertyTypeofDST === "Commercial") {
      properties[i].state = true;
      hasCommercial = true;
      break; // Assuming you want to stop after finding the first occurrence
    }
  }
  const rows = findDst?.property?.map((pro) => ({
    name: pro.propertyName || "-",
    tenant: pro.primaryTenant || "-",
    msa: pro.msa || "-",
    lease: pro.leaseType || "-",
    street: pro.streetAddress || "-",
    city: `${`${pro.city}, ${pro.states}, ${pro.zip}`}` || "-",
    sector: pro.sector || "-",
    tenantCredit: pro.tenantCreditRating || "-",
    units: pro.totalUnits || "-",
    buildings: pro.NumberOfBuildings || "-",
    style: pro.style || "-",
    class: pro.class || "-",
    totalsf: Number(pro.totalSf).toLocaleString("en-US") || "-",
  }));

  return (
    <div style={{ margin: "2rem 0rem" }} className="landscape-view">
      <style>{printStyles}</style>
      <div style={{ display: "flex", gap: "2rem", margin: "0.5rem 0rem" }}>
        <h4 style={{ margin: "0px", fontSize: "0.9rem" }}>PORTFOLIO SUMMARY</h4>
        <p
          style={{ margin: "0px", fontSize: "0.9rem" }}
        >{`Number of Properties: ${rows?.length}`}</p>
      </div>

      <div>
        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            border: "1px solid #656565",
          }}
          className="print-table"
        >
          <thead>
            <tr>
              <th style={tableHeading}>
                <b>Property Name</b>
              </th>
              <th style={tableHeading}>
                <b>Primary Tenant</b>
              </th>
              <th style={tableHeading}>
                <b>MSA</b>
              </th>
              <th style={tableHeading}>
                <b>Lease Type</b>
              </th>
              <th style={tableHeading}>
                <b>Street Address</b>
              </th>
              <th style={tableHeading}>
                <b>City, State, ZIP</b>
              </th>
              <th style={tableHeading}>
                <b>Sector</b>
              </th>
              <th style={tableHeading}>
                <b>Tenant Credit Rating</b>
              </th>
              <th style={tableHeading}>
                <b>Total Units</b>
              </th>
              <th style={tableHeading}>
                <b>No. of Buildings</b>
              </th>
              <th style={tableHeading}>
                <b>Style</b>
              </th>
              <th style={tableHeading}>
                <b>Class</b>
              </th>
              <th style={tableHeading}>
                <b>Total SF</b>
              </th>
            </tr>
          </thead>
          <tbody>
            {rows?.map((row) => (
              <tr key={row.name}>
                <td style={tableCellStyle}>{row.name}</td>
                <td style={tableCellStyle}>{row.tenant}</td>
                <td style={tableCellStyle}>{row.msa}</td>
                <td style={tableCellStyle}>{row.lease}</td>
                <td style={tableCellStyle}>{row.street}</td>
                <td style={tableCellStyle}>{row.city}</td>
                <td style={tableCellStyle}>{row.sector}</td>
                <td style={tableCellStyle}>{row.tenantCredit}</td>
                <td style={tableCellStyle}>{row.units}</td>
                <td style={tableCellStyle}>{row.buildings}</td>
                <td style={tableCellStyle}>{row.style}</td>
                <td style={tableCellStyle}>{row.class}</td>
                <td style={tableCellStyle}>{row.totalsf}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default DstDetailPrint3;
