import React, { useState, useRef, useEffect } from "react";
import {
  Card,
  Grid,
  Box,
  Button,
  InputAdornment,
  TextField,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { addMonths, format } from "date-fns";
import ReactToPrint from "react-to-print";

const tableCellStyle = {
  padding: "6px 0px",
  border: "1px solid #656565",
  textAlign: "center",
};

const tableHeading = {
  padding: "6px 0px",
  border: "1px solid #656565",
  textAlign: "center",
  backgroundColor: "#1d1d1d",
  color: "#fff",
};

const printStyles = `
  @media print {
    @page {
      margin: 0.3cm;
    }

    body {
      font-size: 12pt;
      margin: 0;
      padding: 0;
    }
    .hide-in-print {
      display: none !important;
    }

    .print-table {
      font-size: 0.7rem;
    }

    table.print-table {
      font-size: 0.7rem !important; 
      width: 100%;
      border-collapse: collapse;
      overflow: hidden; /* Prevent table from scrolling */
    }

    .print-table-container {
      border: 1px solid black;
      border-radius: 1px;
      margin: 0.5rem 0rem;
      width: 100%;
      overflow-x: auto;
    }

    .print-table-row:last-child {
      page-break-inside: avoid;
    }
    h4 {
      font-size: 0.7rem;
    }
    p {
      font-size: 0.7rem;
    }
    h3 {
      font-size: 1rem;
    }
    li {
      font-size: 0.7rem;
    }
    div {
      font-size: 0.7rem;
    }

    /* Landscape styles for DstDetailPrint3 */
  //   .landscape-view {
  //     width: 100vh; /* Swap width and height for landscape */
  //     height: 100vw;
  //     transform: rotate(-90deg) translateX(-100%);
  //     transform-origin: top left;
  //     page-break-after: always;
  //   }
  // }
`;

function LoanCalculator() {
  const componentRef = useRef();
  const { state } = useLocation();
  const loanDataFromState = state?.formData;
  const [loading, setLoading] = useState(false);
  const [loanData, setLoanData] = useState();
  const [currentDate, setCurrentDate] = useState("");
  const [balanceDetail, setBalanceDetail] = useState("");
  const [principalDetail, setPrincipalDetail] = useState("");

  useEffect(() => {
    if (loanDataFromState && typeof loanDataFromState === "object") {
      setLoanData(loanDataFromState);
    }
  }, [loanDataFromState]);

  console.log(loanData, "loanData");

  // Removing extra symbols and commas from the values
  useEffect(() => {
    if (loanData) {
      const dynamicValues = {
        allOther: loanData.allOther,
        amountBorrowed: loanData.amountBorrowed,
        OtherMisc: loanData.OtherMisc,
        adjustedbasis: loanData.adjustedbasis,
        annualPropertyTax: loanData.annualPropertyTax,
        associationDues: loanData.associationDues,
        bookkeeping: loanData.bookkeeping,
        capexSincePurchase: loanData.capexSincePurchase,
        depreciationClaimed: loanData.depreciationClaimed,
        eachUnitVacany: loanData.eachUnitVacany,
        estimatedGrossValue: loanData.estimatedGrossValue,
        expectedSale: loanData.expectedSale,
        facilityMaintenance: loanData.facilityMaintenance,
        forwardlosses: loanData.forwardlosses,
        grossRent: loanData.grossRent,
        hardscaping: loanData.hardscaping,
        hardscapingYears: loanData.hardscapingYears,
        hoaFee: loanData.hoaFee,
        hvac: loanData.hvac,
        hvacYears: loanData.hvacYears,
        interestRate: loanData.interestRate,
        issurance: loanData.issurance,
        landscape: loanData.landscape,
        legal: loanData.legal,
        loanBalance: loanData.loanBalance,
        loanTerms: loanData.loanTerms,
        majorRepairs: loanData.majorRepairs,
        marketing: loanData.marketing,
        mortgagepayment: loanData.mortgagepayment,
        personalhoursSpent: loanData.personalhoursSpent,
        plumbing: loanData.plumbing,
        plumbingYears: loanData.plumbingYears,
        propertyManagement: loanData.propertyManagement,
        principal: loanData.principal,
        purchaseAmount: loanData.purchaseAmount,
        reimbursedUtility: loanData.reimbursedUtility,
        reimbursements: loanData.reimbursements,
        roof: loanData.roof,
        roofYears: loanData.roofYears,
        security: loanData.security,
        structural: loanData.structural,
        structuralYears: loanData.structuralYears,
        tenants: loanData.tenants,
        trash: loanData.trash,
        unitTurnOver: loanData.unitTurnOver,
        water: loanData.water,
      };

      updateNumericFields(loanData, dynamicValues);
    }
  }, [loanData]);

  function updateNumericFields(dataObject, updates) {
    if (dataObject) {
      const updatedLoanData = { ...dataObject };
      let hasChanges = false;

      for (const fieldName in updates) {
        if (updates.hasOwnProperty(fieldName)) {
          const newValue = updates[fieldName];
          const cleanedValue = newValue?.toString().replace(/[^0-9.]/g, "");

          if (dataObject[fieldName] !== cleanedValue) {
            updatedLoanData[fieldName] = cleanedValue;
            hasChanges = true;
          }
        }
      }

      if (hasChanges) {
        setLoanData(updatedLoanData);
      }
    } else {
      console.error("Invalid data structure.");
    }
  }

  console.log("after setting", loanData);

  // fields calculations
  const startDate = loanData?.refinanceDate
    ? loanData?.refinanceDate
    : loanData?.purchaseDate;
  const interestRatePercent = +loanData?.interestRate / 100;
  const r = interestRatePercent / 12;
  const numOfMonths = +loanData?.loanTerms * 12;
  const X = Math.pow(1 + r, numOfMonths) - 1;
  const forY = Math.pow(1 + r, numOfMonths);
  const Y = r * forY;
  const Z = X / Y;
  const monthlyPayment = +loanData?.amountBorrowed / Z;
  const totalCost = monthlyPayment * (+loanData?.loanTerms * 12);
  const ammountBorrow = +loanData?.amountBorrowed;
  const totalInterest = totalCost - ammountBorrow;

  // Columns calcultion based ammount borrowed
  const rows = [];
  let currentBalance = ammountBorrow;

  for (let index = 0; index < numOfMonths; index++) {
    const paymentDate = addMonths(startDate, index + 1);
    const beginningBalance = currentBalance;
    const interest =
      (beginningBalance * interestRatePercent * +loanData?.loanTerms) /
      numOfMonths;
    const principal = monthlyPayment - interest;
    const endingBalance = beginningBalance - principal;
    const selectedDate = new Date(currentDate);
    const rowPaymentDate = new Date(paymentDate);

    rows.push({
      serial: `${index + 1}`,
      paymentDate: format(paymentDate, "MM/dd/yyyy"),
      beginningBalance: `$ ${
        Number(beginningBalance.toFixed(2)).toLocaleString("en-US") || 0
      }`,
      payment: `$ ${
        Number(monthlyPayment.toFixed(2)).toLocaleString("en-US") || 0
      }`,
      principal: `$ ${
        Number(principal.toFixed(2)).toLocaleString("en-US") || 0
      }`,
      interest: `$ ${Number(interest.toFixed(2)).toLocaleString("en-US") || 0}`,
      endingBalance: `$ ${
        Number(endingBalance.toFixed(2)).toLocaleString("en-US") || 0
      }`,
      paymentMonth:
        selectedDate >= rowPaymentDate &&
        selectedDate < addMonths(rowPaymentDate, 1)
          ? 1
          : 0,
    });

    currentBalance = endingBalance;
  }

  useEffect(() => {
    const x = rows.find((y) => y.paymentMonth === 1);
    if (x) {
      setBalanceDetail(x.beginningBalance);
      setPrincipalDetail(x.principal);
    } else {
      setBalanceDetail(null);
      setPrincipalDetail(null);
    }
  }, [rows]);

  const newDate1 = new Date(startDate);
  const formattedStartDate = newDate1.toLocaleDateString("en-US");

  const newDate2 = new Date(currentDate);
  const formattedCurrentDate = newDate2.toLocaleDateString("en-US");

  const handleDateChange = (newDate) => {
    const updatedData = { ...loanData, reportDate: newDate };
    setLoanData(updatedData);
    setCurrentDate(newDate)
  };

  return (
    <div style={{ margin: "2rem" }} className="landscape-view">
      <style>{printStyles}</style>
      <Grid container spacing={2}>
        <Card
          style={{
            width: "100%",
            marginTop: "1rem",
            marginLeft: "1rem",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <ReactToPrint
              trigger={() => (
                <Button
                  variant="contained"
                  style={{
                    marginTop: "2rem",
                    marginRight: "2rem",
                    backgroundColor: "#1976d2",
                    color: "#fff",
                  }}
                >
                  Print
                </Button>
              )}
              content={() => componentRef.current}
            />
          </div>
          <div style={{ padding: "0rem 2.5rem" }} ref={componentRef}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h3>Simple Loan Amortization Calculator</h3>
              <div>
                <TextField
                  size="small"
                  className="hide-in-print"
                  margin="normal"
                  fullWidth
                  name="currentDate"
                  type="date"
                  style={{ margin: "5px" }}
                  value={loanData?.reportDate}
                  onChange={(e) => handleDateChange(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Current Date"
                />
              </div>
            </div>
            <Grid container style={{ padding: "2rem 0rem" }} spacing={2}>
              <Grid item xs={3.7} className="Grid-Gap">
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <div>
                    <p
                      style={{
                        margin: "0px",
                        marginBottom: "0.2rem",
                      }}
                    >
                      <b>Loan Amount</b>
                    </p>
                    <p
                      style={{
                        margin: "0px",
                        marginBottom: "0.2rem",
                      }}
                    >
                      <b>Annual Interest Rate</b>
                    </p>
                    <p
                      style={{
                        margin: "0px",
                        marginBottom: "0.2rem",
                      }}
                    >
                      <b>Loan Period in Years</b>
                    </p>
                    <p
                      style={{
                        margin: "0px",
                        marginBottom: "0.2rem",
                      }}
                    >
                      <b>Start Date of Loan</b>
                    </p>
                  </div>
                  <div>
                    <p
                      style={{
                        margin: "0px",
                        marginBottom: "0.2rem",
                      }}
                    >
                      {`$ ${
                        Number(ammountBorrow.toFixed(2)).toLocaleString(
                          "en-US"
                        ) || 0
                      }`}
                    </p>
                    <p
                      style={{
                        margin: "0px",
                        marginBottom: "0.2rem",
                      }}
                    >
                      {`${Number(loanData?.interestRate) || 0}%`}
                    </p>
                    <p
                      style={{
                        margin: "0px",
                        marginBottom: "0.2rem",
                      }}
                    >
                      {loanData?.loanTerms}
                    </p>
                    <p
                      style={{
                        margin: "0px",
                        marginBottom: "0.2rem",
                      }}
                    >
                      {startDate === "" ? "N/A" : formattedStartDate}
                    </p>
                  </div>
                </div>
              </Grid>
              <Grid item xs={0.3} className="Grid-Gap"></Grid>
              <Grid item xs={3.7} className="Grid-Gap">
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <div>
                    <p
                      style={{
                        margin: "0px",
                        marginBottom: "0.2rem",
                      }}
                    >
                      <b>Monthly Payment</b>
                    </p>
                    <p
                      style={{
                        margin: "0px",
                        marginBottom: "0.2rem",
                      }}
                    >
                      <b>Number of Payments</b>
                    </p>
                    <p
                      style={{
                        margin: "0px",
                        marginBottom: "0.2rem",
                      }}
                    >
                      <b>Total Interest</b>
                    </p>
                    <p
                      style={{
                        margin: "0px",
                        marginBottom: "0.2rem",
                      }}
                    >
                      <b>Total Cost of Loan</b>
                    </p>
                  </div>
                  <div>
                    <p
                      style={{
                        margin: "0px",
                        marginBottom: "0.2rem",
                      }}
                    >
                      {`$ ${
                        Number(monthlyPayment.toFixed(2)).toLocaleString(
                          "en-US"
                        ) || 0
                      }`}
                    </p>
                    <p
                      style={{
                        margin: "0px",
                        marginBottom: "0.2rem",
                      }}
                    >
                      {+numOfMonths || 0}
                    </p>
                    <p
                      style={{
                        margin: "0px",
                        marginBottom: "0.2rem",
                      }}
                    >
                      {`$ ${
                        Number(totalInterest.toFixed(2)).toLocaleString(
                          "en-US"
                        ) || 0
                      }`}
                    </p>
                    <p
                      style={{
                        margin: "0px",
                        marginBottom: "0.2rem",
                      }}
                    >
                      {`$ ${
                        Number(totalCost.toFixed(2)).toLocaleString("en-US") ||
                        0
                      }`}
                    </p>
                  </div>
                </div>
              </Grid>
              <Grid item xs={0.3} className="Grid-Gap"></Grid>
              <Grid item xs={3.7} className="Grid-Gap">
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <div>
                    <p
                      style={{
                        margin: "0px",
                        marginBottom: "0.2rem",
                      }}
                    >
                      <b>Current Date</b>
                    </p>
                    <p
                      style={{
                        margin: "0px",
                        marginBottom: "0.2rem",
                      }}
                    >
                      <b>Current Balance</b>
                    </p>
                    <p
                      style={{
                        margin: "0px",
                        marginBottom: "0.2rem",
                      }}
                    >
                      <b>Monthly Principal</b>
                    </p>
                  </div>
                  <div>
                    <p
                      style={{
                        margin: "0px",
                        marginBottom: "0.2rem",
                      }}
                    >
                      {currentDate === "" ? "N/A" : formattedCurrentDate}
                    </p>
                    <p
                      style={{
                        margin: "0px",
                        marginBottom: "0.2rem",
                      }}
                    >
                      {balanceDetail || "-"}
                    </p>
                    <p
                      style={{
                        margin: "0px",
                        marginBottom: "0.2rem",
                      }}
                    >
                      {principalDetail || "-"}
                    </p>
                  </div>
                </div>
              </Grid>
            </Grid>
            <div>
              <table
                style={{
                  width: "100%",
                  borderCollapse: "collapse",
                }}
                className="print-table"
              >
                <thead>
                  <tr>
                    <th style={tableHeading}>No.</th>
                    <th style={tableHeading}>Payment Date</th>
                    <th style={tableHeading}>Beginning Balance</th>
                    <th style={tableHeading}>Payment</th>
                    <th style={tableHeading}>Principal</th>
                    <th style={tableHeading}>Interest</th>
                    <th style={tableHeading}>Ending Balance</th>
                    <th style={tableHeading}>Current Payment Month (0 or 1)</th>
                  </tr>
                </thead>
                <tbody>
                  {rows?.map((row) => (
                    <tr key={row.name}>
                      <td style={tableCellStyle}>{row.serial}</td>
                      <td style={tableCellStyle}>{row.paymentDate}</td>
                      <td style={tableCellStyle}>{row.beginningBalance}</td>
                      <td style={tableCellStyle}>{row.payment}</td>
                      <td style={tableCellStyle}>{row.principal}</td>
                      <td style={tableCellStyle}>{row.interest}</td>
                      <td style={tableCellStyle}>{row.endingBalance}</td>
                      <td style={tableCellStyle}>{row.paymentMonth}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </Card>
      </Grid>
    </div>
  );
}

export default LoanCalculator;
