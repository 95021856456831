import React from "react";
import MyContext from "./appContext";
import tokenStorage from "../services/tokenStorage";
import { setAxiosAuthorizationHeader } from "../axiosConfig";
import Loader from "../components/centerLoader";

class MyProvider extends React.Component {
  state = {
    user: {},
    // dsts:[],
    loading: false,
  };
  componentDidMount() {
    this.setState({ user: tokenStorage.getUserInfo() });
    this.setTokensFromLocalStorage(tokenStorage.getToken());
    const dsts = localStorage.getItem("dsts");
    if (dsts) {
      const parseDsts = JSON.parse(dsts);
      console.log(parseDsts, "ParseDSts");
      this.setState({ dsts: parseDsts });
    }
  }

  setTokensFromLocalStorage = async (token) => {
    if (token) {
      await setAxiosAuthorizationHeader(token);
    }
  };

  updateState = async (name, value) => {
    this.setState({ ...this.state, [name]: value });
    if (name === "dsts") {
      const newDsts = JSON.stringify(value);
      await localStorage.setItem("dsts", newDsts);
    }
  };

  render() {
    return (
      <MyContext.Provider
        value={{
          state: this.state,
          updateState: this.updateState,
          setTokensFromLocalStorage: this.setTokensFromLocalStorage,
        }}
      >
        {this.props.children}
        {this.state.loading && <Loader />}
      </MyContext.Provider>
    );
  }
}

export default MyProvider;
