import React, { useState, useRef } from "react";
import {
  Grid,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import ReactToPrint from "react-to-print";
import { Button, Paper } from "@material-ui/core";
import USAMap from "react-usa-map";
import { alpha, makeStyles, styled } from "@material-ui/core/styles";
import {
  LandscapeOrientation,
  PortraitOrientation,
} from "../../utills/landscapeOrientation";
import { pink } from "@mui/material/colors";

const useStyles = makeStyles({
  customTable: {
    "& .MuiTableCell-sizeSmall": {
      padding: "10px 0px 10px 16px",
    },
  },
});

const PinkSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: pink[600],
    "&:hover": {
      backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: pink[600],
  },
}));

const IllustrationReport = () => {
  const classes = useStyles();
  const componentRef = useRef();
  const companyName = localStorage.getItem("companyName");
  const { state } = useLocation();
  const tableRows = state?.tableRows;
  const otherInfo = state?.otherInfo;
  const [portraitLayout, setPortraitLayout] = useState(false);
  const mapFillColor = "#212A3E";
  const statesCustomConfig = () => {
    console.log("The state count is........0", stateCount);
    return stateCount;
  };

  const cleanNumeric = (value) => {
    if (typeof value !== "string") {
      value = String(value);
    }
    return parseFloat(value.replace(/[^0-9.]/g, ""));
  };
  let statesArray = [];
  const rows = tableRows?.map((row) => {
    let stateValue = Array.isArray(row.state)
      ? row.state.join(", ")
      : row.state;
    if (stateValue && stateValue !== "N/A") {
      // Split the state values by comma and trim whitespace
      let splitStates = stateValue.split(",").map((state) => state.trim());
      // Push each individual state value to statesArray
      statesArray.push(...splitStates);
    }

    return {
      name: row.name,
      equity: cleanNumeric(row.equity),
      debt: Math.round(cleanNumeric(row.debt)),
      total: Math.round(cleanNumeric(row.total)),
      ltv: +row.ltv,
      sector: row.sector,
      yield: cleanNumeric(row.yield),
      unit: cleanNumeric(row.unit),
      state: stateValue,
    };
  });

  // To remove duplicates, convert statesArray to Set and back to Array
  const uniqueStatesArray = [...new Set(statesArray)];
  let stateCount = {};
  for (var i = 0; i < statesArray.length; i++) {
    stateCount[statesArray[i]] = {
      count: 1 + (stateCount[statesArray[i]]?.count || 0),
      fill: mapFillColor,
    };
  }

  const columnTotals = {
    equity: cleanNumeric(
      rows?.reduce((total, row) => +total + cleanNumeric(row.equity), 0)
    ),
    debt: cleanNumeric(
      rows?.reduce((total, row) => +total + cleanNumeric(row.debt), 0)
    ),
    total: cleanNumeric(
      rows?.reduce((total, row) => +total + cleanNumeric(row.total), 0)
    ),
    ltv: +(
      (cleanNumeric(
        rows?.reduce((total, row) => +total + cleanNumeric(row.debt), 0)
      ) /
        cleanNumeric(
          rows?.reduce((total, row) => +total + cleanNumeric(row.total), 0)
        )) *
      100
    ).toFixed(2),
    yield: tableRows
      ?.reduce((total, row) => {
        return total + cleanNumeric(row.equity) * cleanNumeric(row.yield);
      }, 0)
      .toFixed(2),
    unit: cleanNumeric(
      rows?.reduce((total, row) => +total + cleanNumeric(row.unit), 0)
    ),
  };

  const totalRow = {
    name: "Portfolio Results (Weighted):",
    equity: +columnTotals.equity,
    debt: +columnTotals.debt,
    total: +columnTotals.total,
    ltv: +columnTotals.ltv,
    yield:
      +columnTotals.yield / +columnTotals.equity
        ? (+columnTotals.yield / +columnTotals.equity).toFixed(2)
        : 0,
    unit: +columnTotals.unit,
  };

  rows?.push(totalRow);

  const newDate = new Date(otherInfo?.date);
  const formattedDate = newDate.toLocaleDateString("en-US");

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          columnGap: "1.5rem",
          marginTop: "2rem",
          marginRight: "2rem",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          <Typography
            style={{ color: portraitLayout === false ? "#1565c0" : "#333" }}
          >
            Portrait
          </Typography>
          <PinkSwitch
            checked={!portraitLayout}
            onChange={() => setPortraitLayout(!portraitLayout)}
          />
          <Typography
            style={{
              color: portraitLayout === true ? "#1565c0" : "#333",
            }}
          >
            Landscape
          </Typography>
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {portraitLayout ? <PortraitOrientation /> : <LandscapeOrientation />}
          <ReactToPrint
            trigger={() => (
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#1976d2",
                  color: "#fff",
                }}
              >
                Print
              </Button>
            )}
            content={() => componentRef.current}
          />
        </div>
      </div>
      <div style={{ margin: "0rem 2rem" }} ref={componentRef}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h4>
            DST PORTFOLIO ILLUSTRATION<sup>(1)</sup>
          </h4>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "end",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "6rem",
              }}
            >
              <h5 style={{ margin: "0px" }}>Prepared for:</h5>
              <p style={{ margin: "0px", fontSize: "0.9rem" }}>
                {otherInfo?.client || "-"}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "2rem",
              }}
            >
              <h5 style={{ margin: "0px" }}>Date:</h5>
              <p style={{ margin: "0px", fontSize: "0.9rem" }}>
                {formattedDate || "-"}
              </p>
            </div>
          </div>
        </div>
        <div>
          <div style={{ display: "flex", gap: "3rem", fontSize: "0.9rem" }}>
            <h5>Client Investment</h5>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: "0.7rem",
              }}
            >
              <h5 style={{ margin: "0px" }}>Equity</h5>
              <p style={{ margin: "0px", fontSize: "0.8rem" }}>
                {`$ ${otherInfo?.equity || 0}`}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: "0.7rem",
              }}
            >
              <h5 style={{ margin: "0px" }}>Debt</h5>
              <p style={{ margin: "0px", fontSize: "0.8rem" }}>
                {`$ ${otherInfo?.debt || 0}`}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: "0.7rem",
              }}
            >
              <h5 style={{ margin: "0px" }}>Total</h5>
              <p style={{ margin: "0px", fontSize: "0.8rem" }}>
                {`$ ${otherInfo?.total || 0}`}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: "0.7rem",
              }}
            >
              <h5 style={{ margin: "0px" }}>LTV</h5>
              <p style={{ margin: "0px", fontSize: "0.8rem" }}>
                {`${otherInfo?.ltv || 0}`}
              </p>
            </div>
          </div>
        </div>
        <div>
          <TableContainer>
            <Table
              sx={{ minWidth: 650 }}
              aria-label="simple table"
              classes={{ root: classes.customTable }}
              size="small"
            >
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontSize: "0.8rem" }}>
                    Proposed Portfolio
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: "0.8rem" }}>
                    Equity
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontSize: "0.8rem" }}
                  ></TableCell>
                  <TableCell align="center" style={{ fontSize: "0.8rem" }}>
                    Debt
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: "0.8rem" }}>
                    Total
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: "0.8rem" }}>
                    LTV
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: "0.8rem" }}>
                    Sector
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: "0.8rem" }}>
                    Current Distribution<sup>(2)</sup>
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: "0.8rem" }}>
                    Units
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: "0.8rem" }}>
                    States
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows?.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      style={{
                        fontWeight: index === rows.length - 1 ? "bold" : null,
                        fontSize: "0.7rem",
                      }}
                    >
                      {row.name || "-"}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        color:
                          index === rows.length - 1 ? "#000000de" : "#000000de",
                        fontWeight: index === rows.length - 1 ? "bold" : null,
                        fontSize: "0.7rem",
                      }}
                    >
                      {`$ ${row.equity.toLocaleString() || 0}`}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        fontWeight: index === rows.length - 1 ? "bold" : null,
                        fontSize: "0.7rem",
                      }}
                    >
                      {`${
                        ((+row.equity / +columnTotals.equity) * 100).toFixed(
                          1
                        ) || 0
                      }%`}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        fontWeight: index === rows.length - 1 ? "bold" : null,
                        fontSize: "0.7rem",
                      }}
                    >
                      {`$ ${Number(row.debt).toLocaleString("en-US") || 0}`}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        fontWeight: index === rows.length - 1 ? "bold" : null,
                        fontSize: "0.7rem",
                      }}
                    >
                      {`$ ${Number(row.total).toLocaleString("en-US") || 0}`}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        fontWeight: index === rows.length - 1 ? "bold" : null,
                        fontSize: "0.7rem",
                      }}
                    >
                      {`${row.ltv || 0}%`}
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: "0.7rem" }}>
                      {row.sector}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        fontWeight: index === rows.length - 1 ? "bold" : null,
                        fontSize: "0.7rem",
                      }}
                    >
                      {`${row.yield || 0}%`}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        fontWeight: index === rows.length - 1 ? "bold" : null,
                        fontSize: "0.7rem",
                      }}
                    >
                      {Number(row.unit).toLocaleString("en-US") || 0}
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: "0.7rem" }}>
                      {row.state}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div style={{ margin: "1rem 0rem" }}>
          <Grid container spacing={2}>
            <Grid item xs={6} className="Grid-Gap">
              <p style={{ margin: "0px", fontSize: "0.5rem" }}>
                (1)Results are not guaranteed and individual results may vary.
                Though offering objectives and pro forma may be based on
                contractual obligations, there is no guarantee that monthly cash
                flow will be achieved or any offering will meet its investment
                objectives. Past performance of a Sponsor and any past offerings
                are not indicative of future results. Please review the PPM for
                full details before making any investments.
                <br />
                (2) Current cash flow is not an assurance of future results and
                does not directly represent investor return. There is no
                guarantee investors will receive distribution or the return of
                their capital. Distributions may be derived from sources other
                than earnings.
                <br />
                <hr />
                <b>
                  Because investor situations and objectives vary this
                  information is not intended to indicate suitability or a
                  recommendation for all investors.{" "}
                </b>{" "}
                <br />
                This is for informational purposes only and does not constitute
                an offer to purchase or sell securitized real estate investments
                and is authorized for use only when accompanies or preceded by
                the applicable Private Placement Memorandum (PPM). Such offers
                are only made through the sponsors PPM which is solely available
                to accredited investors and accredited entities. This should not
                be relied upon as tax or legal advice. Please consult the
                appropriate professional regarding your individual circumstance.
                <br />
                <br />
                Offerings may only be available to accredited investors
                (typically defined as having a $1 million net worth excluding
                primary residence or $200,000 income individually/$300,000
                jointly of the last two years; or have an active Series 7,
                Series 82, or Series 65). Individuals holding a Series 66 do not
                fall under this definition and accredited entities only. If you
                are unsure if you are an accredited investor and/or an
                accredited entity, please verify with your CPA and Attorney.
                <br />
                <br />
                <b>
                  Potential cash flows/returns/appreciation are not guaranteed
                  and could be lower than anticipated. Diversification does not
                  guarantee a profit or protect against a loss in a declining
                  market. It is a method used to help manage investment risk.
                </b>
                <br />
                <br />
                Prospective investors should perform their own due diligence
                carefully and review the “Risk Factors” section of any
                prospectus, private placement memorandum or offering circular
                before considering any investment. There is no assurance that
                the Offering will achieve all or any of its objectives.
                <br />
                <br />
                There are material risks associated with investing in DST
                properties and real estate securities including liquidity,
                tenant vacancies, general market conditions and competition,
                lack of operating history, interest rate risks, the risk of new
                supply coming to market and softening rental rates, general
                risks of owning/operating commercial and multifamily properties,
                short term leases associated with multi-family properties,
                financing risks, potential adverse tax consequences, general
                economic risks, development risks, long hold periods, and
                potential loss of the entire investment principal.
                <br />
                <br />
                Investments in commodities may have greater volatility than
                investments in traditional securities, particularly if the
                instruments involve leverage. The value of commodity-linked
                derivative instruments may be affected by changes in overall
                market movements, commodity index volatility, changes in
                interest rates or factors affecting a particular industry or
                commodity, such as drought, floods, weather, livestock disease,
                embargoes, tariffs and international economic, political and
                regulatory developments. Use of leveraged commodity-linked
                derivatives creates an opportunity for increased return but, at
                the same time, creates the possibility for greater loss.
                <br />
                <br />
                <b>
                  FOR ONE-ON-ONE ACCREDITED INVESTOR USE ONLY. FURTHER
                  DISTRIBUTION IS PROHIBITED
                </b>
                <br />
              </p>
            </Grid>
            <Grid item xs={6} className="Grid-Gap">
              <Grid item xs={12} className="Grid-Gap">
                <div>
                  <USAMap customize={statesCustomConfig()} />
                </div>
              </Grid>
              <Grid item xs={12} className="Grid-Gap">
                <p style={{ margin: "0px", fontSize: "0.5rem" }}>
                  A REIT is a security that sells like a stock on the major
                  exchanges and invests in real estate directly, either through
                  properties or mortgages. REITs receive special tax
                  considerations and typically offer investors high yields, as
                  well as a highly liquid method of investing in real estate.
                  There are risks associated with these types of investments and
                  include but are not limited to the following: Typically, no
                  secondary market exists for the security listed above.
                  Potential difficulty discerning between routine interest
                  payments and principal repayment. Redemption price of a REIT
                  may be worth more or less than the original price paid. Value
                  of the shares in the trust will fluctuate with the portfolio
                  of underlying real estate. Involves risks such as refinancing
                  in the real estate industry, interest rates, availability of
                  mortgage funds, operating expenses, cost of insurance, lease
                  terminations, potential economic and regulatory changes. This
                  is neither an offer to sell nor a solicitation or an offer to
                  buy the securities described herein. The offering is made only
                  by the Prospectus.
                  <br />
                  <br />
                  Securities offered through Concorde Investment Services, LLC
                  (CIS), member FINRA/SIPC. Advisory services offered through
                  Concorde Asset Management, LLC (CAM), an SEC-registered
                  investment adviser. <b>{companyName}</b> is independent of CIS and
                  CAM, all of whom are independent of offering companies listed
                  herein.
                </p>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};
export default IllustrationReport;
