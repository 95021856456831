import React from "react";

export const LandscapeOrientation = () => (
  <style type="text/css">{"@media print{@page {size: landscape}}"}</style>
);

export const PortraitOrientation = () => (
  <style type="text/css">
    {`
        @media print {
          @page {
            size: portrait;
          }
        }
      `}
  </style>
);
