const tokenStorage = (function () {
  function getRole() {
    try {
      let userInfo = JSON.parse(localStorage.getItem("__set"));
      console.log("The userINfo iss,,,,,.,", userInfo.role);
      if (userInfo && userInfo.role) {
        return userInfo.role;
      } else return false;
    } catch (error) {
      return null;
    }
  }

  function getToken() {
    let userInfo = JSON.parse(localStorage.getItem("__set"));

    if (userInfo && userInfo.token) {
      return userInfo.token;
    } else return false;
  }

  function getUserInfo() {
    try {
      let userInfo = JSON.parse(localStorage.getItem("__set"));
      return userInfo;
    } catch (error) {
      return null;
    }
  }

  return {
    getToken,
    getUserInfo,
    getRole,
  };
})();

export default tokenStorage;
