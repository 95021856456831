import React, { useState, useContext, useEffect } from "react";
import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import MyContext from "../context/appContext";
import { toast } from "react-toastify";
import { Storage } from "aws-amplify";
import FileUploadRoundedIcon from "@mui/icons-material/FileUploadRounded";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import { API } from "aws-amplify";
import { useParams } from "react-router-dom";
import CancelIcon from "@material-ui/icons/Cancel";
import { pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const ViewDstPropertiesThumbnails = ({ selectedDst }) => {
  let { id } = useParams();
  const appContext = useContext(MyContext);
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileType, setSelectedFileType] = useState(false);
  const [pdfHistory, setPdfHistory] = useState();
  let userInfo = JSON.parse(localStorage.getItem("__set"));
  const [name, setName] = useState("");
  let userEmail = localStorage.getItem("userEmail");

  useEffect(() => {
    if (id) {
      getDstById();
    }
  }, [id]);

  const getDstById = async () => {
    try {
      appContext.updateState("loading", true);
      const payload = {
        queryStringParameters: {
          tableName: "dsts_table-dev",
          id: id,
        },
      };
      const res = await API.get("dynamoRW", "/dsts", payload);
      console.log("res", res);
      setPdfHistory(res.Item);
      appContext.updateState("loading", false);
    } catch (error) {
      appContext.updateState("loading", false);
      console.log(error.message);
      toast(`No DST Found! ${error.message}`, { type: "error" });
    }
  };

  const formatFileSize = (sizeInBytes) => {
    const kilobyte = 1024;
    const megabyte = kilobyte * 1024;

    if (sizeInBytes < kilobyte) {
      return sizeInBytes + " B";
    } else if (sizeInBytes < megabyte) {
      return (sizeInBytes / kilobyte).toFixed(2) + " KB";
    } else {
      return (sizeInBytes / megabyte).toFixed(2) + " MB";
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      setSelectedFile(file);
      const fileType = file.type;
      if (fileType === "application/pdf") {
        setSelectedFileType(true);
      }
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    const fileType = file.type;
    if (file) {
      setSelectedFile(file);
    } else {
      console.error("Invalid file type. Please drop a PDF file.");
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleRemoveFile = () => {
    setSelectedFile(null);
    setSelectedFileType(false);
    setName("");
  };

  const handleAddData = async () => {
    // Check file extension
    const allowedExtensions = ['.pdf', '.jpg', '.jpeg', '.png', '.bmp'];
    const fileExtension = '.' + selectedFile.name.split('.').pop().toLowerCase();

    if (!allowedExtensions.includes(fileExtension)) {
      toast.error("Invalid file type. Allowed types: PDF, JPG, JPEG, PNG, BMP");
      return;
    }

    if (selectedFileType) {
      if (name === "" || name === null || name.length === 0) {
        toast.error("Please enter name");
        return;
      }
    }
    if (selectedFile) {
      appContext.updateState("loading", true);
      try {
        const key = await uploadImage(
          selectedFile,
          selectedDst.fullLegalNameOfDst
        );
        if (key) {
          await uploadRecord();
        } else {
          console.error("Failed to upload file.");
          toast.error("Failed to upload file.");
        }
      } catch (error) {
        console.error("Error uploading file:", error);
        toast.error("Error uploading file.");
      } finally {
        appContext.updateState("loading", false);
        setSelectedFile(null);
        setSelectedFileType(false);
        setName("");
      }
    }
  };

  const uploadImage = async (file, dstName) => {
    try {
      appContext.updateState("loading", true);

      Storage.configure({
        AWSS3: {
          bucket: process.env.REACT_APP_S3_BUCKET,
          region: process.env.REACT_APP_LAMBDA_REGION,
        },
      });

      if (file instanceof File) {
        const key =
          file.type === "application/pdf"
            ? `${dstName}-thumbnail_pdf-${name}.pdf`
            : `${dstName}-thumbnail.png`;
        console.log("key is according to file type", key);
        const response = await Storage.put(key, file, {
          contentType: "*",
        });

        appContext.updateState("loading", false);
        return response.key;
      } else {
        console.error("Invalid file type:", typeof file);
      }
      appContext.updateState("loading", false);
      return null;
    } catch (error) {
      appContext.updateState("loading", false);
      console.error("Error uploading image:", error);
      return null;
    }
  };

  const uploadRecord = async () => {
    const sizeInString = selectedFile.size.toString();
    const fileJson = {
      fileName: name,
      name: selectedFile.name,
      uploadedBy: userEmail,
      size: sizeInString,
      date: new Date(parseInt(Date.now())).toLocaleDateString(),
    };
    try {
      appContext.updateState("loading", true);
      const params = {
        body: {
          tableName: "dsts_table-dev",
          Key: {
            id: id,
          },
          AttributeUpdates: {
            uploaded_pdf__details: {
              Value: [{ ...fileJson }],
              Action: "ADD",
            },
          },
        },
      };
      const res = await API.put("dynamoRW", "/dsts", params);
      if (res) {
        await getDstById();
      }
      appContext.updateState("loading", false);
      toast.success("File uploaded successfully!");
    } catch (error) {
      appContext.updateState("loading", false);
      console.log(error.message);
      toast(`Error uploading image! ${error.message}`, { type: "error" });
    }
  };

  const deleteRecord = async (file, fileName) => {
    if (!Array.isArray(pdfHistory?.uploaded_pdf__details)) {
      throw new Error("Invalid properties data");
    }

    try {
      appContext.updateState("loading", true);

      // Delete from S3
      const s3Key = fileName ?
        `${selectedDst.fullLegalNameOfDst}-thumbnail_pdf-${fileName}.pdf` :
        `${selectedDst.fullLegalNameOfDst}-thumbnail.png`;

      await Storage.remove(s3Key);

      // Delete from DynamoDB
      const updatedPdfHistory = {
        ...pdfHistory,
        uploaded_pdf__details: pdfHistory.uploaded_pdf__details.filter(
          (pdf) => pdf.name !== file
        ),
      };

      const params = {
        body: {
          tableName: "dsts_table-dev",
          Key: {
            id: id,
          },
          AttributeUpdates: {
            uploaded_pdf__details: {
              Value: updatedPdfHistory.uploaded_pdf__details,
              Action: "PUT",
            },
          },
        },
      };

      const res = await API.put("dynamoRW", "/dsts", params);
      if (res) {
        await getDstById();
      }
      toast.success("File deleted successfully!");
    } catch (error) {
      console.log(error.message);
      toast(`Error deleting file! ${error.message}`, { type: "error" });
    } finally {
      appContext.updateState("loading", false);
      setLoading(false);
    }
  };

  const pdf = pdfHistory;

  return (
    <>
      {!selectedFile ? (
        <>
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              alignSelf: "center",
              margin: "0rem 10rem ",
            }}
          >
            <h4>
              Upload the Executive Summary, PPM and Supplements.
            </h4>
          </div>
          <div
            style={{
              border: "2px dashed #dfdfdf",
              padding: "3rem 2rem",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              margin: "1rem 10rem",
            }}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginBottom: "1rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FileUploadRoundedIcon
                  style={{
                    fontSize: "4rem",
                    backgroundColor: "#c7c7c7",
                    borderRadius: "50%",
                    padding: "0.5rem",
                    color: "#fff",
                    marginBottom: "1.5rem",
                  }}
                />
                <p
                  style={{
                    margin: "0px",
                    fontSize: "1.4rem",
                    color: "#494949",
                  }}
                >
                  <Button
                    component="label"
                    variant="outlined"
                    style={{
                      border: "none",
                      fontSize: "1.3rem",
                      padding: "0px",
                      textTransform: "inherit",
                    }}
                  >
                    Browse
                    <VisuallyHiddenInput
                      type="file"
                      onChange={handleFileChange}
                    />
                  </Button>{" "}
                  or drag files here
                </p>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div
          style={{
            border: "2px dashed #dfdfdf",
            padding: "3rem 2rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            margin: "1rem 10rem",
          }}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <div
              style={{
                marginTop: "0.5rem",
                backgroundColor: "#fbfbf8",
                padding: "0.5rem 2rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                gap: "0.5rem",
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ fontSize: "1.5rem" }}>{selectedFile?.name}</div>
                <p style={{ color: "#717171", margin: "0px" }}>
                  {formatFileSize(selectedFile?.size)}
                </p>
              </div>
              <CancelIcon
                style={{ cursor: "pointer", fontSize: "1.3rem" }}
                onClick={handleRemoveFile}
              />
            </div>
          </div>
        </div>
      )}

      {selectedFileType && (
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center",
            margin: "-1rem 10rem ",
          }}
        >
          <TextField
            size="small"
            margin="normal"
            fullWidth
            style={{
              marginBottom: "2rem",
              marginTop: "2rem",
              width: "25%",
              backgroundColor: "#fff",
            }}
            type="text"
            placeholder="Please enter file name..."
            value={name}
            onChange={(event) => setName(event.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start"></InputAdornment>
              ),
            }}
          />
        </div>
      )}
      {selectedFile ? (
        <div
          style={{
            width: "100%",
            marginTop: "0rem",
            textAlign: "right",
            paddingRight: "10rem",
          }}
        >
          <Button onClick={handleAddData} type="submit" variant="contained">
            Upload
          </Button>
        </div>
      ) : null}

      {pdfHistory?.uploaded_pdf__details?.length > 0 ? (
        <>
          {pdfHistory?.uploaded_pdf__details?.map((pdf, ind) => (
            <div
              style={{
                padding: "0.5rem 2rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "0.5rem",
                margin: "1rem 10rem",
                border: "1px solid #dfdfdf",
              }}
              key={ind}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                <div>
                  <InsertDriveFileOutlinedIcon
                    style={{
                      fontSize: "3.5rem",
                      backgroundColor: "#eaeaea",
                      borderRadius: "5px",
                      padding: "0.5rem",
                      color: "#b1b1b1",
                      marginTop: "0.5rem",
                    }}
                  />
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ display: "flex" }}>
                    {pdf?.fileName?.length > 1 && (
                      <p
                        style={{
                          fontSize: "1.2rem",
                          color: "#2b2b2b",
                          margin: "0px",
                        }}
                      >
                        {`Display Name: ${pdf.fileName}`}
                      </p>
                    )}

                    <p
                      style={{
                        fontSize: "1.2rem",
                        color: "#2b2b2b",
                        margin: "0px",
                        marginLeft: "10px",
                      }}
                    >
                      {`Uploaded On: ${pdf.date}`}
                    </p>
                  </div>

                  <div style={{ display: "flex" }}>
                    <p style={{ color: "#717171", margin: "0px" }}>
                      {`File Name: ${pdf?.name}`}
                    </p>
                    <p
                      style={{
                        color: "#717171",
                        margin: "0px",
                        marginLeft: "10px",
                      }}
                    >
                      Uploaded By: {userInfo?.attributes?.name}
                    </p>
                  </div>
                  <div style={{ display: "flex" }}>
                    <p style={{ color: "#717171", margin: "0px" }}>
                      {formatFileSize(pdf.size)}
                    </p>
                  </div>
                </div>
              </div>
              <IconButton>
                <DeleteIcon
                  style={{ cursor: "pointer", fontSize: "1.3rem" }}
                  onClick={() => deleteRecord(pdf.name, pdf.fileName)}
                />
              </IconButton>
            </div>
          ))}
        </>
      ) : null}
    </>
  );
};

export default ViewDstPropertiesThumbnails;
