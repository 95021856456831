import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MyContext from "../context/appContext";
import { API, Storage } from "aws-amplify";
import { toast } from "react-toastify";
import { Button, ImageList, ImageListItem, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
const PropertyImages = () => {
  let { id } = useParams();
  const appContext = useContext(MyContext);
  const [selectedDst, setSelectedDst] = useState([]);
  const [loading, setLoading] = useState(false);
  const [getImage, setGetImage] = useState([]);
  const [highlightedImageIndex, setHighlightedImageIndex] = useState(null);
  const [featureImage, setFeatureImage] = useState("");
  const [uploaded_pdf__thumbnails, setUploaded_pdf__thumbnails] = useState("");
  const [enlargedImageIndex, setEnlargedImageIndex] = useState(null);

  useEffect(() => {
    if (id) {
      getPropertyData();
      fetchImage();
    }
  }, [id]);
  useEffect(() => {
    if (selectedDst && selectedDst.property) {
      fetchImage();
    }
  }, [selectedDst]);

  const getPropertyData = async () => {
    try {
      appContext.updateState("loading", true);
      const payload = {
        queryStringParameters: {
          tableName: "dsts_table-dev",
          id: id,
        },
      };
      const res = await API.get("dynamoRW", "/dsts", payload);
      setSelectedDst(res.Item);
      appContext.updateState("loading", false);
    } catch (error) {
      appContext.updateState("loading", false);
      console.log(error.message);
      toast(`No DST Found! ${error.message}`, { type: "error" });
    } finally {
      setLoading(false);
    }
  };

  const fetchImage = async () => {
    try {
      appContext.updateState("loading", true);
      if (!selectedDst) {
        console.error("Selected destination is undefined.");
        return;
      }
      if (selectedDst?.property && selectedDst?.property?.length) {
        Storage.configure({
          AWSS3: {
            bucket: process.env.REACT_APP_S3_BUCKET,
            region: process.env.REACT_APP_LAMBDA_REGION,
          },
        });
        const images = await Promise.all(
          selectedDst?.property?.map(async (itm) => {
            if (itm?.images && Array.isArray(itm.images)) {
              return Promise.all(
                itm.images.map(async (img) => {
                  const image = await Storage.get(img);
                  return image;
                })
              );
            } else {
              return [];
            }
          })
        );
        const flattenedImages = images.flat();
        setGetImage(flattenedImages);
      }
      appContext.updateState("loading", false);
    } catch (error) {
      appContext.updateState("loading", false);
      console.error("Error fetching images:", error);
    }
  };

  const handleImageClick = (index) => {
    const imageUrl = getImage[index];
    const imageName = imageUrl.split("/").pop();
    const imageNameWithoutParams = imageName.split("?")[0];
    const decodedImageName = decodeURIComponent(imageNameWithoutParams);
    setFeatureImage(decodedImageName);
    setUploaded_pdf__thumbnails(decodedImageName);
    setHighlightedImageIndex(index);
  };
  
  const handleFeatureImage = async () => {
    try {
      appContext.updateState("loading", true);
      const updatedSelectedDst = { ...selectedDst };
      updatedSelectedDst.featureImage = featureImage;
      updatedSelectedDst.uploaded_pdf__thumbnails = uploaded_pdf__thumbnails;

      const params = {
        body: {
          tableName: "dsts_table-dev",
          Key: {
            id: updatedSelectedDst.id,
          },
          AttributeUpdates: {
            featureImage: {
              Value: featureImage,
              Action: "PUT",
            },
            uploaded_pdf__thumbnails: {
              Value: featureImage,
              Action: "PUT",
            },
          },
        },
      };

      const res = await API.put("dynamoRW", "/dsts", params);
      appContext.updateState("loading", false);
      toast("Image Selected Successfully", { type: "success" });
    } catch (error) {
      appContext.updateState("loading", false);
      console.log(error.message);
      toast(`Error Adding Image! ${error.message}`, {
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };
  const handleImage = (index) => {
    setEnlargedImageIndex(index);
  };

  const handleCloseImage = () => {
    setEnlargedImageIndex(null);
  };

  const delFromS3 = async (url) => {
    try {
      appContext.updateState("loading", true);
      const imageName = url.split("/").pop();
      const imageNameWithoutParams = imageName.split("?")[0];
      const decodedImageName = decodeURIComponent(imageNameWithoutParams);
      await Storage.remove(decodedImageName, {
        level: "public",
        contentType: "image/jpeg",
      });
      const updatedSelectedDst = { ...selectedDst };
      updatedSelectedDst.property.forEach((property) => {
        if (Array.isArray(property.images)) {
          property.images = property.images.filter(
            (image) => image !== decodedImageName
          );
        }
      });
      setSelectedDst(updatedSelectedDst);
      const params = {
        body: {
          tableName: "dsts_table-dev",
          Key: {
            id: id,
          },
          AttributeUpdates: {
            property: {
              Value: updatedSelectedDst.property,
              Action: "PUT",
            },
          },
        },
      };

      const res = await API.put("dynamoRW", "/dsts", params);
      toast("Image Deleted Successfully!", {
        type: "success",
      });
      appContext.updateState("loading", false);
    } catch (err) {
      appContext.updateState("loading", false);
      console.log("Deleting image from S3 error: ", err);
      toast(`Error Deleting Image! ${err.message}`, {
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div>
        {getImage?.length ? (
          <ImageList cols={5}>
            {getImage.map((url, index) => (
              <ImageListItem
                key={index}
                onMouseEnter={() => handleImageClick(index)}
                onMouseLeave={() => {
                  setHighlightedImageIndex(null);
                }}
                sx={{
                  position: "relative",
                  cursor: "pointer",
                }}
              >
                <img
                  onClick={() => handleImage(index)}
                  src={url}
                  alt={`Image ${index}`}
                  style={{
                    height: "200px",
                    filter:
                      highlightedImageIndex === index
                        ? "brightness(60%)"
                        : "none",
                  }}
                />
                {enlargedImageIndex !== null && (
                  <Modal
                    open={enlargedImageIndex !== null}
                    onClose={handleCloseImage}
                    aria-labelledby="enlarged-image-modal"
                    aria-describedby="enlarged-image-description"
                  >
                    <div
                      style={{
                        position: "fixed",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        maxWidth: "90%",
                        maxHeight: "90%",
                        padding: "20px",

                        textAlign: "center",
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          maxWidth: "100%",
                          maxHeight: "90vh",
                        }}
                      >
                        <img
                          src={getImage[enlargedImageIndex]}
                          alt={`Image ${enlargedImageIndex}`}
                          style={{ width: "100%", height: "90vh" }}
                        />
                        <Button
                          onClick={handleCloseImage}
                          style={{
                            position: "absolute",
                            left: "auto",
                            cursor: "pointer",
                          }}
                        >
                          <CloseIcon style={{ color: "white" }} />
                        </Button>
                      </div>
                    </div>
                  </Modal>
                )}
                {highlightedImageIndex === index && (
                  <>
                    <Button
                      onClick={handleFeatureImage}
                      variant="contained"
                      style={{
                        position: "absolute",
                        bottom: "10px",
                        right: "10px",
                        zIndex: 1,
                      }}
                    >
                      Mark Feature
                    </Button>
                    <Button
                      onClick={() => delFromS3(url)}
                      variant="contained"
                      color="error"
                      style={{
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                        zIndex: 1,
                      }}
                    >
                      Delete
                    </Button>
                  </>
                )}
              </ImageListItem>
            ))}
          </ImageList>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "50vh",
            }}
          >
            <p>No image found</p>
          </div>
        )}
      </div>
    </>
  );
};

export default PropertyImages;
