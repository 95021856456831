import React, { useContext, useEffect, useState } from "react";
import { Button, ThemeProvider } from "@material-ui/core";
import { API, Auth } from "aws-amplify";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { toast } from "react-toastify";
import MyContext from "../context/appContext";
import AWS from "aws-sdk";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const ManageUsers = () => {
  const appContext = useContext(MyContext);
  const role = localStorage.getItem("role");
  const [stateList, setStateList] = useState([]);

  const apiKey = process.env.REACT_APP_API_KEY;
  const secret = process.env.REACT_APP_SECRET_KEY;
  const REGION = process.env.REACT_APP_LAMBDA_REGION;
  const Lambda_ARN = process.env.REACT_APP_LAMBDA_ARN;

  useEffect(() => {
    getAccountsForApproval();
  }, []);

  const getAccountsForApproval = async () => {
    try {
      appContext.updateState("loading", true);
      let tablename = "altsnotifications-dev";

      const res = await API.get(
        "dynamoRW",
        `/notifications?tablename=${tablename}`
      );
      const filteredList = res.Items.filter((item) => {
        if (role === "Super Admin") {
          return item.role !== "Investor";
        } else if (role === "Advisor") {
          return item.role === "Investor";
        }
      });
      setStateList(filteredList);
      appContext.updateState("loading", false);
    } catch (error) {
      appContext.updateState("loading", false);
      console.log(error.message);
      toast(`No State Found! ${error.message}`, { type: "error" });
    }
  };

  const onAcceptUserPressed = async (_index) => {
    try {
      appContext.updateState("loading", true);
      let { id, username, userEmail, role } = stateList[_index];
      const approvalAccountId = await Auth.currentAuthenticatedUser();

      const deleteParams = {
        body: {
          tableName: "altsnotifications-dev",
          Key: {
            id: id,
          },
        },
      };
      const deleteRes = await API.del("dynamoRW", "/dsts", deleteParams);

      if (deleteRes.ResponseMetadata.HTTPStatusCode === 200) {
        const addParams = {
          body: {
            tableName: "approvedUsers-dev",
            Item: {
              id: id,
              approvalAccountId: approvalAccountId.username,
              username: username,
              userEmail: userEmail,
              status: true,
              role: role,
            },
          },
        };
        const addRes = await API.post("dynamoRW", "/dsts", addParams);
        if (addRes.ResponseMetadata.HTTPStatusCode === 200) {
          getAccountsForApproval();
          toast("User request approved successfully and notified via email", {
            type: "success",
          });
          await sendApprovalEmail(userEmail);
        }
      }

      appContext.updateState("loading", false);
    } catch (error) {
      appContext.updateState("loading", false);
      toast(`Error saving state tax! ${error.message}`, { type: "error" });
    }
  };

  const sendApprovalEmail = async (userEmail) => {
    appContext.updateState("loading", true);
    try {
      AWS.config.update({
        accessKeyId: apiKey,
        secretAccessKey: secret,
        region: REGION,
      });
      const lambda = new AWS.Lambda();

      const functionArn = Lambda_ARN;

      const payload = {
        recipient: userEmail,
        subject: `Invitation from Altsillustrator`,
        mailBody: `
        We are pleased to inform you that your account has been successfully approved.
        You can now log in to the system to access the features and services available
        `,
      };
      const params = {
        FunctionName: functionArn,
        InvocationType: "RequestResponse",
        Payload: JSON.stringify(payload),
      };

      const response = await lambda.invoke(params).promise();
      appContext.updateState("loading", false);
    } catch (error) {
      appContext.updateState("loading", false);
      toast(error.message, { type: "error" });
    }
  };

  const onDeclineUserPressed = async (_index) => {
    try {
      appContext.updateState("loading", true);
      let { id, username, userEmail, role } = stateList[_index];
      const deleteParams = {
        body: {
          tableName: "altsnotifications-dev",
          Key: {
            id: id,
          },
        },
      };
      const deleteRes = await API.del("dynamoRW", "/dsts", deleteParams);

      if (deleteRes.ResponseMetadata.HTTPStatusCode === 200) {
        const addParams = {
          body: {
            tableName: "approvedUsers-dev",
            Item: {
              id: id,
              username: username,
              userEmail: userEmail,
              status: false,
              role: role,
            },
          },
        };
        const addRes = await API.post("dynamoRW", "/dsts", addParams);
        if (addRes.ResponseMetadata.HTTPStatusCode === 200) {
          getAccountsForApproval();
          toast("User declined successfully!", {
            type: "success",
          });
        }
      }

      appContext.updateState("loading", false);
    } catch (error) {
      appContext.updateState("loading", false);
      toast(`${error.message}`, { type: "error" });
    }
  };

  return (
    <div style={{ padding: "20px 20px" }}>
      <ThemeProvider>
        <div>
          <h3>Approval Requests</h3>
        </div>
        <TableContainer>
          <Table
            style={{ minWidth: 700, margin: "0 auto" }}
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">Name</StyledTableCell>
                <StyledTableCell align="center">Email</StyledTableCell>
                <StyledTableCell align="center">Status</StyledTableCell>
                <StyledTableCell align="center">Approval </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {stateList?.map((row, ind) => (
                <StyledTableRow key={ind}>
                  <StyledTableCell align="center">
                    {row.username}
                  </StyledTableCell>

                  <StyledTableCell align="center">
                    {row.userEmail}
                  </StyledTableCell>
                  <StyledTableCell align="center">Pending</StyledTableCell>
                  <StyledTableCell align="center">
                    <Button
                      onClick={() => onDeclineUserPressed(ind)}
                      variant="contained"
                      style={{
                        marginTop: 3,
                        marginBottom: 2,
                        backgroundColor: "#d32f2f",
                        color: "white",
                        textTransform: "inherit",
                      }}
                    >
                      Decline
                    </Button>
                    <Button
                      onClick={() => onAcceptUserPressed(ind)}
                      variant="contained"
                      style={{
                        marginTop: 3,
                        marginBottom: 2,
                        marginLeft: 5,
                        backgroundColor: "#2e7d32",
                        color: "#fff",
                        textTransform: "inherit",
                      }}
                    >
                      Accept
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </ThemeProvider>
    </div>
  );
};

export default ManageUsers;
