import React, { useState, useRef } from "react";
import {
  Grid,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import ReactToPrint from "react-to-print";
import { Button, Paper } from "@material-ui/core";
import USAMap from "react-usa-map";
import { alpha, makeStyles, styled } from "@material-ui/core/styles";
import {
  LandscapeOrientation,
  PortraitOrientation,
} from "../../utills/landscapeOrientation";
import { pink } from "@mui/material/colors";

const useStyles = makeStyles({
  customTable: {
    "& .MuiTableCell-sizeSmall": {
      padding: "10px 0px 10px 16px",
    },
  },
});

const PinkSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: pink[600],
    "&:hover": {
      backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: pink[600],
  },
}));

const NoiCapRatePrint = () => {
  const classes = useStyles();
  const componentRef = useRef();
  const { state } = useLocation();
  const otherInfo = state?.otherInfo;
  const rows1 = state?.rows1;
  const rows2 = state?.rows2;
  const rows3 = state?.rows3;
  const rows4 = state?.rows4;
  const rows5 = state?.rows5;
  const rows6 = state?.rows6;
  const rows7 = state?.rows7;
  const [portraitLayout, setPortraitLayout] = useState(false);
  
  function removeDollarSign(value) {
    if (typeof value === "string") {
      return value.replace(/\$/g, "");
    }
    return value;
  }

  // Usage
  const offeringEquity = removeDollarSign(otherInfo?.offeringEquity);
  const offeringDebt = removeDollarSign(otherInfo?.offeringDebt);
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          columnGap: "1.5rem",
          marginTop: "2rem",
          marginRight: "2rem",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "1rem",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <Typography
                style={{ color: portraitLayout === false ? "#1565c0" : "#333" }}
              >
                Portrait
              </Typography>
              <PinkSwitch
                checked={!portraitLayout}
                onChange={() => setPortraitLayout(!portraitLayout)}
              />
              <Typography
                style={{
                  color: portraitLayout === true ? "#1565c0" : "#333",
                }}
              >
                Landscape
              </Typography>
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              {portraitLayout ? (
                <PortraitOrientation />
              ) : (
                <LandscapeOrientation />
              )}
              <ReactToPrint
                trigger={() => (
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "#1976d2",
                      color: "#fff",
                    }}
                  >
                    Print
                  </Button>
                )}
                content={() => componentRef.current}
              />
            </div>
          </div>
          <div>
            <h5 style={{ margin: "0.2rem" }}>
              Best print result: Portrait, 40% scale
            </h5>
          </div>
        </div>
      </div>
      <div style={{ margin: "0rem 2rem" }} ref={componentRef}>
        <p style={{ fontSize: "0.8rem" }}>
          This analysis is for illustrative purposes only. There is no assurance
          that a DST will experience NOI changes within this range. Actual NOI
          changes are never linear. There is no assurance that exit
          capitalization rates will fall within this range. There is no
          assurance that at property will be sold within any given timeframe;
          seven years is an arbitrary time period. Starting NOI is a pro forma
          projection. The purpose of this analysis is merely to convey the
          sensitivity of future investment equity upon sale to movements in NOI
          and cap rates. Actual sale expenses will vary -see PPM for disposition
          fees. A positive reserve balance or reduced loan balance at sale could
          slightly augment illustrated return
        </p>
        <div style={{display:"flex", alignItems:"center", gap: "1rem"}}>
          <h5>ASSUMPTIONS:</h5>
          <p style={{ fontSize: "0.8rem" }}>
            No amortization, no return of reserves, 4% sale expenses Net
            proceeds do not include distributions of income
          </p>
        </div>
        <h4>
          NOI/Cap Rate Sensitivity Analysis <br /> 7 Year Hold Before Sale
        </h4>
        <div
          style={{
            display: "flex",
            gap: "2rem",
            marginTop: "2rem",
            fontSize: "0.9rem",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: "0.7rem",
            }}
          >
            <h5 style={{ margin: "0px" }}>Offering Equity</h5>
            <p
              style={{ margin: "0px", fontSize: "0.8rem", textAlign: "center" }}
            >
              {`$ ${offeringEquity}` || "-"}
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: "0.7rem",
            }}
          >
            <h5 style={{ margin: "0px" }}>Offering Debt</h5>
            <p
              style={{ margin: "0px", fontSize: "0.8rem", textAlign: "center" }}
            >
              {`$ ${offeringDebt}` || "-"}
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: "0.7rem",
            }}
          >
            <h5 style={{ margin: "0px" }}>Total Offering Price</h5>
            <p
              style={{ margin: "0px", fontSize: "0.8rem", textAlign: "center" }}
            >
              {`$ ${otherInfo?.total}` || "-"}
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: "0.7rem",
            }}
          >
            <h5 style={{ margin: "0px" }}>Starting NOI From PPM</h5>
            <p
              style={{ margin: "0px", fontSize: "0.8rem", textAlign: "center" }}
            >
              {`$ ${otherInfo?.startingPPM}` || "-"}
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: "0.7rem",
            }}
          >
            <h5 style={{ margin: "0px" }}>All in CAP Rate</h5>
            <p
              style={{ margin: "0px", fontSize: "0.8rem", textAlign: "center" }}
            >
              {`${(+otherInfo?.capRate).toFixed(2)}%` || "-"}
            </p>
          </div>
        </div>
        <div style={{ margin: "2rem 0rem" }}>
          <div style={{ backgroundColor: "#111", padding: "0.3rem 0rem" }}>
            <h4 style={{ color: "#fff", margin: "0px", textAlign: "center" }}>
              Annual NOI Change (+4.5%)
            </h4>
          </div>
          <TableContainer>
            <Table
              sx={{ minWidth: 650 }}
              aria-label="simple table"
              classes={{ root: classes.customTable }}
              size="small"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Cap Rate</TableCell>
                  <TableCell>-50 bps</TableCell>
                  <TableCell>Flat</TableCell>
                  <TableCell>+50 bps</TableCell>
                  <TableCell>+100 bps</TableCell>
                  <TableCell>+150 bps</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows1.map((row, ind) => (
                  <TableRow key={ind}>
                    <TableCell>{row.capRate}</TableCell>
                    <TableCell>
                      {row.minus50 !== "-" ? (
                        <span
                          style={{
                            color:
                              ind === rows1.length - 1
                                ? row.minus50 >= 1.0
                                  ? "green"
                                  : row.minus50 >= 0.9 && row.minus50 < 1.0
                                  ? "#ff9f00"
                                  : row.minus50 < 0.9
                                  ? "red"
                                  : "normal"
                                : "normal",
                            fontWeight:
                              ind === rows1.length - 1 ? "bold" : "normal",
                          }}
                        >
                          {row.minus50}
                        </span>
                      ) : (
                        "-"
                      )}
                    </TableCell>
                    <TableCell>
                      {row.flat !== "-" ? (
                        <span
                          style={{
                            color:
                              ind === rows1.length - 1
                                ? row.flat >= 1.0
                                  ? "green"
                                  : row.flat >= 0.9 && row.flat < 1.0
                                  ? "#ff9f00"
                                  : row.flat < 0.9
                                  ? "red"
                                  : "normal"
                                : "normal",
                            fontWeight:
                              ind === rows1.length - 1 ? "bold" : "normal",
                          }}
                        >
                          {row.flat}
                        </span>
                      ) : (
                        "-"
                      )}
                    </TableCell>
                    <TableCell>
                      {row.plus50 !== "-" ? (
                        <span
                          style={{
                            color:
                              ind === rows1.length - 1
                                ? row.plus50 >= 1.0
                                  ? "green"
                                  : row.plus50 >= 0.9 && row.plus50 < 1.0
                                  ? "#ff9f00"
                                  : row.plus50 < 0.9
                                  ? "red"
                                  : "normal"
                                : "normal",
                            fontWeight:
                              ind === rows1.length - 1 ? "bold" : "normal",
                          }}
                        >
                          {row.plus50}
                        </span>
                      ) : (
                        "-"
                      )}
                    </TableCell>
                    <TableCell>
                      {row.plus100 !== "-" ? (
                        <span
                          style={{
                            color:
                              ind === rows1.length - 1
                                ? row.plus100 >= 1.0
                                  ? "green"
                                  : row.plus100 >= 0.9 && row.plus100 < 1.0
                                  ? "#ff9f00"
                                  : row.plus100 < 0.9
                                  ? "red"
                                  : "normal"
                                : "normal",
                            fontWeight:
                              ind === rows1.length - 1 ? "bold" : "normal",
                          }}
                        >
                          {row.plus100}
                        </span>
                      ) : (
                        "-"
                      )}
                    </TableCell>
                    <TableCell>
                      {row.plus150 !== "-" ? (
                        <span
                          style={{
                            color:
                              ind === rows1.length - 1
                                ? row.plus150 >= 1.0
                                  ? "green"
                                  : row.plus150 >= 0.9 && row.plus150 < 1.0
                                  ? "#ff9f00"
                                  : row.plus150 < 0.9
                                  ? "red"
                                  : "normal"
                                : "normal",
                            fontWeight:
                              ind === rows1.length - 1 ? "bold" : "normal",
                          }}
                        >
                          {row.plus150}
                        </span>
                      ) : (
                        "-"
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div style={{ margin: "2rem 0rem" }}>
          <div style={{ backgroundColor: "#111", padding: "0.3rem 0rem" }}>
            <h4 style={{ color: "#fff", margin: "0px", textAlign: "center" }}>
              Annual NOI Change (+3%)
            </h4>
          </div>
          <TableContainer>
            <Table
              sx={{ minWidth: 650 }}
              aria-label="simple table"
              classes={{ root: classes.customTable }}
              size="small"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Cap Rate</TableCell>
                  <TableCell>-50 bps</TableCell>
                  <TableCell>Flat</TableCell>
                  <TableCell>+50 bps</TableCell>
                  <TableCell>+100 bps</TableCell>
                  <TableCell>+150 bps</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows2.map((row, ind) => (
                  <TableRow key={ind}>
                    <TableCell>{row.capRate}</TableCell>
                    <TableCell>
                      {row.minus50 !== "-" ? (
                        <span
                          style={{
                            color:
                              ind === rows1.length - 1
                                ? row.minus50 >= 1.0
                                  ? "green"
                                  : row.minus50 >= 0.9 && row.minus50 < 1.0
                                  ? "#ff9f00"
                                  : row.minus50 < 0.9
                                  ? "red"
                                  : "normal"
                                : "normal",
                            fontWeight:
                              ind === rows1.length - 1 ? "bold" : "normal",
                          }}
                        >
                          {row.minus50}
                        </span>
                      ) : (
                        "-"
                      )}
                    </TableCell>
                    <TableCell>
                      {row.flat !== "-" ? (
                        <span
                          style={{
                            color:
                              ind === rows1.length - 1
                                ? row.flat >= 1.0
                                  ? "green"
                                  : row.flat >= 0.9 && row.flat < 1.0
                                  ? "#ff9f00"
                                  : row.flat < 0.9
                                  ? "red"
                                  : "normal"
                                : "normal",
                            fontWeight:
                              ind === rows1.length - 1 ? "bold" : "normal",
                          }}
                        >
                          {row.flat}
                        </span>
                      ) : (
                        "-"
                      )}
                    </TableCell>
                    <TableCell>
                      {row.plus50 !== "-" ? (
                        <span
                          style={{
                            color:
                              ind === rows1.length - 1
                                ? row.plus50 >= 1.0
                                  ? "green"
                                  : row.plus50 >= 0.9 && row.plus50 < 1.0
                                  ? "#ff9f00"
                                  : row.plus50 < 0.9
                                  ? "red"
                                  : "normal"
                                : "normal",
                            fontWeight:
                              ind === rows1.length - 1 ? "bold" : "normal",
                          }}
                        >
                          {row.plus50}
                        </span>
                      ) : (
                        "-"
                      )}
                    </TableCell>
                    <TableCell>
                      {row.plus100 !== "-" ? (
                        <span
                          style={{
                            color:
                              ind === rows1.length - 1
                                ? row.plus100 >= 1.0
                                  ? "green"
                                  : row.plus100 >= 0.9 && row.plus100 < 1.0
                                  ? "#ff9f00"
                                  : row.plus100 < 0.9
                                  ? "red"
                                  : "normal"
                                : "normal",
                            fontWeight:
                              ind === rows1.length - 1 ? "bold" : "normal",
                          }}
                        >
                          {row.plus100}
                        </span>
                      ) : (
                        "-"
                      )}
                    </TableCell>
                    <TableCell>
                      {row.plus150 !== "-" ? (
                        <span
                          style={{
                            color:
                              ind === rows1.length - 1
                                ? row.plus150 >= 1.0
                                  ? "green"
                                  : row.plus150 >= 0.9 && row.plus150 < 1.0
                                  ? "#ff9f00"
                                  : row.plus150 < 0.9
                                  ? "red"
                                  : "normal"
                                : "normal",
                            fontWeight:
                              ind === rows1.length - 1 ? "bold" : "normal",
                          }}
                        >
                          {row.plus150}
                        </span>
                      ) : (
                        "-"
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div style={{ margin: "2rem 0rem" }}>
          <div style={{ backgroundColor: "#111", padding: "0.3rem 0rem" }}>
            <h4 style={{ color: "#fff", margin: "0px", textAlign: "center" }}>
              Annual NOI Change (+1.5%)
            </h4>
          </div>
          <TableContainer>
            <Table
              sx={{ minWidth: 650 }}
              aria-label="simple table"
              classes={{ root: classes.customTable }}
              size="small"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Cap Rate</TableCell>
                  <TableCell>-50 bps</TableCell>
                  <TableCell>Flat</TableCell>
                  <TableCell>+50 bps</TableCell>
                  <TableCell>+100 bps</TableCell>
                  <TableCell>+150 bps</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows3.map((row, ind) => (
                  <TableRow key={ind}>
                    <TableCell>{row.capRate}</TableCell>
                    <TableCell>
                      {row.minus50 !== "-" ? (
                        <span
                          style={{
                            color:
                              ind === rows1.length - 1
                                ? row.minus50 >= 1.0
                                  ? "green"
                                  : row.minus50 >= 0.9 && row.minus50 < 1.0
                                  ? "#ff9f00"
                                  : row.minus50 < 0.9
                                  ? "red"
                                  : "normal"
                                : "normal",
                            fontWeight:
                              ind === rows1.length - 1 ? "bold" : "normal",
                          }}
                        >
                          {row.minus50}
                        </span>
                      ) : (
                        "-"
                      )}
                    </TableCell>
                    <TableCell>
                      {row.flat !== "-" ? (
                        <span
                          style={{
                            color:
                              ind === rows1.length - 1
                                ? row.flat >= 1.0
                                  ? "green"
                                  : row.flat >= 0.9 && row.flat < 1.0
                                  ? "#ff9f00"
                                  : row.flat < 0.9
                                  ? "red"
                                  : "normal"
                                : "normal",
                            fontWeight:
                              ind === rows1.length - 1 ? "bold" : "normal",
                          }}
                        >
                          {row.flat}
                        </span>
                      ) : (
                        "-"
                      )}
                    </TableCell>
                    <TableCell>
                      {row.plus50 !== "-" ? (
                        <span
                          style={{
                            color:
                              ind === rows1.length - 1
                                ? row.plus50 >= 1.0
                                  ? "green"
                                  : row.plus50 >= 0.9 && row.plus50 < 1.0
                                  ? "#ff9f00"
                                  : row.plus50 < 0.9
                                  ? "red"
                                  : "normal"
                                : "normal",
                            fontWeight:
                              ind === rows1.length - 1 ? "bold" : "normal",
                          }}
                        >
                          {row.plus50}
                        </span>
                      ) : (
                        "-"
                      )}
                    </TableCell>
                    <TableCell>
                      {row.plus100 !== "-" ? (
                        <span
                          style={{
                            color:
                              ind === rows1.length - 1
                                ? row.plus100 >= 1.0
                                  ? "green"
                                  : row.plus100 >= 0.9 && row.plus100 < 1.0
                                  ? "#ff9f00"
                                  : row.plus100 < 0.9
                                  ? "red"
                                  : "normal"
                                : "normal",
                            fontWeight:
                              ind === rows1.length - 1 ? "bold" : "normal",
                          }}
                        >
                          {row.plus100}
                        </span>
                      ) : (
                        "-"
                      )}
                    </TableCell>
                    <TableCell>
                      {row.plus150 !== "-" ? (
                        <span
                          style={{
                            color:
                              ind === rows1.length - 1
                                ? row.plus150 >= 1.0
                                  ? "green"
                                  : row.plus150 >= 0.9 && row.plus150 < 1.0
                                  ? "#ff9f00"
                                  : row.plus150 < 0.9
                                  ? "red"
                                  : "normal"
                                : "normal",
                            fontWeight:
                              ind === rows1.length - 1 ? "bold" : "normal",
                          }}
                        >
                          {row.plus150}
                        </span>
                      ) : (
                        "-"
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div style={{ margin: "2rem 0rem" }}>
          <div style={{ backgroundColor: "#111", padding: "0.3rem 0rem" }}>
            <h4 style={{ color: "#fff", margin: "0px", textAlign: "center" }}>
              Annual NOI Change (Flat)
            </h4>
          </div>
          <TableContainer>
            <Table
              sx={{ minWidth: 650 }}
              aria-label="simple table"
              classes={{ root: classes.customTable }}
              size="small"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Cap Rate</TableCell>
                  <TableCell>-50 bps</TableCell>
                  <TableCell>Flat</TableCell>
                  <TableCell>+50 bps</TableCell>
                  <TableCell>+100 bps</TableCell>
                  <TableCell>+150 bps</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows4.map((row, ind) => (
                  <TableRow key={ind}>
                    <TableCell>{row.capRate}</TableCell>
                    <TableCell>
                      {row.minus50 !== "-" ? (
                        <span
                          style={{
                            color:
                              ind === rows1.length - 1
                                ? row.minus50 >= 1.0
                                  ? "green"
                                  : row.minus50 >= 0.9 && row.minus50 < 1.0
                                  ? "#ff9f00"
                                  : row.minus50 < 0.9
                                  ? "red"
                                  : "normal"
                                : "normal",
                            fontWeight:
                              ind === rows1.length - 1 ? "bold" : "normal",
                          }}
                        >
                          {row.minus50}
                        </span>
                      ) : (
                        "-"
                      )}
                    </TableCell>
                    <TableCell>
                      {row.flat !== "-" ? (
                        <span
                          style={{
                            color:
                              ind === rows1.length - 1
                                ? row.flat >= 1.0
                                  ? "green"
                                  : row.flat >= 0.9 && row.flat < 1.0
                                  ? "#ff9f00"
                                  : row.flat < 0.9
                                  ? "red"
                                  : "normal"
                                : "normal",
                            fontWeight:
                              ind === rows1.length - 1 ? "bold" : "normal",
                          }}
                        >
                          {row.flat}
                        </span>
                      ) : (
                        "-"
                      )}
                    </TableCell>
                    <TableCell>
                      {row.plus50 !== "-" ? (
                        <span
                          style={{
                            color:
                              ind === rows1.length - 1
                                ? row.plus50 >= 1.0
                                  ? "green"
                                  : row.plus50 >= 0.9 && row.plus50 < 1.0
                                  ? "#ff9f00"
                                  : row.plus50 < 0.9
                                  ? "red"
                                  : "normal"
                                : "normal",
                            fontWeight:
                              ind === rows1.length - 1 ? "bold" : "normal",
                          }}
                        >
                          {row.plus50}
                        </span>
                      ) : (
                        "-"
                      )}
                    </TableCell>
                    <TableCell>
                      {row.plus100 !== "-" ? (
                        <span
                          style={{
                            color:
                              ind === rows1.length - 1
                                ? row.plus100 >= 1.0
                                  ? "green"
                                  : row.plus100 >= 0.9 && row.plus100 < 1.0
                                  ? "#ff9f00"
                                  : row.plus100 < 0.9
                                  ? "red"
                                  : "normal"
                                : "normal",
                            fontWeight:
                              ind === rows1.length - 1 ? "bold" : "normal",
                          }}
                        >
                          {row.plus100}
                        </span>
                      ) : (
                        "-"
                      )}
                    </TableCell>
                    <TableCell>
                      {row.plus150 !== "-" ? (
                        <span
                          style={{
                            color:
                              ind === rows1.length - 1
                                ? row.plus150 >= 1.0
                                  ? "green"
                                  : row.plus150 >= 0.9 && row.plus150 < 1.0
                                  ? "#ff9f00"
                                  : row.plus150 < 0.9
                                  ? "red"
                                  : "normal"
                                : "normal",
                            fontWeight:
                              ind === rows1.length - 1 ? "bold" : "normal",
                          }}
                        >
                          {row.plus150}
                        </span>
                      ) : (
                        "-"
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div style={{ margin: "2rem 0rem" }}>
          <div style={{ backgroundColor: "#111", padding: "0.3rem 0rem" }}>
            <h4 style={{ color: "#fff", margin: "0px", textAlign: "center" }}>
              Annual NOI Change (-1.5%)
            </h4>
          </div>
          <TableContainer>
            <Table
              sx={{ minWidth: 650 }}
              aria-label="simple table"
              classes={{ root: classes.customTable }}
              size="small"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Cap Rate</TableCell>
                  <TableCell>-50 bps</TableCell>
                  <TableCell>Flat</TableCell>
                  <TableCell>+50 bps</TableCell>
                  <TableCell>+100 bps</TableCell>
                  <TableCell>+150 bps</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows5.map((row, ind) => (
                  <TableRow key={ind}>
                    <TableCell>{row.capRate}</TableCell>
                    <TableCell>
                      {row.minus50 !== "-" ? (
                        <span
                          style={{
                            color:
                              ind === rows1.length - 1
                                ? row.minus50 >= 1.0
                                  ? "green"
                                  : row.minus50 >= 0.9 && row.minus50 < 1.0
                                  ? "#ff9f00"
                                  : row.minus50 < 0.9
                                  ? "red"
                                  : "normal"
                                : "normal",
                            fontWeight:
                              ind === rows1.length - 1 ? "bold" : "normal",
                          }}
                        >
                          {row.minus50}
                        </span>
                      ) : (
                        "-"
                      )}
                    </TableCell>
                    <TableCell>
                      {row.flat !== "-" ? (
                        <span
                          style={{
                            color:
                              ind === rows1.length - 1
                                ? row.flat >= 1.0
                                  ? "green"
                                  : row.flat >= 0.9 && row.flat < 1.0
                                  ? "#ff9f00"
                                  : row.flat < 0.9
                                  ? "red"
                                  : "normal"
                                : "normal",
                            fontWeight:
                              ind === rows1.length - 1 ? "bold" : "normal",
                          }}
                        >
                          {row.flat}
                        </span>
                      ) : (
                        "-"
                      )}
                    </TableCell>
                    <TableCell>
                      {row.plus50 !== "-" ? (
                        <span
                          style={{
                            color:
                              ind === rows1.length - 1
                                ? row.plus50 >= 1.0
                                  ? "green"
                                  : row.plus50 >= 0.9 && row.plus50 < 1.0
                                  ? "#ff9f00"
                                  : row.plus50 < 0.9
                                  ? "red"
                                  : "normal"
                                : "normal",
                            fontWeight:
                              ind === rows1.length - 1 ? "bold" : "normal",
                          }}
                        >
                          {row.plus50}
                        </span>
                      ) : (
                        "-"
                      )}
                    </TableCell>
                    <TableCell>
                      {row.plus100 !== "-" ? (
                        <span
                          style={{
                            color:
                              ind === rows1.length - 1
                                ? row.plus100 >= 1.0
                                  ? "green"
                                  : row.plus100 >= 0.9 && row.plus100 < 1.0
                                  ? "#ff9f00"
                                  : row.plus100 < 0.9
                                  ? "red"
                                  : "normal"
                                : "normal",
                            fontWeight:
                              ind === rows1.length - 1 ? "bold" : "normal",
                          }}
                        >
                          {row.plus100}
                        </span>
                      ) : (
                        "-"
                      )}
                    </TableCell>
                    <TableCell>
                      {row.plus150 !== "-" ? (
                        <span
                          style={{
                            color:
                              ind === rows1.length - 1
                                ? row.plus150 >= 1.0
                                  ? "green"
                                  : row.plus150 >= 0.9 && row.plus150 < 1.0
                                  ? "#ff9f00"
                                  : row.plus150 < 0.9
                                  ? "red"
                                  : "normal"
                                : "normal",
                            fontWeight:
                              ind === rows1.length - 1 ? "bold" : "normal",
                          }}
                        >
                          {row.plus150}
                        </span>
                      ) : (
                        "-"
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div style={{ margin: "2rem 0rem" }}>
          <div style={{ backgroundColor: "#111", padding: "0.3rem 0rem" }}>
            <h4 style={{ color: "#fff", margin: "0px", textAlign: "center" }}>
              Annual NOI Change (-3%)
            </h4>
          </div>
          <TableContainer>
            <Table
              sx={{ minWidth: 650 }}
              aria-label="simple table"
              classes={{ root: classes.customTable }}
              size="small"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Cap Rate</TableCell>
                  <TableCell>-50 bps</TableCell>
                  <TableCell>Flat</TableCell>
                  <TableCell>+50 bps</TableCell>
                  <TableCell>+100 bps</TableCell>
                  <TableCell>+150 bps</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows6.map((row, ind) => (
                  <TableRow key={ind}>
                    <TableCell>{row.capRate}</TableCell>
                    <TableCell>
                      {row.minus50 !== "-" ? (
                        <span
                          style={{
                            color:
                              ind === rows1.length - 1
                                ? row.minus50 >= 1.0
                                  ? "green"
                                  : row.minus50 >= 0.9 && row.minus50 < 1.0
                                  ? "#ff9f00"
                                  : row.minus50 < 0.9
                                  ? "red"
                                  : "normal"
                                : "normal",
                            fontWeight:
                              ind === rows1.length - 1 ? "bold" : "normal",
                          }}
                        >
                          {row.minus50}
                        </span>
                      ) : (
                        "-"
                      )}
                    </TableCell>
                    <TableCell>
                      {row.flat !== "-" ? (
                        <span
                          style={{
                            color:
                              ind === rows1.length - 1
                                ? row.flat >= 1.0
                                  ? "green"
                                  : row.flat >= 0.9 && row.flat < 1.0
                                  ? "#ff9f00"
                                  : row.flat < 0.9
                                  ? "red"
                                  : "normal"
                                : "normal",
                            fontWeight:
                              ind === rows1.length - 1 ? "bold" : "normal",
                          }}
                        >
                          {row.flat}
                        </span>
                      ) : (
                        "-"
                      )}
                    </TableCell>
                    <TableCell>
                      {row.plus50 !== "-" ? (
                        <span
                          style={{
                            color:
                              ind === rows1.length - 1
                                ? row.plus50 >= 1.0
                                  ? "green"
                                  : row.plus50 >= 0.9 && row.plus50 < 1.0
                                  ? "#ff9f00"
                                  : row.plus50 < 0.9
                                  ? "red"
                                  : "normal"
                                : "normal",
                            fontWeight:
                              ind === rows1.length - 1 ? "bold" : "normal",
                          }}
                        >
                          {row.plus50}
                        </span>
                      ) : (
                        "-"
                      )}
                    </TableCell>
                    <TableCell>
                      {row.plus100 !== "-" ? (
                        <span
                          style={{
                            color:
                              ind === rows1.length - 1
                                ? row.plus100 >= 1.0
                                  ? "green"
                                  : row.plus100 >= 0.9 && row.plus100 < 1.0
                                  ? "#ff9f00"
                                  : row.plus100 < 0.9
                                  ? "red"
                                  : "normal"
                                : "normal",
                            fontWeight:
                              ind === rows1.length - 1 ? "bold" : "normal",
                          }}
                        >
                          {row.plus100}
                        </span>
                      ) : (
                        "-"
                      )}
                    </TableCell>
                    <TableCell>
                      {row.plus150 !== "-" ? (
                        <span
                          style={{
                            color:
                              ind === rows1.length - 1
                                ? row.plus150 >= 1.0
                                  ? "green"
                                  : row.plus150 >= 0.9 && row.plus150 < 1.0
                                  ? "#ff9f00"
                                  : row.plus150 < 0.9
                                  ? "red"
                                  : "normal"
                                : "normal",
                            fontWeight:
                              ind === rows1.length - 1 ? "bold" : "normal",
                          }}
                        >
                          {row.plus150}
                        </span>
                      ) : (
                        "-"
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div style={{ margin: "2rem 0rem" }}>
          <div style={{ backgroundColor: "#111", padding: "0.3rem 0rem" }}>
            <h4 style={{ color: "#fff", margin: "0px", textAlign: "center" }}>
              Annual NOI Change (-4.5%)
            </h4>
          </div>
          <TableContainer>
            <Table
              sx={{ minWidth: 650 }}
              aria-label="simple table"
              classes={{ root: classes.customTable }}
              size="small"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Cap Rate</TableCell>
                  <TableCell>-50 bps</TableCell>
                  <TableCell>Flat</TableCell>
                  <TableCell>+50 bps</TableCell>
                  <TableCell>+100 bps</TableCell>
                  <TableCell>+150 bps</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows7.map((row, ind) => (
                  <TableRow key={ind}>
                    <TableCell>{row.capRate}</TableCell>
                    <TableCell>
                      {row.minus50 !== "-" ? (
                        <span
                          style={{
                            color:
                              ind === rows1.length - 1
                                ? row.minus50 >= 1.0
                                  ? "green"
                                  : row.minus50 >= 0.9 && row.minus50 < 1.0
                                  ? "#ff9f00"
                                  : row.minus50 < 0.9
                                  ? "red"
                                  : "normal"
                                : "normal",
                            fontWeight:
                              ind === rows1.length - 1 ? "bold" : "normal",
                          }}
                        >
                          {row.minus50}
                        </span>
                      ) : (
                        "-"
                      )}
                    </TableCell>
                    <TableCell>
                      {row.flat !== "-" ? (
                        <span
                          style={{
                            color:
                              ind === rows1.length - 1
                                ? row.flat >= 1.0
                                  ? "green"
                                  : row.flat >= 0.9 && row.flat < 1.0
                                  ? "#ff9f00"
                                  : row.flat < 0.9
                                  ? "red"
                                  : "normal"
                                : "normal",
                            fontWeight:
                              ind === rows1.length - 1 ? "bold" : "normal",
                          }}
                        >
                          {row.flat}
                        </span>
                      ) : (
                        "-"
                      )}
                    </TableCell>
                    <TableCell>
                      {row.plus50 !== "-" ? (
                        <span
                          style={{
                            color:
                              ind === rows1.length - 1
                                ? row.plus50 >= 1.0
                                  ? "green"
                                  : row.plus50 >= 0.9 && row.plus50 < 1.0
                                  ? "#ff9f00"
                                  : row.plus50 < 0.9
                                  ? "red"
                                  : "normal"
                                : "normal",
                            fontWeight:
                              ind === rows1.length - 1 ? "bold" : "normal",
                          }}
                        >
                          {row.plus50}
                        </span>
                      ) : (
                        "-"
                      )}
                    </TableCell>
                    <TableCell>
                      {row.plus100 !== "-" ? (
                        <span
                          style={{
                            color:
                              ind === rows1.length - 1
                                ? row.plus100 >= 1.0
                                  ? "green"
                                  : row.plus100 >= 0.9 && row.plus100 < 1.0
                                  ? "#ff9f00"
                                  : row.plus100 < 0.9
                                  ? "red"
                                  : "normal"
                                : "normal",
                            fontWeight:
                              ind === rows1.length - 1 ? "bold" : "normal",
                          }}
                        >
                          {row.plus100}
                        </span>
                      ) : (
                        "-"
                      )}
                    </TableCell>
                    <TableCell>
                      {row.plus150 !== "-" ? (
                        <span
                          style={{
                            color:
                              ind === rows1.length - 1
                                ? row.plus150 >= 1.0
                                  ? "green"
                                  : row.plus150 >= 0.9 && row.plus150 < 1.0
                                  ? "#ff9f00"
                                  : row.plus150 < 0.9
                                  ? "red"
                                  : "normal"
                                : "normal",
                            fontWeight:
                              ind === rows1.length - 1 ? "bold" : "normal",
                          }}
                        >
                          {row.plus150}
                        </span>
                      ) : (
                        "-"
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
  );
};
export default NoiCapRatePrint;
