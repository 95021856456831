import React from "react";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";

const COLORS = ["#81abcc82", "#cec9c9"];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
  payload,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  let fill = "#333";
  let textAnchor = x > cx ? "start" : "end";
  if (payload.name === "Total Tax") {
    textAnchor = "middle";
  } else if (payload.name === "Available") {
    textAnchor = "middle";
  }

  return (
    <text
      x={x}
      y={y}
      fill={fill}
      textAnchor={textAnchor}
      dominantBaseline="central"
    >
      <tspan
        x={x}
        y={y - 10}
        fontSize="15px"
      >{`$${payload.value.toLocaleString()}`}</tspan>
      <tspan x={x} y={y + 10} fontSize="12px" fontWeight="bold">
        {payload.name}
      </tspan>
    </text>
  );
};

const Chart = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart width={400} height={400}>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          labelLine={false}
          label={renderCustomizedLabel}
          outerRadius={80}
          fill="#8884d8"
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

export default Chart;
