import React, { useCallback, useContext, useEffect, useState } from "react";
import { Grid, MenuItem } from "@material-ui/core";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import MyContext from "../context/appContext";
import { createTheme } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router-dom";
import { API } from "aws-amplify";
import { toast } from "react-toastify";
import { Alert } from "@mui/material";
import { debounce } from "lodash";

const TaxEstimator = ({}) => {
  const navigate = useNavigate();
  const appContext = useContext(MyContext);
  const [loading, setLoading] = useState(false);
  const [stateList, setStateList] = useState([]);
  const [error, setError] = useState(false);
  const { state } = useLocation();
  const [outputNotify, setOutputNotify] = useState(false);
  const [capexWarning, setCapexWarning] = useState(false);
  const customPropValue = state?.customProp;
  const [formData, setFormData] = useState({
    clientName: "",
    interviewDate: "",
    reportDate: "",
    streetAddress: "",
    stateZipAddress: "",
    clientStateResidance: "",
    propertyState: "",
    msa: "",
    submarket: "",
    residentialOrCommercial: "",
    propertyType: "",
    descriptiveNotes: "",
    estimatedGrossValue: "",
    expectedSale: "",
    adjustedbasis: "",
    rentableUnits: "",
    rentableSquareFeet: "",
    purchaseDate: "",
    purchaseAmount: "",
    forwardlosses: "",
    amountBorrowed: "",
    refinanceDate: "",
    interestRate: "",
    loanTerms: "",
    loanBalance: "",
    mortgagepayment: "",
    propertyManagement: "",
    principal: "",
    depreciationClaimed: "",
    cumulativeDepreciation: "",
    valueImprovements: "",
    capexSincePurchase: "",
    grossRent: "",
    reimbursements: "",
    water: "",
    trash: "",
    landscape: "",
    facilityMaintenance: "",
    security: "",
    reimbursedUtility: "",
    hoaFee: "",
    associationDues: "",
    majorRepairs: "",
    OtherMisc: "",
    personalhoursSpent: "",
    bookkeeping: "",
    marketing: "",
    issurance: "",
    annualPropertyTax: "",
    legal: "",
    eachUnitVacany: "",
    unitTurnOver: "",
    tenants: "",
    roof: "",
    roofYears: "",
    plumbing: "",
    plumbingYears: "",
    hvac: "",
    hvacYears: "",
    hardscaping: "",
    hardscapingYears: "",
    structural: "",
    structuralYears: "",
    allOther: "",
    otherYears: "",
    fetchedPrincipal: false,
  });

  const [errors, setErrors] = useState({
    clientName: false,
    reportDate: false,
    streetAddress: false,
    clientStateResidance: false,
    propertyState: false,
    residentialOrCommercial: false,
    estimatedGrossValue: false,
    expectedSale: false,
    rentableUnits: false,
    purchaseDate: false,
    purchaseAmount: false,
    forwardlosses: false,
    amountBorrowed: false,
    refinanceDate: false,
    interestRate: false,
    loanTerms: false,
    loanBalance: false,
    mortgagepayment: false,
    principal: false,
    depreciationClaimed: false,
    valueImprovements: false,
    capexSincePurchase: false,
    grossRent: false,
    rentableUnits: false,
  });
  const domRef = React.useRef();

  useEffect(() => {
    getStates();
  }, []);

  const applyMask = (fieldName, inputVal) => {
    const fieldsToMask = [
      "estimatedGrossValue",
      "adjustedbasis",
      "purchasePrice",
      "purchaseAmount",
      "forwardlosses",
      "amountBorrowed",
      "loanBalance",
      "mortgagepayment",
      "principal",
      "capexSincePurchase",
      "grossRent",
      "reimbursements",
      "propertyManagement",
      "water",
      "trash",
      "landscape",
      "facilityMaintenance",
      "security",
      "reimbursedUtility",
      "hoaFee",
      "associationDues",
      "majorRepairs",
      "OtherMisc",
      "bookkeeping",
      "marketing",
      "issurance",
      "annualPropertyTax",
      "legal",
      "tenants",
      "roof",
      "plumbing",
      "hvac",
      "hardscaping",
      "structural",
      "allOther",
      "cumulativeDepreciation",
    ];

    if (fieldsToMask.includes(fieldName)) {
      const cleanInput = inputVal.replace(/,/g, "").replace(/\.(?=.*\.)/g, "");
      const [wholePart, decimalPart] = cleanInput.split(".");
      const formattedWholePart = wholePart.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ","
      );

      if (decimalPart !== undefined) {
        return `${formattedWholePart}.${decimalPart}`;
      } else {
        return formattedWholePart;
      }
    } else {
      return inputVal;
    }
  };

  useEffect(() => {
    const storedFormState = sessionStorage.getItem("formState");

    if (storedFormState) {
      const { cashFlow } = JSON.parse(storedFormState);
      setFormData(cashFlow);
    }
  }, [customPropValue]);

  const handleChange = (e) => {
    setOutputNotify(false);
    setError(false);
    const inputVal = e.target.value;
    const fieldName = e.target.name;
    const maskedInput = applyMask(fieldName, inputVal);

    if (fieldName === 'capexSincePurchase' || fieldName === 'adjustedbasis') {
      const otherField = fieldName === 'capexSincePurchase' ? 'adjustedbasis' : 'capexSincePurchase';
      if (inputVal && formData[otherField]) {
        setCapexWarning(true);
        return;
      } else {
        setCapexWarning(false);
      }
    }

    if (["principal", "mortgagepayment", "loanBalance"].includes(fieldName)) {
      setFormData((prevFormData) => {
        const newFormData = {
          ...prevFormData,
          [fieldName]: maskedInput,
          fetchedPrincipal: false,
        };
        handleSessionState(newFormData);
        return newFormData;
      });
    } else {
      setFormData((prevFormData) => {
        const newFormData = {
          ...prevFormData,
          [fieldName]: maskedInput,
        };
        handleSessionState(newFormData);
        return newFormData;
      });
    }
    if (inputVal.trim() !== "") {
      setErrors({
        ...errors,
        [fieldName]: false,
      });
    }
  };

  const debouncedSaveSession = useCallback(
    debounce((newFormData) => {
      sessionStorage.setItem(
        "formState",
        JSON.stringify({ cashFlow: newFormData })
      );
    }, 300),
    []
  );

  const handleSessionState = (newFormData) => {
    debouncedSaveSession(newFormData);
  };

  const onSessionClear = async () => {
    sessionStorage.clear("formState");
    setFormData((prevFormData) => {
      const clearedState = Object.fromEntries(
        Object.keys(prevFormData).map((key) => [key, ""])
      );
      return clearedState;
    });
  };

  const onSubmitTax = async () => {
    const requiredFields = [
      "clientName",
      "reportDate",
      "streetAddress",
      "streetAddress",
      "residentialOrCommercial",
      "estimatedGrossValue",
      "expectedSale",
      "purchaseDate",
    ];
    try {
      appContext.updateState("loading", true);

      let hasErrors = false;
      const newErrors = {};
      requiredFields.forEach((field) => {
        if (formData[field].trim() === "") {
          newErrors[field] = true;
          hasErrors = true;
        }
      });
      setErrors(newErrors);

      if (hasErrors) {
        appContext.updateState("loading", false);
        return;
      }

      const formState = {
        cashFlow: { ...formData },
      };
      sessionStorage.setItem("formState", JSON.stringify(formState));
      sessionStorage.setItem("domRef", domRef.current.innerHTML);
      navigate("/tax-report", {
        state: {
          formData,
        },
      });
      appContext.updateState("loading", false);
    } catch (error) {
      appContext.updateState("loading", false);
      toast(`Error posting Tax Analysis! ${error.message}`, {
        type: "error",
      });
    }
  };

  const getStates = async () => {
    try {
      const payload = {
        queryStringParameters: {
          tableName: "state_info-dev",
          id: "all",
          LastEvaluatedKey: "None",
        },
      };
      const res = await API.get("dynamoRW", "/dsts", payload);
      setStateList(res);
    } catch (error) {
      console.log(error.message);
      toast(`No State Found! ${error.message}`, { type: "error" });
    } finally {
      setLoading(false);
    }
  };

  const stateOption = stateList
    ?.map((st) => ({
      state: st.state,
      code: st.code,
    }))
    .sort((a, b) => a.state.localeCompare(b.state));

  return (
    <div ref={domRef} style={{ padding: "0px 20px" }}>
      <Box sx={{ width: "100%" }}>
        <Container component="main">
          <CssBaseline />
          <Box>
            <h3 className="hide-on-print" style={{ margin: "2rem 0rem" }}>
              Tax Estimator Questionnaire
            </h3>
            <Grid container sx={{ py: 2 }} spacing={2}>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  error={errors.clientName}
                  size="small"
                  margin="normal"
                  fullWidth
                  name="clientName"
                  value={formData.clientName}
                  onChange={handleChange}
                  label="Client Name"
                  InputProps={{
                    style: { backgroundColor: "#e1f2fa" },
                  }}
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  error={errors.reportDate}
                  value={formData.reportDate}
                  size="small"
                  margin="normal"
                  fullWidth
                  name={"reportDate"}
                  onChange={(e) => {
                    setFormData((prevFormData) => {
                      const newFormData = {
                        ...prevFormData,
                        reportDate: e.target.value,
                      };
                      handleSessionState(newFormData);
                      return newFormData;
                    });
                    setErrors({
                      ...errors,
                      reportDate: false,
                    });
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    style: { backgroundColor: "#e1f2fa" },
                  }}
                  type="date"
                  label="Report Date"
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  error={errors.streetAddress}
                  size="small"
                  margin="normal"
                  fullWidth
                  name={"streetAddress"}
                  value={formData.streetAddress}
                  InputProps={{
                    style: { backgroundColor: "#e1f2fa" },
                  }}
                  onChange={handleChange}
                  label={"Rental Property Street Address"}
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  value={formData.propertyState}
                  error={errors.propertyState}
                  select
                  size="small"
                  margin="normal"
                  fullWidth
                  name="propertyState"
                  onChange={(e) => {
                    setFormData((prevFormData) => {
                      const newFormData = {
                        ...prevFormData,
                        propertyState: e.target.value,
                      };
                      handleSessionState(newFormData);
                      return newFormData;
                    });
                    setErrors({
                      ...errors,
                      propertyState: false,
                    });
                  }}
                  label="Select Property State"
                  InputProps={{
                    style: { backgroundColor: "#e1f2fa" },
                  }}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        style: {
                          maxHeight: "300px",
                        },
                      },
                    },
                  }}
                >
                  <MenuItem value="" key="">
                    Clear
                  </MenuItem>
                  {stateOption?.map((option) => (
                    <MenuItem
                      key={option.code}
                      value={`${option.state} - ${option.code}`}
                    >
                      {`${option.state} - ${option.code}`}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  value={formData.clientStateResidance}
                  error={errors.clientStateResidance}
                  select
                  size="small"
                  margin="normal"
                  fullWidth
                  name="clientStateResidance"
                  onChange={(e) => {
                    setFormData((prevFormData) => {
                      const newFormData = {
                        ...prevFormData,
                        clientStateResidance: e.target.value,
                      };
                      handleSessionState(newFormData);
                      return newFormData;
                    });
                    setErrors({
                      ...errors,
                      clientStateResidance: false,
                    });
                  }}
                  label="Select Client State of Residence"
                  InputProps={{
                    style: { backgroundColor: "#e1f2fa" },
                  }}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        style: {
                          maxHeight: "300px",
                        },
                      },
                    },
                  }}
                >
                  <MenuItem value="" key="">
                    Clear
                  </MenuItem>
                  {stateOption?.map((option) => (
                    <MenuItem
                      key={option.code}
                      value={`${option.state} - ${option.code}`}
                    >
                      {`${option.state} - ${option.code}`}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
            <h3>Property Description and History</h3>
            <Grid container sx={{ py: 2 }} spacing={2}>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  error={errors.estimatedGrossValue}
                  size="small"
                  margin="normal"
                  fullWidth
                  value={formData.estimatedGrossValue}
                  InputProps={{
                    style: { backgroundColor: "#e1f2fa" },
                  }}
                  onChange={handleChange}
                  name={"estimatedGrossValue"}
                  label={`Client's estimated gross value of the property`}
                />
              </Grid>

              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  error={errors.expectedSale}
                  size="small"
                  margin="normal"
                  fullWidth
                  name={"expectedSale"}
                  value={formData.expectedSale}
                  InputProps={{
                    style: { backgroundColor: "#e1f2fa" },
                  }}
                  onChange={handleChange}
                  label={`Expected sale costs (%)`}
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  error={errors.purchaseAmount}
                  size="small"
                  margin="normal"
                  fullWidth
                  onChange={handleChange}
                  name={"purchaseAmount"}
                  InputProps={{
                    style: { backgroundColor: "#e1f2fa" },
                  }}
                  value={formData.purchaseAmount}
                  label={`Est. purchase amount`}
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  value={formData.residentialOrCommercial}
                  error={errors.residentialOrCommercial}
                  size="small"
                  select
                  margin="normal"
                  fullWidth
                  name={"residentialOrCommercial"}
                  onChange={(e) => {
                    setFormData((prevFormData) => {
                      const newFormData = {
                        ...prevFormData,
                        residentialOrCommercial: e.target.value,
                      };
                      handleSessionState(newFormData);
                      return newFormData;
                    });
                    setErrors({
                      ...errors,
                      residentialOrCommercial: false,
                    });
                  }}
                  InputProps={{
                    style: { backgroundColor: "#e1f2fa" },
                  }}
                  label={"Residential or Commercial"}
                >
                  <MenuItem value="" key="">
                    Clear
                  </MenuItem>
                  {["residential", "commercial"].map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  error={errors.purchaseDate}
                  value={formData.purchaseDate}
                  size="small"
                  margin="normal"
                  fullWidth
                  name={"purchaseDate"}
                  onChange={(e) => {
                    setFormData((prevFormData) => {
                      const newFormData = {
                        ...prevFormData,
                        purchaseDate: e.target.value,
                      };
                      handleSessionState(newFormData);
                      return newFormData;
                    });
                    setErrors({
                      ...errors,
                      purchaseDate: false,
                    });
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    style: { backgroundColor: "#e1f2fa" },
                  }}
                  type="date"
                  label="Est. purchase date"
                />
              </Grid>
            </Grid>

            <h3>Loan</h3>
            <Grid container sx={{ py: 2 }} spacing={2}>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  error={errors.loanBalance}
                  size="small"
                  margin="normal"
                  fullWidth
                  name={"loanBalance"}
                  value={formData.loanBalance}
                  InputProps={{
                    style: { backgroundColor: "#e1f2fa" },
                  }}
                  onChange={handleChange}
                  label={`Est. current loan balance`}
                />
              </Grid>
              <Grid item xs={8} className="Grid-Gap">
              <p >If loan balance is not known, enter data into <b>purple fields</b> in <b>Cash Flow Analysis</b> to estimate loan balance</p>
              </Grid>
            </Grid>
            <h3>Depreciation</h3>
            <p style={{margin:"0px"}}>Does client know:</p>
            <Grid container sx={{ py: 2 }} spacing={2}>
            <Grid item xs={4} className="Grid-Gap">
                <TextField
                  error={errors.cumulativeDepreciation}
                  size="small"
                  margin="normal"
                  fullWidth
                  name={"cumulativeDepreciation"}
                  value={formData.cumulativeDepreciation}
                  onChange={handleChange}
                  label={`Total cumulative depreciation claimed`}
                  InputProps={{
                    style: { backgroundColor: "#e1f2fa" },
                  }}
                />
              </Grid>
              <Grid item xs={12} className="Grid-Gap">
              <p>If client knows total cumulative depreciation, continue to <b>Capex</b>.</p>
              </Grid>
              <Grid item xs={7} className="Grid-Gap">
              <p style={{backgroundColor:"#D9D9D9", marginTop:"0px", padding:"7px"}}><b>If total cumulative depreciation is not known,</b> complete these two fields:</p>
              </Grid>
              <Grid container sx={{py:2}} spacing={2}>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  error={errors.depreciationClaimed}
                  size="small"
                  margin="normal"
                  fullWidth
                  name={"depreciationClaimed"}
                  value={formData.depreciationClaimed}
                  onChange={handleChange}
                  label={`Est. years of depreciation claimed`}
                  InputProps={{
                    style: { backgroundColor: "#e6e6fa" },
                  }}
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  error={errors.valueImprovements}
                  size="small"
                  margin="normal"
                  fullWidth
                  value={formData.valueImprovements}
                  name={"valueImprovements"}
                  onChange={handleChange}
                  label={`Est.% value of improvements`}
                  helperText={"Default= 50%"}
                  InputProps={{
                    style: { backgroundColor: "#e6e6fa" },
                  }}
                />
              </Grid>
              </Grid>
            </Grid>
            <h3>Capex</h3>
            <p style={{margin:"0px"}}>Enter estimated capital expenditures since purchase <b>–OR–</b> Adjusted basis, if known</p>
            {capexWarning && (
        <Alert severity="warning" style={{margin: '10px 0px'}}>
          Please enter data in only one of these fields: Est. capex since purchase OR Adjusted basis.
        </Alert>
      )}
            <Grid container sx={{ py: 2 }} spacing={2}>
            <Grid item xs={4} className="Grid-Gap">
                <TextField
                  error={errors.capexSincePurchase}
                  size="small"
                  margin="normal"
                  fullWidth
                  name={"capexSincePurchase"}
                  value={formData.capexSincePurchase}
                  onChange={handleChange}
                  label={`Est. capex since purchase`}
                  InputProps={{
                    style: { backgroundColor: "#e1f2fa" },
                  }}
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  size="small"
                  margin="normal"
                  fullWidth
                  value={formData.adjustedbasis}
                  onChange={handleChange}
                  name={"adjustedbasis"}
                  label={`Adjusted basis, if known`}
                />
              </Grid>
            </Grid>
            <h3>Optional Adjustments</h3>
            <p style={{margin:"0px"}}>Optional Adjustments Use this field to factor in carried-forward losses <b>or</b> to apply the Sec. 121 exclusion of $250k or $500k</p>
            <Grid container sx={{ py: 2 }} spacing={2}>
            <Grid item xs={4} className="Grid-Gap">
                <TextField
                  error={errors.forwardlosses}
                  size="small"
                  margin="normal"
                  fullWidth
                  name={"forwardlosses"}
                  value={formData.forwardlosses}
                  onChange={handleChange}
                  label={`Carried-forward losses`}
                />
              </Grid>
            </Grid>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                width: "100%",
                marginTop: "1.5rem",
              }}
            >
              {outputNotify ? (
                <Alert severity="error">
                  Please fill the required fields for amortization calculations.
                </Alert>
              ) : null}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                width: "100%",
                marginBottom: "2rem",
              }}
            >
              <Button
                onClick={onSessionClear}
                variant="contained"
                sx={{ mt: 0, mb: 0, mr: 1, textTransform: "inherit" }}
              >
                Reset
              </Button>

              <Button
                onClick={onSubmitTax}
                variant="contained"
                sx={{ mt: 0, mb: 0, textTransform: "inherit" }}
              >
                Tax Illustration
              </Button>
            </div>
          </Box>
        </Container>
      </Box>
    </div>
  );
};

export default TaxEstimator;
